<template>
  <div class="inteBox">
    <intNavheader activeNameCheck="first" ></intNavheader>
    <div class="titleTopLine"></div>
    <div class="titleTopCenter noflex-l-s">
      <div class="titleTopCenterLeft">
        <waitNumber :waitDataTotalNumber="waitDataTotal"></waitNumber>
        <div class="titleTopCenterLeftBox" @click="reload">新建患者</div>
        <div style="padding: 0 20px" v-if="todayStepIndex == 1 && stepIndex == 1">
          <el-input placeholder="搜索今日患者" prefix-icon="el-icon-search" v-model="todayPatient" clearable
            @keyup.enter.native="getTodayPatientData">
            <el-button slot="append" class="search-btn" type="button" icon="el-icon-search"
              @click="getTodayPatientData"> </el-button>
          </el-input>
        </div>
        <!-- <div style="padding: 0 20px" v-if="todayStepIndex == 2">
          <el-input placeholder="搜索暂存列表" prefix-icon="el-icon-search" v-model="todayPatient1" clearable>
            <el-button slot="append" class="search-btn" type="button" icon="el-icon-search" @click="getMZDiagnoseListPatientData"> </el-button>
          </el-input>
        </div> -->
        <div v-if="stepIndex == 1">
          <div class="noflex-l-s">
            <div :class="todayStepIndex == 1 ? 'todayTitle' : 'todayTitleno'" @click="gotodayChange(1)"><i
                class="el-icon-user-solid"></i>今日就诊</div>
            <div :class="todayStepIndex == 2 ? 'todayTitle' : 'todayTitleno'" @click="gotodayChange(2)"><i
                class="el-icon-user-solid"></i>暂存列表 <span>({{ZCPatientList.length}})</span></div>
          </div>
          <div class="todayDetail" v-if="todayStepIndex == 1">
            <div class="todayTitleTh todayTitleThTtitle">
              <div class="thwidth">患者姓名</div>

              <div class="thwidth">最近就诊</div>
            </div>
            <div v-if="todayPatientList.length > 0">
              <div class="todayTitleTh todayTitleThDetail" v-for="(itemToday, indexToday) in todayPatientList"
                :key="indexToday" @click="goTodayDetail(itemToday)">
                <div class="thwidth">{{ itemToday.patientName }}<span style="color: red;" v-if="itemToday.severity == 1">（急）</span></div>

                <div class="thwidth" style="font-size: 14px">{{ itemToday.latestTime }}</div>
              </div>
            </div>
            <div v-else>
              <div class="todayTitleTh todayTitleThDetail">
                <div class="thwidth">暂无数据</div>
              </div>
            </div>
          </div>
          <div class="todayDetail" v-if="todayStepIndex == 2">
            <div class="todayTitleTh todayTitleThTtitle">
              <div class="thwidth">患者姓名</div>
              <div class="thwidth">最近就诊</div>
            </div>
            <div v-if="ZCPatientList.length > 0">
              <div class="todayTitleTh todayTitleThDetail" v-for="(itemzc, indexzc) in ZCPatientList" :key="indexzc"
                @click="goZcDetail(itemzc)">
                <div class="thwidth">{{ itemzc.patient.name }}</div>
                <div class="thwidth" style="font-size: 14px">{{ itemzc.addTime }}</div>
              </div>
              <div class="pageBox">
                <el-pagination background layout="prev, pager, next" :page-size="ZCpatientSize"
                  @current-change="ZChandleCurrentChange" :current-page.sync="ZCpatientcurrentPage"
                  :total="ZCpatientTotal"> </el-pagination>
              </div>
            </div>
            <div v-else>
              <div class="todayTitleTh todayTitleThDetail">
                <div class="thwidth">暂无数据</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="stepIndex > 1">
          <div class="flex-l-b titleTopLeftTable">
            <div :class="leftTable == 1 ? 'titleTopLeftTableC' : 'notitleTopLeftTableC'" @click="goleft2Patient(1)"><i
                class="el-icon-user-solid"></i>患者信息</div>
            <div :class="leftTable == 2 ? 'titleTopLeftTableC' : 'notitleTopLeftTableC'" @click="goleft2Patient(2)"><i
                class="el-icon-s-order"></i>患者历史</div>
          </div>
          <div v-if="leftTable == 1" class="titleTopLeftTable" style="border: none;padding-right:10px;padding-top:24px">
            <div class="flex-l">
              <div class="titleTopLeftTableTag"></div>
              <div style="color: #999999">患者基本信息</div>
            </div>
            <div style="margin-left: 15px; margin-top: 5px">
              <div></div>
              <div>
                <span>{{ patientDetail.name }}</span>，<span>{{ patientDetail.sex ? "男" : "女" }}</span>，<span>{{
                  patientDetail.age }}岁</span>
              </div>
              <div v-if="patientDetail.diseaseName">基础病:{{ patientDetail.diseaseName }}</div>
            </div>
            <div v-if="showCheckItemsList.length > 0" class="flex-l" style="margin-top: 10px;align-items: center;justify-content: space-between;">
              <div class="flex-l">
                <div class="titleTopLeftTableTag"></div>
                <div style="color: #999999">检查治疗项
                  <!-- <span :class="isPaystep2 ? 'payTrue':'nopay'">{{isPaystep2 ? '(已付费)':'(未付费)'}}</span> -->
                  <span v-if="isPaystep2" class="payTrue">(已付费)</span>
                </div>
              </div>
              <!-- <el-button  v-if="!isPaystep2" size="mini" type='primary' @click="goisPaystep2">付费</el-button> -->
            </div>
            <div class="flex-l-s checkItemsBox" style="margin-left: 15px; margin-top: 5px"
              v-for="(itemshowcheckItems, indexshow) in showCheckItemsList" :key="indexshow">
              <div>{{ itemshowcheckItems.checkItemValue.itemName }}</div>
              <div>{{ itemshowcheckItems.checkItemsNum }}次</div>
              <div class="nopay">￥{{ itemshowcheckItems.tradingPrice }}</div>
            </div>
            <div v-if="stepIndex > 2">
              <div class="flex-l" style="margin-top: 10px">
                <div class="titleTopLeftTableTag"></div>
                <div style="color: #999999">体格检查</div>
              </div>

              <div style="margin-left: 15px; margin-top: 5px">
                <div></div>
                <div class="flex-l" style="flex-wrap: wrap">
                  <div style="margin-right: 5px">
                    <span>体温：</span><span>{{ patientFormDes.temperature ? patientFormDes.temperature : "-" }} ℃</span>
                  </div>
                  <div style="margin-right: 5px">
                    <span>体重：</span><span>{{ patientFormDes.weight ? patientFormDes.weight : "-" }}kg</span>
                  </div>
                  <div style="margin-right: 5px">
                    <span>血压：</span><span>{{ patientFormDes.heightTension ? patientFormDes.heightTension : "-" }}/{{
                      patientFormDes.lowTension ? patientFormDes.lowTension : "-" }}mmHg</span>
                  </div>
                </div>
              </div>
              <div style="margin-left: 15px; margin-top: 5px">
                <div></div>
                <div class="flex-l" style="flex-wrap: wrap">
                  <div style="margin-right: 5px">
                    <span>身高：</span><span>{{ patientFormDes.height ? patientFormDes.height : "-" }}CM</span>
                  </div>
                  <div style="margin-right: 5px">
                    <span>呼吸：</span><span>{{ patientFormDes.breathe ? patientFormDes.breathe : "-" }}次/分</span>
                  </div>
                  <div style="margin-right: 5px">
                    <span>脉搏：</span><span>{{ patientFormDes.pulse ? patientFormDes.pulse : "-" }}次/分</span>
                  </div>
                </div>
              </div>
              <div class="flex-l" style="margin-top: 10px">
                <div class="titleTopLeftTableTag"></div>
                <div style="color: #999999">诊断</div>
              </div>
              <div style="margin-left: 15px; margin-top: 5px">
                <div></div>
                <div>
                  <span v-for="(item, index) in patientFormDes.diseaseDiagnose" :key="index">{{ item }}</span>
                </div>
              </div>
              <div class="flex-l" style="margin-top: 10px">
                <div class="titleTopLeftTableTag"></div>
                <div style="color: #999999">病史</div>
              </div>
              <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                <div class="titleTopLeftTableTag1"></div>
                <div style="color: #999999">家族病史</div>
              </div>
              <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                <div>{{ patientFormDes.family ? patientFormDes.family : "--" }}</div>
              </div>
              <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                <div class="titleTopLeftTableTag1"></div>
                <div style="color: #999999">既往史</div>
              </div>
              <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                <div>{{ patientFormDes.past ? patientFormDes.past : "--" }}</div>
              </div>
              <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                <div class="titleTopLeftTableTag1"></div>
                <div style="color: #999999">过敏史</div>
              </div>
              <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                <div>
                  <span>{{ patientFormDes.irritability ? patientFormDes.irritability : "--" }}</span>
                </div>
              </div>
              <div class="flex-l" style="margin-top: 10px">
                <div class="titleTopLeftTableTag"></div>
                <div style="color: #999999">主诉</div>
              </div>
              <div style="margin-left: 15px; margin-top: 5px">
                <div></div>
                <div>
                  <span>{{ patientFormDes.main ? patientFormDes.main : "--" }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="leftTable == 2">
            <div class="todayDetail" style="height: 400px; overflow-y: auto">
              <div class="todayTitleTh todayTitleThTtitle">
                <div class="thwidthL">就诊时间</div>
                <div class="thwidthL">诊断结果</div>
              </div>
              <div v-if="patientDetail.patientDiagnose.length != 0">
                <div
                  :class="diseaseIndex == index ? 'todayTitleTh todayTitleThDetail titleTopLeftTableC' : 'todayTitleTh todayTitleThDetail notitleTopLeftTableC'"
                  style="padding: 10px 5px" v-for="(item, index) in patientDetail.patientDiagnose" :key="index"
                  @click="godetailNext(item, index)">
                  <div class="thwidthL">{{ item.addTime }}</div>
                  <div class="thwidthL2">{{ item.diseaseDiagnose }}</div>
                </div>
              </div>

              <div v-else class="todayTitleTh todayTitleThDetail titleTopLeftTableC" style="padding: 10px 5px">暂无数据
              </div>
            </div>
            <div class="thirdrightBox" v-if="patientDetail.patientDiagnose.length != 0">
              <div class="flex-l-b">
                <div class="flex-l">
                  <div style="color: #333333;font-weight: bold;">诊断</div>
                </div>
                <div>
                  <el-button size="mini">复诊{{ visitData.length - 1 }}</el-button>
                </div>
              </div>
              <div class="flex-l" style="margin-top:10px;justify-content: flex-end;">
                <el-button size="mini" type='primary' @click='addVisit'>添加复诊</el-button>
                <!-- <el-button size="mini" type='primary' @click='adddiseaseDiagnose'>添加诊断</el-button> -->
              </div>
              <div>
                <div v-for="(item, index) in visitData" :key="index" @click="govisit(item, index)">
                  <div class="flex-l" style="margin-top: 10px">
                    <div class="titleTopLeftTableTag"></div>
                    <div style="color: #999999">{{ index == 0 ? '初诊' : '复诊' + index }}</div>
                  </div>
                  <div
                    :class="patientDiagnoseIndex == index ? 'diseaseIndexBox diseaseIndexBoxPot' : 'diseaseIndexBoxPot'">
                    {{ item.diseaseDiagnose }}
                  </div>
                  <!-- <div :class="patientDiagnoseIndex == diseaseIndex ? 'diseaseIndexBox' : ''">
                    <i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px" @click="goAdddiseaseDiagnose(patientDetail.patientDiagnose[diseaseIndex].diseaseDiagnose)"></i>
                    {{ patientDetail.patientDiagnose[diseaseIndex].diseaseDiagnose }}
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div style="text-align: center" v-if="stepIndex == 2">
            <el-button type="primary" round @click="goCheckItems">检查治疗项</el-button>
          </div>
        </div>
      </div>
      <div class="titleTopCenterCenter">
        <div class="titleTopCenterCenterTop flex-l-s">
          <!-- @click="goNextStep(1)" -->
          <div class="flex-l hardClick">
            <div :class="stepIndex > 0 ? 'sortBox' : 'nosortBox'">01</div>
            <div :class="stepIndex > 0 ? 'sortBoximg' : 'nosortBoximg'">填写患者信息</div>
          </div>
          <!-- @click="goNextStep(2)" -->
          <div class="flex-l hardClick">
            <div :class="stepIndex > 1 ? 'sortBox' : 'nosortBox'">02</div>
            <div :class="stepIndex > 1 ? 'sortBoximg' : 'nosortBoximg'">中医诊断</div>
          </div>
          <!-- @click="goNextStep(3)" -->
          <div class="flex-l hardClick">
            <div :class="stepIndex > 2 ? 'sortBox' : 'nosortBox'">03</div>
            <div :class="stepIndex > 2 ? 'sortBoximg' : 'nosortBoximg'">开具处方</div>
          </div>
        </div>
        <div class="titleTopCenterCenterContent" v-if="stepIndex == 1">
          <div class="Content1Title" style="padding-left: 20%">基本资料</div>
          <div class="Content1Title" style="padding: 10px 20%">
            <el-form :model="patientForm" ref="patientForm" :rules="patientFormRules" label-position="top">
              <div class="flex-l" style="width: 100%">
                <el-form-item label="姓名" prop="name" style="width: 50%">
                  <el-input v-model="patientForm.name"></el-input>
                </el-form-item>
                <el-form-item label="年龄" prop="age" style="width: 36%;margin-left: 20px">
                  <div class="flex-l">
                    <el-input  style="width: 40%; margin-right: 10px" v-model="patientForm.age" :controls='false' :min="1" :max="100" onkeyup="this.value=this.value.replace(/\D/g,'')"
                    onafterpaste="this.value=this.value.replace(/\D/g,'')"></el-input>
                    <!-- <el-input-number  style="width: 40%; margin-right: 10px" v-model="patientForm.age" :controls='false' :min="1" :max="100"></el-input-number > -->
                    <el-select v-model="ageValue" placeholder="请选择" style="width: 70px">
                      <el-option v-for="item in ageOptions" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <el-form-item label="性别" prop="sex">
                  <div class="flex-l-s" style="justify-content: end;" @click="goAddPatientSex(patientForm.sex)">
                    <div :class="patientForm.sex == true ? 'typeChecked' : 'notypeChecked'">男</div>
                    <div :class="patientForm.sex == false ? 'typeChecked' : 'notypeChecked'" style="margin-left: 10px">女
                    </div>
                  </div>
                </el-form-item>
              </div>
              <el-form-item label="身份证号" prop="idNo" style="margin-top: 10px">
                <div class="flex-l"><el-input class="" v-model="patientForm.idNo"
                    style="margin-right: 10px"></el-input><el-button type="primary"
                    @click.prevent="goIdDomain">读取身份证</el-button></div>
              </el-form-item>
              <el-form-item prop="phone" label="电话号码" style="margin-top: 10px" :rules="[
                { required: true, message: '请输入电话号码', trigger: 'blur' },
                { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: '请输入合法手机号/电话号', trigger: 'blur' },
              ]">
                <el-input v-model="patientForm.phone"></el-input>
              </el-form-item>
              <!-- <el-form-item label="地址" prop="address" :label-width="formLabelWidth" v-model="patientForm.address">
                <el-col :span="12">
                  <el-cascader style="width: 100%" clearable size="large" :options="regionDatas" ref="cascaderAddr"
                    :props="cateProps" v-model="patientForm.address" @change="handleChange"></el-cascader>
                </el-col>
                <el-col :span="12">
                  <el-input v-model="patientForm.address2" placeholder="请输入详细地址" clearable maxlength="20"
                    show-word-limit></el-input>
                </el-col>
              </el-form-item> -->
              <el-form-item label="地址" prop="address" style="margin-top: 10px">
                <el-input v-model="patientForm.address"></el-input>
              </el-form-item>
              <el-form-item label="基础病" prop="diseaseName" style="margin-top: 10px">
                <el-input v-model="patientForm.diseaseName"></el-input>
              </el-form-item>
              <el-form-item style="text-align: right; margin-top: 20px">
                <el-button type="primary" @click="refPatientForm">重置</el-button>
                <el-button v-if="patientForm.id" type="primary" @click="goaddPatientForm('patientForm')">下一步</el-button>
                <el-button v-else type="primary" @click="addPatientForm('patientForm')">下一步</el-button>
                <!-- <el-button type="primary" @click="golockScreen">锁屏</el-button> -->
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="titleTopCenterCenterContent" v-if="stepIndex == 2">
          <div class="Content1Title" style="padding-top: 10px">
            <el-form class="patientDiv" :model="patientFormDes" ref="patientFormDes" label-position="top">
              <div class="">症状诊断</div>
              <div class="flex-l-b">
                <el-form-item prop="main" label="主诉/现病史" style="width: 100%">
                <div style='display: flex;justify-content: end;margin-bottom:5px'>
                  <div></div>
                  <el-button  @click="godeletemain" size="mini" type="danger">清除内容</el-button>
                </div>
                  <div style="position: relative;" class="allDiseaseBox11"  ref="mainShowBox">
                    <el-input class="textareaAllDiseaseBox1" type="textarea" autosize v-model="patientFormDes.main"  @focus="mainfocus"></el-input>
                    <div class="allDiseaseBox1" v-show="mainShow">
                        <div class="allDiseaseBoxTitle">
                          常见症状
                          <i class="el-icon-close allDiseaseBoxTitleIcon" @click="mainBlur"></i>
                        </div>
                        <div class="allDiseaseBoxContent">
                          <div v-for="(item,index) in pastShowList" :key="index" @click="mainShowClick(item)">
                            {{item.content}}
                          </div>
                        </div>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item prop="diseaseDiagnose" label="诊断" style="width: 100%;margin-left: 80px"
                  :rules="[{ required: true, message: '请输入诊断信息', trigger: 'blur' }]">
                  <!-- <el-input  v-model="patientFormDes.diseaseDiagnose"></el-input> -->
                  <el-select v-model="patientFormDes.diseaseDiagnose" multiple filterable allow-create remote
                    default-first-option :remote-method="remoteMethod" @change="diseasChange" @remove-tag="removediseasChange" value-key="diseaseKey"
                    placeholder="请选择疾病名称" style="width: 100%">
                    <el-option v-for="item in diseasOptions" :key="item.diseaseKey" :label="item.diseaseName"
                      :value="item"> </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <el-form-item prop="diseaseDiagnose" label="鉴别分析">
                <el-tag type="info"
                  style="white-space:pre-wrap !important;height:auto !important;line-height:25px !important;padding:5px 10px !important;margin-bottom: 5px"
                  closable v-for="(item, i) in patientFormDes.discriminatoryAnalysisList" :key="i"
                  @close="handleClose(item)">{{ item }}</el-tag>
              </el-form-item>
              <div class="flex-l-b">
                <el-form-item prop="past" label="既往史" style="width: 100%">
                <div style="position: relative;" ref="pastShowBox">
                  <el-input v-model="patientFormDes.past"  @focus="pastfocus"></el-input>
                  <div class="allDiseaseBox" v-if="pastShow" >
                      <div class="allDiseaseBoxTitle">
                        常见既往史
                        <i class="el-icon-close allDiseaseBoxTitleIcon" @click="pastBlur"></i>
                      </div>
                      <div class="allDiseaseBoxContent">
                        <div v-for="(item,index) in pastShowList" :key="index" @click="pastShowClick(item)">
                          {{item.content}}
                        </div>
                      </div>
                  </div>
                </div>
              </el-form-item>
                <el-form-item prop="family" label="家族史" style="width: 100%; margin-left: 80px">
                  <div style="position: relative;"  ref="familyShowBox">
                    <el-input v-model="patientFormDes.family" @focus="familyfocus"></el-input>
                    <div class="allDiseaseBox" v-if="familyShow">
                        <div class="allDiseaseBoxTitle">
                          常见家族史
                          <i class="el-icon-close allDiseaseBoxTitleIcon" @click="familyBlur"></i>
                        </div>
                        <div class="allDiseaseBoxContent">
                          <div v-for="(item,index) in pastShowList" :key="index"  @click="familyShowClick(item)">
                            {{item.content}}
                          </div>
                        </div>
                    </div>
                  </div>
                </el-form-item>
              </div>
              <el-form-item prop="irritability" label="过敏史" style="width: 45%;">
                  <div style="position: relative;"  ref="irritabilityShowBox">
                    <el-input v-model="patientFormDes.irritability" @focus="irritabilityfocus"></el-input>
                    <div class="allDiseaseBox" v-if="irritabilityShow">
                        <div class="allDiseaseBoxTitle">
                          常见过敏史
                          <i class="el-icon-close allDiseaseBoxTitleIcon" @click="irritabilityBlur"></i>
                        </div>
                        <div class="allDiseaseBoxContent">
                          <div v-for="(item,index) in pastShowList" :key="index" @click="irritabilityShowClick(item)">
                            {{item.content}}
                          </div>
                        </div>
                    </div>
                  </div>
              </el-form-item>
              <div class="flex-l" style="margin-top: 30px">
                <div>体格检查</div>
                <el-button size="mini" type="primary" :icon="Showtemperature ? 'el-icon-minus' : 'el-icon-plus'"
                  style="margin-left: 10px" @click="goShowtemperature"> </el-button>
              </div>
              <div class="flex-l-s" v-if="Showtemperature">
                <el-form-item prop="temperature" label="体温">
                  <div class="flex-l"><el-input class="stepInput" v-model="patientFormDes.temperature"
                      placeholder="0"></el-input>℃</div>
                </el-form-item>
                <el-form-item prop="weight" label="体重">
                  <div class="flex-l">
                    <el-input v-model="patientFormDes.weight" placeholder="0" class="stepInput"></el-input>
                    <div class="inputTag">KG</div>
                  </div>
                </el-form-item>
                <el-form-item prop="height" label="身高">
                  <div class="flex-l">
                    <el-input v-model="patientFormDes.height" placeholder="0" class="stepInput"></el-input>
                    <div class="inputTag">CM</div>
                  </div>
                </el-form-item>
                <el-form-item prop="breathe" label="呼吸">
                  <div class="flex-l">
                    <el-input v-model="patientFormDes.breathe" placeholder="0" class="stepInput"></el-input>
                    <div class="inputTag">次/分</div>
                  </div>
                </el-form-item>
                <el-form-item prop="pulse" label="脉搏">
                  <div class="flex-l">
                    <el-input v-model="patientFormDes.pulse" placeholder="0" class="stepInput"></el-input>
                    <div class="inputTag">次/分</div>
                  </div>
                </el-form-item>
              </div>
              <div class="flex-l-b" v-if="Showtemperature">
                <el-form-item prop="heightTension" label="血压">
                  <div class="flex-l">
                    <el-input v-model="patientFormDes.heightTension" placeholder="0"
                      class="stepInput"></el-input>/<el-input v-model="patientFormDes.lowTension" placeholder="0"
                      class="stepInput" style="margin-left: 10px"></el-input>
                    <div class="inputTag">mmHg</div>
                  </div>
                </el-form-item>
                <el-form-item prop="otherCheck" label="其他体格检查" style="width: 100%; margin-left: 80px">
                  <el-input type="textarea" v-model="patientFormDes.otherCheck"></el-input>
                </el-form-item>
              </div>
              <div class="flex-l" style="margin-top: 10px">
                <div>症状照片</div>
                <el-button size="mini" type="primary" :icon="ShowPhoto ? 'el-icon-minus' : 'el-icon-plus'"
                  style="margin-left: 10px" @click="goShowPhoto"> </el-button>
              </div>
              <el-dialog :visible.sync="fitDialogVisible">
                <img width="100%" :src="Services.ossUpload + fitDialogVisibleImgUrl" alt="" />
              </el-dialog>
              <div v-show="ShowPhoto">
                <div style="font-size: 14px; font-weight: 400; padding-bottom: 10px; padding-top: 10px">
                  最多上传5张图片，最多不超过20M</div>
                <el-upload :headers="headers" :action="FileUploadUrl" list-type="picture-card" :limit="5" multiple
                  :on-preview="DiagnoseImagePreview" :on-remove="DiagnoseImageRemove" :on-success="DiagnoseImageUpload"
                  :file-list="ImageContentList">
                  <i class="el-icon-plus"></i>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，最多上传5张，单张大小不超过1M</div>
                </el-upload>
                <div class="flex-l">
                  <div v-for="fit in ShowPhotoList" :key="fit" @click="goShowPhotocfCliclk(fit)"
                    style="margin-right: 10px">
                    <el-image style="width: 148px; height: 148px"
                      :src="Services.ossUpload + fit.filePath"></el-image>
                  </div>
                </div>

                <el-dialog :visible.sync="Image.dialogVisible">
                  <img width="100%" :src="Image.dialogImageUrl" alt="" />
                </el-dialog>
              </div>
              <div class="flex-l" style="margin-top: 10px">
                <div>上传处方图片</div>
                <el-button size="mini" type="primary" :icon="ShowPhotocf ? 'el-icon-minus' : 'el-icon-plus'"
                  style="margin-left: 10px" @click="goShowPhotocf"> </el-button>
              </div>
              <div v-show="ShowPhotocf">
                <div style="font-size: 14px; font-weight: 400; padding-bottom: 10px; padding-top: 10px">
                  最多上传5张图片，最多不超过20M</div>
                <div class="flex-l">
                  <el-upload :headers="headers" :action="FileUploadUrl" list-type="picture-card" :limit="5" multiple
                    :on-preview="handleImagePreview" :on-remove="handleImageRemove" :on-success="handleImageUpload"
                    :file-list="ImageContentList">
                    <i class="el-icon-plus"></i>
                    <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，最多上传5张，单张大小不超过1M</div>
                  </el-upload>
                </div>
                <el-dialog :visible.sync="Image.dialogVisible">
                  <img width="100%" :src="Image.dialogImageUrl" alt="" />
                </el-dialog>
                <div class="flex-l">
                  <div v-for="fit in ShowPhotocfList" :key="fit" @click="goShowPhotocfCliclk(fit)"
                    style="margin-right: 10px">
                    <el-image style="width: 148px; height: 148px"
                      :src="Services.Authorization + fit.filePath"></el-image>
                  </div>
                </div>
              </div>
              <el-form-item style="text-align: right; margin-top: 60px; padding-bottom: 40px">
                <el-button @click="btnReferral">转诊患者</el-button>
                <el-button @click="alreadySubmitForm('patientFormDes')">手动暂存</el-button>
                <el-button type="primary" @click="goNextStep(1)">上一步</el-button>
                <el-button type="primary" @click="addSubmitForm('patientFormDes')">下一步</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 体格检查 -->
        <div class="titleTopCenterCenterContent" v-if="stepIndex == 3">
          <div class="Content1Title" style="padding: 10px 5%">
            <el-form :model="patientFormPre" ref="patientFormPre" label-position="top">
              <div class="">开具处方</div>
              <el-form-item prop="age" style="width: 100%">
                <div class="flex-l">
                  <div @click="go3tabClick(0)" :class="tab3ClickData == 0 ? 'tag3Checked' : 'tag3'"><i
                      class="el-icon-plus"></i>方剂处方</div>
                  <div @click="go3tabClick(1)" :class="tab3ClickData == 1 ? 'tag3Checked' : 'tag3'"><i
                      class="el-icon-plus"></i>成药处方</div>
                  <div @click="go3tabClick(2)" :class="tab3ClickData == 2 ? 'tag3Checked' : 'tag3'"><i
                      class="el-icon-plus"></i>输液处方</div>
                  <div @click="goComtabClick1" class="tag3">用药审查</div>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="table3Box" v-if="tab3ClickData == 0">
                  <div class="flex-l-b titleTopright1 titleSize14">
                    <div></div>
                    <div style="margin-right: 15px" @click="goAddcompositionList">添加中药方剂</div>
                  </div>
                  <div v-for="(itemCom, indexCom) in patientFormPre.compositionList" :key="indexCom"
                    style="margin-top: 20px">
                    <el-form-item :prop="'compositionList.' + indexCom + '.prescriptionName'" label="中药方剂名称"
                      :rules="[{ required: true, message: '请输入中药方剂名称', trigger: 'blur' }]">
                      <div class="flex-l-b titleSize14" style="margin-bottom: 20px">
                        <el-input v-model="itemCom.prescriptionName" placeholder="请输入中药方剂名称"
                          style="width: 40%"></el-input>
                        <div class="flex-l titleTopright1 titleSize14">
                          <div @click="godeleteCompositionList(indexCom)">删除中药方剂</div>
                        </div>
                      </div>
                    </el-form-item>
                    <el-table :data="itemCom.mzPrescriptionDetail" border>
                      <el-table-column type="index" width="50"> </el-table-column>
                      <el-table-column prop="drugOrgId" align="center" label="成分" width="200">
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="'compositionList.' + indexCom + '.mzPrescriptionDetail.' + scope.$index + '.drugOrgId'"
                            :rules="[
                              { required: true, message: '请选择成分', trigger: 'blur' },
                              { pattern: /^(?!0$).*/, message: '请选择成分', trigger: 'blur' },
                            ]">
                            <div style="position: relative" class="drugOrgNameBox">
                              <el-select v-model="scope.row.drugOrgName" filterable remote size="small" reserve-keyword
                                placeholder="请输入成分" ref="selectLabel" @focus="handleFocus"
                                :remote-method="SelectComposition" @change="ConstituteChange($event, scope.row)">
                                <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId"
                                  :label="item.drugOrgName" :value="item.drugOrgId"> </el-option>
                              </el-select>
                              <div class="infoWarn" v-if="scope.row.drugOrgId == '0'"
                                style="color: #fc1414; font-size: 14px"><i class="el-icon-info"></i>该药品不存在您的药品库</div>
                            </div>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="weight" align="center" label="用量(g)">
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="'compositionList.' + indexCom + '.mzPrescriptionDetail.' + scope.$index + '.weight'"
                            :rules="[{ required: true, message: '请选择用量', trigger: 'blur' }]">
                            <div style="position: relative" class="drugOrgNameBox">
                              <!-- {{scope.row.weight}}
                              {{ scope.row.weight > scope.row.inventoryNumber }}
                              {{ scope.row.inventoryNumber == '0' && !scope.row.drugInventoryNumber }}
                               {{ scope.row.drugInventoryNumber == '0' && !scope.row.inventoryNumber }}
                                {{ scope.row.weight > scope.row.drugInventoryNumber }} -->
                                <el-input v-model="scope.row.weight" size="small"
                                placeholder="开药量"
                              onkeyup="this.value=this.value.replace(/[^\d.]/g, '').replace(/^\D*(\d{1,12}(?:\.\d{0,3})?).*$/g, '$1')"
                                onafterpaste="this.value=this.value.replace(/[^\d.]/g, '').replace(/^\D*(\d{1,12}(?:\.\d{0,3})?).*$/g, '$1')"></el-input>
                              <!-- <el-input-number v-model="scope.row.weight" size="small" placeholder="用量(g)" :precision="3" :min="0" :controls="false"> </el-input-number> -->
                              <el-tooltip effect="dark" content="库存不足" placement="bottom"
                                v-if="(scope.row.inventoryNumber == '0' && !scope.row.drugInventoryNumber) || (scope.row.drugInventoryNumber == '0' && !scope.row.inventoryNumber) || scope.row.weight > scope.row.inventoryNumber || (scope.row.weight > scope.row.drugInventoryNumber && !scope.row.inventoryNumber) ">
                                <el-button style="color: #fc1414; font-size: 14px" icon="el-icon-info" class="infoWarn1"
                                  type="text"></el-button>
                              </el-tooltip>
                       
                            </div>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="recommendDosage" align="center" label="推荐用量" width="120">
                        <template slot-scope="scope">
                          <div style="position: relative" class="">
                            {{ scope.row.recommendDosage ? scope.row.recommendDosage : "--" }}
                            <el-tooltip effect="dark" content="与推荐用量不符" placement="bottom"
                              v-if="scope.row.weight < scope.row.recommendDosageMin || scope.row.weight > scope.row.recommendDosageMax">
                              <el-button style="color: #e6a23c; font-size: 16px" icon="el-icon-s-management" class="infoWarnCom"
                                type="text"></el-button>
                            </el-tooltip>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remark" align="center" label="炮制方法" width="120">
                        <template slot-scope="scope">
                          <div style="position: relative">
                            {{ scope.row.remark ? scope.row.remark : "--" }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="decoct" align="center" label="煎药方法">
                        <template slot-scope="scope">
                          <el-form-item>
                            <div class="drugOrgNameBox">
                              <el-select v-model="scope.row.decoct" size="mini" placeholder="煎药方法">
                                <el-option v-for="item in usageOption" :key="item" :label="item" :value="item">
                                </el-option>
                              </el-select>
                            </div>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column width="120" align="center">
                        <template slot-scope="scope">
                          <div class="flex-l">
                            <div>￥{{ scope.row.tradingPrice ? parseFloat(itemCom.dosage * (scope.row.weight ?
                              parseFloat(scope.row.weight) : 0) * 1 * scope.row.tradingPrice).toFixed(2) : "0.00" }}
                            </div>
                            <i class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px"
                              @click="gondeletePre(indexCom, scope.$index)"></i>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="titleSize14 titleTopright1 titleTopright1Line" @click="gonaddPre(indexCom)">添加中药材</div>
                    <div class="flex-l" style="margin-top: 5px">
                      <el-form-item :prop="'compositionList.' + indexCom + '.dosage'" label="剂数"
                        :rules="[{ required: true, message: '请输入剂数', trigger: 'blur' }]"> <el-input-number
                          class="stepInput" v-model="itemCom.dosage" controls-position="right"
                          :min="1"></el-input-number>剂 </el-form-item>

                      <el-form-item :prop="'compositionList.' + indexCom + '.dosageNumber'" label="每日剂量"
                        :rules="[{ required: true, message: '请输入每日剂量', trigger: 'blur' }]">
                        <div class="flex-l"><el-input-number class="stepInput" v-model="itemCom.dosageNumber"
                            controls-position="right" :min="1"></el-input-number>剂</div>
                      </el-form-item>

                      <el-form-item :prop="'compositionList.' + indexCom + '.frequency'" label="用药频率"
                        :rules="[{ required: true, message: '请选择用药频率', trigger: 'blur' }]">
                        <div class="flex-l">
                          <el-select v-model="itemCom.frequency" placeholder="用药频率">
                            <el-option v-for="item in frequencyOption" :key="item" :label="item" :value="item">
                            </el-option>
                          </el-select>
                        </div>
                      </el-form-item>
                    </div>
                    <div class="flex-l">
                      <el-form-item :prop="'compositionList.' + indexCom + '.usage'" label="用法"
                        :rules="[{ required: true, message: '请选择用法', trigger: 'blur' }]">
                        <el-select v-model="itemCom.usage" placeholder="用法">
                          <el-option v-for="item in usage" :key="item" :label="item" :value="item"> </el-option>
                        </el-select>
                      </el-form-item>

                      <el-form-item prop="isDecoction" label="是否代煎" style="margin-left: 45px">
                        <div class="flex-l" style="height: 40px">
                          <el-switch v-model="itemCom.isDecoction"></el-switch>
                        </div>
                      </el-form-item>
                      <el-form-item v-if="itemCom.isDecoction" prop="isdeliveryPrice" label="代煎价格"
                        style="margin-left: 65px">
                        <div class="flex-l">
                          <el-select placeholder="请选择" v-model="itemCom.isSelefPriceName" @change="selectChangeSelefPrice($event,indexCom)" style="width:50%">
                          <el-option
                              v-for="item13 in isSelefPriceList"
                              :key="item13.itemName"
                              :label="item13.itemName"
                              :value="item13"
                              :value-key="item13.id"
                              >
                            </el-option>
                          </el-select>
                            <span>￥{{ (parseFloat(itemCom.dosage) * parseFloat(itemCom.isdeliveryPrice)).toFixed(2) }}</span>
                          </div>
                      </el-form-item>
                    </div>
                      <el-form-item :prop="'compositionList.' + indexCom + '.request'" label="服用要求"
                        :rules="[{ required: true, message: '请输入服用要求', trigger: 'blur' }]">
                        <!-- <el-select v-model="itemCom.request" placeholder="服用要求">
                          <el-option v-for="item in requestOption" :key="item.id" :label="item.adviceContent"
                            :value="item.adviceContent">
                          </el-option>
                        </el-select> -->
                          <div class="flex-l"><el-input v-model="itemCom.request" class="stepInput"></el-input>
                            <el-button size="mini" type="primary" icon="el-icon-more"
                              @click="goGetrequestData(indexCom)"></el-button>
                          </div>
                      </el-form-item>
                    <el-form-item style="text-align: right; margin-top: 20px">
                      <el-button type="primary" @click="addpatientFormPreForm(indexCom)">存为常用处方</el-button>
                    </el-form-item>
                  </div>
                </div>
                <div class="table3Box" v-if="tab3ClickData == 1">
                  <div v-for="(itemComCZ, indexComCZ) in patientFormPre.CZcompositionList" :key="indexComCZ"
                    style="margin-top: 20px">
                    <div class="flex-l-b titleSize14" style="margin-bottom: 20px">
                      <el-input v-model="itemComCZ.prescriptionName" placeholder="请输入成药方剂名称"
                        style="width: 40%"></el-input>
                      <div class="flex-l titleTopright1 titleSize14">
                      </div>
                    </div>
                    <el-table :data="itemComCZ.mzPrescriptionDetail" border>
                      <el-table-column prop="drugOrgId" align="center" label="药品名称" width="200">
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="'CZcompositionList.' + indexComCZ + '.mzPrescriptionDetail.' + scope.$index + '.drugOrgId'"
                            :rules="[{ required: true, message: '请选择药品', trigger: 'blur' }]">
                            <div style="position: relative">
                              <el-select v-model="scope.row.drugOrgName" filterable remote size="small" reserve-keyword
                                placeholder="请选择药品" ref="selectLabel" :remote-method="SelectComposition"
                                @focus="handleFocus" @change="ConstituteChange($event, scope.row)">
                                <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId"
                                  :label="item.drugOrgName" :value="item.drugOrgId"> </el-option>
                              </el-select>
                              <div class="infoWarn" v-if="scope.row.drugOrgId == '0'"
                                style="color: #fc1414; font-size: 14px"><i class="el-icon-info"></i>该药品不存在您的药品库</div>
                            </div>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <!-- <el-table-column prop="groupNumber" align="center" label="组号" width="120">
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="'CZcompositionList.' + indexComCZ + '.mzPrescriptionDetail.' + scope.$index + '.groupNumber'">
                            <el-input v-model="scope.row.groupNumber" size="small" placeholder="输入组号"> </el-input>
                          </el-form-item>
                        </template>
                      </el-table-column> -->
                      <el-table-column prop="usage" align="center" label="用法">
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="'CZcompositionList.' + indexComCZ + '.mzPrescriptionDetail.' + scope.$index + '.usage'"
                            :rules="[{ required: true, message: '请选择用法', trigger: 'blur' }]">
                            <el-select v-model="scope.row.usage" size="mini" placeholder="用法">
                              <el-option v-for="item in remarkOption" :key="item" :label="item" :value="item">
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="frequency" align="center" label="用药频率" width="150">
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="'CZcompositionList.' + indexComCZ + '.mzPrescriptionDetail.' + scope.$index + '.frequency'"
                            :rules="[{ required: true, message: '请选择用药频率', trigger: 'blur' }]">
                            <el-select v-model="scope.row.frequency" size="mini" placeholder="用药频率">
                              <el-option v-for="item in frequencyOption" :key="item" :label="item" :value="item">
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="dosageList" align="center" label="单次剂量" width="180">
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="'CZcompositionList.' + indexComCZ + '.mzPrescriptionDetail.' + scope.$index + '.dosageList.dosage'"
                            :rules="[{ required: true, message: '请输入单次剂量', trigger: 'blur' }]">
                            <el-input v-model="scope.row.dosageList.dosage" size="small" placeholder="单次剂量">
                              <el-select style="width: 75px" slot="append" v-model="scope.row.dosageList.dosageUnit"
                                size="mini" placeholder="单位">
                                <el-option v-for="item in kgOption" :key="item" :label="item" :value="item">
                                </el-option>
                              </el-select>
                            </el-input>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="defaultDosage" align="center" label="开药量" width="180">
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="'CZcompositionList.' + indexComCZ + '.mzPrescriptionDetail.' + scope.$index + '.defaultDosageList.defaultDosage'"
                            :rules="[{ required: true, message: '请输入单次剂量', trigger: 'blur' }]">
                            <div style="position: relative">
                              <el-input v-model="scope.row.defaultDosageList.defaultDosage" size="small"
                                placeholder="开药量" onkeyup="this.value=this.value.replace(/\D/g,'')"
                                onafterpaste="this.value=this.value.replace(/\D/g,'')">
                                <template slot="append" >{{scope.row.defaultDosageList.defaultDosageUnit}}</template>
                              </el-input>
                              <el-tooltip effect="dark" content="库存不足" placement="bottom"
                                v-if="(scope.row.inventoryNumber == '0' && !scope.row.drugInventoryNumber) || (scope.row.drugInventoryNumber == '0' && !scope.row.inventoryNumber) || scope.row.defaultDosageList.defaultDosage > scope.row.inventoryNumber">
                                <el-button style="color: #fc1414; font-size: 14px" icon="el-icon-info" class="infoWarn1"
                                  type="text"></el-button>
                              </el-tooltip>
                            </div>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column width="100" align="center">
                        <template slot-scope="scope">
                          <div class="flex-l">
                            <div>￥{{ scope.row.tradingPrice ? parseFloat(scope.row.defaultDosageList.defaultDosage * 1 *
                              scope.row.tradingPrice).toFixed(2) : "0.00" }}</div>
                            <i class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px"
                              @click="gondeletePreCY(indexComCZ, scope.$index)"></i>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="titleSize14 titleTopright1" @click="gonaddPreCY(indexComCZ)">添加药品</div>
                    <el-form-item style="text-align: right; margin-top: 20px">
                      <el-button type="primary" @click="addCyFormPreForm(indexComCZ)">存为常用处方</el-button>
                    </el-form-item>
                  </div>
                </div>
                <div class="table3Box" v-if="tab3ClickData == 2">
                  <div class="flex-l-b titleTopright1 titleSize14">
                    <div></div>
                    <div style="margin-right: 15px" @click="goaddSYList">添加输液处方</div>
                  </div>
                  <div v-for="(itemComSY, indexComSY) in patientFormPre.SYcompositionList" :key="indexComSY"
                    style="margin-top: 20px">
                    <el-form-item :prop="'SYcompositionList.' + indexComSY + '.prescriptionName'" label="输液处方名称"
                      :rules="[{ required: true, message: '请输入输液处方名称', trigger: 'blur' }]">
                      <div class="flex-l-b titleSize14">
                        <el-input v-model="itemComSY.prescriptionName" placeholder="请输入输液处方名称"
                          style="width: 40%"></el-input>
                        <div class="flex-l titleTopright1 titleSize14">
                          <div @click="godeleteSYList(indexComSY)">删除输液处方</div>
                        </div>
                      </div>
                    </el-form-item>
                    <div v-for="(itemComSY1, indexComSY1) in itemComSY.zucompositionList" :key="indexComSY1"
                      style="margin-top: 20px">
                      <div class="flex-l titleSize14">
                        <div class="">组{{ indexComSY1 * 1 + 1 }}</div>
                        <div class="flex-l titleTopright1 titleSize14">
                          <div style="margin-left: 15px" @click="godeleteSYzuList(indexComSY, indexComSY1)">删除该组</div>
                        </div>
                      </div>
                      <el-table :data="itemComSY1.mzPrescriptionTitle" border>
                        <el-table-column prop="usage" align="center" label="用法">
                          <template slot-scope="scope">
                            <el-form-item
                              :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionTitle.' + scope.$index + '.usage'"
                              :rules="[{ required: true, message: '请选择用法', trigger: 'blur' }]">
                              <el-select v-model="scope.row.usage" size="mini" placeholder="用法">
                                <el-option v-for="item in remarkOption" :key="item" :label="item" :value="item">
                                </el-option>
                              </el-select>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column prop="frequency" align="center" label="用药频率">
                          <template slot-scope="scope">
                            <el-form-item
                              :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionTitle.' + scope.$index + '.frequency'">
                              <el-select v-model="scope.row.frequency" size="mini" placeholder="用药频率">
                                <el-option v-for="item in frequencyOption" :key="item" :label="item" :value="item">
                                </el-option>
                              </el-select>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column prop="drippingSpeedList" align="center" label="滴速">
                          <template slot-scope="scope">
                            <el-form-item
                              :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionTitle.' + scope.$index + '.drippingSpeedList.drippingSpeed'">
                              <el-input v-model="scope.row.drippingSpeedList.drippingSpeed" size="small"
                                placeholder="单次剂量">
                                <el-select style="width: 75px" slot="append"
                                  v-model="scope.row.drippingSpeedList.drippingSpeedUnit" size="mini" placeholder="单位">
                                  <el-option v-for="item in SpeeOption" :key="item" :label="item" :value="item">
                                  </el-option>
                                </el-select>
                              </el-input>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column prop="startTime" align="center" label="起始日期">
                          <template slot-scope="scope">
                            <el-form-item
                              :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionTitle.' + scope.$index + '.startTime'">
                              <el-date-picker v-model="scope.row.startTime" type="date" placeholder="选择日期"
                                value-format="yyyy-MM-dd ">
                              </el-date-picker>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column prop="days" align="center" label="连续天数">
                          <template slot-scope="scope">
                            <el-form-item
                              :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionTitle.' + scope.$index + '.days'">
                              <el-input v-model="scope.row.days" size="small" placeholder="天数"> </el-input>
                            </el-form-item>
                          </template>
                        </el-table-column>
                      </el-table>
                      <el-table :data="itemComSY1.mzPrescriptionDetail" border style="margin-top: 10px">
                        <el-table-column prop="drugOrgId" align="center" label="药品名称">
                          <template slot-scope="scope">
                            <el-form-item
                              :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionDetail.' + scope.$index + '.drugOrgId'"
                              :rules="[{ required: true, message: '请输入成分', trigger: 'blur' }]">
                              <div style="position: relative">
                                <el-select v-model="scope.row.drugOrgName" filterable remote size="small"
                                  reserve-keyword placeholder="请输入成分" ref="selectLabel" @focus="handleFocus"
                                  :remote-method="SelectComposition" @change="ConstituteChange($event, scope.row)">
                                  <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId"
                                    :label="item.drugOrgName" :value="item.drugOrgId"> </el-option>
                                </el-select>
                                <div class="infoWarn" v-if="scope.row.drugOrgId == '0'"
                                  style="color: #fc1414; font-size: 14px"><i class="el-icon-info"></i>该药品不存在您的药品库</div>
                              </div>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column prop="st" align="center" label="皮试">
                          <template slot-scope="scope">
                            <el-form-item
                              :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionDetail.' + scope.$index + '.st'">
                              <el-input v-model="scope.row.st" size="small" placeholder="皮试"> </el-input>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column prop="dosageList" align="center" label="单次剂量" width="160">
                          <template slot-scope="scope">
                            <el-form-item
                              :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionDetail.' + scope.$index + '.dosageList.dosage'"
                              :rules="[{ required: true, message: '请输入单次剂量', trigger: 'blur' }]">
                              <el-input v-model="scope.row.dosageList.dosage" size="small" placeholder="单次剂量">
                                <el-select style="width: 75px" slot="append" v-model="scope.row.dosageList.dosageUnit"
                                  size="mini" placeholder="单位">
                                  <el-option v-for="item in kgOption" :key="item" :label="item" :value="item">
                                  </el-option>
                                </el-select>
                              </el-input>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column prop="defaultDosageList" align="center" label="开药量" width="160">
                          <template slot-scope="scope">
                            <el-form-item
                              :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionDetail.' + scope.$index + '.defaultDosageList.defaultDosage'"
                              :rules="[{ required: true, message: '请输入开药量', trigger: 'blur' }]">
                              <div style="position: relative">
                                <el-input v-model="scope.row.defaultDosageList.defaultDosage" size="small"
                                  placeholder="开药量" onkeyup="this.value=this.value.replace(/\D/g,'')"
                                  onafterpaste="this.value=this.value.replace(/\D/g,'')">
                                  <template slot="append" >{{scope.row.defaultDosageList.defaultDosageUnit}}</template>
                                </el-input>
                                <el-tooltip effect="dark" content="库存不足" placement="bottom"
                                  v-if="(scope.row.inventoryNumber == '0' && !scope.row.drugInventoryNumber) || (scope.row.drugInventoryNumber == '0' && !scope.row.inventoryNumber) || scope.row.defaultDosageList.defaultDosage > scope.row.inventoryNumber">
                                  <el-button style="color: #fc1414; font-size: 14px" icon="el-icon-info"
                                    class="infoWarn1" type="text"></el-button>
                                </el-tooltip>
                              </div>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column width="100" align="center">
                          <template slot-scope="scope">
                            <div class="flex-l">
                              <div>￥{{ scope.row.tradingPrice ? parseFloat(scope.row.defaultDosageList.defaultDosage * 1
                                * scope.row.tradingPrice).toFixed(2) : "0.00" }}</div>
                              <i class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px"
                                @click="godeletSYzuDrugList(indexComSY, indexComSY1, scope.$index)"></i>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                      <div class="flex-l titleSize14">
                        <div class="titleSize14 titleTopright1" @click="goaddSYzuDrugList(indexComSY, indexComSY1)">添加药品
                        </div>
                        <div class="titleSize14 titleTopright1" @click="goaddSYzuList(indexComSY)"
                          style="margin-left: 15px">添加组</div>
                      </div>
                    </div>
                    <el-form-item style="text-align: right; margin-top: 20px">
                      <el-button type="primary" @click="addSYFormPreForm(indexComSY)">存为常用处方</el-button>
                    </el-form-item>
                  </div>
                </div>
              </el-form-item>

              <el-form-item prop="age" label="医嘱" style="width: 100%">
                <div class="flex-l"><el-input v-model="patientFormPre.doctorAdvice" class="stepInput"></el-input>
                  <el-button size="mini" type="primary" icon="el-icon-more"
                    @click="goGetDoctorAdviceListData"></el-button>
                </div>
              </el-form-item>

              <div class="flex-l">
                <div>其他收费项目</div>
              </div>
              <el-form-item prop="age" label="附加费用" style="width: 100%">
                <!-- <div v-for="(itemCharge, indexCharge) in patientFormPre.MZOtherCharges" :key="indexCharge"
                  style="margin-top: 10px">
                  <div class="flex-l">
                    <el-input v-model="itemCharge.chargeName" class="stepInput"></el-input>
                    <div>￥</div>
                    <el-input v-model="itemCharge.money" style="width: 7%; margin: 0 10px"></el-input>
                    <div><i class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px"
                        @click="deleteCharges(indexCharge)"></i></div>
                  </div>
                </div> -->
                <div v-for="(itemCharge, indexCharge) in patientFormPre.MZOtherCharges" :key="indexCharge"
                  style="margin-top: 10px">
                  <div class="flex-l">
                    <el-select
                        v-model="itemCharge.chargeName"
                        filterable
                        allow-create
                        default-first-option
                        placeholder="请选择附加费用"
                        class="stepInput"
                        @change="othercheckItemsChange(itemCharge)"
                        value-key="id"
                        >
                        <el-option
                          v-for="item in MZOtherChargesOptions" :key="item.id" :label="item.itemName" :value="item" >
                        </el-option>
                    </el-select>
                    <!-- <el-input v-model="itemCharge.chargeName" class="stepInput"></el-input> -->
                    <div>￥</div>
                    <el-input v-model="itemCharge.money" style="width: 7%; margin: 0 10px"></el-input>
                    <div><i class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px"
                        @click="deleteCharges(indexCharge)"></i></div>
                  </div>
                </div>
                <el-button size="mini" type="primary" icon="el-icon-plus" @click="goAddCharges"> </el-button>
              </el-form-item>
              <div class="Content3TitleBox">
                <div>合计金额</div>
                <div class="Content3TitleBoxRight">
                  <span>合计：</span><span class="Content3TitleBoxRightSpan">￥{{ parseFloat(allTotalMoney).toFixed(2)
                    }}</span>
                </div>
              </div>
              <el-form-item prop="isSelef" label="取药">
                <div class="flex-l">
                  <el-radio v-model="patientFormPre.isSelef" label="1">自取</el-radio>
                  <el-radio v-model="patientFormPre.isSelef" label="2">快递</el-radio>
                </div>
              </el-form-item>
              <el-form-item v-if="patientFormPre.isSelef == '2'" prop="isSelef" label="快递信息">
                <div style="display: flex;align-items: center;width:100%">
                  <div>
                    快递费用：  
                    <el-select v-model="isSelefName" placeholder="请选择" @change="selectChangeSelef" style="width:50%">
                      <el-option
                          v-for="item in isSelefList"
                          :key="item.itemName"
                          :label="item.itemName"
                          :value="item"
                          :value-key="item.id"
                          >
                        </el-option>
                      </el-select>
                      <span style="color: #ee1515">￥{{ patientFormPre.isSelefPrice }}</span>
                  </div>
                  <div style="width:50%;margin-left:2%">
                    <el-input placeholder="请输入姓名/手机号/收货地址" size="small" v-model="searchAdressKeyWord"
                      class="input-with-select"><el-button slot="append" icon="el-icon-search"
                    @click="gosearchAdress"></el-button></el-input>
                  </div>
                </div>
                <div class="adressListBoxTop" style='overflow-y: auto;max-height: 300px;margin-top:10px'>
                  <div :class="messageindex == index ? 'adressListBox' : 'adressListBoxno'"
                    v-for="(item, index) in MessageList" :key="index" @click="goIndexmess(item, index)">
                    <div class="flex-l-b">
                      <div style="display: flex; align-items: center">
                        <div>{{ item.addressee }}</div>
                        <div style="margin-left: 10px">{{ item.phone }}</div>
                        <div style="margin-left: 10px">{{ item.isDefault ? "默认" : "" }}</div>
                      </div>
                      <div>
                        <i v-if="messageindex == index" class="el-icon-success"></i>
                      </div>
                    </div>

                    <div>
                      <div>{{ item.fullAddress }}</div>
                    </div>
                  </div>
                </div>
                <div class="adressListBoxTop"><el-button size="mini" @click="goAdress">使用新地址</el-button> <el-button
                    size="mini" @click="refAdress">刷新列表</el-button></div>
              </el-form-item>
              <el-form-item style="text-align: right; margin-top: 20px; padding-bottom: 40px">
                <!-- <div class="flex-l" style="justify-content: flex-end;">
                                <div>选择是否打印：</div>
                                <el-radio v-model="isSelef" label="1">打印病历</el-radio>
                                <el-radio v-model="isSelef" label="2">打印处方</el-radio>
                            </div> -->
                <!-- <el-button @click="btnReferral">转诊患者</el-button> -->
                <el-button type="primary" @click="goNextStep(2)">上一步</el-button>
                <el-button v-if="userInfo.isMakeDiagnose == 'True'" type="primary"
                  @click="goNextDataSuc('patientFormPre')" :loading="nosucecessloading">免审核提交</el-button>
                <el-button @click="goNextData('patientFormPre')" v-if="detailUserType != '4'">提交审核</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="titleTopCenterRight" v-if="stepIndex == 1">
        <div class="titleTopCenterRightBox">
          <el-tabs v-model="activerightName">
            <el-tab-pane label="患者列表" name="1"></el-tab-pane>
            <!-- <el-tab-pane label="患者列表" name="second"></el-tab-pane>
                    <el-tab-pane label="个人常用处方" name="third"></el-tab-pane>
                    <el-tab-pane label="处方管理" name="fourth"></el-tab-pane> -->
          </el-tabs>
        </div>
        <div class="titleTopCenterRightBox" style="padding-top: 0">
          <el-input placeholder="搜索姓名/手机号" prefix-icon="el-icon-search" v-model="patientKeyWord"
            @keyup.enter.native="patientSearch" clearable>
            <el-button slot="append" class="search-btn" type="button" icon="el-icon-search" @click="patientSearch">
            </el-button>
          </el-input>
        </div>
        <div class="todayDetail">
          <div class="todayTitleTh todayTitleThTtitle">
            <div class="thwidth">患者姓名</div>
            <div class="thwidth">性别</div>
            <div class="thwidth">联系电话</div>
          </div>
          <div class="todayTitleTh todayTitleThDetail" v-for="(item, index) in patientList" :key="index"
            @click="goNextpatientStep(item.id)">
            <div class="thwidth">{{ item.name }}</div>
            <div class="thwidth">{{ item.sex ? "男" : "女" }}</div>
            <div class="thwidth">{{ item.phone }}</div>
          </div>
          <div class="pageBox">
            <el-pagination background layout="prev, pager, next" :page-size="15" @current-change="handleCurrentChange"
              :current-page.sync="patientcurrentPage" :total="patientTotal"> </el-pagination>
          </div>
        </div>
      </div>
      <div class="titleTopCenterRight" v-if="stepIndex == 2">
        <div class="titleTopCenterRightBox" style="padding-bottom: 10px">
          <el-tabs v-model="activerightName" @tab-click="handleClick2">
            <el-tab-pane label="辨证论治" name="1">
              <div style="text-align: center; padding-bottom: 20px;">
                <el-button type="primary" @click="goIndexBZDia">选择症状</el-button>
                <el-button type="warning" @click="goIndexBZ">智能辨证</el-button>
                <el-button type="primary" @click="getBZResultLatestData" plain>刷新</el-button>
              </div>
              <el-input placeholder="请输入辨证名称" v-model="userSymptomValueBZ" class="input-with-select">
                <i slot="suffix" class="el-icon-circle-close" @click="canclechuserBZ" style="margin-top:12px"></i>
                <el-button slot="append" icon="el-icon-search" @click="searchuserBZ"></el-button>
              </el-input>
              <div style="max-height:300px;overflow-y: auto;border-bottom: 1px dashed #cccccc;padding-bottom: 10px;">
                <div style="margin-top: 15px;cursor: pointer;"
                  v-for="(item, index) in searchResultByUserListBZ" :key="index"
                  @click="goResultByAnalysisBZ(item)">
                  <div style="display: flex; align-items: center; justify-content: space-between">
                    <div>
                    {{ item.primarySymptomName }} <span v-if="item.diseaseDepartmentName" style="color:#00AFB5"> {{ '(' + item.diseaseDepartmentName  + ')' }}</span>
                    </div>
                    <el-button type="primary" size="mini">查看症候</el-button>
                  </div>
                </div>
              </div>
              <div style="margin-top: 20px;cursor: pointer;" v-for="(item, index) in searchResultByUserListDetail"
                :key="index" @click.stop="goResultByAnalysis(item,'search')">
                <div style="display: flex; align-items: center; justify-content: space-between">
                  <div><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>{{
                    item.syndromeName }}</div>
                </div>
                <div class="titleRight2Content1 titleSize14">
                  <span  v-for="(itemq, indexq) in item.symptoms"
                    :key="indexq">
                    {{ "【" + itemq + "】" }}
                  </span>
                </div>
              </div>
              <div class="titleSize16" v-if="ResultLatestData.recordResults" style="margin-top:12px">
                <div>
                  <div>
                    <span>{{ patientDetail.name }}</span>，<span>{{ patientDetail.sex ? "男" : "女" }}</span>，<span>{{
                      patientDetail.phone }}</span>
                  </div>
                  <div
                    style="display: flex; align-items: center; justify-content: space-between; margin-top: 20px; margin-bottom: 10px">
                    <div @click.stop="goResultByzhu(ResultLatestData)"><i
                        class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>主症：{{
                          ResultLatestData.primarySymptomName }}</div>
                    <div>{{ ResultLatestData.diagnosisTime.slice(0, 10) }}</div>
                  </div>
                  <div class="ResultLateline">
                    您的症状清单：<span style="color: #df7d1f" v-for="(itemsym, indexitemsym) in ResultLatestData.symptoms"
                      :key="indexitemsym"> {{ "【" + itemsym + "】" }}</span>
                  </div>
                </div>
                <div style="margin-top: 20px;cursor: pointer;" v-for="(item, index) in ResultLatestData.recordResults"
                  :key="index" @click.stop="goResultByAnalysis(item)">
                  <div style="display: flex; align-items: center; justify-content: space-between">
                    <div><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>{{
                      item.syndromeName }}</div>
                    <div>{{ item.matchingRate }}%匹配度</div>
                  </div>
                  <div class="titleRight2Content1 titleSize14">
                    <span :class="itemq.checked ? 'newChecked' : ''" v-for="(itemq, indexq) in item.newsyndromeSymptoms"
                      :key="indexq">
                      {{ "【" + itemq.name + "】" }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="titleSize16" v-else>
                <div style="margin-top: 20px">
                  <img src="@/assets/img/interrogation/nodata.png" alt="" srcset="" />
                  <p style="text-align: center">暂无测试结果~</p>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="辨病论治" name="2">
              <div style="text-align: center; padding-bottom: 20px;">
                <el-button type="primary" @click="goIndexBBDia">选择疾病</el-button>
                <el-button type="warning" @click="goIndexBB">智能辨病</el-button>
                <el-button type="primary" @click="getBBResultLatestData" plain>刷新</el-button>
              </div>
              <el-input placeholder="请输入辨病名称" v-model="userSymptomValueBB" class="input-with-select">
                <i slot="suffix" class="el-icon-circle-close" @click="canclechuserBB" style="margin-top:12px"></i>
                <el-button slot="append" icon="el-icon-search" @click="searchuserBB"></el-button>
              </el-input>
              <div style="max-height:300px;overflow-y: auto;border-bottom: 1px dashed #cccccc;padding-bottom: 10px;">
                <div style="margin-top: 15px;cursor: pointer;"
                  v-for="(item, index) in searchResultByUserListBB" :key="index"
                  @click="goResultByAnalysisBB(item)">
                  <div style="display: flex; align-items: center; justify-content: space-between">
                    <div>
                    {{ item.primarySymptomName }} <span v-if="item.diseaseDepartmentName" style="color:#00AFB5"> {{ '(' + item.diseaseDepartmentName  + ')' }}</span>
                    </div>
                    <el-button type="primary" size="mini">查看症候</el-button>
                  </div>
                </div>
              </div>
              <div style="margin-top: 20px;cursor: pointer;" v-for="(item, index) in searchResultByUserListDetailBB"
                :key="index" @click.stop="goResultByAnalysis(item,'search')">
                <div style="display: flex; align-items: center; justify-content: space-between">
                  <div><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>{{ item.syndromeName }}</div>
                </div>
                <div class="titleRight2Content1 titleSize14">
                  <span  v-for="(itemq, indexq) in item.symptoms"
                    :key="indexq">
                    {{ "【" + itemq + "】" }}
                  </span>
                </div>
              </div>
              <div class="titleSize16" v-if="tcmResultLatestData.recordResults">
                <div>
                  <div>
                    <span>{{ patientDetail.name }}</span
                    >，<span>{{ patientDetail.sex ? "男" : "女" }}</span
                    >，<span>{{ patientDetail.phone }}</span>
                  </div>
                  <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 20px; margin-bottom: 10px">
                    <div @click.stop="goResultByzhu(tcmResultLatestData)"><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>主症：{{ tcmResultLatestData.primarySymptomName }}</div>
                    <div>{{ tcmResultLatestData.diagnosisTime.slice(0, 10) }}</div>
                  </div>
                  <div class="ResultLateline">
                    您的症状清单：<span style="color: #df7d1f" v-for="(itemsym, indexitemsym) in tcmResultLatestData.symptoms" :key="indexitemsym"> {{ "【" + itemsym + "】" }}</span>
                  </div>
                </div>
                <div style="margin-top: 20px;cursor: pointer;" v-for="(item, index) in tcmResultLatestData.recordResults" :key="index" @click.stop="goResultByAnalysis(item)">
                  <div style="display: flex; align-items: center; justify-content: space-between">
                    <div><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>{{ item.syndromeName }}</div>
                    <div>{{ item.matchingRate }}%匹配度</div>
                  </div>
                  <div class="titleRight2Content1 titleSize14">
                    <span :class="itemq.checked ? 'newChecked' : ''" v-for="(itemq, indexq) in item.newsyndromeSymptoms" :key="indexq">
                      {{ "【" + itemq.name + "】" }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="titleSize16" v-else>
                <div style="margin-top: 20px">
                  <img src="@/assets/img/interrogation/nodata.png" alt="" srcset="" />
                  <p style="text-align: center">暂无测试结果~</p>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="虚症辨证" name="3">
              <!-- <div class="titlerightBox2 flex-l-b">
                <div :class="ResultByUserIndex == '0' ? 'titleRight2title' : 'notitleRight2title'" @click="goResultByUserList(0, '中医体质')">体质辨识</div>
                <div :class="ResultByUserIndex == '1' ? 'titleRight2title' : 'notitleRight2title'" @click="goResultByUserList(1, '中医虚证')">虚症辨证</div>
              </div> -->
              <div style="text-align: center; padding-bottom: 20px;">
                <el-button type="primary" @click="goIndex(1, '中医虚证')">开始线上诊断</el-button>
                <el-button type="primary" @click="goResultByUserList(1, '中医虚证')" plain>已诊断，刷新</el-button>
              </div>
               <el-input placeholder="请输入虚证名称" v-model="userSymptomValue" class="input-with-select">
                <i slot="suffix" class="el-icon-circle-close" @click="canclechuserSymptom" style="margin-top:12px"></i>
                <el-button slot="append" icon="el-icon-search" @click="searchuserSymptom"></el-button>
              </el-input>
                <div style="margin-top: 15px;cursor: pointer;"
                  v-for="(item, index) in searchResultByUserList" :key="index"
                  @click="goResultByAnalysisXZ(item)">
                  <div style="display: flex; align-items: center; justify-content: space-between">
                    <div><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>{{
                      item.name
                    }}</div>
     

                  </div>
                  <div class="titleRight2Content1 titleSize14" v-html="item.infos[0].info.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, '$1')">
         
                  </div>
                  <!-- <div class="titleRight2Content1 titleSize14">
                    <span :class="itemq.checked ? 'newChecked' : ''" v-for="(itemq, indexq) in item.newsyndromeSymptoms" :key="indexq">
                      {{ "【" + itemq.name + "】" }}
                    </span>
                  </div> -->
                </div>
              <div class="titleSize16"
                v-if="ResultByUserList[0].questionnaireDetermines.length > 0 && ResultByUserList.length > 0">
                <div style="margin-top: 10px">
                  <span>{{ patientDetail.name }}</span>，<span>{{ patientDetail.sex ? "男" : "女" }}</span>，<span>{{
                    patientDetail.phone }}</span>
                </div>
                <div style="margin-top: 10px">体质测试结果：{{ ResultByUserList[0].questionnaireDetermines[0].name }}</div>
                <div class="ResultLateline">
                  您的症状清单：
                  <span v-if="!ResultByUserList[0].userSymptom"> </span>
                  <span v-else style="color: #df7d1f"
                    v-for="(itemsym, indexitemsym) in ResultByUserList[0].userSymptom.split('|')" :key="indexitemsym">
                    <template v-if="itemsym">{{ "【" + itemsym + "】" }}</template></span>
                </div>
                <div style="margin-top: 15px;cursor: pointer;"
                  v-for="(item, index) in ResultByUserList[0].questionnaireDetermines" :key="index"
                  @click="goResultByAnalysisXZ(item)">
                  <div style="display: flex; align-items: center; justify-content: space-between">
                    <div><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>{{
                      item.name
                    }}</div>
                    <div>{{ item.scores + "分" }}</div>
                  </div>
                  <!-- <div class="titleRight2Content1 titleSize14">
                    <span :class="itemq.checked ? 'newChecked' : ''" v-for="(itemq, indexq) in item.newsyndromeSymptoms" :key="indexq">
                      {{ "【" + itemq.name + "】" }}
                    </span>
                  </div> -->
                </div>
              </div>
              <div v-else>
                <div style="margin-top: 20px">
                  <img src="@/assets/img/interrogation/nodata.png" alt="" srcset="" />
                  <p style="text-align: center">暂无测试结果~</p>
                </div>
              </div>
              <!-- <div v-if="ResultByUserIndex == '0'" style="text-align: center; padding-top: 20px; border-top: 1px dashed #e6e6e6;">
                <el-button type="primary" @click="goResultByUserList(0, '中医体质')" plain>刷新</el-button>
                <el-button type="primary" @click="goIndex(0, '中医体质')">开始诊断</el-button>
              </div> -->

            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="titleTopCenterRight" v-if="stepIndex == 3">
        <div class="titleTopCenterRightBox" style="padding-bottom: 10px">
          <el-tabs v-model="activeright3Name" @tab-click="handleClick3">
            <el-tab-pane label="智能处方" name="first">
            <el-button-group>
                <el-button @click="changeDetails(0)"  :type="changeTable == 0 ? 'primary' : ''">方剂处方</el-button>
                <el-button @click="changeDetails(1)" :type="changeTable == 1 ? 'primary' : ''">成药处方</el-button>
                <el-button @click="changeDetails(2)" :type="changeTable == 2 ? 'primary' : ''">输液处方</el-button>
            </el-button-group>
              <div class="firstRight3Box1" v-if="changeTable == 0">
                <div class="flex-l-b" v-for="(item, index) in zHerbalList" :key="index">
                  <div style="padding: 10px 0;" @click="goherbalDetail2(item.drug)"><i
                      class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 15px"></i>{{ item.drug.name
                    }}
                    <i class="el-icon-tickets" style="color: #C29200;margin-left: 1 5px"></i> <span style="color: #C29200;">{{item.questionnaireResultName}}</span>
                    <!-- <el-tag type="success">{{item.questionnaireResultName}}</el-tag> -->
                  </div>
                </div>
                <div class="flex-l-b" v-for="(item, index) in tHerbalList" :key="index">
                  <div style="padding: 10px 0" @click="goherbalDetail1(item)">
                    <i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 15px"></i>
                      <span v-if="item.associationDrugHashKey">
                         {{ item.drug.name }} <span style="color:#00AFB5">合</span> {{item.associationName}} <i class="el-icon-tickets" style="color: #C29200;margin-left: 5px"></i> <span style="color: #C29200;">{{item.showName}}</span>
                      </span>
                     <span v-else>{{ item.drug.name }} <i class="el-icon-tickets" style="color: #C29200;margin-left: 1 5px"></i> <span style="color: #C29200;">{{item.showName}}</span></span>
                    <div style="margin-left:18px" v-if="item.concomitantDisease">
                      <!-- <el-tag class='eltagre' type="success">{{item.concomitantDisease}}</el-tag> -->
                      <span class='eltagre'>{{item.concomitantDisease}}</span>
                    </div>
                  </div>
                </div>
                <div style="margin-top: 20px" v-if="tHerbalList.length == 0 && zHerbalList.length == 0">
                  <img src="@/assets/img/interrogation/nodata.png" alt="" srcset="" />
                </div>
              </div>
              <div class="firstRight3Box1" v-if="changeTable == 1 || changeTable == 2">
                  <div  v-for="(item1, index) in QtHerbalList" :key="index">
                    <div v-for="(item,index1) in item1" :key="index1">
                      <div style="padding: 10px 0" @click="goherbalDetail1QT(item)">
                        <i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 15px"></i>
                        <span>{{ item.prescriptionName }} <i class="el-icon-tickets" style="color: #C29200;margin-left: 1 5px"></i> <span style="color: #C29200;">{{item.concomitantDisease}}</span></span>
                      </div>
                    </div>
                  </div>
                  <div style="margin-top: 20px" v-if="QtHerbalList.length == 0">
                    <img src="@/assets/img/interrogation/nodata.png" alt="" srcset="" />
                  </div>
              </div>
              <!-- <div class="firstRight3BottomBox">
                <div class="firstRight3BottomBoxDiv">{{ JChooseName ? JChooseName : "暂未选择" }}</div>
                <el-table :data="herbalDetail.compositions" v-loading="loading" style="width: 100%">
                  <el-table-column prop="compositionName" label="中药材" width="80">
                    <template slot-scope="scope">
                      <div data-size="14" v-html="scope.row.compositionName1"></div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="dosage" label="用量"> </el-table-column>
                  <el-table-column prop="recommendDosage" label="推荐用量">
                    <template slot-scope="scope">
                      {{ scope.row.recommendDosage ? scope.row.recommendDosage : "--" }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="remark" label="炮制方法"> </el-table-column>
                </el-table>
              </div>
              <div></div>
              <div style="text-align: right; padding-top: 20px; border-top: 1px dashed #e6e6e6; margin-right: 20px">
                <div v-for="(item, index) in patientFormPre.compositionList" :key="index" style="margin-top: 10px">
                  <el-button type="primary" @click="addgetLoadPrescription(index)">导入处方{{ index * 1 + 1 }}</el-button>
                </div>
              </div> -->
            </el-tab-pane>
            <el-tab-pane label="常用处方" name="second">
              <div class="flex-l-b">
                <el-input placeholder="搜索常用处方..." prefix-icon="el-icon-search" style="width: 80%"
                  v-model="MyCommonKeyword" @input="MyCommonKeywordInput"></el-input>
                <!-- <el-select v-model="scope.row.compositionId" v-if="scope.row.edit"
                                    filterable remote size="small" reserve-keyword placeholder="请输入成分"
                                    ref="selectLabel" :remote-method="SelectComposition"
                                    @change="ConstituteChange($event,scope.row)">
                                    <el-option v-for="item in EditDrugCompositio" :key="item.compositionId"
                                        :label="item.compositionName" :value="item.compositionId">
                                    </el-option>
                                </el-select> -->
                <el-select v-model="drugOrgName" placeholder="请选择" style="width: 140px; margin-left: 10px"
                  @change="MyCommonConstituteChange">
                  <el-option v-for="item in options3RightData" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="firstRight3Box">
                <div class="todayDetail">
                  <div class="todayTitleTh todayTitleThTtitle">
                    <div class="thwidthL">处方名称</div>
                    <div class="thwidthL">类型</div>
                  </div>
                  <div v-for="(itemMyCommon, indexMyCommon) in MyCommonPrescriptionList" :key="indexMyCommon"
                    @click="goMyCommonPrescrip(itemMyCommon, indexMyCommon)">
                    <div
                      :class="MyCommonindex == indexMyCommon ? 'todayTitleTh todayTitleThDetail titleTopLeftTableC' : 'todayTitleTh todayTitleThDetail'"
                      style="padding: 10px 5px">
                      <div class="thwidthL1"><i class="el-icon-circle-plus-outline titleTopLeftTableC"
                          style="margin-right: 5px"></i>{{ itemMyCommon.prescriptionName }}</div>
                      <div class="thwidthL">{{ drugOrgName }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pageBox">
                <el-pagination background layout="prev, pager, next" :page-size="15"
                  @current-change="MyCommonCurrentChange" :current-page.sync="MyCommoncurrentPage"
                  :total="MyCommonPageTotal"> </el-pagination>
              </div>
              <div class="firstRight3BottomBox" v-if="tab3ClickData == 0">
                <div class="firstRight3BottomBoxDiv">{{ GChooseName ? GChooseName : "暂未选择" }}</div>
                <el-table :data="MyCommonPrescripTableData.mzPrescriptionDetail" style="width: 100%">
                  <el-table-column prop="drugOrgName" label="中药材" width="100"> </el-table-column>
                  <el-table-column prop="weight" label="用量" width="100"> </el-table-column>
                  <el-table-column prop="recommendDosage" label="推荐用量">
                    <template slot-scope="scope">
                      {{ scope.row.recommendDosage ? scope.row.recommendDosage : "--" }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="usage" label="炮制方法"> </el-table-column>
                  <el-table-column prop="decoct" label="煎药方法">
                    <template slot-scope="scope">
                      {{ scope.row.decoct ? scope.row.decoct : "--" }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="firstRight3BottomBox" v-if="tab3ClickData == 1">
                <div class="firstRight3BottomBoxDiv">{{ GChooseName ? GChooseName : "暂未选择" }}</div>
                <el-table :data="MyCommonPrescripTableData.mzPrescriptionDetail" style="width: 100%">
                  <el-table-column prop="drugOrgName" label="药品" width="100"> </el-table-column>
                  <el-table-column prop="usage" label="用法" width="100"> </el-table-column>
                  <el-table-column prop="dosage" label="单次剂量">
                    <template slot-scope="scope">
                      {{ scope.row.dosage + scope.row.dosageUnit }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="firstRight3BottomBox" v-if="tab3ClickData == 2">
                <div class="firstRight3BottomBoxDiv">{{ GChooseName ? GChooseName : "暂未选择" }}</div>
                <div v-for="(item, index) in MyCommonPrescripTableDataSY.mzPrescriptionGroup" :key="index">
                  <div class="firstRight3BottomBoxDiv">{{ "组" + index + 1 }}</div>
                  <el-table :data="MyCommonPrescripTableDataSY.mzPrescriptionGroup" style="width: 100%">
                    <el-table-column prop="usage" label="用法" width="100"> </el-table-column>
                    <el-table-column prop="frequency" label="用药频率" width="100"> </el-table-column>
                    <el-table-column prop="drippingSpeed" label="开药量">
                      <template slot-scope="scope">
                        {{ scope.row.drippingSpeed + scope.row.drippingSpeedUnit }}
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-table :data="item.mzPrescriptionDetailSY" style="width: 100%">
                    <el-table-column prop="drugOrgName" label="药品" width="100"> </el-table-column>
                    <el-table-column prop="dosage" label="单次剂量" width="100">
                      <template slot-scope="scope">
                        {{ scope.row.dosage + scope.row.dosageUnit }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="defaultDosage" label="开药量">
                      <template slot-scope="scope">
                        {{ scope.row.defaultDosage + scope.row.defaultDosageUnit }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div v-if="tab3ClickData == 0"
                style="text-align: right; padding-top: 20px; border-top: 1px dashed #e6e6e6; margin-right: 20px">
                <div v-for="(item, index) in patientFormPre.compositionList" :key="index" style="margin-top: 10px">
                  <el-button type="primary" @click="addgetLoadPrescription1(index)">导入处方{{ index * 1 + 1 }}</el-button>
                </div>
              </div>
              <div v-if="tab3ClickData == 1"
                style="text-align: right; padding-top: 20px; border-top: 1px dashed #e6e6e6; margin-right: 20px">
                <div v-for="(item, index) in patientFormPre.CZcompositionList" :key="index" style="margin-top: 10px">
                  <el-button type="primary" @click="addgetLoadPrescription2(index)">导入处方{{ index * 1 + 1 }}</el-button>
                </div>
              </div>
              <div v-if="tab3ClickData == 2"
                style="text-align: right; padding-top: 20px; border-top: 1px dashed #e6e6e6; margin-right: 20px">
                <div v-for="(item, index) in patientFormPre.SYcompositionList" :key="index" style="margin-top: 10px">
                  <el-button type="primary" @click="addgetLoadPrescription3(index)">导入处方{{ index * 1 + 1 }}</el-button>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="经典方" name="fourth">
              <el-input placeholder="输入处方名称" prefix-icon="el-icon-search" v-model="HerbalValue"
                @input="inputChange"></el-input>
              <div class="firstRight3Box">
                <div class="flex-l-b" v-for="(item, index) in HerbalList" :key="index">
                  <div style="padding: 10px 0" @click="goherbalDetail(item)"><i
                      class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>{{
                        item.herbalPrescriptionName }}</div>
                </div>
              </div>
              <div class="firstRight3BottomBox">
                <div class="firstRight3BottomBoxDiv">{{ JChooseName ? JChooseName : "暂未选择" }}</div>
                <el-table :data="herbalDetail.compositions" v-loading="loading" style="width: 100%">
                  <el-table-column prop="compositionName" label="中药材" width="80">
                    <template slot-scope="scope">
                      <div data-size="14" v-html="scope.row.compositionName1"></div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="dosage" label="用量"> </el-table-column>
                  <el-table-column prop="recommendDosage" label="推荐用量">
                    <template slot-scope="scope">
                      {{ scope.row.recommendDosage ? scope.row.recommendDosage : "--" }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="remark" label="炮制方法"> </el-table-column>
                </el-table>
              </div>
              <div>
                用法：
                <div data-size="14" v-html="herbalDetail.medicineinfo"></div>
              </div>
              <div style="text-align: right; padding-top: 20px; border-top: 1px dashed #e6e6e6; margin-right: 20px">
                <div v-for="(item, index) in patientFormPre.compositionList" :key="index" style="margin-top: 10px">
                  <el-button type="primary" @click="addgetLoadPrescription(index)">导入处方{{ index * 1 + 1 }}</el-button>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- 详情 -->
    <el-dialog :title="recordResultsInfo.syndromeName" :visible.sync="setInfoDialog" width="30%">
      <h3 v-if="recordResultsInfo.analysis">鉴别分析</h3>
      <p v-if="recordResultsInfo.analysis"
        v-html="math(recordResultsInfo.analysis.toString().replace(/\n/g, '</p> <p>'))">
      </p>
      <h3 v-if="recordResultsInfo.analysis1">治法</h3>
      <p v-if="recordResultsInfo.analysis1"
        v-html="math(recordResultsInfo.analysis1.toString().replace(/\n/g, '</p> <p>'))">
      </p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="setInfoDialog = false">取 消</el-button>
        <el-button type="primary" @click="goResultBy(recordResultsInfo.item)">确 定</el-button>
      </div>
    </el-dialog>
    <!-- <div style="width:100%;height:200px;background: #ffffff;"></div> -->
    <!-- 检查治疗项 -->
    <el-dialog title="检查治疗项" :visible.sync="checkItemsVisible" width="40%" center :show-close="false">
      <div class="flex-l checkItemsBox" v-for="(itemcheckItems, index) in checkItemsList" :key="index">
        <el-select style="width: 70%" v-model="itemcheckItems.checkItemValue" value-key="id" filterable remote
          reserve-keyword placeholder="请输入检查治疗项" @change="checkItemsChange(itemcheckItems,index)">
          <el-option v-for="item in checkItemsOptions" :key="item.id" :label="item.itemName" :value="item"> </el-option>
        </el-select>
        <!-- <el-input v-model="checkItemsValue" class="stepInput" ></el-input> -->
        <el-input v-model="itemcheckItems.checkItemsNum" @input="InputcheckItemsNum(itemcheckItems)"
          style="width: 8%; margin-right: 10px; margin-left: 10px"></el-input>
        <div class="inputTag">次</div>
        <div>￥{{ itemcheckItems.tradingPrice }}</div>
        <div><i class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px"
            @click="godetcheckItem(index)"></i></div>
      </div>
      <el-button size="mini" type="primary" icon="el-icon-plus" @click="goaddcheckItem"> </el-button>
      <div class="flex-l-b" style="justify-content: flex-end">
        <div class="">合计金额<span style="color:#FF0000;margin-left:10px">￥<span style="color:#FF0000;font-size: 24px;">{{CheckItemValue}}</span></span></div>
        <div style="margin-left:80px">
          <!-- checkItemsVisible = false  -->
          <el-button @click="cancleCheckItemsVisible">取消</el-button>
          <el-button type="primary" @click="submitCheckItemsVisible" :loading="paystep2loading">确认付费</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 处方审核选择医生 -->
    <el-dialog title="提示" :visible.sync="doctorDialogVisible" width="60%" :show-close="false" :close-on-click-modal="false">
      <div class="">
        <!-- <el-steps v-if="!AuditNextDataSuc"  :active="doctorDialogProgress" finish-status="success" simple style="margin-bottom: 20px">
          <el-step title="1.选择审核医生"></el-step>
          <el-step title="2.选择药剂师"></el-step>
          <el-step title="3.支付" ></el-step>
        </el-steps>
        <el-steps v-else  :active="doctorDialogProgress" finish-status="success" simple style="margin-bottom: 20px">
          <el-step title="1.选择药剂师"></el-step>
          <el-step title="2.支付" ></el-step>
        </el-steps> -->
        <el-steps  :active="doctorDialogProgress" finish-status="success" simple style="margin-bottom: 20px">
          <el-step title="1.选择审核医生"></el-step>
          <el-step title="2.支付" ></el-step>
        </el-steps>
        <div class="">
           <!-- && !AuditNextDataSuc -->
          <div class="flex-l" v-if="doctorDialogProgress == 1">
            <el-button style="margin: 10px;margin-top:0" :type="indexTypeDoctor == 0 ? 'primary' : ''" size="small"
              @click="auditkeyDoctor(0)">本院医生</el-button>
            <el-button style="margin: 10px;margin-top:0;margin-left:0" :type="indexTypeDoctor == 1 ? 'primary' : ''"
              size="small" @click="auditkeyDoctor(1)">平台专家</el-button>
          </div>
          <!-- <div class="flex-l" v-if="doctorDialogProgress == 2">
            <el-button style="margin: 10px;margin-top:0">药剂师</el-button>
          </div> -->
          <div class="flex-l" v-if="doctorDialogProgress != 3">
            <el-input v-model="auditkeyWord" placeholder="姓名/电话" style="width: 20%; margin: 0 10px"></el-input>
            <el-select v-model="depvalue" placeholder="请选择部门" @change="depvalueChange" clearable style="width: 15%; margin: 0 10px">
              <el-option style="width: 30%; margin: 0 10px" v-for="(item, index) in depoptions" :key="index"
                :label="item.name" :value="item.id"> </el-option>
            </el-select>
            <div class="flex-l" style="margin: 0 10px">
              <span>价格区间</span>
              <el-input-number :controls="false" v-model="priceKeyWordmin" placeholder="0" style="width: 10%;margin: 0 5px"></el-input-number>
              <span>-</span>
              <el-input-number :controls="false" v-model="priceKeyWordmax" placeholder="0" style="width: 10%;margin: 0 5px"></el-input-number>
              <el-button style="margin: 0 10px" @click="auditkeySearch" type="primary">搜索</el-button>
            </div>
          </div>
          <div class="flex-l-b1" style="min-height:300px;margin-top:10px" v-if="doctorDialogProgress == 1">
            <div style="width: 65%;">
              <div style="max-height:400px;min-height:200px;overflow-y: auto">
                <el-table ref="singleTable" :data="AuditDoctorList" style="width: 100%" highlight-current-row
                  @current-change="handleSelectionChangeAudit">
                  <el-table-column type="index" width="55"> </el-table-column>
                  <el-table-column label="姓名" width="120">
                    <template slot-scope="scope">{{ scope.row.userName }}</template>
                  </el-table-column>
                  <el-table-column prop="adviceContent" label="部门">
                    <template slot-scope="scope">{{ scope.row.department ? scope.row.department.name : '暂无'
                      }}</template>
                  </el-table-column>
                  <el-table-column prop="addTime" label="联系方式" show-overflow-tooltip>
                    <template slot-scope="scope">{{ scope.row.phone }}</template>
                  </el-table-column>

                  <el-table-column prop="auditCount" label="待审核处方" show-overflow-tooltip align='center'>
                    <template slot-scope="scope">
                      <div class="dangeGreen">
                        {{ scope.row.auditCount ? scope.row.auditCount : 0 }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="auditDiagnoseMoney" label="审核费用" show-overflow-tooltip align='center'>
                    <template slot-scope="scope">
                      <div class="danger">
                        {{ scope.row.auditDiagnoseMoney ? scope.row.auditDiagnoseMoney + "元" : 0 }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table>

              </div>

              <div class="pageBox">
                <el-pagination background layout="prev, pager, next" :page-size="15"
                  @current-change="AuditCurrentChange" :current-page.sync="AuditcurrentPage" :total="auditTotal">
                </el-pagination>
              </div>
            </div>
            <div style="width: 30%;font-size:16px;margin-top:-40px">
              <div class="noImgData" v-if="JSON.stringify(auditDetail) == '{}'">
                <img src="@/assets/img/nodata1.png" alt="">
                <div style="text-align: center;color:#999999">暂未选择审核医生~</div>
              </div>
              <div v-else>
                <div class="flex-l">
                  <div class="elImagePho">
                    <img v-if="auditDetail.photoFile" :src="Services.ossUpload + auditDetail.photoFile" alt="">
                    <img v-else src="@/assets/img/head_img.png" />
                  </div>
                  <div>
                    <div class="flex-l" style="margin-left:10px">
                      <div style="font-size:18px;font-weight: bold;">{{ auditDetail.userName }}</div>
                      <div style="margin: 0 10px">{{ auditDetail.department ? auditDetail.department.name : '' }}</div>
                      <div>{{ auditDetail.userPost ? auditDetail.userPost.userPostName : '' }}</div>
                    </div>
                    <div>
                      <div style="margin-left:10px;">{{ auditDetail.organization ? auditDetail.organization.name : '' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div style="margin-top:15px">{{ auditDetail.profile }}</div>
                </div>
                <div style="margin-top:15px">
                  <div style="max-height:300px;overflow-y: auto;"><span
                      style="color:#333333;font-weight: bold;">个人介绍:</span> <span v-if="auditDetail.abstract"
                      v-html="auditDetail.abstract"></span> <span v-else>暂未填写</span></div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="flex-l-b1" style="min-height:300px;margin-top:10px" v-if="doctorDialogProgress == 2">
            <div style="width: 65%;">
              <div style="max-height:400px;min-height:200px;overflow-y: auto">
                <el-table ref="singleTable" :data="drugDoctorList" style="width: 100%" highlight-current-row
                  @current-change="handleSelectionChangeDrug">
                  <el-table-column type="index" width="55"> </el-table-column>
                  <el-table-column label="姓名" width="120">
                    <template slot-scope="scope">{{ scope.row.userName }}</template>
                  </el-table-column>
                  <el-table-column prop="adviceContent" label="部门">
                    <template slot-scope="scope">{{ scope.row.department ? scope.row.department.name : '暂无'
                      }}</template>
                  </el-table-column>
                  <el-table-column prop="addTime" label="联系方式" show-overflow-tooltip>
                    <template slot-scope="scope">{{ scope.row.phone }}</template>
                  </el-table-column>

                  <el-table-column prop="auditCount" label="待审核处方" show-overflow-tooltip align='center'>
                    <template slot-scope="scope">
                      <div class="dangeGreen">
                        {{ scope.row.auditCount ? scope.row.auditCount : 0 }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="auditDiagnoseMoney" label="审核费用" show-overflow-tooltip align='center'>
                    <template slot-scope="scope">
                      <div class="danger">
                        {{ scope.row.userOutpatient.pharmacistAuditMoney ? scope.row.userOutpatient.pharmacistAuditMoney + "元" : 0 }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="pageBox">
                <el-pagination background layout="prev, pager, next" :page-size="15"
                  @current-change="drugCurrentChange" :current-page.sync="drugcurrentPage" :total="drugTotal">
                </el-pagination>
              </div>
            </div>
            <div style="width: 30%;font-size:16px;margin-top:-40px">
              <div class="noImgData" v-if="JSON.stringify(drugDetail) == '{}'">
                <img src="@/assets/img/nodata1.png" alt="">
                <div style="text-align: center;color:#999999">暂未选择药剂师~</div>
              </div>
              <div v-else>
                <div class="flex-l">
                  <div class="elImagePho">
                    <img v-if="drugDetail.photoFile" :src="Services.Authorization + drugDetail.photoFile" alt="">
                    <img v-else src="@/assets/img/head_img.png" />
                  </div>
                  <div>
                    <div class="flex-l" style="margin-left:10px">
                      <div style="font-size:18px;font-weight: bold;">{{ drugDetail.userName }}</div>
                      <div style="margin: 0 10px">{{ drugDetail.department ? drugDetail.department.name : '' }}</div>
                      <div>{{ drugDetail.userPost ? drugDetail.userPost.userPostName : '' }}</div>
                    </div>
                    <div>
                      <div style="margin-left:10px;">{{ drugDetail.organization ? drugDetail.organization.name : '' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div style="margin-top:15px">{{ drugDetail.profile }}</div>
                </div>
                <div style="margin-top:15px">
                  <div style="max-height:300px;overflow-y: auto;"><span
                      style="color:#333333;font-weight: bold;">个人介绍:</span> <span v-if="drugDetail.abstract"
                      v-html="auditDetail.abstract"></span> <span v-else>暂未填写</span></div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div v-if="doctorDialogProgress == 3">
            <el-form class="dialog-form" :model="charge" ref="chargeForm">
              <el-form-item label="应收金额" style="color: #ff0000" class="numT">
                <span style="font-size: 25px"><span style="font-size: 14px">￥</span>{{ charge.totalMoney ?
                  (charge.totalMoney - charge.totalPayMoney).toFixed(2) : "0.00" }}</span>
              </el-form-item>
              <template v-if="charge.credit">
                <el-form-item label="姓名" prop="debtUserName">
                  <el-input v-model="charge.debtUserName" style="width: 80%" clearable></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="debtUserPhone">
                  <el-input v-model="charge.debtUserPhone" style="width: 80%" clearable></el-input>
                </el-form-item>
                <el-form-item label="挂账金额" prop="debtMoney">
                  <el-input-number v-model="charge.debtMoney" size="small" controls-position="right" :precision="2" :step="1"
                    :min="1" class="leftInput" @change="checkDebtMoney" style="width: 150px"></el-input-number>
                </el-form-item>
              </template>
              <el-form-item label="付款方式">
                <el-radio-group v-model="charge.payWay" @input='chargePayWayInput'>
                  <el-radio :label="1" :disabled="payAuditloadingTrue">微信</el-radio>
                  <el-radio :label="2">支付宝</el-radio>
                  <el-radio :label="0" :disabled="assetsBalanceIsTrue">余额</el-radio><span
                    style="font-size: 14px; color: #ff0000">（可用余额：￥{{ UserBalance.assetsBalance }}）</span>
                </el-radio-group>
              </el-form-item>
              <template v-if="charge.payWay == 0">
                <el-form-item label="支付密码" prop="payPwd"> <el-input type="password" maxlength="6" v-model="charge.payPwd"
                    show-password style="width: 200px"></el-input><span
                    style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer" @click="getPayPwd">忘记密码？</span>
                </el-form-item>
              </template>
              <template v-if="charge.payWay == 1">
                <div class="flex-space-between-columns" v-if="qRcodeUrl">
                  <img style="width: 120px; height: 120px" :src="qRcodeUrl" alt="Base64 Image" />
                  <div>
                    <span>打开微信扫码支付~ </span>
                    <span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer">刷新</span>
                  </div>
                </div>
              </template>
              <template v-if="charge.payWay == 2">
                <div class="flex-space-between-columns" v-if="qRcodeUrlZ">
                  <iframe
                    :srcdoc="qRcodeUrlZ"
                    frameborder="no"
                    border="0"
                    marginwidth="0"
                    marginheight="0"
                    scrolling="no"
                    width="200"
                    height="200"
                    style="overflow: hidden;margin:10px 0"
                  >
                  </iframe>
                  <div>
                    <span>打开支付宝扫码支付~ </span>
                    <span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer">刷新</span>
                  </div>
                </div>
              </template>
            </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="doctorDialogProgress == 1">
        <el-button @click="cancledialogVisible">取 消</el-button>
        <el-button type="primary" @click="adddialogVisible" :loading="payAuditloading">提交</el-button>
      </span>
      <!-- <span slot="footer" class="dialog-footer" v-if="doctorDialogProgress == 2">
        <el-button @click="cancledialogVisible">取 消</el-button>
        <el-button type="primary" @click="stepadddialogVisible">上一步</el-button>
        <el-button type="primary" @click="addDrugVisible">下一步</el-button>
      </span> -->
      <span slot="footer" class="dialog-footer" v-if="doctorDialogProgress == 3">
        <el-button @click="cancledialogVisible1">取 消</el-button>
        <el-button type="primary" @click="firstDialogVisible">上一步</el-button>
        <el-button type="primary" @click="addpayVisible" :loading="payAuditloading">确认</el-button>
      </span>
    </el-dialog>
    <!-- 用药审查配伍 -->
    <el-dialog title="提示" :visible.sync="comDialogVisible" width="60%">
      <div class="pei-bottom">
        <!-- 推荐用量提示 -->
        <div v-if="!compatibilityResultShow && recommendPreList.length > 0">
           <span style="color:#00AFB5;font-size:16px" v-for="(item,index) in recommendPreList" :key="index">{{item.drugOrgName + ','}}</span>  <span style="color:#FF0000;font-size:16px">与推荐用量不符，请谨慎使用</span>
        </div>
        <ul class="tab-tilte clearfix" v-if="compatibilityResultShow">
          <li @click="cur = 0" :class="[{ active: cur == 0 }, total > 0 ? '' : 'greenbg']">
            <i :class="total > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>全部</em><span>{{ total }}</span>
          </li>
          <li @click="cur = 1" :class="[{ active: cur == 1 }, similarTotal > 0 ? '' : 'greenbg']">
            <i :class="similarTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>重复用药</em><span>{{ similarTotal
              }}</span>
          </li>
          <li @click="cur = 2" :class="[{ active: cur == 2 }, compatibilityTotal > 0 ? '' : 'greenbg']">
            <i :class="compatibilityTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>相互作用</em><span>{{
              compatibilityTotal }}</span>
          </li>
          <li @click="cur = 3" :class="[{ active: cur == 3 }, crowdTotal > 0 ? '' : 'greenbg']">
            <i :class="crowdTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>特殊人群</em><span>{{ crowdTotal
              }}</span>
          </li>
          <li @click="cur = 4" :class="[{ active: cur == 4 }, dietTotal > 0 ? '' : 'greenbg']">
            <i :class="dietTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>饮食禁忌</em><span>{{ dietTotal
              }}</span>
          </li>
          <li @click="cur = 5" :class="[{ active: cur == 5 }, avoidTotal > 0 ? '' : 'greenbg']">
            <i :class="avoidTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>用药禁忌</em><span>{{ avoidTotal
              }}</span>
          </li>
        </ul>
        <div class="tab-content">
          <ul v-show="cur == 0">
            <li v-for="(similar, index) in similarList" :key="index + '1'">
              <div class="peitab-tit" @click="showCourseInfoList($event)">
                <h3>
                  【重复用药】<span>{{ similar.sourceDrugName }} 与 {{ similar.targetDrugName }} 存在重复用药情况，请谨慎使用！</span>
                </h3>
                <div class="show-more">
                  <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                  <i class="el-icon-arrow-down"></i>
                </div>
              </div>
              <div class="peitab-mes">
                <p v-html="similarhtmlRaw(similar)"></p>
              </div>
            </li>
            <li v-for="(compare, index) in compatibilityList" :key="index + '2'">
              <div class="peitab-tit" @click="showCourseInfoList($event)">
                <div v-if="compare.compatibilityType == 7">
                  <h3 v-for="(tip, index1) in compare.tips" :key="index1">
                    【相互作用】<span>{{ compare.sourceDrugName }} : {{ tip.targetDrugName }} </span>
                  </h3>
                </div>
                <div v-else>
                  <h3>
                    【相互作用】<span>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} 存在以下配伍结果</span>
                  </h3>
                  <div class="show-more">
                    <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </div>
              </div>
              <div v-if="compare.tips" class="peitab-mes">
                <div v-for="(types, index1) in tipGroup(compare.tips)" :key="index1 + '3'">
                  <div v-if="types.keys <= 3">
                    <p v-for="(val, index2) in types.value" :key="index2 + '4'" v-html="namehtmlraw(val)"></p>
                  </div>
                  <div v-if="types.keys == 4">
                    <div v-for="(val, index2) in types.value.filter((i) => i.title == '临床建议')" :key="index2 + '6'">
                      <p v-html="namehtmlraw(val)"></p>
                    </div>
                  </div>
                  <div v-if="types.keys == 5">
                    <p v-for="(val, index2) in types.value" :key="index2 + '7'" v-html="namehtmlraw(val)"></p>
                  </div>
                  <div v-if="types.keys == 7">
                    <p v-for="(val, index2) in types.value" :key="index2 + '6'">建议单独使用</p>
                  </div>
                  <div v-if="types.keys == 8">
                    <p v-for="(val, index2) in types.value.filter((i) => i.title == '结果')" :key="index2 + '8'"
                      v-html="namehtmlraw(val)"></p>
                  </div>
                  <p v-if="types.keys != 7 && types.keys != 0" class="linkDetail"
                    @click="toCompatibilityDetail(compare.sourceType, compare.sourceHashKey, compare.targetType, compare.targetHashKey)">
                    查看详情</p>
                </div>
              </div>
              <div v-else class="peitab-mes">
                <div>
                  <p>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} ： 无配伍资料或配伍意见不一致。</p>
                </div>
              </div>
            </li>
            <li v-for="(ins, index) in getcrowdList()" :key="index + '9'">
              <div v-for="(item3, index1) in ins.value" :key="index1 + 'a'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【特殊人群1】<span>{{ item3.name }} ({{ ins.tag }}) </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <p v-for="(val, index2) in item3.items" :key="index2 + 'b'" v-html="contenthtmlRaw(val)"></p>
                </div>
              </div>
            </li>
            <template v-if="getNumByType(instructions, 2) > 0">
              <li v-for="(ins, index) in instructions.filter((i) => i.type == 2)[0].items" :key="index + 'e'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【饮食禁忌】<span>{{ ins.name }} </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <div v-for="(item, index1) in ins.items.filter((i) => i.target == null)" :key="index1 + 'f'">
                    <p v-html="item.content.replace(/\n/g, '<br>')"></p>
                  </div>
                </div>
              </li>
            </template>
            <template v-if="getNumByType(instructions, 1) > 0">
              <li v-for="(ins, index) in instructions.filter((i) => i.type == 1)[0].items" :key="index + 'c'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【用药禁忌】<span>{{ ins.name }} </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <p v-for="(item, index1) in ins.items" :key="index1 + 'd'" v-html="contenthtmlRaw(item)"></p>
                </div>
              </li>
            </template>
          </ul>
          <div v-show="cur == 1">
            <li v-for="(similar, index) in similarList" :key="index + 'h'">
              <div class="peitab-tit" @click="showCourseInfoList($event)">
                <h3>
                  【重复用药】<span>{{ similar.sourceDrugName }} 与 {{ similar.targetDrugName }} 存在重复用药情况，请谨慎使用！</span>
                </h3>
                <div class="show-more">
                  <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                  <i class="el-icon-arrow-down"></i>
                </div>
              </div>
              <div class="peitab-mes">
                <p v-html="similarhtmlRaw(similar)"></p>
              </div>
            </li>
          </div>
          <div v-show="cur == 2">
            <li v-for="(compare, index) in compatibilityList" :key="index + 'i'">
              <div class="peitab-tit" @click="showCourseInfoList($event)">
                <div v-if="compare.compatibilityType == 7">
                  <h3 v-for="(tip, index1) in compare.tips" :key="index1 + 'j'">
                    【相互作用】<span>{{ compare.sourceDrugName }} : {{ tip.targetDrugName }} </span>
                  </h3>
                </div>
                <div v-else>
                  <h3>
                    【相互作用】<span>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} 存在以下配伍结果</span>
                  </h3>
                  <div class="show-more">
                    <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </div>
              </div>
              <div v-if="compare.tips" class="peitab-mes">
                <div v-for="(types, index1) in tipGroup(compare.tips)" :key="index1 + 'k'">
                  <div v-if="types.keys <= 3">
                    <p v-for="(val, index2) in types.value" :key="index2 + 'l'" v-html="namehtmlraw(val)"></p>
                  </div>
                  <div v-if="types.keys == 4">
                    <div v-for="(val, index2) in types.value.filter((i) => i.title == '临床建议')" :key="index2 + 'n'">
                      <p v-html="namehtmlraw(val)"></p>
                    </div>
                  </div>
                  <div v-if="types.keys == 5">
                    <p v-for="(val, index2) in types.value" :key="index2 + 'o'" v-html="namehtmlraw(val)"></p>
                  </div>
                  <div v-if="types.keys == 7">
                    <p v-for="(val, index2) in types.value" :key="index2 + '6'">建议单独使用</p>
                  </div>
                  <div v-if="types.keys == 8">
                    <p v-for="(val, index2) in types.value.filter((i) => i.title == '结果')" :key="index2 + 'p'"
                      v-html="namehtmlraw(val)"></p>
                  </div>
                  <p v-if="types.keys != 7 && types.keys != 0" class="linkDetail"
                    @click="toCompatibilityDetail(compare.sourceType, compare.sourceHashKey, compare.targetType, compare.targetHashKey)">
                    查看详情</p>
                </div>
              </div>
              <div v-else class="peitab-mes">
                <p>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} ： 无配伍资料或配伍意见不一致。</p>
              </div>
            </li>
          </div>
          <div v-show="cur == 3">
            <li v-for="(ins, index) in getcrowdList()" :key="index + 'q'">
              <div v-for="(item3, index1) in ins.value" :key="index1 + 'r'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【特殊人群】<span>{{ item3.name }} ({{ ins.tag }}) </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <p v-for="(val, index2) in item3.items" :key="index2 + 's'" v-html="contenthtmlRaw(val)"></p>
                </div>
              </div>
            </li>
          </div>
          <div v-show="cur == 4">
            <template v-if="getNumByType(instructions, 2) > 0">
              <li v-for="(ins, index) in instructions.filter((i) => i.type == 2)[0].items" :key="index + 't'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【饮食禁忌】<span>{{ ins.name }} </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <div v-for="(item, index1) in ins.items.filter((i) => i.target == null)" :key="index1 + 'u'">
                    <p v-html="item.content.replace(/\n/g, '<br>')"></p>
                  </div>
                  <div v-for="(item, index1) in dietGroup(ins.items.filter((i) => i.target != null))"
                    :key="index1 + 'v'">
                    <p v-html="contenthtmlRaw(item, true)"></p>
                  </div>
                </div>
              </li>
            </template>
          </div>
          <div v-show="cur == 5">
            <template v-if="getNumByType(instructions, 1) > 0">
              <li v-for="(ins, index) in instructions.filter((i) => i.type == 1)[0].items" :key="index + 'w'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【用药禁忌】<span>{{ ins.name }} </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <p v-for="(item, index1) in ins.items" :key="index1 + 'x'" v-html="contenthtmlRaw(item)"></p>
                </div>
              </li>
            </template>
          </div>
        </div>
      </div>
      <div class="flex-l-b">
        <el-checkbox  v-if="!compatibilityResultShow" v-model="Agreement">我已知悉以上药品所包含的风险并愿意承担相应责任</el-checkbox>
        <div v-else></div>
        <span>
          <el-button @click="cancleComDialogVisible">取 消</el-button>
          <el-button type="primary" @click="goComDialogVisible">确 定</el-button>
        </span>
      </div>
      <!-- <span slot="footer" class="dialog-footer">

      </span> -->
    </el-dialog>
    <!-- 签名生成的图片 -->
    <el-dialog title="提示" :visible.sync="comDialogAdviceVisible" width="60%">
      <el-table ref="multipleTable" :data="AdviceList" tooltip-effect="dark" style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="名称" width="120">
          <template slot-scope="scope">{{ scope.row.adviceName }}</template>
        </el-table-column>
        <el-table-column prop="adviceContent" label="内容"> </el-table-column>
        <el-table-column prop="addTime" label="添加时间" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.addTime | timefilters }}</template>
        </el-table-column>
      </el-table>
      <div class="pageBox">
        <el-pagination background layout="prev, pager, next" :page-size="15" @current-change="AdvicCurrentChange"
          :current-page.sync="AdvicurrentPage" :total="AdvicePageTotal"> </el-pagination>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="comDialogAdviceVisible = false">取 消</el-button>
        <el-button type="primary" @click="comDialogAdviceVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="服用要求" :visible.sync="comrequestVisible" width="60%">
      <el-table ref="multipleTable" :data="requestOption" tooltip-effect="dark" style="width: 100%"
        @selection-change="handlerequestOption">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="名称" width="120">
          <template slot-scope="scope">{{ scope.row.adviceName }}</template>
        </el-table-column>
        <el-table-column prop="adviceContent" label="内容"> </el-table-column>
        <el-table-column prop="addTime" label="添加时间" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.addTime | timefilters }}</template>
        </el-table-column>
      </el-table>
      <!-- <div class="pageBox">
        <el-pagination background layout="prev, pager, next" :page-size="15" @current-change="AresdvicCurrentChange"
          :current-page.sync="AresdvicurrentPage" :total="AresdvicePageTotal"> </el-pagination>
      </div> -->

      <span slot="footer" class="dialog-footer">
        <el-button @click="comrequestVisible = false">取 消</el-button>
        <el-button type="primary" @click="comrequestVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="电子签名" :visible.sync="esigndialogVisible" width="30%" :before-close="canclesigndialogVisible">
      <vue-esign style="border: 1px solid #ddd; height: 200px" ref="esign" :isCrop="isCrop" :width="600" :height="200"
        :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor"></vue-esign>
      <div class="flex-l-b" style="margin-top:20px">
        <div class="flex-l" v-if="UserSignImg">
          <el-checkbox v-model="signMent">使用上次签名 </el-checkbox>
          <img style="width:100px;height:40px" :src="Services.ossUpload + UserSignImg" alt="" srcset="" />
        </div>
        <div v-else> </div>
        <span>
          <el-button @click="handleReset">清空签名</el-button>
          <el-button @click="canclesigndialogVisible" >取 消</el-button>
          <el-button type="primary" @click="handleGenerate">确 定</el-button>
        </span>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="handleReset">清空签名</el-button>
        <el-button @click="esigndialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleGenerate">确 定</el-button>
      </span> -->
    </el-dialog>
    <!-- 支付检查治疗项 -->
    <el-dialog title="支付界面" :visible.sync="paystep2Visible" :close-on-click-modal="false" :show-close="false"  width="30%">
      <el-form class="dialog-form" :model="charge" ref="chargeForm">
        <el-form-item label="应收金额" style="color: #ff0000" class="numT">
          <span style="font-size: 25px"><span style="font-size: 14px">￥</span>{{ charge.totalMoney ?
            (charge.totalMoney - charge.totalPayMoney).toFixed(2) : "0.00" }}</span>
        </el-form-item>
        <template v-if="charge.credit">
          <el-form-item label="姓名" prop="debtUserName">
            <el-input v-model="charge.debtUserName" style="width: 80%" clearable></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="debtUserPhone">
            <el-input v-model="charge.debtUserPhone" style="width: 80%" clearable></el-input>
          </el-form-item>
          <el-form-item label="挂账金额" prop="debtMoney">
            <el-input-number v-model="charge.debtMoney" size="small" controls-position="right" :precision="2" :step="1"
              :min="1" class="leftInput" @change="checkDebtMoney" style="width: 150px"></el-input-number>
          </el-form-item>
        </template>
        <el-form-item label="付款方式">
          <el-radio-group v-model="charge.payWay" @input='chargePayWayInput'>
            <el-radio :label="1">微信</el-radio>
            <el-radio :label="2">支付宝</el-radio>
            <el-radio :label="0" :disabled="assetsBalanceIsTrue">余额</el-radio><span
              style="font-size: 14px; color: #ff0000">（可用余额：￥{{ UserBalance.assetsBalance }}）</span>
          </el-radio-group>
        </el-form-item>
        <template v-if="charge.payWay == 0">
          <el-form-item label="支付密码" prop="payPwd"> <el-input type="password" maxlength="6" v-model="charge.payPwd"
              show-password style="width: 200px"></el-input><span
              style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer" @click="getPayPwd">忘记密码？</span>
          </el-form-item>
        </template>
        <template v-if="charge.payWay == 1">
          <div class="flex-space-between-columns" v-if="qRcodeUrl">
            <img style="width: 120px; height: 120px" :src="qRcodeUrl" alt="Base64 Image" />
            <div>
              <span>打开微信扫码支付~ </span>
              <span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer">刷新</span>
            </div>
          </div>
        </template>
        <template v-if="charge.payWay == 2">
          <div class="flex-space-between-columns" v-if="qRcodeUrlZ">
            <iframe
              :srcdoc="qRcodeUrlZ"
              frameborder="no"
              border="0"
              marginwidth="0"
              marginheight="0"
              scrolling="no"
              width="200"
              height="200"
              style="overflow: hidden;margin:10px 0"
            >
            </iframe>
            <div>
              <span>打开支付宝扫码支付~ </span>
              <span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer">刷新</span>
            </div>
          </div>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancleSaveBtn2('chargeForm')">取 消</el-button>
        <el-button type="primary" @click="SaveBtn2('chargeForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 支付订单 -->
    <el-dialog title="支付界面" :visible.sync="paydialogVisible" :close-on-click-modal="false" :show-close="false" width="30%">
      <el-form class="dialog-form" :model="charge" ref="chargeForm">
        <el-form-item label="应收金额" style="color: #ff0000" class="numT">
          <span style="font-size: 25px"><span style="font-size: 14px">￥</span>{{ charge.totalMoney ?
            (charge.totalMoney - charge.totalPayMoney).toFixed(2) : "0.00" }}</span>
        </el-form-item>
        <template v-if="charge.credit">
          <el-form-item label="姓名" prop="debtUserName">
            <el-input v-model="charge.debtUserName" style="width: 80%" clearable></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="debtUserPhone">
            <el-input v-model="charge.debtUserPhone" style="width: 80%" clearable></el-input>
          </el-form-item>
          <el-form-item label="挂账金额" prop="debtMoney">
            <el-input-number v-model="charge.debtMoney" size="small" controls-position="right" :precision="2" :step="1"
              :min="1" class="leftInput" @change="checkDebtMoney" style="width: 150px"></el-input-number>
          </el-form-item>
        </template>
        <el-form-item label="付款方式">
          <el-radio-group v-model="charge.payWay" @input='chargePayWayInput'>
            <el-radio :label="1">微信</el-radio>
            <el-radio :label="2">支付宝</el-radio>
            <el-radio :label="0" :disabled="assetsBalanceIsTrue">余额</el-radio><span
              style="font-size: 14px; color: #ff0000">（可用余额：￥{{ UserBalance.assetsBalance }}）</span>
          </el-radio-group>
        </el-form-item>
        <template v-if="charge.payWay == 0">
          <el-form-item label="支付密码" prop="payPwd"> <el-input type="password" maxlength="6" v-model="charge.payPwd"
              show-password style="width: 200px"></el-input><span
              style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer" @click="getPayPwd">忘记密码？</span>
          </el-form-item>
        </template>
        <template v-if="charge.payWay == 1">
          <div class="flex-space-between-columns" v-if="qRcodeUrl">
            <img style="width: 120px; height: 120px" :src="qRcodeUrl" alt="Base64 Image" />
            <div>
              <span>打开微信扫码支付~ </span>
              <span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer">刷新</span>
            </div>
          </div>
        </template>
        <template v-if="charge.payWay == 2">
          <div class="flex-space-between-columns" v-if="qRcodeUrlZ">
            <iframe
              :srcdoc="qRcodeUrlZ"
              frameborder="no"
              border="0"
              marginwidth="0"
              marginheight="0"
              scrolling="no"
              width="200"
              height="200"
              style="overflow: hidden;margin:10px 0"
            >
            </iframe>
            <div>
              <span>打开支付宝扫码支付~ </span>
              <span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer">刷新</span>
            </div>
          </div>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancleSaveBtn('chargeForm')">取 消</el-button>
        <el-button type="primary" @click="SaveBtn('chargeForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 转诊信息 -->
    <el-dialog :title="referralTitle" :visible.sync="referrallogVisible" @close="cancleReferral('referralForm')"
      width="50%">
      <div v-show="referralModal" class="referralMessage">
        <div class="referralMessageTop">
          <span class="referralMessageTopLeft">{{ this.patientForm?.name }}</span>
          <div class="referralMessageTopRight">
            <p>性别：<span>{{ this.patientForm?.sex ? '男' : '女' || '--' }}</span></p>
            <p>年龄：<span>{{ this.patientForm?.age || '--' }}</span></p>
            <p>身份证号：<span>{{ this.patientForm?.idNo || '--' }}</span></p>
            <p>联系电话：<span>{{ this.patientForm?.phone || '--' }}</span></p>
            <p>住址：<span>{{ this.patientForm?.address || '--' }}</span></p>
          </div>
        </div>
        <div class="referralMessageSecond">
          <P class="referralMessageTitle"><span class="referralMessageSpan"></span>患者诊断信息</P>
          <div>
            <p>诊断：<span style="color: #01C2AC">{{ this.chiefComplaint }}</span></p>
            <p>主诉：{{ this.patientFormDes?.main || '--' }}</p>
          </div>
        </div>
        <div class="referralMessageThree">
          <P class="referralMessageTitle"><span class="referralMessageSpan"></span>患者体征信息</P>
          <div class="referralMessageThreeContent">
            <p>体温：<span style="color: #01C2AC">{{ this.patientFormDes?.temperature || '--' }}</span>℃</p>
            <p>体重：<span style="color: #01C2AC">{{ this.patientFormDes?.weight || '--' }}</span>kg</p>
            <p>身高：<span style="color: #01C2AC">{{ this.patientFormDes?.height || '--' }}</span>cm</p>
            <p>血压：<span style="color: #01C2AC">{{ this.patientFormDes?.heightTension || '--' }}</span>/<span
                style="color: #01C2AC">{{ this.patientFormDes?.lowTension
                  ||
                  '--' }}</span>mmHg</p>
            <p>呼吸：<span style="color: #01C2AC">{{ this.patientFormDes?.breathe || '--' }}</span>次/分</p>
            <p>脉搏：<span style="color: #01C2AC">{{ this.patientFormDes?.pulse || '--' }}</span>次/分</p>
          </div>
          <p>其他：{{ this.patientFormDes?.otherCheck || '--' }}</p>
        </div>
        <div class="referralMessageFour">
          <P class="referralMessageTitle"><span class="referralMessageSpan"></span>既往病史</P>
          <div class="referralMessageFourContent">
            <p>既往史：{{ this.patientFormDes?.past || '--' }}</p>
            <p>过敏史：{{ this.patientFormDes?.irritability || '--' }}</p>
            <p>家族史：{{ this.patientFormDes?.family || '--' }}</p>
          </div>
          <div class="flex-l" style="margin-top: 20px;">症状图片：
            <div style="flex-wrap: wrap" v-if="patientFormDes?.DiagnoseImgList?.length" class="flex-l">
              <div v-for="(item, index) in patientFormDes?.DiagnoseImgList" style="margin-left: 10px;" :key="index">
                <a :href="Services.ossUpload + item.filePath" target="_blank" title="查看最大化图片">
                  <img :src="Services.ossUpload + item.filePath" class="avatar" />
                </a>
              </div>
            </div>
            <div  v-if="!patientFormDes?.DiagnoseImgList?.length">--</div>
          </div>
          <div class="flex-l" style="margin-top: 20px;">
            处方图片：
            <div style="flex-wrap: wrap" v-if="patientFormDes?.PrescriptionImgList?.length" class="flex-l">
              <div v-for="(item, index) in patientFormDes.PrescriptionImgList" style="margin-left: 10px;" :key="index">
                <a :href="Services.ossUpload + item.filePath" target="_blank" title="查看最大化图片">
                  <img :src="Services.ossUpload + item.filePath" class="avatar" />
                </a>
              </div>
            </div>
            <div  v-if="!patientFormDes?.PrescriptionImgList?.length">--</div>
          </div>
        </div>
      </div>
      <el-form class="dialog-form" v-show="!referralModal" :model="referralform" ref="referralform" :rules="rules">
          <el-col :span="12">
            <el-form-item label="病情程度" :label-width="formLabelWidth" prop="degree">
              <el-select v-model="referralform.degree" placeholder="请选择病情程度">
                <el-option label="轻度" value="1"></el-option>
                <el-option label="中度" value="2"></el-option>
                <el-option label="重度" value="3"></el-option>
                <el-option label="严重" value="4"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否加急" :label-width="formLabelWidth">
              <el-switch :active-value="1" :inactive-value="0" v-model="referralform.urgent" active-color="#13ce66">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
          <el-form-item label="转诊原因" :label-width="formLabelWidth" prop="reason">
            <el-select style="width: 100%;" v-model="referralform.reason" placeholder="请选择转诊原因">
              <el-option label="病情疑难复杂不能明确诊断，需要进一步诊治" value="病情疑难复杂不能明确诊断，需要进一步诊治"></el-option>
              <el-option label="限于医疗机构的诊疗水平、技术能力或者设备条件等，不具备诊治能力" value="限于医疗机构的诊疗水平、技术能力或者设备条件等，不具备诊治能力"></el-option>
              <el-option label="不具备相应的医疗技术临床应用资质或者手术资质" value="不具备相应的医疗技术临床应用资质或者手术资质"></el-option>
              <el-option label="诊治疾病超出医疗机构执业登记的诊疗科目" value="诊治疾病超出医疗机构执业登记的诊疗科目"></el-option>
              <el-option label="传染病按照有关规定需转入指定医疗机构治疗" value="传染病按照有关规定需转入指定医疗机构治疗"></el-option>
              <el-option label="卫生健康行政部门规定的其他情形" value="卫生健康行政部门规定的其他情形"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" placeholder="请输入内容" v-model="referralform.introduction">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="" :label-width="formLabelWidth">
            <el-radio-group v-model="referralType" @change="selectReferralType">
              <el-radio :label="1">内部</el-radio>
              <el-radio :label="2">合作医院</el-radio>
              <!-- <el-radio :label="3">其他医院</el-radio> -->
            </el-radio-group>
            <!-- <el-checkbox style="margin-left: 15px" v-model="referralform.green"
              @change="checkboxChange">直接转诊到该医院的绿色通道</el-checkbox> -->
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="接诊医院" :label-width="formLabelWidth" prop="hospitalId"
            v-if="referralType == 2 && !referralform.green">
            <el-select v-model="referralform.hospitalId" placeholder="请选择接诊医院" @change="selectHospital">
              <el-option v-for="item in HospitalData" :label="item.name" :value="item.id" :key="item.index">
              </el-option>
          </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="接诊部门" prop="departmentId" :label-width="formLabelWidth"
            v-if="referralType != 3 && !referralform.green">
            <el-cascader ref="myCascader" :options="DepartmentData" :props="{
              expandTrigger: 'hover',
              emitPath: false,
              multiple: false,
              checkStrictly: true,
              value: 'id',
              label: 'name',
              children: 'childrens',
            }" v-model="referralform.departmentId" @change="selectDepartment" :disabled="disdepartment">
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="接诊医生" :label-width="formLabelWidth" prop="doctorId"
            v-if="referralType != 3 && !referralform.green">
            <el-select v-model="referralform.doctorId" placeholder="请选择接诊医生">
              <el-option v-for="item in DoctorData" :label="item.userName" :value="item.id" :key="item.index">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
          <!-- <el-form-item label="接诊医院" :label-width="formLabelWidth" prop="hospitalName" v-if="referralType == 3">
            <el-input placeholder="请输入接诊医院" v-model="referralform.hospitalName"></el-input>
          </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancleReferral('referralForm')">取 消</el-button>
        <el-button type="primary" v-show="!referralModal"
          @click="() => { this.referralModal = true; this.referralTitle = '确认转诊患者信息' }">上一步</el-button>
        <el-button type="primary" v-show="referralModal"
          @click="() => { this.referralModal = false; this.referralTitle = '转诊处理' }">下一步</el-button>
        <el-button type="primary" v-show="!referralModal" @click="saveReferral('referralForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog 
      title="载入处方"
      :visible.sync="hefangDialogVisible"
      width="50%"
    >
      <div class="firstRight3BottomBox" style="padding-top:0px">
        <div class="firstRight3BottomBoxDiv" style="color:#333333">{{ JChooseName ? JChooseName : "暂未选择" }}</div>
        <div class="flex-l-b" style="flex-wrap: wrap;font-size: 16px;align-items: baseline;">
          <div class="" v-for="(item,index) in hefangDialogList" :key="index" style="width:48%;margin-bottom:10px;margin-right: 10px;">
            <span style="color:#E39651">{{item.merchantId}}:</span>
            <span v-for="(item1,index1) in item.dataInfo" :key="index1" style="margin-left:10px">
              <span v-if="item1.addOrSub" @click="addHefangDialogList(item1)">加</span>
              <span v-if="item1.addOrSub" @click="addHefangDialogList(item1)" style="color:#00AFB5"> {{item1.isPrescription ? item1.prescriptionName + "(方剂)" : item1.drugCompositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1")}}</span>
              <span v-if="!item1.addOrSub" @click="cancleHefangDialogList(item1)">去</span>
              <span v-if="!item1.addOrSub" style="color:#FF0000" @click="cancleHefangDialogList(item1)"> {{item1.drugCompositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1")}}</span>
            </span>
          </div>
        </div>
        <div class="flex-l-b" style="font-size: 16px;margin-top:10px" v-if="hefangDialogList.length > 0"> 
          <div class="flex-l">
            <span style="font-weight: bold;">加减药方</span>
            <span style="color:#666666;margin-left:20px">可根据相关症状，点击药材对处方进行调整</span>
          </div>
          <el-button type="primary" @click="resetHefangDialogList" size="mini">撤销</el-button>
        </div>
        <el-table :data="herbalDetail.compositions" v-loading="loading" style="width: 100%;max-height: 300px;overflow-y: auto;">
          <el-table-column prop="compositionName" label="中药材" width="200">
            <template slot-scope="scope">
              <div data-size="14" v-html="scope.row.compositionName1"></div>
            </template>
          </el-table-column>
          <el-table-column prop="dosage" label="用量"  width="200"> </el-table-column>
          <el-table-column prop="recommendDosage" label="推荐用量"  width="280">
            <template slot-scope="scope">
              {{ scope.row.recommendDosage ? scope.row.recommendDosage : "--" }}
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="炮制方法"> </el-table-column>
        </el-table>
      </div>
      <div></div>
      <div style="text-align: right; padding-top: 20px; margin-right: 20px;display: flex;
          align-items: center;
          justify-content: end;">
        <el-button type="primary" @click="hefangDialogVisible = false" plain>取消</el-button>
        <div v-for="(item, index) in patientFormPre.compositionList" :key="index" style="margin-left:10px">
          <el-button type="primary" @click="addgetLoadPrescription(index)">导入处方{{ index * 1 + 1 }}</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog 
      title="载入处方"
      :visible.sync="hefangDialogVisibleQT"
      width="50%"
    >
      <div class="firstRight3BottomBox" style="padding-top:0px">
        <div class="firstRight3BottomBoxDiv" style="color:#333333">{{ JChooseName ? JChooseName : "暂未选择" }}</div>
        <el-table :data="hefangDialogListQT" style="width: 100%;max-height: 300px;overflow-y: auto;">
          <el-table-column prop="compositionName" label="药品" width="200">
            <template slot-scope="scope">
              <div data-size="14" v-html="scope.row.name"></div>
            </template>
          </el-table-column>
          <el-table-column prop="dosage" label="用量" >--</el-table-column>
          <el-table-column prop="dosage" label="备注" >--</el-table-column>
          <!-- <el-table-column prop="recommendDosage" label="推荐用量"  width="280">
            <template slot-scope="scope">
              {{ scope.row.recommendDosage ? scope.row.recommendDosage : "--" }}
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="炮制方法"> </el-table-column> -->
        </el-table>
      </div>
      <div></div>
      <div style="text-align: right; padding-top: 20px; margin-right: 20px;display: flex;
          align-items: center;
          justify-content: end;">
        <el-button type="primary" @click="hefangDialogVisibleQT = false" plain>取消</el-button>
        <div v-if="changeTable == 1" style="display: flex; align-items: center; justify-content: end;">
          <div v-for="(item, index) in patientFormPre.CZcompositionList" :key="index" style="margin-left:10px">
            <el-button type="primary" @click="addgetLoadPrescriptionQTC(index)">导入处方{{ index * 1 + 1 }}</el-button>
          </div>
        </div>
        <div v-if="changeTable == 2" style="display: flex; align-items: center; justify-content: end;">
          <div  v-for="(item, index) in patientFormPre.SYcompositionList" :key="index" style="margin-left:10px ;display: flex; align-items: center; justify-content: end;">
            <div v-for="(item1, index1) in item.zucompositionList" :key="index1" style="margin-left:10px">
              <el-button type="primary" @click="addgetLoadPrescriptionQTS(index,index1)">导入处方{{ index * 1 + 1 + '组' + (index1 * 1 + 1) }}</el-button>
            </div>
          </div>
        </div>

      </div>
    </el-dialog>
    <el-dialog    
    title="选择症状"
      :visible.sync="drugDialogVisible"
      width="50%">
      <div class="" style="">
          <div class="" style="" v-if="categoryList.length > 0">
            <div class="" style="font-size: 16px; font-weight: bold">症状分类</div>
            <div class="" style="display: flex; flex-wrap: wrap">
              <div class="" v-for="(item, index) in categoryList" :key="index" style="margin-top: 10px">
                <div :class="categoryindex == index ? 'goodTagCheck' : 'goodTag'" @click="gotext(item, index)">
                  {{ item.categoryName }}
                </div>
              </div>
            </div>
          </div>
          <div class="" style="margin-top: 15px" v-if="categoryList1.length > 0">
            <div class="" style="font-size: 16px; font-weight: bold">症状分类</div>
            <div class="" style="display: flex; flex-wrap: wrap">
              <div class="" v-for="(item, index) in categoryList1" :key="index" style="margin-top: 10px">
                <div :class="categoryindex1 == index ? 'goodTagCheck' : 'goodTag'" @click="gotext1(item, index)">
                  {{ item.categoryName }}
                </div>
              </div>
            </div>
          </div>
          <div class="" style="margin-top: 15px" v-if="categoryList2.length > 0">
            <div class="" style="font-size: 16px; font-weight: bold">症状分类</div>
            <div class="" style="display: flex; flex-wrap: wrap">
              <div class="" v-for="(item, index) in categoryList2" :key="index" style="margin-top: 10px">
                <div :class="categoryindex2 == index ? 'goodTagCheck' : 'goodTag'" @click="gotext2(item, index)">
                  {{ item.categoryName }}
                </div>
              </div>
            </div>
          </div>
          <!-- 主症列表 -->
          <div v-if="DiagnosiscategoryList.length > 0" class="" style="margin-top: 15px">
            <div class="" style="font-size: 16px; font-weight: bold">症状分类</div>
            <div class="" style="display: flex; flex-wrap: wrap">
              <div class="" v-for="(item, index) in DiagnosiscategoryList" :key="index" style="margin-top: 10px">
                <div :class="Diagnosiscategoryindex == index ? 'goodTagCheck' : 'goodTag'" @click="gotextDiagnosis(item, index)">
                  {{ item.primarySymptomName }}
                </div>
              </div>
              <div style="margin-top: 10px">
                <div v-if="pageIndex < pageCount" class="goodTag" @click="gotextDiagnosiMore">更多...</div>
              </div>
            </div>
          </div>
        </div>
    </el-dialog>
    <el-dialog    
    title="选择疾病"
      :visible.sync="diseaseDialogVisible"
      width="50%">
      <div class="" style="">
          <div class="" style="" v-if="diseasecategoryList.length > 0">
            <div class="" style="font-size: 16px; font-weight: bold">疾病分类</div>
            <div class="" style="display: flex; flex-wrap: wrap">
              <div class="" v-for="(item, index) in diseasecategoryList" :key="index" style="margin-top: 10px">
                <div :class="diseasecategoryindex == index ? 'goodTagCheckD' : 'goodTag'" @click="diseasegotext(item, index)">
                  {{ item.categoryName }}
                </div>
              </div>
            </div>
          </div>
          <div class="" style="margin-top: 15px" v-if="diseasecategoryList1.length > 0">
            <div class="" style="font-size: 16px; font-weight: bold">疾病分类</div>
            <div class="" style="display: flex; flex-wrap: wrap">
              <div class="" v-for="(item, index) in diseasecategoryList1" :key="index" style="margin-top: 10px">
                <div :class="diseasecategoryindex1 == index ? 'goodTagCheckD' : 'goodTag'" @click="diseasegotext1(item, index)">
                  {{ item.categoryName }}
                </div>
              </div>
            </div>
          </div>
          <div class="" style="margin-top: 15px" v-if="diseasecategoryList2.length > 0">
            <div class="" style="font-size: 16px; font-weight: bold">疾病分类</div>
            <div class="" style="display: flex; flex-wrap: wrap">
              <div class="" v-for="(item, index) in diseasecategoryList2" :key="index" style="margin-top: 10px">
                <div :class="diseasecategoryindex2 == index ? 'goodTagCheckD' : 'goodTag'" @click="diseasegotext2(item, index)">
                  {{ item.categoryName }}
                </div>
              </div>
            </div>
          </div>
          <!-- 主症列表 -->
          <div v-if="diseaseDiagnosiscategoryList.length > 0" class="" style="margin-top: 15px">
            <div class="" style="font-size: 16px; font-weight: bold">疾病分类</div>
            <div class="" style="display: flex; flex-wrap: wrap">
              <div class="" v-for="(item, index) in diseaseDiagnosiscategoryList" :key="index" style="margin-top: 10px">
                <div :class="diseaseDiagnosiscategoryindex == index ? 'goodTagCheckD' : 'goodTag'" @click="diseasegotextDiagnosis(item, index)">
                  {{ item.primarySymptomName }}
                </div>
              </div>
              <div style="margin-top: 10px">
                <div v-if="pageIndex < pageCount" class="goodTag" @click="diseasegotextDiagnosiMore">更多...</div>
              </div>
            </div>
          </div>
        </div>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="dialogVisibleDetail" width="50%">
      <div class="dialogTitle">
        {{ selfDiagnosisPrimarySymptomDetail.primarySymptomName }}
      </div>
      <div class="textdetail" v-if="selfDiagnosisPrimarySymptomDetail.symptomDescription">
        <div class="">
          <p v-html="selfDiagnosisPrimarySymptomDetail.description.replace(/\n/g, '</p> <p>')"></p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleDetail = false">取 消</el-button>
        <el-button type="warning" @click="goStartSure">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="diseasedialogVisibleDetail" width="50%">
      <div class="dialogTitle">
        {{ diseaseselfDiagnosisPrimarySymptomDetail.primarySymptomName }}
      </div>
      <div class="textdetail" v-if="diseaseselfDiagnosisPrimarySymptomDetail.symptomDescription">
        <div class="">
          <p v-html="diseaseselfDiagnosisPrimarySymptomDetail.description.replace(/\n/g, '</p> <p>')"></p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="diseasedialogVisibleDetail = false">取 消</el-button>
        <el-button type="primary" @click="goStartSuredisease">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="收货管理"
      :visible.sync="addressDialogVisible"
      width="30%"
      >
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="地区" prop="regionId" :rules="[{ required: true, message: '请选择所在区域', trigger: 'blur' }]">
              <el-cascader :placeholder="ruleForm.regionName ? ruleForm.regionName : '请选择所在区域'"
                  v-model="ruleForm.regionId"
                  :options="RegionData"
                  size="medium"
                  :props="{  emitPath: false, multiple: false, checkStrictly: true,lazy: true,lazyLoad:LasyLoadRegion, value:'id',label:'regionName',children:'childrens' }"
                  style="margin-right: 10px;width: 100%;"
                  @change="gochange"
                  ref="refSubCat"
                  >
              </el-cascader>
          </el-form-item>
          <el-form-item label="详细住址" prop="address" style="margin-top:30px" :rules="[{ required: true, message: '请输入详细住址', trigger: 'blur' }]">
              <el-input v-model="ruleForm.address"></el-input>
          </el-form-item>
          <el-form-item label="收货人" prop="addressee" style="margin-top:30px" :rules="[{ required: true, message: '请输入收货人', trigger: 'blur' }]">
              <el-input type="addressee" v-model="ruleForm.addressee"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone" style="margin-top:30px" >
              <el-input type="phone" v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="是否设为默认" prop="isDefault" style="margin-top:30px">
              <el-switch v-model="ruleForm.isDefault"></el-switch>
          </el-form-item>
          <el-form-item style="margin-top:20px">
              <el-button type="primary" @click="submitForm('ruleForm')" :loading="adressTrue">提交</el-button>
              <el-button @click="resetForm('ruleForm')">取消</el-button>
          </el-form-item>
      </el-form>
  </el-dialog>
    <!-- 开始诊断试题 -->
    <el-drawer class="el-drawer1" :direction="direction" size="90%" custom-class="demo-drawer" :visible.sync="isShowZD"
      :before-close="handleCloseDra">
      <div class='drawer1Btn' @click="rehandleCloseDra">
        再次诊断
        <!-- <el-button type="primary" round></el-button> -->
      </div>
      <div class='drawer2Btn' @click="rehandleCloseDra1">
        返回上一步
        <!-- <el-button type="primary" round></el-button> -->
      </div>
      <div class="flex-center" style="position: relative; height: calc(100vh - 92px)">
        <iframe ref="myIframe"
          id="myIframeId"
          style="border: none; position: absolute; top: 0; left: 0; right: 0; margin-top: -125px; height: calc(100% + 125px)"
          width="100%" height="100%" :src="pathStr1"
          v-if="activerightName == 1"></iframe>
        <iframe ref="myIframe"  id="myIframeId" style="border: none; position: absolute; top: 0; left: 0; right: 0; margin-top: -125px; height: calc(100% + 125px)" 
        width="100%" height="100%" :src="pathStr2" 
        v-if="activerightName == 2"></iframe>
        <iframe ref="myIframe"
         id="myIframeId"
          style="border: none; position: absolute; top: 0; left: 0; right: 0; margin-top: -80px; height: calc(100% + 80px)"
          width="100%" height="100%" :src="pathStr3"
          v-if="activerightName == 3"></iframe>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor"; //调用编辑器
import intNavheader from "../../components/intNavheader";
import waitNumber from "../../components/waitNumber";
import { UserInfo } from "../../components/User/UserInfo";
import { shoppingMall } from "../../components/shoppingMall/shoppingMall";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
import { Referral } from "../../components/Referral/Referral";
import { getckOptionsNames, addCompare, nameConvertValue, getCompatibilityList, removeCompare, updateCompare, removeCompareAll, namehtmlraw, tipGroup, dietGroup, contenthtmlRaw, getcrowdList } from "../../utils/Compatibility";
import { Drug } from "../../components/Drug/Drug";
import { Compatibility } from "../../components/Compatibility/Compatibility";
import { tocompareDetail } from "../../utils/relation";
import { Outpatient } from "@/components/Drug/Outpatient";
import { Test } from "../../components/company/test";
import storage from "@/utils/customstorage";
import servicesData from "../../../public/services.json";
import { Organization } from "../../components/User/Organization";
import { zdrugconsultationMain } from "@/components/zdrugconsultation/zdrugconsultation.js";
import  websocket from '@/utils/webSocket'
// import {
//   regionData,
//   codeToText
// } from "element-china-area-data";
import { DiseaseUtility, replaceContent, checkNameAndPath, toSearchList, toDetailInfo } from "../../utils/diseaseUtility";
export default {
  inject: ["reload"],
  components: {
    intNavheader,
    waitNumber,
    quillEditor,
  },
  data() {
    var user = new UserInfo(this.TokenClient, this.Services.Authorization);
    var organization = new Organization(this.TokenClient, this.Services.Authorization);
    var referral1 = new Referral(this.TokenClient, this.Services.Referral);
    var test = new Test(this.TokenClient, this.Services.Statics);
    var shoppingMalltem = new shoppingMall(this.TokenClient, this.Services.shoppingMall);
    var shoppingMalStatics = new shoppingMall(this.TokenClient, this.Services.Statics);
    var shoppingMalDisease = new shoppingMall(this.TokenClient, this.Services.Disease);
    var UserDistributorltem = new shoppingMall(this.TokenClient, this.Services.Authorization);
    var UserDistributorltemDrug = new shoppingMall(this.TokenClient, this.Services.Drug);
    var patient = new patientMain(this.TokenClient, this.Services.Authorization);
    var drugpatient = new patientMain(this.TokenClient, this.Services.Drug);
    var patientIDCard = new patientMain(this.TokenClient, this.Services.IDCard);
    var drug = new Drug(this.TokenClient, this.Services.Drug);
    var statics = new patientMain(this.TokenClient, this.Services.Statics);
    var DiseasePatient = new patientMain(this.TokenClient, this.Services.Disease);
    var compatibility = new Compatibility(this.TokenClient, this.Services.Drug);
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug);
    var outpatientAuthorization = new Outpatient(this.TokenClient, this.Services.Authorization);
    var zdrugconsultation = new zdrugconsultationMain(this.TokenClient, this.Services.Disease);
    return {
      editorOption: {
        placeholder: "编辑文章内容",
      },
      User: user,
      Organization: organization,
      Referral:referral1,
      zdrugconsultationMain: zdrugconsultation,
      patientDomain: patient,
      IDCardDomain: patientIDCard,
      drugpatientDomain: drugpatient,
      shoppingMalStaticsDomain: shoppingMalStatics,
      shoppingMalDiseaseDomain: shoppingMalDisease,
      outpatientAuthorizationDomain: outpatientAuthorization,
      UserDistributorltemDrugMain: UserDistributorltemDrug,
      OutpatientDomain: outpatient,
      DrugDomain: drug,
      staticsDomain: statics,
      DiseaseDomain: DiseasePatient,
      testDomain: test,
      UserDistributorMain: UserDistributorltem,
      userInfo: this.$store.state.localData.userInfo,
      shoppingMallMain: shoppingMalltem,
      FileUploadUrl: this.Services.Statics + "/api/upload",
      compatibilityDomain: compatibility,
      // 是否选择免审核
      // AuditNextDataSuc:false,
      compatibilityResultShow:true,
      recommendPreList:[],
      // 新增参数
      isLockScreen:false,
      isLockScreenValue:'',
      searchAdressKeyWord: '',
      indexTypeDoctor: 0,
      indexTypeDrug:0,
      patientDiagnoseVisitId: '',
      Agreement:false,
      visitId: 0,
      isVisitId:false,
      doctorDialogProgress:1,
      pastShow:false,
      familyShow:false,
      irritabilityShow:false,
      paystep2loading:false,
      mainShow:false,
      pastShowList:[],
      userSymptomValue:'',
      userSymptomValueBZ:'',
      userSymptomValueBB:'',
      searchResultByUserListBB:[],
      searchResultByUserListBZ:[],
      searchResultByUserListDetail:[],
      searchResultByUserList:[],
      searchResultByUserListDetailBB:[],
      searchResultByUserListBB:[],
      priceKeyWordmin:'0',
      priceKeyWordmax:'0',
      signMent:false,
      UserSignImg:'',
      adressTrue:false,
      addressDialogVisible:false,
      drugDialogVisible:false,
      ruleForm:{
          address: '',
          addressee: '',
          regionId: null,
          regionName:'',
          region:'',
          phone: '',
          fullAddress: '',
          isDefault: false,
          regionCode:''
      },
      rules:{
          addressee:[
              { required: true, message: '请输入姓名', trigger: 'blur' },
          ],
          phone:[
              { required: true, message: '请输入联系方式', trigger: 'blur' },
              { pattern:/^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur"}
          ],
      },
      RegionData:[],
      // 配伍数据
      cur: 0,
      similarTotal: 0, //同类药条数
      compatibilityTotal: 0, //配伍条数
      crowdTotal: 0, //老人、儿童、妊娠、哺乳 总条数
      dietTotal: 0, //饮食条数
      avoidTotal: 0, //禁忌条数
      total: 0, //总条数
      compatibilityList: [], //配伍
      similarList: [], //同类药
      instructions: [], // 用药须知    禁忌1   饮食2   老人3    儿童4    妊娠5   哺乳6
      types: [],
      crowdList: [], // 特殊人群
      fruit: getCompatibilityList(),
      stepIndex: 1,
      patientType: 1, // 固定值
      organitionId: 0, // 固定值
      patientList: [],
      todayPatientList: [],
      ZCPatientList: [],
      ZCpatientTotal: 0,
      ZCpatientSize: 0,
      patientTotal: 0,
      patientPageIndex: 1,
      ZCDiagnoseindex: 1,
      patientDiagnoseIndex: -1,
      ZCpatientcurrentPage: 1,
      visitData: [],
      compositionId: 0,
      // 是否选择检查治疗项
      isCheckItem: false,
      esigndialogVisible: false,
      lineWidth: 6, //画笔粗细
      lineColor: "#000000", //画笔颜色
      bgColor: "", //画布背景色，为空时画布背景透明
      imgurl: "", //签名生成的图片
      isCrop: false, //是否裁剪，在画布设定尺寸基础上裁掉四周空白部分
      checkItemResult: "",
      AuditDoctorList: [],
      drugDoctorList:[],
      auditkeyWord: "",
      depoptions: [],
      auditTotal: 0,
      auditPageIndex: 1,
      AdvicurrentPage: 0,
      AuditcurrentPage: 0,
      AlreadyId: "",
      diseasOptions: [],
      departmentId: 0,
      checkItemsVisible: false,
      ShowPhoto: false,
      ShowPhotocf: false,
      checkItemValue: {
        itemName: "",
      },
      CheckItemValue:0,
      patientcurrentPage: 1,
      checkItemsKeyWord: "",
      checkItemsOptions: [],
      checkItemsList: [
        {
          value: "",
          checkItemsNum: 1,
          tradingPrice: 0,
        },
      ],
      EditDrugCompositio: [],
      ImageContentList: [], //图片上传控件加载的数据
      DiagnoseContent: [], //图片列表
      Image: {
        //单击图片上传控件中的图片时，放大显示
        dialogVisible: false,
        dialogImageUrl: "",
      },
      showCheckItemsList: [],
      Showtemperature: false,
      isAlready: false,
      todayStepIndex: 1,
      checkItemsNum: 0,
      checkItemsValue: "",
      patientKeyWord: "",
      AdviceList: [],
      AdvicePageIndex: 1,
      AdvicePageTotal: 0,
      comDialogAdviceVisible: false,
      comrequestVisible:false,
      activeName: "first",
      doctorDialogVisible: false,
      resultDialogVisible: false,
      comDialogVisible: false,
      leftTable: 1,
      resultValue: "",
      doctorRadio: "1",
      resultRadio: "1",
      activerightName: "1",
      activeright3Name: "first",
      tab3ClickData: 0,
      todayPatient: "",
      patientFormRules: {
        name: [{ required: true, message: "请输入患者姓名", trigger: "blur" }],
        age: [{ required: true, message: "请输入患者年龄", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入患者联系方式", trigger: "blur" },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur" },
        ],
      },
      patientForm: {
        name: "",
        age: "",
        phone: "",
        idNo: "",
        address: "",
        diseaseName: "",
        sex: true,
      },
      patientDetail: {},
      patientFormDes: {
        main: "",
        family: "",
        height: "",
        heightTension: "",
        lowTension: "",
        breathe: "",
        pulse: "",
        temperature: "",
        weight: "",
        irritability: "",
        otherCheck: "",
        past: "",
        diseaseDiagnose: [],
        diseaseDiagnoseHashKeyList:[],
        discriminatoryAnalysis: '',
        discriminatoryAnalysisList: []
      },
      patientFormPre: {
        compositionList: [
          {
            mzPrescriptionDetail: [
              {
                drugOrgId: "",
                drugOrgName: "",
                dosage: "",
                dosageUnit: "g",
                usage: "",
                weight: '',
                remark: "",
                decoct: "",
                recommendDosage: "",
              },
              {
                drugOrgId: "",
                drugOrgName: "",  
                dosage: "",
                dosageUnit: "g",
                usage: "",
                weight: '',
                remark: "",
                decoct: "",
                recommendDosage: "",
              },
              {
                drugOrgId: "",
                drugOrgName: "",
                dosage: "",
                dosageUnit: "g",
                usage: "",
                weight: '',
                remark: "",
                decoct: "",
                recommendDosage: "",
              },
            ],
            usage: "",
            remark: "",
            dosage: "",
            dosageNumber: "",
            frequency: "",
            prescriptionName: "",
            request: "",
            type: 0,
            prescriptionType: 1,
            isDecoction: true,
            isdeliveryPrice: 0,
          },
        ],
        CZcompositionList: [],
        SYcompositionList: [],
        MZOtherChargesOptions:[],
        MZOtherCharges: [
          {
            chargeName: "",
            money: "",
          },
        ],
        doctorAdvice: "",
        isSelef: "2",
        isSelefPrice: 0,
      },
      ageOptions: [
        {
          value: "岁",
          label: "岁",
        },
      ],
      options3RightData: [
        {
          value: "个人",
          label: "个人",
        },
        {
          value: "机构",
          label: "机构",
        },
      ],
      ageValue: "岁",
      remarkOption: ["口服", "外用", "滴眼", "静脉注射", "静脉滴注", "肌内注射", "皮下注射", "皮内注射", "腔内注射", "椎管注射", "动脉注射", "心内注射", "球内注射", "皮下埋植", "口腔喷雾", "口腔吸入", "口腔黏膜给药", "滴鼻", "鼻腔喷雾", "鼻饲", "鼻腔吸入", "滴耳", "眼科外用", "涂于眼睑内", "含漱", "含服", "舌下含服", "直肠给药", "直肠塞入", "肛门涂抹", "阴道给药", "阴道塞入", "阴道擦洗"],
      frequencyOption: ["1日1次(qd)", "1日2次(bid)", "1日3次(tid)", "隔日1次(qod)", "必要时(prn)", "1日4次(qid)", "1周1次(qwd)", "隔周1次(qow)", "隔天1次(qod)", "每晚1次(qn)", "立即(st)"],
      usageOption: ["先煎", "后下", "包煎", "另煎", "烊化", "冲服", "捣碎"],
      // requestOption: ["水煎服；分早晚两次空腹温服", "水煎服；分早晚两次饭前温服", "水煎服；分早晚两次饭后温服", "水煎服；分早晚凉服", "水煎服；1日3次空腹温服", "水煎服；1日3次饭前温服", "水煎服；1日3次饭后温服"],
      requestOption: ["水煎服；分早晚两次空腹温服"],
      kgOption: ["片", "粒", "支", "袋", "枚", "瓶", "滴", "适量", "ug", "mg", "g", "ul", "ml", "IU"],
      SpeeOption: ["g/分钟", "ml/分钟", "ml/小时", "分钟/次", "ug/分钟", "滴/分钟"],
      usage: ["口服", "外用"],
      MyCommonPrescriptionList: [],
      MyCommonPageindex: 1,
      MyCommonPageTotal: 1,
      MyCommonindex: -1,
      MyCommonPrescripTableData: [],
      MyCommonPrescripTableDataSY: [],
      drugOrgName: "个人",
      MyCommonKeyword: "",
      allTotalMoney: 0,
      CheckItem: 0,
      ResultByUserList: [
        {
          questionnaireDetermines: [],
        },
      ],
      ResultByUserIndex: 0,
      HerbalValue: "",
      herbalDetail: "",
      HerbalList: [],
      jdcompositionList: [],
      loading: false,
      ResultLatestData: [],
      tcmResultLatestData:[],
      esignimgurl: "",
      ImageContent: [], //图片列表
      ImageContentList: [], //图片上传控件加载的数据
      DiagnoseContent: [], //图片列表,
      Image: {
        //单击图片上传控件中的图片时，放大显示
        dialogVisible: false,
        dialogImageUrl: "",
      },
      RegionData: [],
      MessageList: [],
      messageindex: 0,
      messagePatinet: {},
      // 查询药品
      drugIdList: [],
      HerbaldetailList: [],
      zancunherbalDetail:{},
      serchdrugIdList: [],
      zserchdrugIdList: [],
      // 推荐处方
      zHerbalList: [],
      tHerbalList: [],
      // 成药处方输液处方
      QtHerbalList:[],
      MyCommoncurrentPage: 0,
      designImg: "",
      auditSignImg: "",
      paydialogVisible: false,
      paystep2Visible:false,
      // 是否支付
      isPaystep2:false,
      // 支付
      charge: {
        id: 0,
        details: [],
        discountsMoney: 0, //优惠金额
        debtUserName: null,
        debtUserPhone: null,
        debtMoney: 0, //挂账总金额
        payWay: 1, //0 现金 1微信支付，2支付宝，3银行卡 4 挂账 5 其他
        payMoney: 0, //支付金额
        totalMoney: 0,
        payment: null, //输入金额
        change: 0, //找零
        credit: false,
      },
      timer: null,
      UserBalance: {
        assetsBalance: 0,
      },
      qRcodeUrl: "",
      qRcodeUrlZ:'',
      weChatInfo: "",
      auditDetail: {},
      drugDetail:{},
      assetsBalanceIsTrue: false,
      payAuditloading:false,
      payAuditloadingTrue:false,
      // 传参储存
      mzPrescriptionData: [],
      mzOtherChargesData: [],
      waitAuditUserId: 0,
      drugAuditUserId:0,
      depvalue: "",
      diseaseIndex: 0,
      showCourseInfo: false,
      ShowPhotocfList: [],
      ShowPhotoList: [],
      fitDialogVisible: false,
      fitDialogVisibleImgUrl: "",
      auditDiagnoseMoney: 0,
      servicesDataId: servicesData,
      waitDataTotal: 0,
      asstoken: "",
      TChooseName: "",
      GChooseName: "",
      JChooseName: "",
      isShowZD: false,
      direction: "ltr",
      pathStr1: "",
      pathStr2: "",
      pathStr3: "",
      setInfoDialog: false,
      recordResultsInfo: {
        syndromeName: "",
        analysis: "",
        step: "",
        item: {},
      },
      timerZ:null,
      referrallogVisible: false,
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        age: [
          {
            pattern: /^(\d|[1-9]\d|1[0-1]\d|120)$/,
            message: "请输入不大于120的整数",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
            message: "请输入合法手机号/电话号",
            trigger: "blur",
          },
        ],
        identity: [
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "请输入正确格式的身份证号码",
            trigger: "blur",
          },
        ],
        // degree: [{ required: true, message: "请选择病情程度", trigger: "blur" }],
        diagnosis: [{ required: true, message: "请输入初步诊断", trigger: "blur" }],
        reason: [{ required: true, message: "请选择转诊原因", trigger: "blur" }],
        hospitalId: [{ required: true, message: "请选择医院", trigger: "blur" }],
        departmentId: [{ required: true, message: "请选择部门", trigger: "blur" }],
        doctorId: [{ required: true, message: "请选择医生", trigger: "blur" }],
      },
      formLabelWidth: "120px",
      chiefComplaint: '',
      referralModal: true,
      referralTitle: '确认转诊患者信息',
      referralform: {
        urgent: false,
        reason: "",
        hospitalId: "",
        green: false,
        degree: '',
        introduction:'',
        departmentId: "",
        doctorId: "",
        hospitalName: ''
      },
      HospitalData: [],
      DepartmentData: [],
      DoctorData: [],
      referralType: 1,
      disdepartment: false,
      // regionDatas: regionData,
      // addtions: [],
      // cateProps: {
      //   value: "value",
      //   label: "label",
      //   children: "children",
      // },
      userReferralId:'',
      UserSign:'',
      nosucecessloading:false,
      isSelefList:[],
      isSelefPriceList:[],
      isSelefPriceName:'',
      isSelefPriceId:'',
      isSelefName:'',
      isSelefId:0,
      userName: this.$store.state.localData.userInfo.name,
      userhead:  this.$store.state.localData.userInfo.photo,
      // 合方
      hefangDialogVisible:false,
      hefangDialogVisibleQT:false,
      hefangDialogListQT:[],
      hefangDialogList:[],
      categoryList: [],
      categoryindex: -1,
      categoryList1: [],
      categoryindex1: -1,
      categoryList2: [],
      categoryindex2: -1,
      DiagnosiscategoryList: [],
      Diagnosiscategoryindex: -1,
      dialogVisibleDetail:false,
      selfDiagnosisPrimarySymptomDetail:{},
      selfDiagnosisId:0,
      // 辨病论治
      diseaseDialogVisible:false,
      diseasecategoryList:[],
      diseasecategoryList1:[],
      diseasecategoryList2:[],
      diseaseDiagnosiscategoryList:[],
      diseasecategoryindex:-1,
      diseasecategoryindex1:-1,
      diseasecategoryindex2:-1,
      diseaseDiagnosiscategoryindex:-1,
      diseasedialogVisibleDetail:false,
      diseaseselfDiagnosisPrimarySymptomDetail:{},
      diseasselfDiagnosisId:0,
      isMainConct:'',
      reqindexCom:0,
      orgexpertId:  JSON.parse(localStorage.getItem('ExpertOrganization')) ? JSON.parse(localStorage.getItem('ExpertOrganization')).organizationId : this.$store.state.localData.userInfo.organizationId,
      orgexpertName: JSON.parse(localStorage.getItem('ExpertOrganization')) ? JSON.parse(localStorage.getItem('ExpertOrganization')).organizationName : this.$store.state.localData.userInfo.organizationName,
      detailUserType:this.$store.state.localData.userInfo.detailUserType,
      changeTable:0
    };
  },
  computed: {
    headers() {
      return {
        Authorization: "Bearer" + " " + this.asstoken,
      };
    },
  },
  watch: {
    // checkItemsList:{
    //   handler(newValue) {
    //     this.CheckItemValue = 0;
    //     this.checkItemsList.map((item, index) => {
    //       this.CheckItemValue += item.tradingPrice;
    //     });
    //   },
    // },
    // showCheckItemsList: {
    //   handler(newValue) {
    //     this.CheckItem = 0;
    //     this.showCheckItemsList.map((item, index) => {
    //       this.CheckItem += item.tradingPrice;
    //     });
    //   },
    // },
    // + this.CheckItem;
    patientFormPre: {
      handler(newValue) {
        if (this.patientFormPre.isSelef == "2") {
          this.allTotalMoney = this.patientFormPre.isSelefPrice + 0;
        } else {
          this.allTotalMoney = 0;
        }
        let isDefaultfalse = false
        this.patientFormPre.compositionList.map((item, index, arr) => {
          item.mzPrescriptionDetail.map(item1 => {
            if (item1.drugOrgName) {
              isDefaultfalse = true
            }
          })
          if (item.isDecoction && isDefaultfalse) {
            item.isdeliveryPrice = item.isdeliveryPrice ? item.isdeliveryPrice : 0;
            this.allTotalMoney += item.dosage * item.isdeliveryPrice;
          }
          item.mzPrescriptionDetail.map((item1, index1, arr1) => {
            if (item1.tradingPrice) {
              item1.tradingPrice = item1.tradingPrice ? item1.tradingPrice : 0;
              this.allTotalMoney += item.dosage * (item1.weight ? parseFloat(item1.weight) : 0) * item1.tradingPrice;
            }
          });
        });
        this.patientFormPre.CZcompositionList.map((item, index, arr) => {
          item.mzPrescriptionDetail.map((item1, index1, arr1) => {
            if (item1.tradingPrice) {
              item1.tradingPrice = item1.tradingPrice ? item1.tradingPrice : 0;
              this.allTotalMoney += item1.tradingPrice * item1.defaultDosageList.defaultDosage;
            }
          });
        });
        this.patientFormPre.SYcompositionList.map((item, index, arr) => {
          item.zucompositionList.map((item1, index1, arr1) => {
            item1.mzPrescriptionDetail.map((item2, index2, arr2) => {
              if (item2.tradingPrice) {
                item2.tradingPrice = item2.tradingPrice ? item2.tradingPrice : 0;
                this.allTotalMoney += item2.tradingPrice * item2.defaultDosageList.defaultDosage;
              }
            });
          });
        });
        this.patientFormPre.MZOtherCharges.map((item, index, arr) => {
          if (item.money) {
            item.money = item.money ? parseFloat(item.money) : 0;
            this.allTotalMoney += item.money;
          }
        });
      },
      deep: true,
    },
  },
  async mounted() {
    // 第二步空白关闭
    document.addEventListener('click', this.handleDocumentClick);
    document.addEventListener('click', this.handleDocumentClick1);
    document.addEventListener('click', this.handleDocumentClick2);
    document.addEventListener('click', this.handleDocumentClick3);
    if (websocket.webClosed.length != 0) {
      websocket.webClosed()
    }
    websocket.Init()
    this.pathStr1 = window.location.origin  + '/#/zdrugconsultation/zDrugStart?ZStype=1'; 
    this.pathStr2 = window.location.origin  + '/#/diseaseConsultation/diseaseStart?ZStype=1';
    this.pathStr3 = window.location.origin  + '/#/company/startTest?titleName=中医虚证';
    this.getPatientListdata();
    this.selectReferralType();
    this.getMZDiagnoseListPatientData()
    this.asstoken = await this.TokenClient.AccessToken();
    window.sessionStorage.removeItem("bianzhenchooseUser");
    window.sessionStorage.removeItem("chooseUser");
    if(this.$route.params){
      this.goNextpatientStep(this.$route.params.id);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timerZ);
    document.removeEventListener('click', this.handleDocumentClick);
    document.removeEventListener('click', this.handleDocumentClick1);
    document.removeEventListener('click', this.handleDocumentClick2);
    document.removeEventListener('click', this.handleDocumentClick3);
  },
  methods: {
    godeletemain(){
      this.patientFormDes.main = ''
    },
    chargePayWayInput(e){
      clearInterval(this.timer);
      clearInterval(this.timerZ);
      this.qRcodeUrl = ''
      this.qRcodeUrlZ = ''
    },
    handleDocumentClick(e) {
      if (!this.$refs.mainShowBox.contains(e.target)) {
          this.mainBlur()
      }
    },
    handleDocumentClick1(e) {
      if (!this.$refs.pastShowBox.contains(e.target)) {
          this.pastBlur();
      }
    },
    handleDocumentClick2(e) {
      if (!this.$refs.familyShowBox.contains(e.target)) {
          this.familyBlur()
      }
    },
    handleDocumentClick3(e) {
      if (!this.$refs.irritabilityShowBox.contains(e.target)) {
          this.irritabilityBlur()
      }
    },
    // 症状分类
    goStart() {
      var _this = this;
      _this.isStartTest = true;
      _this.zdrugconsultationMain.getSelfDiagnosisCategory(
        "",
        function (data) {
          _this.categoryList = data.data;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.categoryList = [];
          }
        }
      );
    },
    godiseaseStart(){
      var _this = this;
      this.isStartTestTcm = true;
      _this.zdrugconsultationMain.getSelfTCMDiseaseCategory(
        "",
        function (data) {
          _this.diseasecategoryList = data.data;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.diseasecategoryList = [];
          }
        }
      );
    },
    godiseaseStartmz(){
      var _this = this;
      this.isStartTestTcm = true;
      _this.zdrugconsultationMain.getmzSelfTCMDiseaseCategory(
        '%20',
        function (data) {
          _this.diseasecategoryList = data.data;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.diseasecategoryList = [];
          }
        }
      );
    },

    diseasegotext(item, index){
      this.diseasecategoryList1 = [];
      this.diseasecategoryindex1 = -1;
      this.diseasecategoryList2 = [];
      this.diseasecategoryindex2 = -1;
      this.diseaseDiagnosiscategoryList = [];
      this.diseaseDiagnosiscategoryindex = -1;
      this.diseaseselfDiagnosisPrimarySymptomDetail = {};
      this.diseasecategoryindex = index;
      if (item.isEnableChildren) {
        if (this.Services.ClientId == 'AssociationWeb') {
          this.getSelfDiagnosisCategoryList1Dmz(item.categoryId);
        } else {
          this.getSelfDiagnosisCategoryList1D(item.categoryId);
        }
      } else {
        if (this.Services.ClientId == 'AssociationWeb') {
          this.getSelfDiagnosisPrimarySymptomListDmz(item.categoryId);
        } else {
          this.getSelfDiagnosisPrimarySymptomListD(item.categoryId);
        }
        
      }
    },
    gotext(item, index) {
      this.categoryList1 = [];
      this.categoryindex1 = -1;
      this.categoryList2 = [];
      this.categoryindex2 = -1;
      this.DiagnosiscategoryList = [];
      this.Diagnosiscategoryindex = -1;
      this.selfDiagnosisPrimarySymptomDetail = {};
      this.categoryindex = index;
      if (item.isEnableChildren) {
        this.getSelfDiagnosisCategoryList1(item.categoryId);
      } else {
        this.getSelfDiagnosisPrimarySymptomList(item.categoryId);
      }
    },
        // 症状分类
    getSelfDiagnosisCategoryList1(e) {
      var _this = this;
      _this.zdrugconsultationMain.getSelfDiagnosisCategory(
        e,
        function (data) {
          _this.categoryList1 = data.data;
        },
        function (error) {
          if (error.statusCode == 404) {
            _this.categoryList1 = [];
          }
        }
      );
    },
    getSelfDiagnosisCategoryList1D(e) {
      var _this = this;
      _this.zdrugconsultationMain.getSelfTCMDiseaseCategory(
        e,
        function (data) {
          _this.diseasecategoryList1 = data.data;
        },
        function (error) {
          if (error.statusCode == 404) {
            _this.diseasecategoryList1 = [];
          }
        }
      );
    },
    getSelfDiagnosisCategoryList1Dmz(e) {
      var _this = this;
      _this.zdrugconsultationMain.getmzSelfTCMDiseaseCategory(
        e,
        function (data) {
          _this.diseasecategoryList1 = data.data;
        },
        function (error) {
          if (error.statusCode == 404) {
            _this.diseasecategoryList1 = [];
          }
        }
      );
    },
    getSelfDiagnosisCategoryList2(e) {
      var _this = this;
      _this.zdrugconsultationMain.getSelfDiagnosisCategory(
        e,
        function (data) {
          _this.categoryList2 = data.data;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.categoryList2 = [];
          }
        }
      );
    },
    getSelfDiagnosisCategoryList2D(e) {
      var _this = this;
      _this.zdrugconsultationMain.getSelfTCMDiseaseCategory(
        e,
        function (data) {
          _this.diseasecategoryList2 = data.data;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.diseasecategoryList2 = [];
          }
        }
      );
    },
    getSelfDiagnosisCategoryList2Dmz(e) {
      var _this = this;
      _this.zdrugconsultationMain.getmzSelfTCMDiseaseCategory(
        e,
        function (data) {
          _this.diseasecategoryList2 = data.data;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.diseasecategoryList2 = [];
          }
        }
      );
    },
    getSelfDiagnosisPrimarySymptomListDmz(e) {
      var _this = this;
      _this.diseaseselfDiagnosisId = e;
      _this.pageIndex = 1;
      _this.zdrugconsultationMain.getSelfTCMDiseasePrimarySymptommz(
        e,
        "%20",
        _this.patientDetail.sex,
        _this.patientDetail.age,
        _this.pageIndex,
        function (data) {
          _this.diseaseDiagnosiscategoryList = data.data.results;
          _this.pageCount = data.data.pageCount;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.diseaseDiagnosiscategoryList = [];
          }
        }
      );
    },
    getSelfDiagnosisPrimarySymptomListD(e) {
      var _this = this;
      _this.diseaseselfDiagnosisId = e;
      _this.pageIndex = 1;
      _this.zdrugconsultationMain.getSelfTCMDiseasePrimarySymptom(
        e,
        "%20",
        _this.patientDetail.sex,
        _this.patientDetail.age,
        _this.pageIndex,
        function (data) {
          _this.diseaseDiagnosiscategoryList = data.data.results;
          _this.pageCount = data.data.pageCount;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.diseaseDiagnosiscategoryList = [];
          }
        }
      );
    },
    diseasegotextDiagnosiMore() {
      var _this = this;
      _this.pageIndex = _this.pageIndex + 1;
        if (_this.Services.ClientId == 'AssociationWeb') {
          _this.zdrugconsultationMain.getSelfTCMDiseasePrimarySymptommz(
            _this.diseaseselfDiagnosisId,
            "%20",
            _this.patientDetail.sex,
            _this.patientDetail.age,
            _this.pageIndex,
            function (data) {
              _this.diseaseDiagnosiscategoryList = _this.diseaseDiagnosiscategoryList.concat(data.data.results);
              _this.pageCount = data.data.pageCount;
            },
            function (error) {
              console.log(error);
              if (error.statusCode == 404) {
                _this.DiagnosiscategoryList = [];
              }
            }
          );
        } else {
          _this.zdrugconsultationMain.getSelfTCMDiseasePrimarySymptom(
            _this.diseaseselfDiagnosisId,
            "%20",
            _this.patientDetail.sex,
            _this.patientDetail.age,
            _this.pageIndex,
            function (data) {
              _this.diseaseDiagnosiscategoryList = _this.diseaseDiagnosiscategoryList.concat(data.data.results);
              _this.pageCount = data.data.pageCount;
            },
            function (error) {
              console.log(error);
              if (error.statusCode == 404) {
                _this.DiagnosiscategoryList = [];
              }
            }
          );
        }

    },
        // 主症列表
    getSelfDiagnosisPrimarySymptomList(e) {
      var _this = this;
      _this.selfDiagnosisId = e;
      _this.pageIndex = 1;
      _this.zdrugconsultationMain.getSelfDiagnosisPrimarySymptom(
        e,
        "%20",
        _this.patientDetail.sex,
        _this.patientDetail.age,
        _this.pageIndex,
        function (data) {
          _this.DiagnosiscategoryList = data.data.results;
          _this.pageCount = data.data.pageCount;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.DiagnosiscategoryList = [];
          }
        }
      );
    },
    gotextDiagnosiMore() {
      var _this = this;
      _this.pageIndex = _this.pageIndex + 1;
      _this.zdrugconsultationMain.getSelfDiagnosisPrimarySymptom(
        _this.selfDiagnosisId,
        "%20",
        _this.patientDetail.sex,
        _this.patientDetail.age,
        _this.pageIndex,
        function (data) {
          _this.DiagnosiscategoryList = _this.DiagnosiscategoryList.concat(data.data.results);
          _this.pageCount = data.data.pageCount;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.DiagnosiscategoryList = [];
          }
        }
      );
    },
    gotext1(item, index) {
      this.categoryList2 = [];
      this.categoryindex2 = -1;
      this.DiagnosiscategoryList = [];
      this.Diagnosiscategoryindex = -1;
      this.selfDiagnosisPrimarySymptomDetail = {};
      this.categoryindex1 = index;
      if (item.isEnableChildren) {
        this.getSelfDiagnosisCategoryList2(item.categoryId);
      } else {
        this.getSelfDiagnosisPrimarySymptomList(item.categoryId);
      }
    },
    diseasegotext1(item,index){
      this.diseasecategoryList2 = [];
      this.diseasecategoryindex2 = -1;
      this.diseaseDiagnosiscategoryList = [];
      this.diseaseDiagnosiscategoryindex = -1;
      this.diseaseselfDiagnosisPrimarySymptomDetail = {};
      this.diseasecategoryindex1 = index;
      if (item.isEnableChildren) {
        if (this.Services.ClientId == 'AssociationWeb') {
           this.getSelfDiagnosisCategoryList2Dmz(item.categoryId);
        } else {
            this.getSelfDiagnosisCategoryList2D(item.categoryId);
        }
      } else {
        if (this.Services.ClientId == 'AssociationWeb') {
          this.getSelfDiagnosisPrimarySymptomListDmz(item.categoryId);
        } else {
          this.getSelfDiagnosisPrimarySymptomListD(item.categoryId);
        }
      }
    },
    diseasegotext2(item, index) {
      this.diseaseDiagnosiscategoryList = [];
      this.diseaseDiagnosiscategoryindex = -1;
      this.diseaseselfDiagnosisPrimarySymptomDetail = {};
      this.diseasecategoryindex2 = index;
      if (item.isEnableChildren) {
        this.getSelfDiagnosisCategoryList3D(item.categoryId);
      } else {
        this.getSelfDiagnosisPrimarySymptomListD(item.categoryId);
      }
    },
    gotext2(item, index) {
      this.DiagnosiscategoryList = [];
      this.Diagnosiscategoryindex = -1;
      this.selfDiagnosisPrimarySymptomDetail = {};
      this.categoryindex2 = index;
      if (item.isEnableChildren) {
        this.getSelfDiagnosisCategoryList3(item.categoryId);
      } else {
        this.getSelfDiagnosisPrimarySymptomList(item.categoryId);
      }
    },
    
    gotextDiagnosis(item, index) {
      this.Diagnosiscategoryindex = index;
      this.getSelfDiagnosisPrimarySymptomDetailList(item.id);
    },
    diseasegotextDiagnosis(item, index) {
      this.diseaseDiagnosiscategoryindex = index;
      this.getSelfDiagnosisPrimarySymptomDetailListD(item.id);
    },
        // 主症详情
    getSelfDiagnosisPrimarySymptomDetailList(e) {
      var _this = this;
      _this.zdrugconsultationMain.getSelfDiagnosisPrimarySymptomDetail(
        e,
        function (data) {
          _this.selfDiagnosisPrimarySymptomDetail = data.data;
          // _this.selfSex = data.data.filterSex;
          // _this.selfAge = data.data.minAge;
          // _this.quename = data.data.primarySymptomName;
          _this.dialogVisibleDetail = true;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    getSelfDiagnosisPrimarySymptomDetailListD(e) {
      var _this = this;
      _this.zdrugconsultationMain.getSelfDiagnosisPrimarySymptomDetail(
        e,
        function (data) {
          _this.diseaseselfDiagnosisPrimarySymptomDetail = data.data;
          // _this.selfSex = data.data.filterSex;
          // _this.selfAge = data.data.minAge;
          // _this.quename = data.data.primarySymptomName;
          _this.diseasedialogVisibleDetail = true;
        },
        function (error) {
          console.log(error);
        }
      );
    },

    goStartSure(){
      var _this = this
      _this.searchResultByUserListBZ = []
      _this.dialogVisibleDetail = false;
      _this.drugDialogVisible = false
      _this.searchResultByUserListBZ.push(_this.selfDiagnosisPrimarySymptomDetail)
        _this.DiseaseDomain.getSelfManagerData(
        _this.selfDiagnosisPrimarySymptomDetail.id,
        function (data) {
          _this.searchResultByUserListDetail = data.data;
        },
        function (error) {
          _this.searchResultByUserListDetail = [];
        }
      );

    },
    goStartSuredisease(){
      var _this = this
      _this.searchResultByUserListBB = []
      _this.diseasedialogVisibleDetail = false;
      _this.diseaseDialogVisible = false
      _this.searchResultByUserListBB.push(_this.diseaseselfDiagnosisPrimarySymptomDetail)
      _this.DiseaseDomain.getSelfManagerData(
        _this.diseaseselfDiagnosisPrimarySymptomDetail.id,
        function (data) {
          _this.searchResultByUserListDetailBB = data.data;
        },
        function (error) {
          _this.searchResultByUserListDetailBB = [];
        }
      );
    },
    canclesigndialogVisible(){
      this.esigndialogVisible = false
      // this.AuditNextDataSuc = false  
      this.doctorDialogProgress = 1
    },
    selectChangeSelef(e){
      var _this = this
        _this.patientFormPre.isSelefPrice = e.tradingPrice;
        _this.patientFormPre.chargeName = e.itemName
        _this.isSelefName = e.itemName
        _this.isSelefId = e.id
        // _this.isSelefName = data.data[0].itemName
    },
    selectChangeSelefPrice(e,index){
      var _this = this
      _this.patientFormPre.compositionList[index].isSelefPriceName = e.itemName
      _this.patientFormPre.compositionList[index].isdeliveryPriceId = e.id
      _this.patientFormPre.compositionList[index].isdeliveryPrice = e.tradingPrice
    },
    // 地址
      gochange(){
          this.ruleForm.regionName = this.$refs["refSubCat"].getCheckedNodes()[0].data.fullName
          this.ruleForm.regionCode = this.$refs["refSubCat"].getCheckedNodes()[0].data.regionCode
      },
      InitialRegion() {
          var _this = this;
          _this.UserDistributorMain.Regions(null,
          function (data) { _this.RegionData = data.data; _this.addressDialogVisible = true },
          function (err) { console.log(err); });
      },
      LasyLoadRegion(node, resolve) {
          var _this = this;
          _this.UserDistributorMain.Regions(node.value,
          function (data) {
              for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
                  data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 3;
              }
              resolve(data.data);
          },
          function (err) {
              resolve([]);
          });
      },
      resetForm(){
        var _this = this
          _this.ruleForm={
            address: '',
            addressee: '',
            regionId: null,
            regionName:'',
            region:'',
            phone: '',
            fullAddress: '',
            isDefault: false,
            regionCode:''
        },
        _this.addressDialogVisible = false
      },
      submitForm(formName){
            var _this = this
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    _this.adressTrue = true
                    _this.ruleForm.fullAddress =  this.ruleForm.regionName + this.ruleForm.address
                    let  params = _this.ruleForm
                    _this.shoppingMallMain.addReceivingAddress(params,function (data) {
                        _this.ruleForm={
                            address: '',
                            addressee: '',
                            regionId: null,
                            regionName:'',
                            region:'',
                            phone: '',
                            fullAddress: '',
                            isDefault: false,
                            regionCode:''
                        },
                        setTimeout(()=>{
                          _this.addressDialogVisible = false
                         _this.adressTrue = false
                        },1000)
                        _this.AllMessages()
                    },
                      function (err) {
                          _this.MessageList = []
                          _this.adressTrue = false
                          console.log(err);
                      }
                    )
                }

            })
        },

    // 获取签名数据
    GetUserSign(){
      var _this = this
      _this.patientDomain.GetUserSignData(
        function (data) {
          _this.UserSignImg = data.data
          _this.esigndialogVisible = true;
        },
        function (error) {
          _this.esigndialogVisible = true;
          console.log(error);
        })
    },
    // 控制显示
    mainShowClick(e){
      if (this.patientFormDes.main.indexOf(e.content) == -1) {
        this.patientFormDes.main = this.patientFormDes.main + e.content + ','
      }
    },
    stopPropagation(event){
      event.stopPropagation(); // 阻止点击事件向上冒泡
    },
    pastShowClick(e){
      if (this.patientFormDes.past.indexOf(e.content) == -1) {
        this.patientFormDes.past = this.patientFormDes.past + e.content + ','
      }
    },
    familyShowClick(e){
      if (this.patientFormDes.family.indexOf(e.content) == -1) {
         this.patientFormDes.family = this.patientFormDes.family + e.content + ','
      }
    },
    irritabilityShowClick(e){
      if (this.patientFormDes.irritability.indexOf(e.content) == -1) { 
        this.patientFormDes.irritability = this.patientFormDes.irritability + e.content + ','
      }
    },
    mainBlur(){
      this.mainShow = false
    },
    mainfocus(){
      this.GetSymptomListDataList(1)
      this.mainShow = true
      this.irritabilityShow = false
      this.familyShow = false
      this.pastShow = false
    },
    irritabilityBlur(){
      this.irritabilityShow = false
    },
    irritabilityfocus(){
      this.GetSymptomListDataList(3)
      this.irritabilityShow = true
      this.familyShow = false
      this.pastShow = false
      this.mainShow = false
    },
    familyBlur(){
      this.familyShow = false
    },
    familyfocus(){
      this.GetSymptomListDataList(4)
      this.familyShow = true
      this.irritabilityShow = false
      this.pastShow = false
      this.mainShow = false
    },
    pastBlur(){
      this.pastShow = false
    },
    pastfocus(){
      this.GetSymptomListDataList(2)
      this.pastShow = true
      this.familyShow = false
      this.irritabilityShow = false
      this.mainShow = false
    },
    onEditorChange({ editor, html, text }) {
      this.content = html;
    },
    replaceContent2(content) {
      content = content.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1");
      return content;
    },
    math(info) {
      return this.replaceContent2(info);
    },
    GetSymptomListDataList(type){
      var _this = this;
      _this.drugpatientDomain.GetSymptomListData( type,
        function (data) {
          _this.pastShowList = data.data;
        },
        function (error) {
          _this.pastShowList = [];
        }
      );
    },
    gettWaitData() {
      var _this = this;
      _this.waitDataTotal = 0;
      _this.drugpatientDomain.getMZIndex(
        function (data) {
          _this.waitDataTotal = data.data.waitAuditCount;
          _this.getTakeRequestList();
        },
        function (error) {
          _this.waitDataTotal = 0;
          _this.getTakeRequestList();
        }
      );
    },
    //地址选择
    // handleChange(value) {
    //   this.patientForm.address = value;
    //   var name = "";
    //   this.patientForm.address.map((item) => (name += codeToText[item] + "")); //将省市区三个拼接一起
    //   this.addtions.names = name;
    // },
    refPatientForm() {
      var _this = this;
      _this.patientForm = {
        name: "",
        age: "",
        phone: "",
        idNo: "",
        address: "",
        sex: true,
        diseaseName: "",
      };
    },
    goIdDomain() {
      var _this = this;
      // 身份证
      // 18889/api/connect
      _this.IDCardDomain.GetIdConcetDetail(
        function (data) {
          console.log(data);
        },
        function (error) {
          let errData = JSON.parse(error);
          if (errData.resultFlag == "0") {
            _this.IDCardDomain.GetIdcardDetailTrue(
              function (data) {
                console.log(data);
              },
              function (error) {
                let errDataTrue = JSON.parse(error);
                if (errDataTrue.resultFlag == "0") {
                  _this.IDCardDomain.GetIdcardDetail(
                    function (data) {
                      console.log(data);
                    },
                    function (error) {
                      let errContentData = JSON.parse(error);
                      if (errContentData.resultFlag == "0") {
                        let IDval = errContentData.resultContent.certNumber.length;
                        let IDiden = errContentData.resultContent.certNumber;
                        let IDmyDate = new Date();
                        let IDmonth = IDmyDate.getMonth() + 1;
                        let IDday = IDmyDate.getDate();
                        let IDage = 0;
                        if (IDval == 18) {
                          IDage = IDmyDate.getFullYear() - IDiden.substring(6, 10) - 1;
                          if (IDiden.substring(10, 12) < IDmonth || (IDiden.substring(10, 12) == IDmonth && IDiden.substring(12, 14) <= IDday)) IDage++;
                        }
                        if (IDval == 15) {
                          IDage = IDmyDate.getFullYear() - IDiden.substring(6, 8) - 1901;
                          if (IDiden.substring(8, 10) < IDmonth || (IDiden.substring(8, 10) == IDmonth && IDiden.substring(10, 12) <= IDday)) IDage++;
                        }
                        let randnum = Math.random() * 10000000000000;
                        randnum = Math.floor(randnum);
                        let fileName = "zhu/" + randnum + ".jpg";
                        let esignimgurlFile = _this.dataURLtoFile("data:image/png;base64," + errContentData.resultContent.identityPic, fileName);
                        let dataform = new FormData();
                        let myauditSignImg = "";
                        dataform.append("file", esignimgurlFile);
                        _this
                          .$axios({
                            method: "POST",
                            url: _this.FileUploadUrl,
                            headers: { "Content-Type": "multipart/form-data", Authorization: "Bearer" + " " + _this.asstoken },
                            data: dataform,
                          })
                          .then((res) => {
                            myauditSignImg = res.data[0].completeFilePath;
                            _this.patientForm = {
                              name: errContentData.resultContent.partyName,
                              age: IDage,
                              phone: "",
                              idNo: errContentData.resultContent.certNumber,
                              address: errContentData.resultContent.certAddress,
                              sex: errContentData.resultContent.certType,
                              photo: myauditSignImg,
                              diseaseName: "",
                            };
                            // _this.getNextCompletePrescription();
                          })
                          .catch((err) => {
                            // _this.$message.error("上传签名失败");
                          });
                      } else {
                        _this.$message({
                          type: "error",
                          message: errContentData.errorMsg,
                        });
                      }
                    }
                  );
                }
              }
            );
          } else {
            _this.$message({
              type: "error",
              message: "请检查硬件设备",
            });
          }
        }
      );
    },
    dataChange(e) {
      console.log(e);
    },
    goTodayDetail(item) {
      var _this = this;
      _this.patientDomain.GetPatientDetail(
        item.patientId,
        function (data) {
          if (data.data.patientDiagnose.length > 0) {
            for (let i = 0; i < data.data.patientDiagnose.length; i++) {
              let f = data.data.patientDiagnose[i].addTime.split("T");
              data.data.patientDiagnose[i].addTime = f[0];
            }
          }
          _this.patientDetail = data.data;
          _this.patientForm = data.data;
          _this.visitId = _this.patientDetail.patientDiagnose[0].id
          _this.patientDomain.GetPatientType(
            _this.patientDetail.id, _this.patientDetail.patientDiagnose[0].id,
            function (data) {
              _this.visitData = data.data.patientDiagnose
                // 获取最近一次暂存列表
                _this.patientDomain.getMZDiagnosePationt(
                  _this.patientDetail.id,
                  function (data) {
                    _this.checkItemResult = data.data.id;
                    _this.patientFormDes.breathe = data.data.breathe ? data.data.breathe : 0;
                    _this.patientFormDes.family = data.data.family ? data.data.family.toString() : "";
                    _this.patientFormDes.height = data.data.height ? data.data.height : 0;
                    _this.patientFormDes.heightTension = data.data.heightTension ? data.data.heightTension : 0;
                    _this.patientFormDes.irritability = data.data.irritability ? data.data.irritability.toString() : "";
                    _this.patientFormDes.lowTension = data.data.lowTension ? data.data.lowTension : 0;
                    _this.patientFormDes.main = data.data.main ? data.data.main.toString() : "";
                    _this.patientFormDes.otherCheck = data.data.otherCheck ? data.data.otherCheck.toString() : "";
                    _this.patientFormDes.past = data.data.past ? data.data.past.toString() : "";
                    _this.patientFormDes.pulse = data.data.pulse ? data.data.pulse : 0;
                    _this.patientFormDes.temperature = data.data.temperature ? data.data.temperature : 0;
                    _this.patientFormDes.weight = data.data.weight ? data.data.weight : 0;
                    _this.patientFormDes.PatientId = data.data.patientId;
                    _this.patientFormDes.diseaseDiagnose = data.data.diseaseDiagnose != '' ? data.data.diseaseDiagnose.split(",") : '';
                    _this.patientFormDes.diseaseDiagnoseHashKeyList = data.data.diseaseDiagnoseHashKeyList != '' ? JSON.parse(data.data.discriminatoryAnalysis) : "";
                    _this.patientFormDes.discriminatoryAnalysis = data.data.discriminatoryAnalysis ? data.data.discriminatoryAnalysis : "";
                    if (_this.patientFormDes.discriminatoryAnalysis) {
                      _this.patientFormDes.discriminatoryAnalysisList.push( data.data.discriminatoryAnalysis)
                    }
                    _this.ShowPhotocfList = data.data.prescriptionImgList;
                    _this.ShowPhotoList = data.data.diagnoseImgList;
                    // _this.stepIndex = 2;
                    _this.isCheckItem = true;
                  },
                  function (error) {

                    console.log(error);
                })
            },
            function (error) {
              console.log(error);
            })
        },
        function (error) {
          console.log(error);
          _this.patientDetail = [];
        }
      );
    },
    goZcDetail(item) {
      var _this = this;
      _this.patientDomain.GetPatientDetail(
        item.patientId,
        function (data) {
          if (data.data.patientDiagnose.length > 0) {
            for (let i = 0; i < data.data.patientDiagnose.length; i++) {
              let f = data.data.patientDiagnose[i].addTime.split("T");
              data.data.patientDiagnose[i].addTime = f[0];
            }
          }
          _this.patientDetail = data.data;
          _this.patientForm = data.data;
          _this.checkItemResult = item.id;
          _this.patientFormDes.breathe = item.breathe ? item.breathe : 0;
          _this.patientFormDes.family = item.family ? item.family.toString() : "";
          _this.patientFormDes.height = item.height ? item.height : 0;
          _this.patientFormDes.heightTension = item.heightTension ? item.heightTension : 0;
          _this.patientFormDes.irritability = item.irritability ? item.irritability.toString() : "";
          _this.patientFormDes.lowTension = item.lowTension ? item.lowTension : 0;
          _this.patientFormDes.main = item.main ? item.main.toString() : "";
          _this.patientFormDes.otherCheck = item.otherCheck ? item.otherCheck.toString() : "";
          _this.patientFormDes.past = item.past ? item.past.toString() : "";
          _this.patientFormDes.pulse = item.pulse ? item.pulse : 0;
          _this.patientFormDes.temperature = item.temperature ? item.temperature : 0;
          _this.patientFormDes.weight = item.weight ? item.weight : 0;
          _this.patientFormDes.PatientId = item.patientId;
          _this.patientFormDes.diseaseDiagnose = item.diseaseDiagnose != '' ?  item.diseaseDiagnose.split(",") : '';
          _this.patientFormDes.diseaseDiagnoseHashKeyList =  item.diseaseDiagnoseHashKeyList != '' ? JSON.parse(item.diseaseDiagnoseHashKeyList) : []
          _this.patientFormDes.discriminatoryAnalysis = item.discriminatoryAnalysis ? item.discriminatoryAnalysis : "";
          if (_this.patientFormDes.discriminatoryAnalysis) {
            _this.patientFormDes.discriminatoryAnalysisList.push(item.discriminatoryAnalysis)
          }
          _this.ShowPhotocfList = item.prescriptionImgList;
          _this.ShowPhotoList = item.diagnoseImgList;
          _this.stepIndex = 2;
          _this.isCheckItem = true;
          _this.getBZResultLatestData();
        },
        function (error) {
          console.log(error);
          _this.patientDetail = [];
        }
      );
    },
    getPayPwd() {
      const href = this.$router.resolve("../personal/resetPassword");
      window.open(href.href, "_blank");
    },
    cancleSaveBtn() {
      var _this = this;
      _this.$message({
        type: "error",
        message: "取消支付!",
      });
      _this.paydialogVisible = false;
      _this.$router.push({
        name: "orderList",
      });
    },

    SaveBtn() {
      let _this = this;
      let form = {
        id: _this.charge.id,
        paymentType:3,
        details: _this.charge.details,
        payments: [
          {
            discountsMoney: _this.charge.discountsMoney,
            debtUserName: _this.charge.debtUserName,
            debtUserPhone: _this.charge.debtUserPhone,
            debtMoney: _this.charge.debtMoney,
            payWay: _this.charge.payWay,
            payMoney: (_this.charge.totalMoney - _this.charge.totalPayMoney).toFixed(2),
            totalMoney: (_this.charge.totalMoney - _this.charge.totalPayMoney).toFixed(2),
          },
        ],
      };
      if (_this.charge.payWay == 0) {
        _this.outpatientAuthorizationDomain.getIsSetPayPassword(
          function (data) {
            _this.outpatientAuthorizationDomain.getIsPayPassword(
              {
                payPassword: _this.charge.payPwd,
              },
              function (data) {
                _this.OutpatientDomain.DrugPayBalancePayment(form, function (data) {
                  _this.$message({
                    type: "success",
                    message: "支付成功!",
                  });
                  _this.paydialogVisible = false;
                  _this.reload();
                });
              },
              function (error) {
                _this.$message({
                  type: "info",
                  message: "支付密码错误",
                });
              }
            );
          },
          function (error) {
            _this
              .$confirm("未设置支付密码？去设置", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                const href = _this.$router.resolve("../personal/resetPassword");
                window.open(href.href, "_blank");
              });
          }
        );
      } else if (_this.charge.payWay == 1) {
        _this.OutpatientDomain.DrugPayMent(
          form,
          function (data) {
            // loading.close();
            // _this.qRcodeUrl = _this.Services.Drug + data.data.qRcodeUrl;
            _this.qRcodeUrl = 'data:image/jpeg;base64,' + data.data.qRcodeBase64;
            _this.weChatInfo = data.data;
            var isSuccess = false;
            _this.timer = window.setInterval(() => {
              setTimeout(function () {
                _this.UserDistributorltemDrugMain.VerificationSuccessPay(
                  _this.weChatInfo,
                  function (data2) {
                    isSuccess = data2.data.isSuccess;
                    if (isSuccess) {
                      clearInterval(_this.timer);
                      _this.$message({
                        type: "success",
                        message: "支付成功!",
                      });
                      _this.qRcodeUrl = ''
                      _this.paydialogVisible = false;
                      _this.reload();
                      // _this.chargedialogVisible = false
                      // _this.$router.go(-1);
                    }
                  },
                  function (error) {
                    console.log(error);
                  }
                );
              }, 0);
            }, 5000);
            // setTimeout(() => {
            //     if (!isSuccess) {
            //         clearInterval(timer)
            //         if (paymentDialog.value) {
            //             proxy.$message({
            //                 type: 'error',
            //                 message: '支付超时!'
            //             });
            //             getList();
            //         }
            //     }
            // }, 300000)
          },
          function (error) {
            // loading.close();
            let jsonObj = JSON.parse(error.msg);
            _this.$message({
              message: jsonObj.msg,
              type: "error",
            });
            console.log(error);
          }
        );
      } else  {
        _this.OutpatientDomain.DrugPayMent(
          form,
          function (data) {
            // loading.close();
            _this.qRcodeUrlZ = data.data.alipayData;
            _this.weChatInfo = data.data;
            var isSuccess = false;
            _this.timerZ = window.setInterval(() => {
              setTimeout(function () {
                _this.UserDistributorltemDrugMain.VerificationSuccessPay(
                  // _this.weChatInfo,
                  {orderNo: _this.weChatInfo.orderNo,
                    attach: "MZPayment"
                  },
                  function (data2) {
                    isSuccess = data2.data.isSuccess;
                    if (isSuccess) {
                      clearInterval(_this.timerZ);
                      _this.$message({
                        type: "success",
                        message: "支付成功!",
                      });
                      _this.qRcodeUrlZ = ''
                      _this.paydialogVisible = false;
                      _this.reload();
                      // _this.chargedialogVisible = false
                      // _this.$router.go(-1);
                    }
                  },
                  function (error) {
                    console.log(error);
                  }
                );
              }, 0);
            }, 5000);
            // setTimeout(() => {
            //     if (!isSuccess) {
            //         clearInterval(timer)
            //         if (paymentDialog.value) {
            //             proxy.$message({
            //                 type: 'error',
            //                 message: '支付超时!'
            //             });
            //             getList();
            //         }
            //     }
            // }, 300000)
          },
          function (error) {
            // loading.close();
            let jsonObj = JSON.parse(error.msg);
            _this.$message({
              message: jsonObj.msg,
              type: "error",
            });
            console.log(error);
          }
        );
      }
    },
    //查询部门
    selectHospital() {
      // getAllHospital
      var _this = this;
      const str = _this.referralform.hospitalId ? this.referralform.hospitalId : this.userInfo.organizationId
      _this.Organization.Department(
        str,
        function (data) {
          _this.DepartmentData = data.data;
          _this.referralform.departmentId = "";
          _this.referralform.doctorId = "";
        },
        function (err) {
          console.log(err);
        }
      );
    },
    selectDepartment(data) {
      var _this = this;
      _this.departmentName = this.$refs.myCascader.getCheckedNodes()[0].label;
      const str = _this.referralform.hospitalId ? this.referralform.hospitalId : this.userInfo.organizationId
      _this.User.RefeAllUser(
        str,
        data,
        function (data) {
          _this.DoctorData = data.data;
          _this.referralform.doctorId = "";
        },
        function (err) {
          console.log(err);
        }
      );
    },
    getOrgInfo() {
      var _this = this;
      _this.Organization.Detial(
        this.userInfo.organizationId,
        function (data) {
          _this.orgData = data.data;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    selectReferralType(e) {
      var _this = this;
      this.referralform.departmentId = "";
      this.referralform.doctorId = "";
      this.HospitalData = [];
      this.DepartmentData = [];
      this.DoctorData = [];
      _this.referralform.green = false;
      if (this.referralType == 1) {
        this.referralform.hospitalId = this.userInfo.organizationId;
        this.selectHospital();
        this.getOrgInfo();
      } else {
        this.referralform.hospitalId = '';
        _this.Organization.getAllHospital(
          _this.referralform.hospitalId,
          function (data) {
            _this.HospitalData = data.data;
          },
          function (err) {
            console.log(err);
          }
        );
      }
    },
    checkboxChange(e) {
      if (e) {
        this.referralType = 0;
      } else {
        this.referralType = 1;
      }
    },
    //新增转诊
    saveReferral() {
      var _this = this;
      var item = _this.patientForm;
      if(!_this.userReferralId){
        _this.userReferralId = _this.patientForm.id;
      }
      _this.$refs.referralform.validate(valid => {
        if (valid) {
          _this.Referral.AddReferral(
            item.name,
            item.sex ? 1 : 0,
            item.age,
            item.phone,
            item.idNo,
            _this.referralform.degree,
            _this.chiefComplaint,
            _this.referralform.introduction,
            _this.userReferralId,
            _this.patientForm?.address,
            function (data) {
              _this.SaveUpload(data?.data?.referralId);
            },
            function (err) {
              _this.$message.error("新增失败");
              console.log(err);
            }
          );
        } else {
          return false;
        }
      });

    },
    //转诊处理
    SaveUpload(referralId) {
      var _this = this;
      var item = _this.referralform;
      var Hospital = _this.HospitalData.find(function (x) {
        return x.id === item.hospitalId;
      });
      var Doctor = {
        id: "",
        phone: "",
        userName: "",
      };
      if (this.referralType == 1) {
        Hospital = {
          phone: this.orgData.phone,
          name: this.orgData.name,
        };
      }
      if (_this.DoctorData.length > 0) {
        Doctor = _this.DoctorData.find(function (x) {
          return x.id === item.doctorId;
        });
      }
      
      this.$refs.referralform.validate(valid => {
        if (valid) {
          _this.alreadySubmitForm('patientFormDes');
          _this.Referral.Upload(
            referralId,
            item.urgent ? 1 : 0,
            item.reason,
            item.introduction,
            item.doctorId,
            item.hospitalId,
            Hospital.phone,
            Hospital.name,
            _this.departmentName,
            Doctor.phone,
            Doctor.userName,
            _this.userReferralId,
            function (data) {
              _this.referrallogVisible = false;
              _this.$message({
                message: "转诊成功",
                type: "success",
              });
              _this.$router.push({
                name: "referralAndmedical",
              });
            },
            function (err) {
              console.log(err);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //确认转诊患者按钮
    btnReferral() {
      const newArr = this.patientFormDes.diseaseDiagnose.map(item => {
        if (Object.prototype.toString.call(item) == '[object Object]') {
          return item.diseaseName;
        } else {
          return item;
        }
      })
      this.chiefComplaint = newArr.join(',');
      this.referrallogVisible = true
    },
    //取消转诊按钮
    cancleReferral() {
      this.$refs.referralform.clearValidate();
      this.$refs.referralform.resetFields();
      this.referralModal = true;
      this.referralTitle = '确认转诊患者信息';
      this.referrallogVisible = false;
    },
    getAllUserBalance() {
      var _this = this;
      _this.UserDistributorMain.getUserDistributor(
        function (data) {
          _this.UserBalance = data.data;
          if (_this.UserBalance.assetsBalance * 1 < _this.charge.totalMoney * 1) {
            _this.assetsBalanceIsTrue = true;
            _this.charge.payWay = 1;
          } else {
            _this.assetsBalanceIsTrue = false;
          }
        },
        function (err) {
          _this.UserBalance = [];
          console.log(err);
        }
      );
    },
    goResultByzhu(item) {
      this.patientFormDes.main = item.primarySymptomName + ";" + item.symptoms;
    },
    diseasChange(e) {
      this.drugIdList = e;
    },
    removediseasChange(e){
      console.log(this.patientFormDes.discriminatoryAnalysis)
      for (let i=0; i<this.patientFormDes.discriminatoryAnalysisList.length; i++) {
        if (this.patientFormDes.discriminatoryAnalysisList[i].indexOf(e) != -1) {
              this.patientFormDes.discriminatoryAnalysisList.splice(i, 1);
        }
      }
      this.patientFormDes.discriminatoryAnalysis = this.patientFormDes.discriminatoryAnalysisList.toString()
    },
    remoteMethod(e) {
      this.getDiseasesSearchData(e);
    },
    getDiseasesSearchData(keyWord) {
      var _this = this;
      _this.DiseaseDomain.getDiseasesSearch(
        "全部",
        "1",
        "0",
        keyWord,
        function (data) {
          _this.diseasOptions = data.data.results;
        },
        function (err) {
          _this.diseasOptions = [];
          console.log(err);
        }
      );
    },
    changeDetails(e){  
      this.changeTable = e
      this.getResultDrugsData(e)
    },
    checkSameDataQt(resData,resData1){
				let tempArr = [];
				let Data = [];
				for (let i = 0; i < resData.length; i++) {
          // replace(/\s*/g,"")去掉空字符
				   if (tempArr.indexOf(resData[i].prescriptionName.replace(/\s*/g,"")) == -1) {
					 Data.push({
            concomitantDisease:resData1.syndromeName,
					   dataInfo: [resData[i]],
					   prescriptionName:  resData[i].prescriptionName.replace(/\s*/g,"")
					 });
					 tempArr.push(resData[i].prescriptionName.replace(/\s*/g,""));
				   } else {
					 for (let j = 0; j < Data.length; j++) {
					   if (Data[j].prescriptionName.replace(/\s*/g,"") == resData[i].prescriptionName.replace(/\s*/g,"")) {
						 Data[j].dataInfo.push(resData[i]);
						 break;
					   }
					 }
				   }
				 }
				 return Data
		},
    getResultDrugsData(type) {
      var _this = this;
      let params = [];
      // 辩证或虚证推荐处方
      let params1 = _this.patientFormDes.diseaseDiagnose.split(",");
      let diseaseDiagnoseHashKeyList1 = _this.patientFormDes.diseaseDiagnoseHashKeyList != '' && typeof(JSON.parse(_this.patientFormDes.diseaseDiagnoseHashKeyList)) == 'object' ? JSON.parse(_this.patientFormDes.diseaseDiagnoseHashKeyList) : []
      // if (_this.serchdrugIdList.length == 0 && diseaseDiagnoseHashKeyList1.length > 0 && typeof(diseaseDiagnoseHashKeyList1) == 'object' ) {
      //   params1.map((item1, index1) => {
      //     diseaseDiagnoseHashKeyList1.map((item, index) => {
      //       if (item1 == item.syndromeName) {
      //         params.push(item.syndromeId ? item.syndromeId : item.id);
      //       }
      //     });
      //   });
      // } else {
      //   params1.map((item1, index1) => {
      //     _this.serchdrugIdList.map((item, index) => {
      //       if (item1 == item.syndromeName) {
      //         params.push(item.syndromeId ? item.syndromeId : item.id);
      //       }
      //     });
      //   });
      // }
      params1.map((item1, index1) => {
        diseaseDiagnoseHashKeyList1.map((item, index) => {
          if (item1 == item.syndromeName) {
            params.push(item.syndromeId ? item.syndromeId : item.id);
          }
        });
      });
      let drugType = type ? type : '0'
      _this.shoppingMalDiseaseDomain.getResultDrugs( drugType,
        params,
        function (data) {
          // 方剂处方
          if (drugType == 0) {
              _this.getcheckItemstDataPreD();
              _this.GetCheckItemsTopQT()
              _this.tHerbalList = [];
              data.data.forEach((item, index) => {
                if (item.syndromeDrugs.length > 0) {
                  item.syndromeDrugs.forEach((item1, index1) => {
                    item1.showName = item.syndromeName
                    if (!item1.isAssociation) {
                      _this.tHerbalList.push(item1);
                    }
                  });
                }
              });
          } else {
            _this.QtHerbalList = []
            for (let i=0; i<data.data.length; i++ ) {
              _this.QtHerbalList.push(
                _this.checkSameDataQt(data.data[i].syndromeDrugs,data.data[i])
              )
            }
            console.log(_this.QtHerbalList)
          }
     
          // data.data.forEach((item, index) => {
          //   if (item.drugs.length > 0) {
          //     item.drugs.forEach((item1, index1) => {
          //       _this.tHerbalList.push(item1);
          //     });
          //   }
          // });
        },
        function (error) {
          _this.getcheckItemstDataPreD();
          _this.GetCheckItemsTopQT()
          _this.tHerbalList = [];
          _this.QtHerbalList = []
        }
      );
    },
    getzResultDrugsData() {
      var _this = this;
      let params = [];
      let params1 = _this.patientFormDes.diseaseDiagnose.split(",");
      let diseaseDiagnoseHashKeyList1 = _this.patientFormDes.diseaseDiagnoseHashKeyList != '' && typeof(JSON.parse(_this.patientFormDes.diseaseDiagnoseHashKeyList)) == 'object' ? JSON.parse(_this.patientFormDes.diseaseDiagnoseHashKeyList) : []
      // if (_this.zserchdrugIdList.length == 0 && diseaseDiagnoseHashKeyList1.length > 0 && typeof(diseaseDiagnoseHashKeyList1) == 'object') {
      //   params1.map((item1, index1) => {
      //    diseaseDiagnoseHashKeyList1.map((item, index) => {
      //       if (item1 == item.name) {
      //         if (item.questionnaireResultId) {
      //             params.push(item.questionnaireResultId);
      //         } else {
      //             params.push(item.id);

      //         }
      //       }
      //     });
      //   });
      // } else {
      //   params1.map((item1, index1) => {
      //     _this.zserchdrugIdList.map((item, index) => {
      //       if (item1 == item.name) {
      //         if (item.questionnaireResultId) {
      //             params.push(item.questionnaireResultId);
      //         } else {
      //             params.push(item.id);

      //         }
      //       }
      //     });
      //   });
      // }
        params1.map((item1, index1) => {
         diseaseDiagnoseHashKeyList1.map((item, index) => {
            if (item1 == item.name) {
              if (item.questionnaireResultId) {
                  params.push(item.questionnaireResultId);
              } else {
                  params.push(item.id);

              }
            }
          });
        });
        _this.shoppingMalStaticsDomain.ZgetResultDrugs(
          params,
          function (data) {
            _this.getResultDrugsData();
            _this.zHerbalList = data.data;
            // data.data.forEach((item, index) => {
            //   _this.zHerbalList.push(item.drug);
            // });
          },
          function (error) {
            _this.getResultDrugsData();
            _this.zHerbalList = [];
          }
        );

    },
    AllMessages() {
      var _this = this;
      _this.shoppingMallMain.getReceivingAddress(
        function (data) {
          if (data.data) {
            _this.MessageList = data.data.reverse();
            _this.messagePatinet = data.data[0];
          }
          _this.getDrugCompositio();
        },
        function (err) {
          _this.MessageList = [];
          _this.getDrugCompositio();
          console.log(err);
        }
      );
    },
    beforeAvatarUpload(felr) {
      console.log(felr);
    },
    DiagnoseImageUpload(response, file, fileList) {
      var _this = this;
      response.forEach(function (file) {
        var imgarr = {
          filePath: file.completeFilePath,
          fileName: file.fileName,
          fileHash: file.fileHash,
          fileExt: file.fileExtension,
        };

        _this.DiagnoseContent.push(imgarr);
      });
      _this.diagnoseForm.DiagnoseImgList = _this.DiagnoseContent;
    },
    DiagnoseImageRemove(file, fileList) {
      var _this = this;
      const filePath = file.response[0].completeFilePath;
      //2.从pics数组中找到图片对应的索引值
      //形参‘x’，是数组pic里的每一项
      const i = _this.DiagnoseContent.findIndex((x) => x.completeFilePath == filePath);
      //调用数组的splice方法，把图片的信息对象从pics数组中移除
      _this.DiagnoseContent.splice(i, 1);
      _this.patientFormDes.DiagnoseImgList = _this.DiagnoseContent;
    },
    DiagnoseImagePreview(file) {
      this.Image.dialogImageUrl = file.url;
      this.Image.dialogVisible = true;
    },
    handleImagePreview(file) {
      this.Image.dialogImageUrl = file.url;
      this.Image.dialogVisible = true;
    },
    handleImageRemove(file, fileList) {
      var _this = this;
      const filePath = file.response[0].completeFilePath;
      //2.从pics数组中找到图片对应的索引值
      //形参‘x’，是数组pic里的每一项
      const i = _this.ImageContent.findIndex((x) => x.completeFilePath == filePath);
      //调用数组的splice方法，把图片的信息对象从pics数组中移除
      _this.ImageContent.splice(i, 1);
      _this.diagnoseForm.PrescriptionImgList = _this.ImageContent;
    },
    handleImageUpload(response, file, fileList) {
      var _this = this;
      response.forEach(function (file) {
        var imgarr = {
          filePath: file.completeFilePath,
          fileName: file.fileName,
          fileHash: file.fileHash,
          fileExt: file.fileExtension,
        };

        _this.ImageContent.push(imgarr);
      });
      _this.patientFormDes.PrescriptionImgList = _this.ImageContent;
    },
    toCompatibilityDetail(sourceType, sourceHashKey, targetType, targetHashKey) {
      tocompareDetail(sourceType, sourceHashKey, targetType, targetHashKey);
    },
    compatibility(peiwuList) {
      var _this = this;
      _this.types = [0, 1, 2, 3, 4, 5, 6];
      _this.compatibilityList = [];
      _this.similarList = [];
      _this.instructions = [];
      _this.dietTotal = 0;
      _this.avoidTotal = 0;
      _this.total = 0;
      _this.crowdTotal = 0;
      _this.compatibilityTotal = 0;
      _this.similarTotal = 0;
      let params = {};
      params.items = peiwuList;
      (params.types = _this.types),
        _this.compatibilityDomain.MZCompatibilitys(
          params,
          function (data) {
            _this.oldName = getCompatibilityList()
              .filter((x) => x.state === "1")
              .map((x) => x.value)
              .join("---");
              if (!_this.compatibilityResultShow) {
                _this.compatibilityList = data.data.compatibility.filter((x) => x.compatibilityType !== 7 && x.result);
              } else {
                _this.compatibilityList = data.data.compatibility.filter((x) => x.compatibilityType !== 7);
              }
            _this.similarList = data.data.similar;
            _this.instructions = data.data.instructions;
            _this.types = data.data.types;
            _this.totalprocess();
          },
          function (error) {
            console.log("配伍请求异常:", error);
          }
        );
    },
    totalprocess() {
      this.similarTotal = this.types.indexOf(0) != -1 && this.similarList != null ? this.similarList.length : 0;
      this.compatibilityTotal = this.types.indexOf(0) != -1 ? this.compatibilityList.length : 0;
      this.crowdTotal = this.getNumByType(this.instructions, 3) + this.getNumByType(this.instructions, 4) + this.getNumByType(this.instructions, 5) + this.getNumByType(this.instructions, 6);

      this.dietTotal = this.getNumByType(this.instructions, 2);
      this.avoidTotal = this.getNumByType(this.instructions, 1);
      this.total = this.avoidTotal + this.dietTotal + this.crowdTotal + this.compatibilityTotal + this.similarTotal;
    },
    similarhtmlRaw(item) {
      var info = "";
      if (item.sourceDrugCategory != item.targetDrugCategory) {
        // var reg = new RegExp(item.sourceDrugComposition.replace(/\s/g, "").split("").join("|"), "ig");
        // var reg1=new RegExp(',','g')
        // console.log(item.targetDrugComposition.match(reg).join().replace(reg1,''))
        info = `${item.sourceDrugName}:${item.sourceDrugComposition}<br> ${item.targetDrugName}:${item.targetDrugComposition} <br> ${item.sourceDrugName} 与 ${item.targetDrugName} 含有相同成分 `;
      } else {
        info = `${item.sourceDrugName} 与 ${item.targetDrugName} 同属于 ${item.sourceDrugCategory}(${item.sourceDrugCategoryName})`;
      }
      // info=`${item.sourceDrugName}:${item.sourceDrugComposition}<br>
      //     ${item.targetDrugName}:${item.targetDrugComposition} <br>
      //     ${item.sourceDrugName} 与 ${item.targetDrugName} 含有相同成分 `
      return info;
    },
    namehtmlraw(item) {
      return namehtmlraw(item);
    },
    //tip分组
    tipGroup(list) {
      return tipGroup(list);
    },
    //内容HTML转换
    contenthtmlRaw(item, isdiet = false) {
      return contenthtmlRaw(item, isdiet);
    },
    //饮食禁忌 分组
    dietGroup(list) {
      return dietGroup(list);
    },
    //特殊人群列表
    getcrowdList() {
      return getcrowdList(this.instructions);
    },
    showCourseInfoList(e) {
      const target = e.currentTarget;
      // 寻找父元素的兄弟元素进行显示与隐藏控制
      $(target).siblings().toggle();
      // 切换样式-图标
      $(target).find("i").toggleClass("el-icon-arrow-down").addClass("el-icon-arrow-up");
      if ($(target).siblings().is(":hidden")) {
        $(target).find(".show-text").html("展开");
      } else {
        $(target).find(".show-text").html("收起");
      }
    },
    // 配伍
    getNumByType(array, type) {
      let num = 0;
      if (array.filter((i) => i.type == type).length > 0) {
        num = array.filter((i) => i.type == type)[0].items.length;
      }
      return num;
    },
    getTakeRequestList() {
      var _this = this;
      _this.drugpatientDomain.TakeRequestList(
        "%20",
        "1",
        _this.orgexpertId,
        function (data) {
          _this.requestOption = data.data.results;
          _this.getTodayPatientData();
        },
        function (err) {
          _this.EditDrugCompositio = [];
          _this.getTodayPatientData();
          console.log(err);
        }
      );
    },
    getDrugCompositio() {
      var _this = this;
      _this.drugpatientDomain.GetDrugByOrgInTop10(
        "%20",_this.orgexpertId,
        function (data) {
          for (let i = 0; i < data.data.length; i++) {
            var label = `${data.data[i].mzDrug.drugName}（${data.data[i].specification}/${data.data[i].specificationUnit}-${data.ata[i].productFactory}）`;
            _this.EditDrugCompositio.push({
              drugOrgId: data.data[i].id,
              drugOrgName: label,
              drugOrgName1: data.data[i].mzDrug.drugName,
              drug: data.data[i],
            });
          }
        },
        function (err) {
          _this.EditDrugCompositio = [];
          console.log(err);
        }
      );
    },
    getBZResultLatestData() {
      var _this = this;
      _this.ResultLatestData = [];
      _this.DiseaseDomain.getBZResultLatest(
        _this.patientDetail.phone,
        function (data) {
          _this.ResultLatestData = data.data;
          let contantList = [];
          for (let i = 0; i < _this.ResultLatestData.recordResults.length; i++) {
            if (_this.ResultLatestData.recordResults[i].pulseSymptoms == null) {
              _this.ResultLatestData.recordResults[i].pulseSymptoms = [];
            } else if (_this.ResultLatestData.recordResults[i].symptoms == null) {
              _this.ResultLatestData.recordResults[i].symptoms = [];
            } else if (_this.ResultLatestData.recordResults[i].tongueSymptoms == null) {
              _this.ResultLatestData.recordResults[i].tongueSymptoms = [];
            } else {
              console.log("123");
            }
            contantList = _this.ResultLatestData.recordResults[i].pulseSymptoms.concat(_this.ResultLatestData.recordResults[i].symptoms.concat(_this.ResultLatestData.recordResults[i].tongueSymptoms));
            _this.ResultLatestData.recordResults[i].contantLists = contantList;
            let newsyndromeSymptoms1 = [];
            for (let k = 0; k < _this.ResultLatestData.recordResults[i].syndromeSymptoms.length; k++) {
              newsyndromeSymptoms1.push({
                name: _this.ResultLatestData.recordResults[i].syndromeSymptoms[k],
                checked: false,
              });
            }
            _this.ResultLatestData.recordResults[i].newsyndromeSymptoms = newsyndromeSymptoms1;
          }
          for (let m = 0; m < _this.ResultLatestData.recordResults.length; m++) {
            for (let n = 0; n < _this.ResultLatestData.recordResults[m].contantLists.length; n++) {
              for (let j = 0; j < _this.ResultLatestData.recordResults[m].newsyndromeSymptoms.length; j++) {
                if (_this.ResultLatestData.recordResults[m].newsyndromeSymptoms[j].name == _this.ResultLatestData.recordResults[m].contantLists[n]) {
                  _this.ResultLatestData.recordResults[m].newsyndromeSymptoms[j].checked = true;
                }
              }
            }
          }
        //           辩证 searchResultByUserListBZ
        // searchResultByUserListDetail
        _this.searchResultByUserListBZ = []
         _this.searchResultByUserListDetail = []
        },
        function (error) {
          _this.ResultLatestData = [];
        }
      );
    },
    getBBResultLatestData() {
      var _this = this;
      _this.tcmResultLatestData = [];
      if (_this.Services.ClientId == 'AssociationWeb') {
          _this.DiseaseDomain.getBBResultLatestmz(
            _this.patientDetail.phone,
            function (data) {
              _this.tcmResultLatestData = data.data;
              let contantList = [];
              for (let i = 0; i < _this.tcmResultLatestData.recordResults.length; i++) {
                if (_this.tcmResultLatestData.recordResults[i].pulseSymptoms == null) {
                  _this.tcmResultLatestData.recordResults[i].pulseSymptoms = [];
                } else if (_this.tcmResultLatestData.recordResults[i].symptoms == null) {
                  _this.tcmResultLatestData.recordResults[i].symptoms = [];
                } else if (_this.tcmResultLatestData.recordResults[i].tongueSymptoms == null) {
                  _this.tcmResultLatestData.recordResults[i].tongueSymptoms = [];
                } else {
                  console.log("123");
                }
                contantList = _this.tcmResultLatestData.recordResults[i].pulseSymptoms.concat(_this.tcmResultLatestData.recordResults[i].symptoms.concat(_this.tcmResultLatestData.recordResults[i].tongueSymptoms));
                _this.tcmResultLatestData.recordResults[i].contantLists = contantList;
                let newsyndromeSymptoms1 = [];
                for (let k = 0; k < _this.tcmResultLatestData.recordResults[i].syndromeSymptoms.length; k++) {
                  newsyndromeSymptoms1.push({
                    name: _this.tcmResultLatestData.recordResults[i].syndromeSymptoms[k],
                    checked: false,
                  });
                }
                _this.tcmResultLatestData.recordResults[i].newsyndromeSymptoms = newsyndromeSymptoms1;
              }
              for (let m = 0; m < _this.tcmResultLatestData.recordResults.length; m++) {
                for (let n = 0; n < _this.tcmResultLatestData.recordResults[m].contantLists.length; n++) {
                  for (let j = 0; j < _this.tcmResultLatestData.recordResults[m].newsyndromeSymptoms.length; j++) {
                    if (_this.tcmResultLatestData.recordResults[m].newsyndromeSymptoms[j].name == _this.tcmResultLatestData.recordResults[m].contantLists[n]) {
                      _this.tcmResultLatestData.recordResults[m].newsyndromeSymptoms[j].checked = true;
                    }
                  }
                }
              }
              _this.searchResultByUserListBB = []
              _this.searchResultByUserListDetailBB = []
            },
            function (error) {
              _this.tcmResultLatestData = [];
            }
          );
      } else {
        _this.DiseaseDomain.getBBResultLatest(
          _this.patientDetail.phone,
          function (data) {
            _this.tcmResultLatestData = data.data;
            let contantList = [];
            for (let i = 0; i < _this.tcmResultLatestData.recordResults.length; i++) {
              if (_this.tcmResultLatestData.recordResults[i].pulseSymptoms == null) {
                _this.tcmResultLatestData.recordResults[i].pulseSymptoms = [];
              } else if (_this.tcmResultLatestData.recordResults[i].symptoms == null) {
                _this.tcmResultLatestData.recordResults[i].symptoms = [];
              } else if (_this.tcmResultLatestData.recordResults[i].tongueSymptoms == null) {
                _this.tcmResultLatestData.recordResults[i].tongueSymptoms = [];
              } else {
                console.log("123");
              }
              contantList = _this.tcmResultLatestData.recordResults[i].pulseSymptoms.concat(_this.tcmResultLatestData.recordResults[i].symptoms.concat(_this.tcmResultLatestData.recordResults[i].tongueSymptoms));
              _this.tcmResultLatestData.recordResults[i].contantLists = contantList;
              let newsyndromeSymptoms1 = [];
              for (let k = 0; k < _this.tcmResultLatestData.recordResults[i].syndromeSymptoms.length; k++) {
                newsyndromeSymptoms1.push({
                  name: _this.tcmResultLatestData.recordResults[i].syndromeSymptoms[k],
                  checked: false,
                });
              }
              _this.tcmResultLatestData.recordResults[i].newsyndromeSymptoms = newsyndromeSymptoms1;
            }
            for (let m = 0; m < _this.tcmResultLatestData.recordResults.length; m++) {
              for (let n = 0; n < _this.tcmResultLatestData.recordResults[m].contantLists.length; n++) {
                for (let j = 0; j < _this.tcmResultLatestData.recordResults[m].newsyndromeSymptoms.length; j++) {
                  if (_this.tcmResultLatestData.recordResults[m].newsyndromeSymptoms[j].name == _this.tcmResultLatestData.recordResults[m].contantLists[n]) {
                    _this.tcmResultLatestData.recordResults[m].newsyndromeSymptoms[j].checked = true;
                  }
                }
              }
            }
            _this.searchResultByUserListBB = []
            _this.searchResultByUserListDetailBB = []
          },
          function (error) {
            _this.tcmResultLatestData = [];
          }
        );
      }
    },
    addgetLoadPrescriptionQTS(index,index1){
      var _this = this
      var params = []
      for (let i = 0; i < _this.hefangDialogListQT.length; i++) { 
          params.push({
            drugName: _this.hefangDialogListQT[i].name,
            drugHashKey: _this.hefangDialogListQT[i].drugHashKey,
          });
      }
      _this.drugpatientDomain.getLoadPrescription(
         _this.orgexpertId, params,
          function (data) {
            let item1 = data.data
            for (let i=0; i<item1.length; i++) {
              _this.patientFormPre.SYcompositionList[index].zucompositionList[index1].mzPrescriptionDetail.push({
                 drugOrgId: item1[i].drugOrgId,
                  drugOrgName: item1[i].drugOrgName,
                  // st: item1[i].st,
                  dosageList: {
                    dosage: item1[i].dosage,
                    dosageUnit: item1[i].dosageUnit,
                  },
                defaultDosageList: {
                  defaultDosage: item1[i].defaultDosage,
                  defaultDosageUnit: item1.isSeparate ? item1[i].separateNumberUnit : (item1[i].defaultDosageUnit ? item1[i].defaultDosageUnit : item1[i].specificationUnit),
                },
                tradingPrice: item1[i].tradingPrice,
                inventoryNumber: item1[i].drugInventoryNumber,
                hashKey:item1[i].drugHashKey
              })
            }
            _this.patientFormPre.SYcompositionList[index].prescriptionName += _this.JChooseName + ","
            let paramsData = _this.patientFormPre.SYcompositionList[index].zucompositionList[index1].mzPrescriptionDetail
            const uniqueArray = paramsData.filter((item, index, self) => self.findIndex((t) => t.drugOrgName === item.drugOrgName && t.drugOrgName) === index);
            _this.patientFormPre.SYcompositionList[index].zucompositionList[index1].mzPrescriptionDetail = uniqueArray
            _this.hefangDialogVisibleQT = false
          },
          function (error) {
            _this.hefangDialogVisibleQT = false
            // _this.jdcompositionList = [];
          }
        );
    },
    addgetLoadPrescriptionQTC(){
      var _this = this
      var params = []
      for (let i = 0; i < _this.hefangDialogListQT.length; i++) { 
          params.push({
            drugName: _this.hefangDialogListQT[i].name,
            drugHashKey: _this.hefangDialogListQT[i].drugHashKey,
          });
      }
      _this.drugpatientDomain.getLoadPrescription(
         _this.orgexpertId, params,
          function (data) {
            let item1 = data.data
            for (let i=0; i<item1.length; i++) {
              _this.patientFormPre.CZcompositionList[0].mzPrescriptionDetail.push({
                drugOrgId: item1[i].drugOrgId,
                drugOrgName: item1[i].drugOrgName,
                // groupNumber: item1.groupNumber,
                frequency: item1[i].frequency,
                usage: item1[i].usage,
                remark: item1[i].remark,
                dosageList: {
                  dosage: item1[i].dosage,
                  dosageUnit: item1[i].dosageUnit,
                },
                defaultDosageList: {
                  defaultDosage: item1[i].defaultDosage,
                  defaultDosageUnit: item1.isSeparate ? item1[i].separateNumberUnit : (item1[i].defaultDosageUnit ? item1[i].defaultDosageUnit : item1[i].specificationUnit),
                },
                tradingPrice: item1[i].tradingPrice,
                inventoryNumber: item1[i].drugInventoryNumber,
                hashKey:item1[i].drugHashKey
              })
            }
            _this.patientFormPre.CZcompositionList[0].prescriptionName += _this.JChooseName + ",";
            let paramsData = _this.patientFormPre.CZcompositionList[0].mzPrescriptionDetail
            const uniqueArray = paramsData.filter((item, index, self) => self.findIndex((t) => t.drugOrgName === item.drugOrgName && t.drugOrgName) === index);
            console.log(uniqueArray)
            _this.patientFormPre.CZcompositionList[0].mzPrescriptionDetail = uniqueArray
            _this.hefangDialogVisibleQT = false
          },
          function (error) {
            _this.hefangDialogVisibleQT = false
            // _this.jdcompositionList = [];
          }
        );
     
    },
    addgetLoadPrescription(index) {
      var _this = this;
       _this.jdcompositionList = []
      if (_this.herbalDetail.compositions) {
        let params = [];
        for (let i = 0; i < _this.herbalDetail.compositions.length; i++) {
          let content = _this.herbalDetail.compositions[i].compositionName;
          let reg = /\[\[(.*?)\]\]/g;
          let result = [];
          while (true) {
            let itemArr = reg.exec(content);
            if (itemArr === null) {
              break;
            }
            result.push(itemArr[0]); //[[麸炒|Dict|48111]]
          }
          let longestString = ""; // 存放最长字符串的变量
          if (result.length > 1) {
            result.map((item) => {
              if (item.length > longestString.length) {
                item = longestString;
              }
            });
          } else {
            longestString = result[0]; // 存放最长字符串的变量
          }
          params.push({
            drugName: _this.herbalDetail.compositions[i].compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1"),
            remark: _this.herbalDetail.compositions[i].remark,
            weight: _this.herbalDetail.compositions[i].dosage ? parseFloat(_this.herbalDetail.compositions[i].dosage) : 0,
            drugHashKey: longestString.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$3"),
            preHashKey: _this.herbalDetail.hashKey,
            materialType: _this.herbalDetail.compositions[i].compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$2"),
            recommendDosage: _this.herbalDetail.compositions[i].recommendDosage ? _this.herbalDetail.compositions[i].recommendDosage : "",
          });
          params.map((item, index) => {
            if (item.drugName == item.drugHashKey || !item.drugHashKey || item.drugHashKey == "Search") {
              item.drugHashKey = "";
            } else {
              item.drugHashKey = item.drugHashKey;
            }
          });
        }
        _this.drugpatientDomain.getLoadPrescription(
         _this.orgexpertId, params,
          function (data) {
             _this.jdcompositionList = _this.patientFormPre.compositionList[index].mzPrescriptionDetail
            // _this.patientFormPre.compositionList[index].mzPrescriptionDetail = [];
            if (_this.JChooseName) {
              if (_this.patientFormPre.compositionList[index].prescriptionName.indexOf(_this.JChooseName) == -1) {
                _this.patientFormPre.compositionList[index].prescriptionName += _this.JChooseName + ",";
              }
            }
            _this.jdcompositionList = _this.jdcompositionList.concat(data.data);
            for (let i=0; i<_this.jdcompositionList.length; i++) {
              if (_this.jdcompositionList[i].recommendDosage) {
                var f = _this.jdcompositionList[i].recommendDosage.split('-')
                var f1 = parseFloat(f[0]) * 1 
                var f2 = parseFloat(f[1]) * 1
                _this.jdcompositionList[i]['recommendDosageMin'] = f1
                _this.jdcompositionList[i]['recommendDosageMax'] = f2
              }
            }
            const uniqueArray = _this.jdcompositionList.filter((item, index, self) => self.findIndex((t) => t.drugOrgName === item.drugOrgName && t.drugOrgName) === index);
            _this.patientFormPre.compositionList[index].mzPrescriptionDetail = uniqueArray;
            _this.jdcompositionList = []
            _this.hefangDialogVisible = false
          },
          function (error) {
            _this.jdcompositionList = [];
          }
        );
      }
    },
    addgetLoadPrescription1(index) {
      var _this = this;
      let params1 = {};
      let priceParams = [];
      let priceParamsres = [];
      _this.MyCommonPrescripTableData.mzPrescriptionDetail.map((item, index) => {
        priceParams.push({
          drugOrgId: item.drugOrgId,
          drugOrgName: item.drugOrgName,
          remark: item.remark,
          weight: parseFloat(item.weight),
          decoct: item.decoct,
          recommendDosage: item.recommendDosage,
        });
      });
      params1.prescriptionName = _this.MyCommonPrescripTableData.prescriptionName;
      params1.request = _this.MyCommonPrescripTableData.request;
      params1.usage = _this.MyCommonPrescripTableData.usage;
      params1.frequency = _this.MyCommonPrescripTableData.frequency;
      params1.dosage = _this.MyCommonPrescripTableData.dosage;
      params1.dosageNumber = _this.MyCommonPrescripTableData.dosageNumber;
      params1.mzPrescriptionDetail = priceParams;
      _this.drugpatientDomain.getAddLoadPrescriptionCommon(params1, function (data) {
        data.data.mzPrescriptionDetail.map((item1, index) => {
          priceParamsres.push({
            drugOrgId: item1.drugOrgId,
            drugOrgName: item1.drugOrgName,
            remark: item1.remark,
            weight: item1.weight,
            decoct: item1.decoct,
            recommendDosage: item1.recommendDosage,
            tradingPrice: item1.tradingPrice,
            inventoryNumber: item1.drugInventoryNumber,
          });
        });
        if (_this.patientFormPre.compositionList[index].prescriptionName.indexOf(_this.MyCommonPrescripTableData.prescriptionName) == -1) {
          _this.patientFormPre.compositionList[index].prescriptionName += _this.MyCommonPrescripTableData.prescriptionName + ",";
        }
        _this.patientFormPre.compositionList[index].mzPrescriptionDetail = _this.patientFormPre.compositionList[index].mzPrescriptionDetail.concat(priceParamsres);
        const uniqueArray = _this.patientFormPre.compositionList[index].mzPrescriptionDetail.filter((item, index, self) => self.findIndex((t) => t.drugOrgName === item.drugOrgName && t.drugOrgName) === index);
        _this.patientFormPre.compositionList[index].mzPrescriptionDetail = uniqueArray;
      });
    },
    addgetLoadPrescription2(index) {
      var _this = this;
      let params1 = {};
      let priceParams = [];
      let priceParamsres = [];
      _this.MyCommonPrescripTableData.mzPrescriptionDetail.map((item, index) => {
        priceParams.push({
          drugOrgId: item.drugOrgId,
          drugOrgName: item.drugOrgName,
          groupNumber: item.groupNumber,
          frequency: item.frequency,
          usage: item.usage,
          remark: item.remark,
          dosage: item.dosage,
          dosageUnit: item.dosageUnit,
          defaultDosage: item.defaultDosage,
          defaultDosageUnit: item.defaultDosageUnit,
        });
      });
      params1.prescriptionName = _this.MyCommonPrescripTableData.prescriptionName;
      params1.mzPrescriptionDetail = priceParams;
      _this.drugpatientDomain.getAddLoadPrescriptionCommon(params1, function (data) {
        data.data.mzPrescriptionDetail.map((item1, index) => {
          priceParamsres.push({
            drugOrgId: item1.drugOrgId,
            drugOrgName: item1.drugOrgName,
            groupNumber: item1.groupNumber,
            frequency: item1.frequency,
            usage: item1.usage,
            remark: item1.remark,
            dosageList: {
              dosage: item1.dosage,
              dosageUnit: item1.dosageUnit,
            },
            defaultDosageList: {
              defaultDosage: item1.defaultDosage,
              defaultDosageUnit: item1.defaultDosageUnit,
            },
            tradingPrice: item1.tradingPrice,
            inventoryNumber: item1.drugInventoryNumber,
          });
        });
        if (_this.patientFormPre.CZcompositionList[index].prescriptionName.indexOf(_this.MyCommonPrescripTableData.prescriptionName) == -1) {
          _this.patientFormPre.CZcompositionList[index].prescriptionName += _this.MyCommonPrescripTableData.prescriptionName + ",";
        }
        _this.patientFormPre.CZcompositionList[index].mzPrescriptionDetail = _this.patientFormPre.CZcompositionList[index].mzPrescriptionDetail.concat(priceParamsres);
        const uniqueArray = _this.patientFormPre.CZcompositionList[index].mzPrescriptionDetail.filter((item, index, self) => self.findIndex((t) => t.drugOrgName === item.drugOrgName && t.drugOrgName) === index);
        _this.patientFormPre.CZcompositionList[index].mzPrescriptionDetail = uniqueArray;
      });
    },
    addgetLoadPrescription3(index) {
      var _this = this;
      let CYdata12 = {};
      let params1 = {
        prescriptionName: _this.MyCommonPrescripTableDataSY.prescriptionName,
        type: 2,
        prescriptionType: 1,
        mzPrescriptionGroup: [],
      };
      CYdata12 = {
        prescriptionName: _this.MyCommonPrescripTableDataSY.prescriptionName,
        type: 2,
        prescriptionType: 1,
        mzPrescriptionGroup: [],
      };
      _this.MyCommonPrescripTableDataSY.mzPrescriptionGroup.map((item, index) => {
        CYdata12.mzPrescriptionGroup.push({
          days: item.days,
          drippingSpeed: item.drippingSpeed,
          drippingSpeedUnit: item.drippingSpeedUnit,
          frequency: item.frequency,
          usage: item.usage,
          startTime: item.startTime,
          mzPrescriptionDetailSY: [],
        }),
          item.mzPrescriptionDetailSY.map((item1, index1) => {
            CYdata12.mzPrescriptionGroup.map((itemCY, indexCY) => {
              itemCY.mzPrescriptionDetailSY.push({
                drugOrgId: item1.drugOrgId,
                drugOrgName: item1.drugOrgName,
                st: item1.st,
                dosage: item1.dosage,
                dosageUnit: item1.dosageUnit,
                defaultDosage: item1.defaultDosage,
                defaultDosageUnit: item1.defaultDosageUnit,
              });
            });
          });
      });
      // CYdata = CYdata.concat(_this.MyCommonPrescripTableDataSY).concat(_this.patientFormPre.SYcompositionList[index].mzPrescriptionDetail)
      params1.mzPrescriptionGroup = CYdata12.mzPrescriptionGroup;
      _this.drugpatientDomain.getAddLoadPrescriptionCommon(params1, function (data) {
        let priceParamsres = [];
        data.data.mzPrescriptionGroup.map((item1, index1) => {
          item1.mzPrescriptionDetailSY.map((item, index) => {
            params1.mzPrescriptionGroup.map((item1SY) => {
              item1SY.mzPrescriptionDetailSY.map((itemSY) => {
                itemSY.inventoryNumber = item.drugInventoryNumber;
                itemSY.tradingPrice = item.tradingPrice;
              });
            });
          });
        });
        _this.patientFormPre.SYcompositionList.map((item, index) => {
          item.zucompositionList.map((itemZ) => {
            params1.mzPrescriptionGroup.map((itemRe) => {
              itemZ.mzPrescriptionTitle[0].usage = itemRe.usage;
              itemZ.mzPrescriptionTitle[0].days = itemRe.days;
              itemZ.mzPrescriptionTitle[0].frequency = itemRe.frequency;
              itemZ.mzPrescriptionTitle[0].startTime = itemRe.startTime;
              itemZ.mzPrescriptionTitle[0].drippingSpeedList = {
                drippingSpeed: itemRe.drippingSpeed,
                drippingSpeedUnit: itemRe.drippingSpeedUnit,
              };
              itemRe.mzPrescriptionDetailSY.map((itemReSY) => {
                priceParamsres.push({
                  drugOrgId: itemReSY.drugOrgId,
                  drugOrgName: itemReSY.drugOrgName,
                  st: itemReSY.st,
                  dosageList: {
                    dosage: itemReSY.dosage,
                    dosageUnit: itemReSY.dosageUnit,
                  },
                  defaultDosageList: {
                    defaultDosage: itemReSY.defaultDosage,
                    defaultDosageUnit: itemReSY.defaultDosageUnit,
                  },
                });
              });
            });
          });
        });
        if (_this.patientFormPre.SYcompositionList[index].prescriptionName.indexOf(_this.MyCommonPrescripTableDataSY.prescriptionName) == -1) {
          _this.patientFormPre.SYcompositionList[index].prescriptionName += _this.MyCommonPrescripTableDataSY.prescriptionName + ",";
        }
        for (let i = 0; i < _this.patientFormPre.SYcompositionList[index].zucompositionList.length; i++) {
          _this.patientFormPre.SYcompositionList[index].zucompositionList[i].mzPrescriptionDetail = _this.patientFormPre.SYcompositionList[index].zucompositionList[i].mzPrescriptionDetail.concat(priceParamsres);
          const uniqueArray = _this.patientFormPre.SYcompositionList[index].zucompositionList[i].mzPrescriptionDetail.filter((item, index, self) => self.findIndex((t) => t.drugOrgName === item.drugOrgName && t.drugOrgName) === index);
          _this.patientFormPre.SYcompositionList[index].zucompositionList[i].mzPrescriptionDetail = uniqueArray;
        }
      });
    },
    cancleHefangDialogList(item){
      var _this = this
      for (let i=0; i<_this.herbalDetail.compositions.length; i++) {
        if (item.drugCompositionId == _this.herbalDetail.compositions[i].compositionId) {
            _this.herbalDetail.compositions.splice(i,1)
            return 
        }
      }
      _this.$message({
        message: "药品不存在",
        type: "error",
      });
    },
    addHefangDialogList(item){
      var _this = this
      if (item.isPrescription) {
          _this.drugpatientDomain.getHerbalPrescriptionDetail(
            item.prescriptioHashKey,
            function (data) {
            let herbalDetailHF = {}
            herbalDetailHF = data.data
              herbalDetailHF.compositions.map((item) => {
                item.compositionName = item.compositionName
                item.compositionName1 = item.compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1");
                item.compositionNamehashKey = herbalDetailHF.hashKey;
                item.compositionId = item.compositionId;
                item.remark = item.remark ? item.remark : '';
                item.recommendDosage = item.recommendDosage ? item.recommendDosage : '';
                item.dosage = item.dosage ? item.dosage : '';
              });
              const uniqueArray = herbalDetailHF.compositions.concat(_this.herbalDetail.compositions).filter((item, index, self) => self.findIndex((t) => t.compositionId === item.compositionId) === index);
              _this.herbalDetail.compositions = uniqueArray
            },
            function (error) {
              _this.herbalDetailHF = {};
            }
          );
      } else {
        for (let i=0; i<_this.herbalDetail.compositions.length; i++) {
          if (item.drugCompositionId == _this.herbalDetail.compositions[i].compositionId) {
              _this.$message({
                message: "药品已添加",
                type: "error",
              });
              return;
          }
        }
        _this.herbalDetail.compositions.unshift({
          compositionName:item.drugCompositionName,
          compositionName1:item.drugCompositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1"),
          compositionNamehashKey:item.drugHashKey,
          compositionId:item.drugCompositionId,
          remark: '',
          recommendDosage: "",
          dosage:''
        })
      }

        
    },
    resetHefangDialogList(){
      var _this = this
      var  item = _this.zancunherbalDetail
      if (!item.associationDrugHashKey) {
        _this.hefangDialogList = []
        // _this.hefangDialogList = item.drugAddOrSub
         _this.hefangDialogList = _this.checkSameData(item.drugAddOrSub)
          // 单方
          _this.loading = true;
          let dishashKey = "";
          dishashKey = item.drugHashKey;
          _this.JChooseName = item.herbalPrescriptionName ? item.herbalPrescriptionName : item.drug.name;
          _this.drugpatientDomain.getHerbalPrescriptionDetail(
            item.drugHashKey,
            function (data) {
              _this.herbalDetail = data.data;
              _this.herbalDetail.compositions.map((item) => {
                item.compositionName1 = item.compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1");
                item.compositionNamehashKey = dishashKey;
                return;
              });
              _this.herbalDetail.infos.map((item, index) => {
                if (item.title == "用法") {
                  _this.herbalDetail.medicineinfo = item.info;
                }
              });
              _this.loading = false;
            },
            function (error) {
              _this.herbalDetail = {};
            }
          );
      } else {
          // 合方
          _this.hefangDialogList = []
          // _this.hefangDialogList = item.drugAddOrSub
           _this.hefangDialogList = _this.checkSameData(item.drugAddOrSub)
          _this.loading = true;
          let dishashKey = "";
          dishashKey = item.drugHashKey;
          _this.JChooseName = item.herbalPrescriptionName ? item.herbalPrescriptionName : item.drug.name + '合' +item.associationName;
          let params = []
          params.push(item.drugHashKey)
          params.push(item.associationDrugHashKey)
          _this.drugpatientDomain.getHerbalPrescriptionMore(
            params,
            function (data) {
              let herbalDetailParams = {
                compositions:[]
              };
              data.data.map((item1)=>{
                  item1.compositions.map((item) => {
                    item.compositionName1 = item.compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1");
                    item.compositionNamehashKey = dishashKey;
                    item.compositionId = item.compositionId
                    return;
                  });
                 herbalDetailParams.compositions = herbalDetailParams.compositions.concat(item1.compositions)
                  // item1.infos.map((item, index) => {
                  //   if (item.title == "用法") {
                  //     item1.medicineinfo = item.info;
                  //   }
                  // });
              })
            _this.herbalDetail = herbalDetailParams; 
            _this.loading = false;
            },
            function (error) {
              _this.herbalDetail = {};
            }
          );
      }
    },
    checkSameData(resData){
				let tempArr = [];
				let Data = [];
				for (let i = 0; i < resData.length; i++) {
				   if (tempArr.indexOf(resData[i].symptom) == -1) {
					 Data.push({
					   dataInfo: [resData[i]],
					   merchantId:  resData[i].symptom
					 });
					 tempArr.push(resData[i].symptom);
				   } else {
					 for (let j = 0; j < Data.length; j++) {
					   if (Data[j].merchantId == resData[i].symptom) {
						 Data[j].dataInfo.push(resData[i]);
						 break;
					   }
					 }
				   }
				 }
				 return Data
		},
    // 获取最新数据
    getDataNew(item,newparams1){
      var _this = this
      return new Promise((resolve, reject) => {
      _this.drugpatientDomain.getCompositionsByIds(
          newparams1,
          function (data) {
            for (let i=0; i<item.drugAddOrSub.length; i++) {
              for (let j=0; j<data.data.length; j++) {
                if (item.drugAddOrSub[i].addOrSub && !item.drugAddOrSub[i].isPrescription && item.drugAddOrSub[i].drugCompositionId == data.data[j].compositionId) {
                  item.drugAddOrSub[i].drugCompositionName = data.data[j].compositionName
                }
              }
            }
            resolve(item)
          },
          function (error) {
            resolve(item)
          }
        );
    });
    },
    goherbalDetail1QT(item){
      var _this = this
      _this.JChooseName = item.prescriptionName
      _this.hefangDialogListQT = item.dataInfo
      _this.hefangDialogVisibleQT = true
    },
   async goherbalDetail1(item){
      var _this = this;
      let newparams1 = []
      item.drugAddOrSub.map(res=>{
        if (res.addOrSub && !res.isPrescription)  {
          newparams1.push(res.drugCompositionId)
        }
      })
      let newitem =  await _this.getDataNew(item,newparams1)
      // _this.drugpatientDomain.getCompositionsByIds(
      //   newparams1,
      //   function (data) {
      //     for (let i=0; i<item.drugAddOrSub.length; i++) {
      //       for (let j=0; j<data.data.length; j++) {
      //         if (item.drugAddOrSub[i].addOrSub && !item.drugAddOrSub[i].isPrescription && item.drugAddOrSub[i].drugCompositionId == data.data[j].compositionId) {
      //           item.drugAddOrSub[i].drugCompositionName = data.data[j].compositionName
      //         }
      //       }
      //     }

      //   },
      //   function (error) {
          
      //   }
      // );
      _this.zancunherbalDetail = newitem
      // _this.zancunherbalDetail = item
      _this.hefangDialogList = []
      // _this.hefangDialogList = item.drugAddOrSub 
      // 合并症状数据
      _this.hefangDialogList = _this.checkSameData(item.drugAddOrSub)
      if (item.associationDrugHashKey == null) {
          // 单方
          _this.loading = true;
          let dishashKey = "";
          dishashKey = item.drugHashKey;
          _this.JChooseName = item.herbalPrescriptionName ? item.herbalPrescriptionName : item.drug.name;
          _this.drugpatientDomain.getHerbalPrescriptionDetail(
            item.drugHashKey,
            function (data) {
              _this.herbalDetail = data.data;
              _this.herbalDetail.compositions.map((item) => {
                item.compositionName = item.compositionName
                item.compositionName1 = item.compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1");
                item.compositionNamehashKey = dishashKey;
                return;
              });
              _this.herbalDetail.infos.map((item, index) => {
                if (item.title == "用法") {
                  _this.herbalDetail.medicineinfo = item.info;
                }
              });
              _this.hefangDialogVisible = true
              _this.loading = false;
            },
            function (error) {
              _this.$message.error('暂无处方详情');
              _this.herbalDetail = {};
            }
          );
      } else {
          // 合方
          _this.loading = true;
          let dishashKey = "";
          dishashKey = item.drugHashKey;
          _this.JChooseName = item.herbalPrescriptionName ? item.herbalPrescriptionName : item.drug.name + ' 合 ' +item.associationName;
          let params = []
          params.push(item.drugHashKey)
          params.push(item.associationDrugHashKey)
          _this.drugpatientDomain.getHerbalPrescriptionMore(
            params,
            function (data) {
              let herbalDetailParams = {
                compositions:[]
              };
              data.data.map((item1)=>{
                  item1.compositions.map((item) => {
                    item.compositionName = item.compositionName
                    item.compositionName1 = item.compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1");
                    item.compositionNamehashKey = dishashKey;
                    item.compositionId = item.compositionId
                    return;
                  });
                 herbalDetailParams.compositions = herbalDetailParams.compositions.concat(item1.compositions)
              })
            _this.herbalDetail = herbalDetailParams;
             const uniqueArray = herbalDetailParams.compositions.filter((item, index, self) => self.findIndex((t) => t.compositionName1 === item.compositionName1) === index);
             herbalDetailParams.compositions = uniqueArray
            _this.hefangDialogVisible = true
            _this.loading = false;
            },
            function (error) {
              _this.$message.error('暂无处方详情');
              _this.herbalDetail = {};
            }
          );
      }
      
    },
    goherbalDetail2(item){
      var _this = this;
      _this.loading = true;
      let dishashKey = "";
      dishashKey = item.hashKey;
      _this.JChooseName = item.herbalPrescriptionName ? item.herbalPrescriptionName : item.name;
      _this.drugpatientDomain.getHerbalPrescriptionDetail(
        item.hashKey,
        function (data) {
          _this.herbalDetail = data.data;
          _this.herbalDetail.compositions.map((item) => {
            item.compositionName1 = item.compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1");
            item.compositionNamehashKey = dishashKey;
            return;
          });
          _this.herbalDetail.infos.map((item, index) => {
            if (item.title == "用法") {
              _this.herbalDetail.medicineinfo = item.info;
            }
          });
          _this.hefangDialogVisible = true
          _this.loading = false;
        },
        function (error) {
          _this.$message.error('暂无处方详情');
          _this.herbalDetail = {};
        }
      );
    },
    goherbalDetail(item) {
      var _this = this;
      _this.loading = true;
      let dishashKey = "";
      dishashKey = item.hashKey;
      _this.JChooseName = item.herbalPrescriptionName ? item.herbalPrescriptionName : item.name;
      _this.drugpatientDomain.getHerbalPrescriptionDetail(
        item.hashKey,
        function (data) {
          _this.herbalDetail = data.data;
          _this.herbalDetail.compositions.map((item) => {
            item.compositionName1 = item.compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1");
            item.compositionNamehashKey = dishashKey;
            return;
          });
          _this.herbalDetail.infos.map((item, index) => {
            if (item.title == "用法") {
              _this.herbalDetail.medicineinfo = item.info;
            }
          });
          _this.loading = false;
        },
        function (error) {
          _this.herbalDetail = {};
        }
      );
    },
    inputChange() {
      this.GetHerbalPrescriptionListdata();
    },
    GetHerbalPrescriptionListdata() {
      var _this = this;
      _this.drugpatientDomain.GetHerbalPrescriptionList(
        "0",
        "1",
        _this.HerbalValue,
        function (data) {
          _this.HerbalList = data.data.results;
        },
        function (error) {
          _this.HerbalList = [];
        }
      );
    },
    goResultByUserList(index, name) {
      var _this = this;
      _this.ResultByUserIndex = index;
      let queId = 0;
      // _this.patientDetail.phone
      _this.staticsDomain.Questionnaire(
        function (data) {
          var data = data.data.filter((i) => i.name.includes(name));
          // _this.ResultByUserList = data.data.results
          queId = data[0].id;
          _this.staticsDomain.getUserResultByUser(
            _this.userInfo.id,
            "%20",
            "1",
            "%20",
            "%20",
            queId,
            _this.patientDetail.phone,
            function (data) {
              if (data.data.results.length > 0) {
                data.data.results.map((item, index) => {
                  item.questionnaireDetermines.sort((a, b) => {
                    return b.scores - a.scores;
                  });
                });
                _this.ResultByUserList = data.data.results;
              } else {
                _this.ResultByUserList = [
                  {
                    questionnaireDetermines: [],
                  },
                ];
              }
              _this.searchResultByUserList = []
            },
            function (error) {
              _this.ResultByUserList = [
                {
                  questionnaireDetermines: [],
                },
              ];
            }
          );
        },
        function (error) {
          console.log(error);
        }
      );
    },
    getUserResultByUserData() {
      let queId = 1;
      _this.staticsDomain.getUserResultByUser(
        this.userInfo.id,
        "%20",
        "%20",
        "%20",
        queId,
        this.patientDetail.phone,
        function (data) {
          _this.ResultByUserList = data.data.results;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    govisit(item, index) {
      var _this = this
      _this.patientDiagnoseIndex = index
      _this.patientDiagnoseVisitId = item.id
    },
    adddiseaseDiagnose() {
      var _this = this;
      if (!_this.visitId || !_this.patientDiagnoseVisitId) {
        _this.$message({
          message: "请选择复诊信息",
          type: "error",
        });
        return;
      }
      _this.patientDomain.GetPatientDiagnoseDetail(
        _this.patientDiagnoseVisitId,
        function (data) {
          _this.patientFormDes.breathe = data.data.breathe ? data.data.breathe : 0;
          _this.patientFormDes.family = data.data.family ? data.data.family.toString() : "";
          _this.patientFormDes.height = data.data.height ? data.data.height : 0;
          _this.patientFormDes.heightTension = data.data.heightTension ? data.data.heightTension : 0;
          _this.patientFormDes.irritability = data.data.irritability ? data.data.irritability.toString() : "";
          _this.patientFormDes.lowTension = data.data.lowTension ? data.data.lowTension : 0;
          _this.patientFormDes.main = data.data.main ? data.data.main.toString() : "";
          _this.patientFormDes.otherCheck = data.data.otherCheck ? data.data.otherCheck.toString() : "";
          _this.patientFormDes.past = data.data.past ? data.data.past.toString() : "";
          _this.patientFormDes.pulse = data.data.pulse ? data.data.pulse : 0;
          _this.patientFormDes.temperature = data.data.temperature ? data.data.temperature : 0;
          _this.patientFormDes.weight = data.data.weight ? data.data.weight : 0;
          _this.patientFormDes.PatientId = data.data.patientId;
          _this.patientFormDes.diseaseDiagnose = data.data.diseaseDiagnose.split(",");
          _this.ShowPhotocfList = data.data.prescriptionImgList;
          _this.ShowPhotoList = data.data.diagnoseImgList;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    addVisit() {
      var _this = this;
      if (!_this.visitId || !_this.patientDiagnoseVisitId) {
        _this.$message({
          message: "请选择复诊信息",
          type: "error",
        });
        return;
      }
      _this.isVisitId = true
      _this.patientDomain.GetPatientDiagnoseDetail(
        _this.patientDiagnoseVisitId,
        function (data) {
          _this.patientFormDes.topId = _this.visitId
          _this.patientFormDes.breathe = data.data.breathe ? data.data.breathe : 0;
          _this.patientFormDes.family = data.data.family ? data.data.family.toString() : "";
          _this.patientFormDes.height = data.data.height ? data.data.height : 0;
          _this.patientFormDes.heightTension = data.data.heightTension ? data.data.heightTension : 0;
          _this.patientFormDes.irritability = data.data.irritability ? data.data.irritability.toString() : "";
          _this.patientFormDes.lowTension = data.data.lowTension ? data.data.lowTension : 0;
          _this.patientFormDes.main = data.data.main ? data.data.main.toString() : "";
          _this.patientFormDes.otherCheck = data.data.otherCheck ? data.data.otherCheck.toString() : "";
          _this.patientFormDes.past = data.data.past ? data.data.past.toString() : "";
          _this.patientFormDes.pulse = data.data.pulse ? data.data.pulse : 0;
          _this.patientFormDes.temperature = data.data.temperature ? data.data.temperature : 0;
          _this.patientFormDes.weight = data.data.weight ? data.data.weight : 0;
          _this.patientFormDes.PatientId = data.data.patientId;
          _this.patientFormDes.diseaseDiagnose = data.data.diseaseDiagnose.split(",");
          _this.ShowPhotocfList = data.data.prescriptionImgList;
          _this.ShowPhotoList = data.data.diagnoseImgList;
          _this.drugpatientDomain.MZPrescriptionDetail(
            _this.patientDiagnoseVisitId,
            function (data) {
              if (data.data.mzPrescriptionOut.mzPrescriptionYP.length > 0) {
                _this.patientFormPre.compositionList = []
                for (let i = 0; i < data.data.mzPrescriptionOut.mzPrescriptionYP.length; i++) {
                  _this.patientFormPre.compositionList.push({
                    usage: data.data.mzPrescriptionOut.mzPrescriptionYP[i].usage,
                    prescriptionName: data.data.mzPrescriptionOut.mzPrescriptionYP[i].prescriptionName,
                    dosageNumber: data.data.mzPrescriptionOut.mzPrescriptionYP[i].dosageNumber,
                    dosage: data.data.mzPrescriptionOut.mzPrescriptionYP[i].dosage,
                    frequency: data.data.mzPrescriptionOut.mzPrescriptionYP[i].frequency,
                    request: data.data.mzPrescriptionOut.mzPrescriptionYP[i].request,
                    remake: data.data.mzPrescriptionOut.mzPrescriptionYP[i].remake,
                    type: 0,
                    prescriptionType: 1,
                    mzPrescriptionDetail: [],
                    isDecoction: true,
                    isdeliveryPrice: 0,
                  });
                  for (let j = 0; j < data.data.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail.length; j++) {
                    for (let m = 0; m < _this.patientFormPre.compositionList.length; m++) {
                      if (data.data.mzPrescriptionOut.mzPrescriptionYP[i].prescriptionName == _this.patientFormPre.compositionList[m].prescriptionName) {
                        _this.patientFormPre.compositionList[m].mzPrescriptionDetail.push({
                          drugOrgId: data.data.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].drugOrgId ? data.data.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].drugOrgId : 0,
                          drugOrgName: data.data.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].drugOrgName,
                          dosage: data.data.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].dosage,
                          dosageUnit: data.data.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].dosageUnit,
                          usage: data.data.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].usage,
                          weight: data.data.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].weight,
                          remark: data.data.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].remark,
                          decoct: data.data.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].decoct,
                          recommendDosage: data.data.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].recommendDosage,
                          tradingPrice: data.data.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].tradingPrice,
                          drugInventoryNumber: data.data.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].drugInventoryNumber,
                          drugHashKey:data.data.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].drugHashKey,
                        });
                      }
                    }
                  }
                }
              }
              if (data.data.mzPrescriptionOut.mzPrescriptionCY.length > 0) {
                _this.patientFormPre.CZcompositionList = []
                for (let i = 0; i < data.data.mzPrescriptionOut.mzPrescriptionCY.length; i++) {
                  _this.patientFormPre.CZcompositionList.push({
                    prescriptionName: data.data.mzPrescriptionOut.mzPrescriptionCY[i].prescriptionName,
                    type: 1,
                    prescriptionType: 1,
                    mzPrescriptionDetail: [],
                  });
                  for (let j = 0; j < data.data.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail.length; j++) {
                    for (let m = 0; m < _this.patientFormPre.CZcompositionList.length; m++) {
                      if (data.data.mzPrescriptionOut.mzPrescriptionCY[i].prescriptionName == _this.patientFormPre.CZcompositionList[m].prescriptionName) {
                        _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail.push({
                          drugOrgId: data.data.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].drugOrgId ? data.data.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].drugOrgId : 0,
                          drugOrgName: data.data.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].drugOrgName,
                          dosageList: {
                            dosage: data.data.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].dosage,
                            dosageUnit: data.data.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].dosageUnit,
                          },
                          defaultDosageList: {
                            defaultDosage: data.data.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].defaultDosage,
                            defaultDosageUnit: data.data.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].defaultDosageUnit,
                          },
                          groupNumber: data.data.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].groupNumber,
                          frequency: data.data.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].frequency,
                          usage: data.data.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].usage,
                          tradingPrice: data.data.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].tradingPrice,
                          drugInventoryNumber: data.data.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].drugInventoryNumber,
                          drugHashKey:data.data.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].drugHashKey,
                        });
                      }

                    }
                  }
                }
              }
              if (data.data.mzPrescriptionOut.mzPrescriptionSYGroup.length > 0) {
                _this.patientFormPre.SYcompositionList = []
                for (let i = 0; i < data.data.mzPrescriptionOut.mzPrescriptionSYGroup.length; i++) {
                  _this.patientFormPre.SYcompositionList.push({
                    prescriptionName: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].prescriptionName,
                    type: 2,
                    prescriptionType: 1,
                    zucompositionList: [],
                  });
                  for (let j = 0; j < data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups.length; j++) {
                    for (let m = 0; m < _this.patientFormPre.SYcompositionList.length; m++) {
                      if (data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].prescriptionName == _this.patientFormPre.SYcompositionList[m].prescriptionName) {
                        _this.patientFormPre.SYcompositionList[m].zucompositionList.push({
                          mzPrescriptionTitle: [],
                          mzPrescriptionDetail: [],
                        });
                        for (let x = 0; x < data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY.length; x++) {
                          for (let y = 0; y < _this.patientFormPre.SYcompositionList[m].zucompositionList.length; y++) {
                            _this.patientFormPre.SYcompositionList[m].zucompositionList[y].mzPrescriptionDetail.push({
                              drugOrgId: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].drugOrgId ? data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].drugOrgId : 0,
                              drugOrgName: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].drugOrgName,
                              st: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].st,
                              dosageList: {
                                dosage: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].dosage,
                                dosageUnit: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].dosageUnit,
                              },
                              defaultDosageList: {
                                defaultDosage: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].defaultDosage,
                                defaultDosageUnit: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].defaultDosageUnit,
                              },
                              tradingPrice: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].tradingPrice,
                              drugInventoryNumber: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].drugInventoryNumber,
                              drugHashKey:data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].drugHashKey,
                            });
                            _this.patientFormPre.SYcompositionList[m].zucompositionList[y].mzPrescriptionTitle[0] = {
                              frequency: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].frequency,
                              usage: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].usage,
                              days: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].days,
                              startTime: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].startTime,
                              drippingSpeedList: {
                                drippingSpeed: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].drippingSpeed,
                                drippingSpeedUnit: data.data.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].drippingSpeedUnit,
                              },
                            };
                          }
                        }
                      }
                    }
                  }
                }
              }
            })
        },
        function (error) {
          console.log(error);
        }
      );
    },
    godetailNext(item, index) {
      var _this = this;
      _this.visitId = item.id
      _this.diseaseIndex = index;
      _this.patientDiagnoseIndex = -1
      _this.patientDomain.GetPatientType(
        _this.patientDetail.id, item.id,
        function (data) {
          _this.visitData = data.data.patientDiagnose
        },
        function (error) {
          console.log(error);
        })
    },
    goAdddiseaseDiagnose(name) {
      this.patientFormDes.diseaseDiagnose.push(name);
      this.patientFormDes.diseaseDiagnose = this.patientFormDes.diseaseDiagnose.filter((item, index, self) => {
        return self.indexOf(item) == index;
      });
    },
    //过滤html标签
    filter(html) {
      return html
        .replace(/<(?:.|\n)*?>/gm, "")
        .replace(/(&rdquo;)/g, '"')
        .replace(/&ldquo;/g, '"')
        .replace(/&mdash;/g, "-")
        .replace(/&nbsp;/g, "")
        .replace(/&gt;/g, ">")
        .replace(/&lt;/g, "<")
        .replace(/<[\w\s"':=\/]*/, "");
    },
    handleClose(tag) {
      console.log(this.patientFormDes.discriminatoryAnalysisList)
      this.patientFormDes.discriminatoryAnalysisList.splice(this.patientFormDes.discriminatoryAnalysisList.indexOf(tag), 1);
      this.patientFormDes.discriminatoryAnalysis = this.patientFormDes.discriminatoryAnalysisList.toString()
    },
    goResultBy(name) {
      console.log(name)
      if (name.syndromeName) {
        // 辩证或者辨病
        this.serchdrugIdList.push(name);
        const uniqueArray = this.serchdrugIdList.filter((item, index, self) => self.findIndex((t) => t.syndromeName === item.syndromeName) === index);
        this.serchdrugIdList = uniqueArray
        this.patientFormDes.main = this.patientFormDes.main + this.isMainConct
      } else {
        // 虚症辨证
        this.zserchdrugIdList.push(name);
        const uniqueArray = this.zserchdrugIdList.filter((item, index, self) => self.findIndex((t) => t.name === item.name) === index);
        this.zserchdrugIdList = uniqueArray
      }
      if (this.activerightName == 3) {
        this.patientFormDes.diseaseDiagnose.push(name.name);
      } else {
        this.patientFormDes.diseaseDiagnose.push(name.syndromeName);
      }
      this.patientFormDes.diseaseDiagnose = this.patientFormDes.diseaseDiagnose.filter((item, index, self) => {
        return self.indexOf(item) == index;
      });
      let str = this.math(this.filter( this.recordResultsInfo.analysis ? this.recordResultsInfo.syndromeName + ':' +this.recordResultsInfo.analysis.toString() : '' ));
      if (str) {
        this.patientFormDes.discriminatoryAnalysisList.push(str);
        this.patientFormDes.discriminatoryAnalysisList = this.patientFormDes.discriminatoryAnalysisList.filter((item, index, self) => {
          return self.indexOf(item) == index;
        });
        this.patientFormDes.discriminatoryAnalysis = this.patientFormDes.discriminatoryAnalysisList.toString()
      }
      this.setInfoDialog = false;
    },
    goResultByAnalysis(name,type) {
      var _this = this;
      _this.isMainConct = ''
      if (type && type == 'search') {
        _this.isMainConct =  name.symptoms.toString()
      } else {
        _this.isMainConct = ''
      }
      _this.recordResultsInfo.analysis = "";
      _this.recordResultsInfo.analysis1 = "";
      _this.recordResultsInfo.step = "";
      _this.recordResultsInfo.item = "";
      _this.recordResultsInfo.syndromeName = "";
      _this.zdrugconsultationMain.getSelfDiagnosisSyndrome(
        name.syndromeId ? name.syndromeId : name.id,
        function (data) {
          let res = data.data.syndromeInfo;
          if (res.length > 0) {
            let resInfo1 = res.filter((i) => i.title == "鉴别分析");
            if (resInfo1.length > 0) {
              _this.recordResultsInfo.analysis = resInfo1[0].info;
            }
            let resInfo2 = res.filter((i) => i.title == "治法");
            if (resInfo2.length > 0) {
              _this.recordResultsInfo.analysis1 = resInfo2[0].info;
            }
            _this.recordResultsInfo.item = name;
            _this.recordResultsInfo.syndromeName = name.syndromeName;
            _this.setInfoDialog = true;
          } else {
            _this.$message({
              message: "未查询到数据",
              type: "error",
            });
          }
        },
        function (error) {
          let jsonObj = JSON.parse(error.msg);
          _this.$message({
            message: jsonObj.msg,
            type: "error",
          });
          console.log(error);
        }
      );
    },
    goResultByAnalysisXZ(item) {
      var _this = this;
      _this.recordResultsInfo.analysis = "";
      _this.recordResultsInfo.analysis1 = "";
      _this.recordResultsInfo.item = "";
      _this.recordResultsInfo.syndromeName = "";
      _this.testDomain.getDetailById(
        item.questionnaireResultId ?  item.questionnaireResultId : item.id,
        function (data) {
          let res = data.data.infos;
          if (res.length > 0) {
            let resInfo1 = res.filter((i) => i.title == "分析");
            if (resInfo1.length > 0) {
              _this.recordResultsInfo.analysis = resInfo1[0].info;
            }
            let resInfo2 = res.filter((i) => i.title == "治法");
            if (resInfo2.length > 0) {
              _this.recordResultsInfo.analysis1 = resInfo2[0].info;
            }
            _this.recordResultsInfo.item = item;
            _this.recordResultsInfo.syndromeName = item.name;
            _this.setInfoDialog = true;
          } else {
            _this.$message({
              message: "未查询到数据",
              type: "error",
            });
          }
        },
        function (error) {
          let jsonObj = JSON.parse(error.msg);
          _this.$message({
            message: jsonObj.msg,
            type: "error",
          });
          console.log(error);
        }
      );
    },
    goResultByZ(name) {
      this.zserchdrugIdList.push(name);
      this.patientFormDes.diseaseDiagnose.push(name.name);
      this.patientFormDes.diseaseDiagnose = this.patientFormDes.diseaseDiagnose.filter((item, index, self) => {
        return self.indexOf(item) == index;
      });
    },
    // allTotalMoney(){
    //     let  allTotalMoney = 0
    //    for (let i=0; i<this.patientFormPre.compositionList.length; i++) {
    //     for (let j=0;j< this.patientFormPre.compositionList[i].mzPrescriptionDetail.length; j++) {
    //         this.patientFormPre.compositionList[i].mzPrescriptionDetail[j].tradingPrice = this.patientFormPre.compositionList[i].mzPrescriptionDetail[j].tradingPrice ? this.patientFormPre.compositionList[i].mzPrescriptionDetail[j].tradingPrice : 0
    //         allTotalMoney += this.patientFormPre.compositionList[i].mzPrescriptionDetail[j].tradingPrice
    //     }

    //    }
    // //    this.patientFormPre.compositionList.forEach((item,index,arr)=>{
    // //     item.mzPrescriptionDetail.forEach((item1,index1,arr1)=>{
    // //             item1.tradingPrice = item1.tradingPrice ? item1.tradingPrice : 0
    // //             allTotalMoney += item1.tradingPrice
    // //     })
    // //    })
    //    return allTotalMoney
    // },
    handleSelectionChange(val) {
      let params = [];
      for (let i = 0; i < val.length; i++) {
        params.push(val[i].adviceContent + ";");
      }
      this.patientFormPre.doctorAdvice = params.toString();
      // val.forEach((item)=> {

      // })
    },
    handlerequestOption(val){
      let params = [];
      for (let i = 0; i < val.length; i++) {
        params.push(val[i].adviceContent + ";");
      }
      this.patientFormPre.compositionList[this.reqindexCom].request =  params.toString();
    },
    getHerbalPrescriptionData() {
      var _this = this;
      _this.drugpatientDomain.getHerbalPrescription(
        function (data) {
          _this.AdviceList = data.data.results;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    goGetDoctorAdviceListData() {
      this.comDialogAdviceVisible = true;
      this.GetDoctorAdviceListData();
    },
    goGetrequestData(indexCom){
      this.reqindexCom = indexCom
      this.comrequestVisible = true;
      this.getTakeRequestList();
    },
    GetDoctorAdviceListData() {
      var _this = this;
      _this.drugpatientDomain.GetDoctorAdviceList(
        "%20",
        _this.AdvicePageIndex,
        _this.orgexpertId,
        function (data) {
          _this.AdviceList = data.data.results;
          _this.AdvicePageTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    goAddCharges() {
      let row = {
        chargeName: "",
        money: "",
      };
      this.patientFormPre.MZOtherCharges.push(row);
    },
    deleteCharges(index) {
      this.patientFormPre.MZOtherCharges.splice(index, 1);
    },
    cancledialogVisible1(){
      var _this = this
      // _this.OutpatientDomain.cacleMZPrescriptionDetail(
      //   _this.charge.id,
      //   function (data) {
      //       _this.$message({
      //         type: "error",
      //         message: "取消支付!",
      //       });
         
      //   },
      //   function (err) {
      //     console.log(err);
      //   }
      // );
       _this.drugDetail = {}
          _this.drugAuditUserId = 0;
          _this.drugDiagnoseMoney = 0;
          _this.auditDetail = {}
          _this.waitAuditUserId = 0;
          _this.auditDiagnoseMoney = 0;
          _this.doctorDialogProgress = 1
          // _this.AuditNextDataSuc = false
          _this.doctorDialogVisible = false
    },
    cancledialogVisible(){
       var _this = this
      _this.drugDetail = {}
      _this.drugAuditUserId = 0;
      _this.drugDiagnoseMoney = 0;
      _this.auditDetail = {}
      _this.waitAuditUserId = 0;
      _this.auditDiagnoseMoney = 0;
      _this.doctorDialogProgress = 1
      // _this.AuditNextDataSuc = false
      _this.doctorDialogVisible = false
    },
    firstDialogVisible(){
      var _this = this
      // _this.OutpatientDomain.cacleMZPrescriptionDetail(
      //   _this.charge.id,
      //   function (data) {
      //       _this.doctorDialogProgress = 1
      //   },
      //   function (err) {
      //     console.log(err);
      //   }
      // );
      _this.doctorDialogProgress = 1
    },
    stepadddialogVisible(){
      var _this = this
      _this.drugDetail = {}
      _this.drugAuditUserId = 0;
      _this.drugDiagnoseMoney = 0;
      _this.doctorDialogProgress = 1
    },
    // 审核付完钱
    auditPaySuceess(){
    let _this = this
    let paramsMZOtherCharges = [];
      for (let h = 0; h < _this.patientFormPre.MZOtherCharges.length; h++) {
        if (_this.patientFormPre.MZOtherCharges[h].chargeName && _this.patientFormPre.MZOtherCharges[h].money) {
          paramsMZOtherCharges.push({
            patientDiagnoseId: _this.patientDiagnoseId * 1,
            diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
            chargeName: _this.patientFormPre.MZOtherCharges[h].chargeName.itemName ? _this.patientFormPre.MZOtherCharges[h].chargeName.itemName : _this.patientFormPre.MZOtherCharges[h].chargeName,
            entityId: _this.patientFormPre.MZOtherCharges[h].entityId ? _this.patientFormPre.MZOtherCharges[h].entityId : 0,
            money: _this.patientFormPre.MZOtherCharges[h].money,
            orderType: 3,
          });
        }
      }
      // if (_this.auditDiagnoseMoney != 0) {
      //   paramsMZOtherCharges.push({
      //     patientDiagnoseId: _this.patientDiagnoseId * 1,
      //     diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
      //     chargeName: "审方费用",
      //     money: _this.auditDiagnoseMoney,
      //     orderType: 5,
      //   });
      // }
      if (_this.patientFormPre.isSelef == "2" && _this.patientFormPre.isSelefPrice != "0") {
        paramsMZOtherCharges.push({
          patientDiagnoseId: _this.patientDiagnoseId * 1,
          diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
          // chargeName:  "快递费用",
          chargeName:_this.patientFormPre.chargeName,
          entityId:_this.isSelefId,
          money: _this.patientFormPre.isSelefPrice,
          orderType: 6,
        });
      }
      for (let i = 0; i < _this.patientFormPre.compositionList.length; i++) {
        if (_this.patientFormPre.isSelef == "1") {
          _this.patientFormPre.compositionList[i].isSelef = true;
        } else {
          _this.patientFormPre.compositionList[i].isSelef = false;
          _this.patientFormPre.compositionList[i].takeUserName = _this.messagePatinet.addressee;
          _this.patientFormPre.compositionList[i].takePhone = _this.messagePatinet.phone;
          _this.patientFormPre.compositionList[i].regionId = _this.messagePatinet.regionId;
          _this.patientFormPre.compositionList[i].regionFull = _this.messagePatinet.regionName;
          _this.patientFormPre.compositionList[i].address = _this.messagePatinet.fullAddress;
          _this.patientFormPre.compositionList[i].expressCompany = _this.patientFormPre.chargeName;
        }
        _this.patientFormPre.compositionList[i].patientId = _this.patientDetail.id;
        _this.patientFormPre.compositionList[i].patientName = _this.patientDetail.name;
        _this.patientFormPre.compositionList[i].age = _this.patientDetail.age;
        _this.patientFormPre.compositionList[i].sex = _this.patientDetail.sex ? 1 : 0;
        _this.patientFormPre.compositionList[i].patientDiagnoseId = _this.patientDiagnoseId * 1;
        _this.patientFormPre.compositionList[i].diseaseDiagnose = _this.patientFormDes.diseaseDiagnose;
        _this.patientFormPre.compositionList[i].patientPhone = _this.patientDetail.phone;
        _this.patientFormPre.compositionList[i].doctorAdvice = _this.patientFormPre.doctorAdvice;
        if (_this.patientFormPre.compositionList[i].isDecoction && _this.patientFormPre.compositionList[i].isdeliveryPrice != "0") {
          paramsMZOtherCharges.push({
            patientDiagnoseId: _this.patientDiagnoseId * 1,
            diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
            chargeName: _this.patientFormPre.compositionList[i].isSelefPriceName,
            money: (parseFloat(_this.patientFormPre.compositionList[i].isdeliveryPrice) * parseFloat(_this.patientFormPre.compositionList[i].dosage)).toFixed(2),
            entityId: _this.patientFormPre.compositionList[i].isdeliveryPriceId,
            orderType: 4,
          });
        }
      }
      for (let k = 0; k < _this.patientFormPre.compositionList.length; k++) {
        let resultCom = [];
        for (let k1 = 0; k1 < _this.patientFormPre.compositionList[k].mzPrescriptionDetail.length; k1++) {
          if (_this.patientFormPre.compositionList[k].mzPrescriptionDetail[k1].drugOrgName) {
            _this.patientFormPre.compositionList[k].mzPrescriptionDetail[k1].weight =  _this.patientFormPre.compositionList[k].mzPrescriptionDetail[k1].weight ? parseFloat(_this.patientFormPre.compositionList[k].mzPrescriptionDetail[k1].weight)  : 0
            resultCom.push(_this.patientFormPre.compositionList[k].mzPrescriptionDetail[k1])
          }
        }
        _this.patientFormPre.compositionList[k].mzPrescriptionDetail = resultCom
      }
      for (let m = 0; m < _this.patientFormPre.CZcompositionList.length; m++) {
        if (_this.patientFormPre.isSelef == "1") {
          _this.patientFormPre.CZcompositionList[m].isSelef = true;
        } else {
          _this.patientFormPre.CZcompositionList[m].isSelef = false;
          _this.patientFormPre.CZcompositionList[m].takeUserName = _this.messagePatinet.addressee;
          _this.patientFormPre.CZcompositionList[m].takePhone = _this.messagePatinet.phone;
          _this.patientFormPre.CZcompositionList[m].regionId = _this.messagePatinet.regionId;
          _this.patientFormPre.CZcompositionList[m].regionFull = _this.messagePatinet.regionName;
          _this.patientFormPre.CZcompositionList[m].address = _this.messagePatinet.fullAddress;
          _this.patientFormPre.CZcompositionList[m].expressCompany = _this.patientFormPre.chargeName;
        }
        if (!_this.patientFormPre.CZcompositionList[m].prescriptionName) {
          _this.patientFormPre.CZcompositionList[m].prescriptionName = "成药处方1";
        }
        _this.patientFormPre.CZcompositionList[m].patientId = _this.patientDetail.id;
        _this.patientFormPre.CZcompositionList[m].patientName = _this.patientDetail.name;
        _this.patientFormPre.CZcompositionList[m].patientDiagnoseId = _this.patientDiagnoseId * 1;
        _this.patientFormPre.CZcompositionList[m].age = _this.patientDetail.age;
        _this.patientFormPre.CZcompositionList[m].sex = _this.patientDetail.sex ? 1 : 0;
        _this.patientFormPre.CZcompositionList[m].diseaseDiagnose = _this.patientFormDes.diseaseDiagnose;
        _this.patientFormPre.CZcompositionList[m].patientPhone = _this.patientDetail.phone;
        _this.patientFormPre.CZcompositionList[m].doctorAdvice = _this.patientFormPre.doctorAdvice;
        for (let n = 0; n < _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail.length; n++) {
          _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosage = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosageList.defaultDosage;
          _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosageUnit = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosageList.defaultDosageUnit;
          _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosage = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosageList.dosage;
          _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosageUnit = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosageList.dosageUnit;
        }
      }
      let resultComCY = [];
      this.patientFormPre.CZcompositionList.map((res) => {
        res.mzPrescriptionDetail.map((res1) => {
          if (res1.drugOrgName) {
            resultComCY.push(res1);
          }
        });
        res.mzPrescriptionDetail = resultComCY;
      });
      let params = [];
      let params1 = [];

      for (let x = 0; x < _this.patientFormPre.SYcompositionList.length; x++) {
        params.push(_this.patientFormPre.SYcompositionList[x]);
      }
      for (let y = 0; y < params.length; y++) {
        params[y].mzPrescriptionGroup = params[y].zucompositionList;
        params1.push({
          mzPrescriptionDetail: params[y].mzPrescriptionDetail,
          prescriptionName: params[y].prescriptionName,
          prescriptionType: params[y].prescriptionType,
          type: params[y].type,
          mzPrescriptionGroup: [],
          // isSelef : true ,
          patientId: _this.patientDetail.id,
          patientName: _this.patientDetail.name,
          patientPhone: _this.patientDetail.phone,
          age: _this.patientDetail.age,
          sex: _this.patientDetail.sex ? 1 : 0,
          patientDiagnoseId: _this.patientDiagnoseId * 1,
          diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
          doctorAdvice: _this.patientFormPre.doctorAdvice,
          waitAuditUserId: params[y].waitAuditUserId,
          waitAuditUserName: params[y].waitAuditUserName,
          waitAuditUserPhone: params[y].waitAuditUserPhone,
          // WaitAuditPharmacistId: params[y].WaitAuditPharmacistId,
          // WaitAuditPharmacistName: params[y].WaitAuditPharmacistName,
          // WaitAuditPharmacistPhone: params[y].WaitAuditPharmacistPhone
        });
      }
      for (let z = 0; z < params.length; z++) {
        for (let o = 0; o < params[z].zucompositionList.length; o++) {
          for (let p = 0; p < params[z].zucompositionList[o].mzPrescriptionDetail.length; p++) {
            params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosage = params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosage;
            params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageUnit = params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosageUnit;
            params[z].zucompositionList[o].mzPrescriptionDetail[p].dosage = params[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosage;
            params[z].zucompositionList[o].mzPrescriptionDetail[p].dosageUnit = params[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosageUnit;
          }
          for (let w = 0; w < params1.length; w++) {
            params1[w].mzPrescriptionGroup.push({
              usage: params[z].zucompositionList[o].mzPrescriptionTitle[0].usage,
              days: params[z].zucompositionList[o].mzPrescriptionTitle[0].days,
              drippingSpeed: params[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeed,
              drippingSpeedUnit: params[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeedUnit,
              frequency: params[z].zucompositionList[o].mzPrescriptionTitle[0].frequency,
              startTime: params[z].zucompositionList[o].mzPrescriptionTitle[0].startTime,
              mzPrescriptionDetailSY: params[z].zucompositionList[o].mzPrescriptionDetail,
            });
          }
        }
      }
      let patientFormPreList = [];
      let patientFormPreCZList = [];
      let patientFormPreSyList = [];
      _this.patientFormPre.compositionList.forEach((item, index) => {
        if (item.prescriptionName) {
          patientFormPreList.push(item);
        }
      });
      _this.patientFormPre.CZcompositionList.forEach((item, index) => {
        patientFormPreCZList.push(item);
      });
      params1.forEach((item, index) => {
        if (_this.patientFormPre.isSelef == "1") {
          item.isSelef = true;
        } else {
          item.isSelef = false;
          item.takeUserName = _this.messagePatinet.addressee;
          item.takePhone = _this.messagePatinet.phone;
          item.regionId = _this.messagePatinet.regionId;
          item.regionFull = _this.messagePatinet.regionName;
          item.address = _this.messagePatinet.fullAddress;
          item.expressCompany = _this.patientFormPre.chargeName;
        }
      });
      params1.forEach((item, index) => {
        if (item.prescriptionName) {
          patientFormPreSyList.push(item);
        }
      });
      let paramsData = patientFormPreList.concat(patientFormPreCZList).concat(patientFormPreSyList);
      if (paramsData.length == 0) {
        _this.$message({
          message: "请完善处方信息",
          type: "error",
        });
        _this.payAuditloading = false
        return;
      }
      if (!_this.messagePatinet.phone && _this.patientFormPre.isSelef == "2") {
        _this.$message({
          message: "暂未填写快递信息",
          type: "error",
        });
        _this.payAuditloading = false
        return;
      }
      _this.drugpatientDomain.getMomentPrescription(
        {
          mzPrescription: paramsData,
          mzOtherCharges: paramsMZOtherCharges,
        },
        function (data) {
          _this.payAuditloading = false
          _this.$message({
            message: "提交审核成功",
            type: "success",
          });
          _this.$router.push({
            name: "PremangePatientList",
          });
        },
        function (error) {
          console.log(error);
        }
      );
    },
    addpayVisible(){
      let _this = this;
      _this.payAuditloading = true
      clearInterval(_this.timer);
      clearInterval(_this.timerZ);
      setTimeout(()=>{
      let form = {
        id: _this.charge.id,
        details: _this.charge.details,
        paymentType:2,
        payments: [
          {
            discountsMoney: _this.charge.discountsMoney,
            debtUserName: _this.charge.debtUserName,
            debtUserPhone: _this.charge.debtUserPhone,
            debtMoney: _this.charge.debtMoney,
            payWay: _this.charge.payWay,
            payMoney: (_this.charge.totalMoney - _this.charge.totalPayMoney).toFixed(2) * 1,
            totalMoney: (_this.charge.totalMoney - _this.charge.totalPayMoney).toFixed(2) * 1
          },
        ],
      };
      if (_this.charge.payWay == 0) {
        _this.outpatientAuthorizationDomain.getIsSetPayPassword(
          function (data) {
            _this.outpatientAuthorizationDomain.getIsPayPassword(
              {
                payPassword: _this.charge.payPwd,
              },
              function (data) {
                _this.OutpatientDomain.DrugPayBalancePayment(form, function (data) {
                  _this.$message({
                    type: "success",
                    message: "支付成功!",
                  });
                    _this.auditPaySuceess()
                });
              },
              function (error) {
                _this.$message({
                  type: "info",
                  message: "支付密码错误",
                });
              }
            );
          },
          function (error) {
            _this
              .$confirm("未设置支付密码？去设置", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                const href = _this.$router.resolve("../personal/resetPassword");
                window.open(href.href, "_blank");
              });
          }
        );
      } else if (_this.charge.payWay == 1) {
        _this.OutpatientDomain.DrugPayMent(
          form,
          function (data) {
            // loading.close();
            // _this.qRcodeUrl = _this.Services.Drug + data.data.qRcodeUrl;
            _this.qRcodeUrl = 'data:image/jpeg;base64,' + data.data.qRcodeBase64;
            _this.weChatInfo = data.data;
            var isSuccess = false;
            _this.timer = window.setInterval(() => {
              setTimeout(function () {
                _this.UserDistributorltemDrugMain.VerificationSuccessPay(
                  _this.weChatInfo,
                  function (data2) {
                    isSuccess = data2.data.isSuccess;
                    if (isSuccess) {
                      clearInterval(_this.timer);
                      _this.$message({
                        type: "success",
                        message: "支付成功!",
                      });
                      _this.qRcodeUrl = ''
                        _this.auditPaySuceess()
                      
                      // _this.chargedialogVisible = false
                      // _this.$router.go(-1);
                    }
                  },
                  function (error) {
                    console.log(error);
                  }
                );
              }, 0);
            }, 5000);
            // setTimeout(() => {
            //     if (!isSuccess) {
            //         clearInterval(timer)
            //         if (paymentDialog.value) {
            //             proxy.$message({
            //                 type: 'error',
            //                 message: '支付超时!'
            //             });
            //             getList();
            //         }
            //     }
            // }, 300000)
          },
          function (error) {
            // loading.close();
            let jsonObj = JSON.parse(error.msg);
            _this.$message({
              message: jsonObj.msg,
              type: "error",
            });
            console.log(error);
          }
        );
      } else  {
        _this.OutpatientDomain.DrugPayMent(
          form,
          function (data) {
            // loading.close();
            _this.qRcodeUrlZ = data.data.alipayData;
            _this.weChatInfo = data.data;
            var isSuccess = false;
            _this.timerZ = window.setInterval(() => {
              setTimeout(function () {
                _this.UserDistributorltemDrugMain.VerificationSuccessPay(
                  // _this.weChatInfo,
                  {orderNo: _this.weChatInfo.orderNo,
                    attach: "MZPayment"
                  },
                  function (data2) {
                    isSuccess = data2.data.isSuccess;
                    if (isSuccess) {
                      clearInterval(_this.timerZ);
                      _this.$message({
                        type: "success",
                        message: "支付成功!",
                      });
                      _this.qRcodeUrlZ = ''
                        _this.auditPaySuceess()
                      // _this.chargedialogVisible = false
                      // _this.$router.go(-1);
                    }
                  },
                  function (error) {
                    console.log(error);
                  }
                );
              }, 0);
            }, 5000);
            // setTimeout(() => {
            //     if (!isSuccess) {
            //         clearInterval(timer)
            //         if (paymentDialog.value) {
            //             proxy.$message({
            //                 type: 'error',
            //                 message: '支付超时!'
            //             });
            //             getList();
            //         }
            //     }
            // }, 300000)
          },
          function (error) {
            // loading.close();
            let jsonObj = JSON.parse(error.msg);
            _this.$message({
              message: jsonObj.msg,
              type: "error",
            });
            console.log(error);
          }
        );
      }
        _this.payAuditloading = false
      },1000)
    },
    adddialogVisible() {
      var _this = this;

      if (_this.waitAuditUserId == "0") {
        _this.$message({
          message: "暂未选择审核人",
          type: "error",
        });
        return;
      }
      if (_this.auditDiagnoseMoney == 0) {
        _this.payAuditloading = true
        setTimeout(()=>{
          _this.goGetAuditPayment0()
        },500)

      } else {
        _this.doctorDialogProgress = 3
        _this.goGetAuditPayment()
      }
      // _this.GetAuditPharmacistData()
    },
    addDrugVisible(){
      var _this = this;
      if (_this.drugAuditUserId == "0") {
        _this.$message({
          message: "暂未选择药剂师",
          type: "error",
        });
        return;
      }
      if (_this.AuditNextDataSuc && _this.drugDiagnoseMoney == 0) {
        _this.getNextCompletePrescription()
      } else if (!_this.AuditNextDataSuc && _this.drugDiagnoseMoney == 0 && _this.auditDiagnoseMoney == 0) {
        _this.auditPaySuceess()
      } else {
        _this.doctorDialogProgress = 3
        _this.goGetAuditPayment()
      }
    },
    goGetAuditPayment0(){
      var _this = this
      // if (_this.AuditNextDataSuc) {
      //     let params = {
      //       OrgId: _this.userInfo.organizationId,
      //       OrgName: _this.userInfo.organizationName,
      //       PatientId: _this.patientDetail.id,
      //       PatientName: _this.patientDetail.name,
      //       DoctorId: _this.userInfo.id,
      //       DoctorName: _this.userInfo.name,
      //       DepartmentId:_this.userInfo.departmentId ? _this.userInfo.departmentId : 0,
      //       DepartmentName:_this.userInfo.department ? _this.userInfo.department : '',
      //       DoctorPhone:_this.userInfo.phone,
      //       PatientDiagnoseId: _this.patientDiagnoseId * 1,
      //       DiagnoseResult: _this.patientFormDes.diseaseDiagnose,
      //       DiagnoseType:_this.isVisitId ? 1 : 0,
      //       OrderType: 1,
      //       TotalMoney: 0,
      //       Details: [],
      //       auditPharmacistId:_this.drugDetail.id,
      //       auditPharmacistName:_this.drugDetail.userName,
      //       auditPharmacistPhone:_this.drugDetail.phone,
      //       // waitAuditUserId:_this.auditDetail.id,
      //       // waitAuditUserName:_this.auditDetail.userName,
      //       // waitAuditUserPhone:_this.auditDetail.phone,
      //     };
      //     params.Details.push({
      //       OrderType: 8,
      //       EntityName: '药剂师审方费用',
      //       Amount: 1,
      //       Unit: "次",
      //       Price: _this.drugDiagnoseMoney,
      //       Money: _this.drugDiagnoseMoney,
      //       CountMoney: _this.drugDiagnoseMoney
      //     });
      //     for(let i=0;i<params.Details.length;i++) {
      //     params.TotalMoney += params.Details[i].CountMoney;
      //   }
      //   params.TotalMoney =  params.TotalMoney.toFixed(2)
      //    _this.drugpatientDomain.GetAuditPayment(
      //       params,
      //       function (data) {
      //         _this.charge = data.data;
      //         _this.charge.payMoney = data.data.totalMoney;
      //         _this.getAllUserBalance();
      //       },
      //       function (error) {
      //         console.log(error);
      //     }
      //   );
      // }
        let params = {
            OrgId: _this.userInfo.organizationId,
            OrgName: _this.userInfo.organizationName,
            PatientId: _this.patientDetail.id,
            PatientName: _this.patientDetail.name,
            DoctorId: _this.userInfo.id,
            DoctorName: _this.userInfo.name,
            DepartmentId:_this.userInfo.departmentId ? _this.userInfo.departmentId : 0,
            DepartmentName:_this.userInfo.department ? _this.userInfo.department : '',
            DoctorPhone:_this.userInfo.phone,
            PatientDiagnoseId: _this.patientDiagnoseId * 1,
            DiagnoseResult: _this.patientFormDes.diseaseDiagnose,
            DiagnoseType:_this.isVisitId ? 1 : 0,
            OrderType: 1,
            TotalMoney: 0,
            Details: [],
            // auditPharmacistId:_this.drugDetail.id,
            // auditPharmacistName:_this.drugDetail.userName,
            // auditPharmacistPhone:_this.drugDetail.phone,
            auditUserId:_this.auditDetail.id,
            auditUserName:_this.auditDetail.userName,
            auditUserPhone:_this.auditDetail.phone,
            auditOrgId:_this.auditDetail.organizationId ? _this.auditDetail.organizationId : 0,
            auditOrgName: _this.auditDetail.organization ? _this.auditDetail.organization.name : '',
            auditDoctorType: _this.indexTypeDoctor
          };
          params.Details.push({
            OrderType: 5,
            EntityName: '医生审方费用',
            Amount: 1,
            Unit: "次",
            Price: _this.auditDiagnoseMoney,
            Money: _this.auditDiagnoseMoney,
            CountMoney: _this.auditDiagnoseMoney,
            OrgId:_this.auditDetail.organizationId ? _this.auditDetail.organizationId : 0,
          });
          // params.Details.push({
          //   OrderType: 8,
          //   EntityName: '药剂师审方费用',
          //   Amount: 1,
          //   Unit: "次",
          //   Price: _this.drugDiagnoseMoney,
          //   Money: _this.drugDiagnoseMoney,
          //   CountMoney: _this.drugDiagnoseMoney
          // });
          for(let i=0;i<params.Details.length;i++) {
          params.TotalMoney += params.Details[i].CountMoney;
        }
        params.TotalMoney =  params.TotalMoney.toFixed(2)
         _this.drugpatientDomain.GetAuditPayment(
            params,
            function (data) {
              _this.charge = data.data;
              _this.charge.payMoney = data.data.totalMoney;

              _this.auditPaySuceess()
            },
            function (error) {
              console.log(error);
          }
        );
        // 审方费
        // _this.AuditNextDataSuc 免审核
        // if (_this.AuditNextDataSuc) {
        //   params.Details.push({
        //   OrderType: 8,
        //   EntityName: '药剂师审方费用',
        //   Amount: 1,
        //   Unit: "次",
        //   Price: _this.drugDiagnoseMoney,
        //   Money: _this.drugDiagnoseMoney,
        //   CountMoney: _this.drugDiagnoseMoney
        // });
        // } else {
        // params.Details.push({
        //   OrderType: 5,
        //   EntityName: '医生审方费用',
        //   Amount: 1,
        //   Unit: "次",
        //   Price: _this.auditDiagnoseMoney,
        //   Money: _this.auditDiagnoseMoney,
        //   CountMoney: _this.auditDiagnoseMoney
        // });
        // params.Details.push({
        //   OrderType: 8,
        //   EntityName: '药剂师审方费用',
        //   Amount: 1,
        //   Unit: "次",
        //   Price: _this.drugDiagnoseMoney,
        //   Money: _this.drugDiagnoseMoney,
        //   CountMoney: _this.drugDiagnoseMoney
        // });
        // }

        
    },
    goGetAuditPayment(){
      var _this = this
      // if (_this.AuditNextDataSuc) {
      //     let params = {
      //       OrgId: _this.userInfo.organizationId,
      //       OrgName: _this.userInfo.organizationName,
      //       PatientId: _this.patientDetail.id,
      //       PatientName: _this.patientDetail.name,
      //       DoctorId: _this.userInfo.id,
      //       DoctorName: _this.userInfo.name,
      //       DepartmentId:_this.userInfo.departmentId ? _this.userInfo.departmentId : 0,
      //       DepartmentName:_this.userInfo.department ? _this.userInfo.department : '',
      //       DoctorPhone:_this.userInfo.phone,
      //       PatientDiagnoseId: _this.patientDiagnoseId * 1,
      //       DiagnoseResult: _this.patientFormDes.diseaseDiagnose,
      //       DiagnoseType:_this.isVisitId ? 1 : 0,
      //       OrderType: 1,
      //       TotalMoney: 0,
      //       Details: [],
      //       auditPharmacistId:_this.drugDetail.id,
      //       auditPharmacistName:_this.drugDetail.userName,
      //       auditPharmacistPhone:_this.drugDetail.phone,
      //       // waitAuditUserId:_this.auditDetail.id,
      //       // waitAuditUserName:_this.auditDetail.userName,
      //       // waitAuditUserPhone:_this.auditDetail.phone,
      //     };
      //     params.Details.push({
      //       OrderType: 8,
      //       EntityName: '药剂师审方费用',
      //       Amount: 1,
      //       Unit: "次",
      //       Price: _this.drugDiagnoseMoney,
      //       Money: _this.drugDiagnoseMoney,
      //       CountMoney: _this.drugDiagnoseMoney
      //     });
      //     for(let i=0;i<params.Details.length;i++) {
      //     params.TotalMoney += params.Details[i].CountMoney;
      //   }
      //   params.TotalMoney =  params.TotalMoney.toFixed(2)
      //    _this.drugpatientDomain.GetAuditPayment(
      //       params,
      //       function (data) {
      //         _this.charge = data.data;
      //         _this.charge.payMoney = data.data.totalMoney;
      //         _this.getAllUserBalance();
      //       },
      //       function (error) {
      //         console.log(error);
      //     }
      //   );
      // }
        let params = {
            OrgId: _this.userInfo.organizationId,
            OrgName: _this.userInfo.organizationName,
            PatientId: _this.patientDetail.id,
            PatientName: _this.patientDetail.name,
            DoctorId: _this.userInfo.id,
            DoctorName: _this.userInfo.name,
            DepartmentId:_this.userInfo.departmentId ? _this.userInfo.departmentId : 0,
            DepartmentName:_this.userInfo.department ? _this.userInfo.department : '',
            DoctorPhone:_this.userInfo.phone,
            PatientDiagnoseId: _this.patientDiagnoseId * 1,
            DiagnoseResult: _this.patientFormDes.diseaseDiagnose,
            DiagnoseType:_this.isVisitId ? 1 : 0,
            OrderType: 1,
            TotalMoney: 0,
            Details: [],
            // auditPharmacistId:_this.drugDetail.id,
            // auditPharmacistName:_this.drugDetail.userName,
            // auditPharmacistPhone:_this.drugDetail.phone,
            auditUserId:_this.auditDetail.id,
            auditUserName:_this.auditDetail.userName,
            auditUserPhone:_this.auditDetail.phone,
            auditOrgId:_this.auditDetail.organizationId ? _this.auditDetail.organizationId : 0,
            auditOrgName: _this.auditDetail.organization ? _this.auditDetail.organization.name : '',
            auditDoctorType: _this.indexTypeDoctor
          };
          params.Details.push({
            OrderType: 5,
            EntityName: '医生审方费用',
            Amount: 1,
            Unit: "次",
            Price: _this.auditDiagnoseMoney,
            Money: _this.auditDiagnoseMoney,
            CountMoney: _this.auditDiagnoseMoney,
            OrgId:_this.auditDetail.organizationId ? _this.auditDetail.organizationId : 0,
          });
          // params.Details.push({
          //   OrderType: 8,
          //   EntityName: '药剂师审方费用',
          //   Amount: 1,
          //   Unit: "次",
          //   Price: _this.drugDiagnoseMoney,
          //   Money: _this.drugDiagnoseMoney,
          //   CountMoney: _this.drugDiagnoseMoney
          // });
          for(let i=0;i<params.Details.length;i++) {
          params.TotalMoney += params.Details[i].CountMoney;
        }
        params.TotalMoney =  params.TotalMoney.toFixed(2)
         _this.drugpatientDomain.GetAuditPayment(
            params,
            function (data) {
              _this.charge = data.data;
              _this.charge.payMoney = data.data.totalMoney;
              _this.getAllUserBalance();
            },
            function (error) {
              console.log(error);
          }
        );
        // 审方费
        // _this.AuditNextDataSuc 免审核
        // if (_this.AuditNextDataSuc) {
        //   params.Details.push({
        //   OrderType: 8,
        //   EntityName: '药剂师审方费用',
        //   Amount: 1,
        //   Unit: "次",
        //   Price: _this.drugDiagnoseMoney,
        //   Money: _this.drugDiagnoseMoney,
        //   CountMoney: _this.drugDiagnoseMoney
        // });
        // } else {
        // params.Details.push({
        //   OrderType: 5,
        //   EntityName: '医生审方费用',
        //   Amount: 1,
        //   Unit: "次",
        //   Price: _this.auditDiagnoseMoney,
        //   Money: _this.auditDiagnoseMoney,
        //   CountMoney: _this.auditDiagnoseMoney
        // });
        // params.Details.push({
        //   OrderType: 8,
        //   EntityName: '药剂师审方费用',
        //   Amount: 1,
        //   Unit: "次",
        //   Price: _this.drugDiagnoseMoney,
        //   Money: _this.drugDiagnoseMoney,
        //   CountMoney: _this.drugDiagnoseMoney
        // });
        // }

        
    },
    handleSelectionChangeDrug(val){
      var _this = this
      _this.drugDetail = val
      _this.drugAuditUserId = val.id;
      _this.drugDiagnoseMoney = val.userOutpatient.pharmacistAuditMoney;
      if (_this.patientFormPre.compositionList.length > 0) {
        for (let i = 0; i < _this.patientFormPre.compositionList.length; i++) {
          _this.patientFormPre.compositionList[i].waitAuditUserId = _this.auditDetail.id;
          _this.patientFormPre.compositionList[i].waitAuditUserName = _this.auditDetail.userName;
          _this.patientFormPre.compositionList[i].waitAuditUserPhone = _this.auditDetail.phone;
          _this.patientFormPre.compositionList[i].auditDoctorType = _this.indexTypeDoctor
          // _this.patientFormPre.compositionList[i].WaitAuditPharmacistId = val.id;
          // _this.patientFormPre.compositionList[i].WaitAuditPharmacistName = val.userName;
          // _this.patientFormPre.compositionList[i].WaitAuditPharmacistPhone = val.phone;
        }
      }
      if (_this.patientFormPre.CZcompositionList.length > 0) {
        for (let i = 0; i < _this.patientFormPre.CZcompositionList.length; i++) {
          _this.patientFormPre.CZcompositionList[i].waitAuditUserId = _this.auditDetail.id;
          _this.patientFormPre.CZcompositionList[i].waitAuditUserName = _this.auditDetail.userName;
          _this.patientFormPre.CZcompositionList[i].waitAuditUserPhone = _this.auditDetail.phone;
          _this.patientFormPre.CZcompositionList[i].auditDoctorType = _this.indexTypeDoctor
          // _this.patientFormPre.CZcompositionList[i].WaitAuditPharmacistId = val.id;
          // _this.patientFormPre.CZcompositionList[i].WaitAuditPharmacistName = val.userName;
          // _this.patientFormPre.CZcompositionList[i].WaitAuditPharmacistPhone = val.phone;
        }
      }
      if (_this.patientFormPre.SYcompositionList.length > 0) {
        for (let i = 0; i < _this.patientFormPre.SYcompositionList.length; i++) {
          _this.patientFormPre.SYcompositionList[i].waitAuditUserId = _this.auditDetail.id;
          _this.patientFormPre.SYcompositionList[i].waitAuditUserName = _this.auditDetail.userName;
          _this.patientFormPre.SYcompositionList[i].waitAuditUserPhone = _this.auditDetail.phone;
          _this.patientFormPre.SYcompositionList[i].auditDoctorType = _this.indexTypeDoctor;
          // _this.patientFormPre.SYcompositionList[i].WaitAuditPharmacistId = val.id;
          // _this.patientFormPre.SYcompositionList[i].WaitAuditPharmacistName = val.userName;
          // _this.patientFormPre.SYcompositionList[i].WaitAuditPharmacistPhone = val.phone;
        }
      }
    },
    handleSelectionChangeAudit(val) {
      var _this = this;
      _this.auditDetail = val
      _this.waitAuditUserId = val.id;
      _this.auditDiagnoseMoney = val.auditDiagnoseMoney;
      if (_this.patientFormPre.compositionList.length > 0) {
        for (let i = 0; i < _this.patientFormPre.compositionList.length; i++) {
          _this.patientFormPre.compositionList[i].waitAuditUserId = _this.auditDetail.id;
          _this.patientFormPre.compositionList[i].waitAuditUserName = _this.auditDetail.userName;
          _this.patientFormPre.compositionList[i].waitAuditUserPhone = _this.auditDetail.phone;
          _this.patientFormPre.compositionList[i].auditDoctorType = _this.indexTypeDoctor
          // _this.patientFormPre.compositionList[i].WaitAuditPharmacistId = val.id;
          // _this.patientFormPre.compositionList[i].WaitAuditPharmacistName = val.userName;
          // _this.patientFormPre.compositionList[i].WaitAuditPharmacistPhone = val.phone;
        }
      }
      if (_this.patientFormPre.CZcompositionList.length > 0) {
        for (let i = 0; i < _this.patientFormPre.CZcompositionList.length; i++) {
          _this.patientFormPre.CZcompositionList[i].waitAuditUserId = _this.auditDetail.id;
          _this.patientFormPre.CZcompositionList[i].waitAuditUserName = _this.auditDetail.userName;
          _this.patientFormPre.CZcompositionList[i].waitAuditUserPhone = _this.auditDetail.phone;
          _this.patientFormPre.CZcompositionList[i].auditDoctorType = _this.indexTypeDoctor
          // _this.patientFormPre.CZcompositionList[i].WaitAuditPharmacistId = val.id;
          // _this.patientFormPre.CZcompositionList[i].WaitAuditPharmacistName = val.userName;
          // _this.patientFormPre.CZcompositionList[i].WaitAuditPharmacistPhone = val.phone;
        }
      }
      if (_this.patientFormPre.SYcompositionList.length > 0) {
        for (let i = 0; i < _this.patientFormPre.SYcompositionList.length; i++) {
          _this.patientFormPre.SYcompositionList[i].waitAuditUserId = _this.auditDetail.id;
          _this.patientFormPre.SYcompositionList[i].waitAuditUserName = _this.auditDetail.userName;
          _this.patientFormPre.SYcompositionList[i].waitAuditUserPhone = _this.auditDetail.phone;
          _this.patientFormPre.SYcompositionList[i].auditDoctorType = _this.indexTypeDoctor;
          // _this.patientFormPre.SYcompositionList[i].WaitAuditPharmacistId = val.id;
          // _this.patientFormPre.SYcompositionList[i].WaitAuditPharmacistName = val.userName;
          // _this.patientFormPre.SYcompositionList[i].WaitAuditPharmacistPhone = val.phone;
        }
      }
    },
    handleReset() {
      if (this.$refs.esign) {
        this.$refs.esign.reset();
      }
    },
    handleGenerate() {
      let _this = this;
      if (_this.signMent) {
        _this.auditSignImg = _this.UserSignImg
        _this.patientDomain.GetSetUserSign(_this.auditSignImg,function (data) {
            // 选择免审核选择药剂师
            _this.esigndialogVisible = false;
            _this.mzPrescriptionData.map((item, index) => {
              item.auditSignImg = _this.auditSignImg;
            });
        _this.getNextCompletePrescription();
            // _this.AuditNextDataSuc = true
            // _this.doctorDialogVisible = true
            // 提交免审核
            // _this.getNextCompletePrescription();
        }, function (error) {
            _this.esigndialogVisible = false;
            _this.mzPrescriptionData.map((item, index) => {
              item.auditSignImg = _this.auditSignImg;
            });
            _this.getNextCompletePrescription();
            // _this.AuditNextDataSuc = true
            // _this.doctorDialogVisible = true
             // 提交免审核
        })


      } else {
          _this.$refs.esign
        .generate()
        .then((res) => {
          let randnum = Math.random() * 10000000000000;
          randnum = Math.floor(randnum);
          let fileName = "zhu/" + randnum + ".jpg";
          let esignimgurlFile = _this.dataURLtoFile(res, fileName);
          let dataform = new FormData();
          dataform.append("file", esignimgurlFile);
          _this
            .$axios({
              method: "POST",
              url: _this.FileUploadUrl,
              headers: { "Content-Type": "multipart/form-data", Authorization: "Bearer" + " " + _this.asstoken },
              data: dataform,
            })
            .then((res) => {
              _this.auditSignImg = res.data[0].completeFilePath;
              _this.patientDomain.GetSetUserSign(_this.auditSignImg,function (data) {
                    // 选择免审核选择药剂师
                    // _this.AuditNextDataSuc = true
                    // _this.doctorDialogVisible = true
                    // 提交免审核
                    _this.esigndialogVisible = false;
                    _this.mzPrescriptionData.map((item, index) => {
                      item.auditSignImg = _this.auditSignImg;
                    });
                    _this.getNextCompletePrescription();
              }, function (error) {
                    // 选择免审核选择药剂师
                    // _this.AuditNextDataSuc = true
                    // _this.doctorDialogVisible = true
                    // 提交免审核
                    _this.esigndialogVisible = false;
                    _this.mzPrescriptionData.map((item, index) => {
                      item.auditSignImg = _this.auditSignImg;
                    });
                    _this.getNextCompletePrescription();
              })

   
            })
            .catch((err) => {
              console.log(err)
              _this.$message.error("上传签名失败");
            });
        })
        .catch((err) => {
          _this.$message.error("请签名之后提交！");
        });
      }
    
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    },
    getuploadData(params) {
      let dataform = new FormData();
      dataform.append("file", params);
      var _this = this;
      _this.patientDomain.getupload(
        dataform,
        function (data) {
          _this.$refs.esign.reset();
        },
        function (error) {
          console.log(error);
          _this.ZCPatientList = [];
        }
      );
    },
    goNextDataSuc(formName) {
      var _this = this;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
            if (_this.patientFormPre.compositionList.length > 0) {
              for (let i = 0; i < _this.patientFormPre.compositionList.length; i++) {
                _this.patientFormPre.compositionList[i].waitAuditUserId = 0;
                _this.patientFormPre.compositionList[i].waitAuditUserName = 0;
                _this.patientFormPre.compositionList[i].waitAuditUserPhone = 0;
                _this.patientFormPre.compositionList[i].auditDoctorType = 0
                if (_this.detailUserType == '4') {
                       _this.patientFormPre.compositionList[i].organizationId = _this.orgexpertId
                       _this.patientFormPre.compositionList[i].organizationName = _this.orgexpertName
                }
           
                // detailUserType
                // _this.patientFormPre.compositionList[i].WaitAuditPharmacistId = val.id;
                // _this.patientFormPre.compositionList[i].WaitAuditPharmacistName = val.userName;
                // _this.patientFormPre.compositionList[i].WaitAuditPharmacistPhone = val.phone;
              }
            }
            if (_this.patientFormPre.CZcompositionList.length > 0) {
              for (let i = 0; i < _this.patientFormPre.CZcompositionList.length; i++) {
                _this.patientFormPre.CZcompositionList[i].waitAuditUserId = 0;
                _this.patientFormPre.CZcompositionList[i].waitAuditUserName = 0;
                _this.patientFormPre.CZcompositionList[i].waitAuditUserPhone = 0;
                _this.patientFormPre.CZcompositionList[i].auditDoctorType = 0
                if (_this.detailUserType == '4') {
                    _this.patientFormPre.CZcompositionList[i].organizationId = _this.orgexpertId
                    _this.patientFormPre.CZcompositionList[i].organizationName = _this.orgexpertName
                }
                // _this.patientFormPre.CZcompositionList[i].WaitAuditPharmacistId = val.id;
                // _this.patientFormPre.CZcompositionList[i].WaitAuditPharmacistName = val.userName;
                // _this.patientFormPre.CZcompositionList[i].WaitAuditPharmacistPhone = val.phone;
              }
            }
            if (_this.patientFormPre.SYcompositionList.length > 0) {
              for (let i = 0; i < _this.patientFormPre.SYcompositionList.length; i++) {
                _this.patientFormPre.SYcompositionList[i].waitAuditUserId = 0;
                _this.patientFormPre.SYcompositionList[i].waitAuditUserName = 0;
                _this.patientFormPre.SYcompositionList[i].waitAuditUserPhone = 0;
                _this.patientFormPre.SYcompositionList[i].auditDoctorType = 0;
                if (_this.detailUserType == '4') {
                    _this.patientFormPre.SYcompositionList[i].organizationId = _this.orgexpertId
                    _this.patientFormPre.SYcompositionList[i].organizationName = _this.orgexpertName
                }
                // _this.patientFormPre.SYcompositionList[i].WaitAuditPharmacistId = val.id;
                // _this.patientFormPre.SYcompositionList[i].WaitAuditPharmacistName = val.userName;
                // _this.patientFormPre.SYcompositionList[i].WaitAuditPharmacistPhone = val.phone;
              }
            }
          let paramsMZOtherCharges = [];
          for (let h = 0; h < _this.patientFormPre.MZOtherCharges.length; h++) {
            if (_this.patientFormPre.MZOtherCharges[h].chargeName && _this.patientFormPre.MZOtherCharges[h].money) {
              paramsMZOtherCharges.push({
                patientDiagnoseId: _this.patientDiagnoseId * 1,
                diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
                chargeName: _this.patientFormPre.MZOtherCharges[h].chargeName.itemName ? _this.patientFormPre.MZOtherCharges[h].chargeName.itemName : _this.patientFormPre.MZOtherCharges[h].chargeName,
                money: _this.patientFormPre.MZOtherCharges[h].money,
                entityId: _this.patientFormPre.MZOtherCharges[h].entityId ? _this.patientFormPre.MZOtherCharges[h].entityId : 0,
                orderType: 3,
                userName: _this.userInfo.name,
                userPhone: _this.userInfo.phone,
                departmentId: _this.userInfo.departmentId ? _this.userInfo.departmentId : 0,
                departmentName: _this.userInfo.department,
              });
            }
          }
          if (_this.patientFormPre.isSelef == "2" && _this.patientFormPre.isSelefPrice != "0") {
            paramsMZOtherCharges.push({
              patientDiagnoseId: _this.patientDiagnoseId * 1,
              diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
              // chargeName: "快递费用",
              chargeName:_this.patientFormPre.chargeName,
              money: _this.patientFormPre.isSelefPrice,
              orderType: 6,
              entityId:_this.isSelefId,
              userName: _this.userInfo.name,
              userPhone: _this.userInfo.phone,
              departmentId: _this.userInfo.departmentId ? _this.userInfo.departmentId : 0,
              departmentName: _this.userInfo.department,
            });
          }
          for (let i = 0; i < _this.patientFormPre.compositionList.length; i++) {
            _this.patientFormPre.compositionList[i].userName = _this.userInfo.name;
            _this.patientFormPre.compositionList[i].userPhone = _this.userInfo.phone;
            _this.patientFormPre.compositionList[i].departmentId = _this.userInfo.departmentId ? _this.userInfo.departmentId : 0;
            _this.patientFormPre.compositionList[i].departmentName = _this.userInfo.department;
            if (_this.patientFormPre.isSelef == "1") {
              _this.patientFormPre.compositionList[i].isSelef = true;
            } else {
              _this.patientFormPre.compositionList[i].isSelef = false;
              _this.patientFormPre.compositionList[i].takeUserName = _this.messagePatinet.addressee;
              _this.patientFormPre.compositionList[i].takePhone = _this.messagePatinet.phone;
              _this.patientFormPre.compositionList[i].regionId = _this.messagePatinet.regionId;
              _this.patientFormPre.compositionList[i].regionFull = _this.messagePatinet.regionName;
              _this.patientFormPre.compositionList[i].address = _this.messagePatinet.fullAddress;
              _this.patientFormPre.compositionList[i].expressCompany = _this.patientFormPre.chargeName;
            }
            _this.patientFormPre.compositionList[i].patientId = _this.patientDetail.id;
            _this.patientFormPre.compositionList[i].patientName = _this.patientDetail.name;
            _this.patientFormPre.compositionList[i].patientPhone = _this.patientDetail.phone;
            _this.patientFormPre.compositionList[i].age = _this.patientDetail.age;
            _this.patientFormPre.compositionList[i].sex = _this.patientDetail.sex ? 1 : 0;
            _this.patientFormPre.compositionList[i].patientDiagnoseId = _this.patientDiagnoseId * 1;
            _this.patientFormPre.compositionList[i].diseaseDiagnose = _this.patientFormDes.diseaseDiagnose;
            _this.patientFormPre.compositionList[i].doctorAdvice = _this.patientFormPre.doctorAdvice;
            let isDefaultfalse = false
            _this.patientFormPre.compositionList[i].mzPrescriptionDetail.map(item => {
              if (item.drugOrgName) {
               item.weight =  parseFloat(item.weight)
                isDefaultfalse = true
              }
            })
            if (_this.patientFormPre.compositionList[i].isDecoction && _this.patientFormPre.isdeliveryPrice != "0" && isDefaultfalse) {
              paramsMZOtherCharges.push({
                patientDiagnoseId: _this.patientDiagnoseId * 1,
                diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
                chargeName: _this.patientFormPre.compositionList[i].isSelefPriceName,
                entityId: _this.patientFormPre.compositionList[i].isdeliveryPriceId,
                money: (parseFloat(_this.patientFormPre.compositionList[i].isdeliveryPrice) * parseFloat(_this.patientFormPre.compositionList[i].dosage)).toFixed(2),
                orderType: 4,
                userName: _this.userInfo.name,
                userPhone: _this.userInfo.phone,
                departmentId: _this.userInfo.departmentId ? _this.userInfo.departmentId : 0,
                departmentName: _this.userInfo.department,
              });
            }
          }
          for (let m = 0; m < _this.patientFormPre.CZcompositionList.length; m++) {
            _this.patientFormPre.CZcompositionList[m].userName = _this.userInfo.name;
            _this.patientFormPre.CZcompositionList[m].userPhone = _this.userInfo.phone;
            _this.patientFormPre.CZcompositionList[m].departmentId = _this.userInfo.departmentId ? _this.userInfo.departmentId : 0;
            _this.patientFormPre.CZcompositionList[m].departmentName = _this.userInfo.department;
            if (_this.patientFormPre.isSelef == "1") {
              _this.patientFormPre.CZcompositionList[m].isSelef = true;
            } else {
              _this.patientFormPre.CZcompositionList[m].isSelef = false;
              _this.patientFormPre.CZcompositionList[m].takeUserName = _this.messagePatinet.addressee;
              _this.patientFormPre.CZcompositionList[m].takePhone = _this.messagePatinet.phone;
              _this.patientFormPre.CZcompositionList[m].regionId = _this.messagePatinet.regionId;
              _this.patientFormPre.CZcompositionList[m].regionFull = _this.messagePatinet.regionName;
              _this.patientFormPre.CZcompositionList[m].address = _this.messagePatinet.fullAddress;
              _this.patientFormPre.CZcompositionList[m].expressCompany = _this.patientFormPre.chargeName;
            }
            _this.patientFormPre.CZcompositionList[m].patientId = _this.patientDetail.id;
            _this.patientFormPre.CZcompositionList[m].patientName = _this.patientDetail.name;
            _this.patientFormPre.CZcompositionList[m].age = _this.patientDetail.age;
            _this.patientFormPre.CZcompositionList[m].sex = _this.patientDetail.sex ? 1 : 0;
            _this.patientFormPre.CZcompositionList[m].patientDiagnoseId = _this.patientDiagnoseId * 1;
            _this.patientFormPre.CZcompositionList[m].patientPhone = _this.patientDetail.phone;
            _this.patientFormPre.CZcompositionList[m].diseaseDiagnose = _this.patientFormDes.diseaseDiagnose;
            _this.patientFormPre.CZcompositionList[m].doctorAdvice = _this.patientFormPre.doctorAdvice;
            for (let n = 0; n < _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail.length; n++) {
              _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosage = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosageList.defaultDosage;
              _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosageUnit = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosageList.defaultDosageUnit;
              _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosage = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosageList.dosage;
              _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosageUnit = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosageList.dosageUnit;
            }
          }
          let params = [];
          let params1 = [];
          for (let x = 0; x < _this.patientFormPre.SYcompositionList.length; x++) {
            params.push(_this.patientFormPre.SYcompositionList[x]);
          }
          for (let y = 0; y < params.length; y++) {
            params[y].mzPrescriptionGroup = params[y].zucompositionList;
            params1.push({
              mzPrescriptionDetail: params[y].mzPrescriptionDetail,
              prescriptionName: params[y].prescriptionName,
              prescriptionType: params[y].prescriptionType,
              type: params[y].type,
              mzPrescriptionGroup: [],
              patientId: _this.patientDetail.id,
              patientName: _this.patientDetail.name,
              patientPhone: _this.patientDetail.phone,
              age: _this.patientDetail.age,
              sex: _this.patientDetail.sex ? 1 : 0,
              patientDiagnoseId: _this.patientDiagnoseId * 1,
              diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
              doctorAdvice: _this.patientFormPre.doctorAdvice,
              userName: _this.userInfo.name,
              userPhone: _this.userInfo.phone,
              departmentId: _this.userInfo.departmentId ? _this.userInfo.departmentId : 0,
              departmentName: _this.userInfo.department,
            });
          }
          for (let z = 0; z < params.length; z++) {
            for (let o = 0; o < params[z].zucompositionList.length; o++) {
              for (let p = 0; p < params[z].zucompositionList[o].mzPrescriptionDetail.length; p++) {
                params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosage = params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosage;
                params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageUnit = params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosageUnit;
                params[z].zucompositionList[o].mzPrescriptionDetail[p].dosage = params[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosage;
                params[z].zucompositionList[o].mzPrescriptionDetail[p].dosageUnit = params[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosageUnit;
              }
              for (let w = 0; w < params1.length; w++) {
                params1[w].mzPrescriptionGroup.push({
                  usage: params[z].zucompositionList[o].mzPrescriptionTitle[0].usage,
                  days: params[z].zucompositionList[o].mzPrescriptionTitle[0].days,
                  drippingSpeed: params[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeed,
                  drippingSpeedUnit: params[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeedUnit,
                  frequency: params[z].zucompositionList[o].mzPrescriptionTitle[0].frequency,
                  startTime: params[z].zucompositionList[o].mzPrescriptionTitle[0].startTime,
                  mzPrescriptionDetailSY: params[z].zucompositionList[o].mzPrescriptionDetail,
                });
              }
            }
          }
          let patientFormPreList = [];
          let patientFormPreCZList = [];
          let patientFormPreSyList = [];
          _this.patientFormPre.compositionList.map((item, index) => {
            if (item.prescriptionName) {
              patientFormPreList.push(item);
            }
          });
          _this.patientFormPre.CZcompositionList.map((item, index) => {
            patientFormPreCZList.push(item);
          });
          patientFormPreCZList.map((item, index)=>{
            item.mzPrescriptionDetail.map((item1,index1)=>{
              if(item1.drugOrgId == '') {
                patientFormPreCZList[index].mzPrescriptionDetail =  patientFormPreCZList[index].mzPrescriptionDetail.splice(index1,1)
              }
            })
            if (item.mzPrescriptionDetail.length == 0) {
             patientFormPreCZList =  []
            }
          })
          params1.forEach((item, index) => {
            if (_this.patientFormPre.isSelef == "1") {
              item.isSelef = true;
            } else {
              item.isSelef = false;
              item.takeUserName = _this.messagePatinet.addressee;
              item.takePhone = _this.messagePatinet.phone;
              item.regionId = _this.messagePatinet.regionId;
              item.regionFull = _this.messagePatinet.regionName;
              item.address = _this.messagePatinet.fullAddress;
              item.expressCompany = _this.patientFormPre.chargeName;
            }
          });
          params1.map((item, index) => {
            if (item.prescriptionName) {
              patientFormPreSyList.push(item);
            }
          });
          let paramsData = patientFormPreList.concat(patientFormPreCZList).concat(patientFormPreSyList);
          if (paramsData.length == 0) {
            _this.$message({
              message: "请完善处方信息",
              type: "error",
            });
            return;
          }
          if (patientFormPreList.length > 0) {
            for (let q = 0; q < patientFormPreList.length; q++) {
                if (!patientFormPreList[q].frequency) {
                  _this.$message({
                    message: "请检查方剂处方用药频率",
                    type: "error",
                  });
                  return;
                }
                if (!patientFormPreList[q].usage) {
                  _this.$message({
                    message: "请检查方剂处方用法",
                    type: "error",
                  });
                  return;
                }
                if (!patientFormPreList[q].request) {
                  _this.$message({
                    message: "请检查方剂处方服用要求",
                    type: "error",
                  });
                  return;
                }
              for (let w = 0; w < patientFormPreList[q].mzPrescriptionDetail.length; w++) {
                let res1 = patientFormPreList[q].mzPrescriptionDetail[w];
                if (!res1.drugOrgId) {
                  _this.$message({
                    message: "请检查方剂处方药品信息",
                    type: "error",
                  });
                  return;
                }
                if (res1.weight == '') {
                  _this.$message({
                    message: "请检查方剂处方用量",
                    type: "error",
                  });
                  return;
                }
                if (res1.weight > res1.inventoryNumber || (res1.inventoryNumber == "0" && !res1.drugInventoryNumber) || (res1.drugInventoryNumber == "0" && !res1.inventoryNumber)) {
                  _this.$message({
                    message: "请检查方剂处方药品库存",
                    type: "error",
                  });
                  return;
                }
              }
            }
          }
          if (patientFormPreCZList.length > 0) {
            for (let e = 0; e < patientFormPreCZList.length; e++) {
              for (let r = 0; r < patientFormPreCZList[e].mzPrescriptionDetail.length; r++) {
                let res1 = patientFormPreCZList[e].mzPrescriptionDetail[r];
                if (!res1.defaultDosageList.defaultDosageUnit && res1.drugOrgId) {
                  _this.$message({
                    message: "请检查成药处方药品开药量单位",
                    type: "error",
                  });
                  return;
                }
                if (!res1.usage && res1.drugOrgId) {
                  _this.$message({
                    message: "请检查成药处方药品用法",
                    type: "error",
                  });
                  return;
                }
                if (!res1.frequency && res1.drugOrgId) {
                  _this.$message({
                    message: "请检查成药处方药品用药频率",
                    type: "error",
                  });
                  return;
                }
                if (res1.drugOrgId && res1.defaultDosageList.defaultDosage > res1.inventoryNumber || (res1.inventoryNumber == "0" && !res1.drugInventoryNumber) || (res1.drugInventoryNumber == "0" && !res1.inventoryNumber)) {
                  _this.$message({
                    message: "请检查成药处方药品库存",
                    type: "error",
                  });
                  return;
                }
              }
            }
          }
          if (patientFormPreSyList.length > 0) {
            for (let t = 0; t < patientFormPreSyList.length; t++) {
              for (let y = 0; y < patientFormPreSyList[t].mzPrescriptionGroup.length; y++) {
                for (let p = 0; p < patientFormPreSyList[t].mzPrescriptionGroup[y].mzPrescriptionDetailSY.length; p++) {
                  let res1 = patientFormPreSyList[t].mzPrescriptionGroup[y].mzPrescriptionDetailSY[p];
                  if (!res1.defaultDosageList.defaultDosageUnit) {
                    _this.$message({
                      message: "请检查输液处方药品开药量单位",
                      type: "error",
                    });
                    return;
                  }
                  if (res1.defaultDosageList.defaultDosage > res1.inventoryNumber || (res1.inventoryNumber == "0" && !res1.drugInventoryNumber) || (res1.drugInventoryNumber == "0" && !res1.inventoryNumber)) {
                    _this.$message({
                      message: "请检查输液处方药品库存",
                      type: "error",
                    });
                    return;
                  }
                }
              }
            }
          }
          if (!_this.messagePatinet.phone && _this.patientFormPre.isSelef == "2") {
            _this.$message({
              message: "暂未填写快递信息",
              type: "error",
            });
            return;
          }
          // 获取推荐用量不符数据
          if (patientFormPreList.length > 0) {
            _this.recommendPreList = []
            for (let q = 0; q < patientFormPreList.length; q++) {
              for (let w = 0; w < patientFormPreList[q].mzPrescriptionDetail.length; w++) {
                let res1 = patientFormPreList[q].mzPrescriptionDetail[w];
                if (parseFloat(res1.weight) < res1.recommendDosageMin || parseFloat(res1.weight) > res1.recommendDosageMax) {
                 _this.recommendPreList.push(res1)
                }
              }
            }
          }
          _this.nosucecessloading = true
          _this.compatibilityResultShow = false
          _this.mzPrescriptionData = paramsData;
          _this.mzOtherChargesData = paramsMZOtherCharges;
          _this.goComtabClick()
          setTimeout(()=>{
            _this.nosucecessloading = false
            if(_this.compatibilityList.length > 0 || _this.recommendPreList.length > 0){
                _this.comDialogVisible = true;
            } else {
              _this.GetUserSign()
              // _this.doctorDialogProgress = 2
              // _this.GetAuditPharmacistData()
            }
          },1000)
        }
      });
    },
    getNextCompletePrescription() {
      var _this = this;
      // if (_this.patientFormPre.SYcompositionList.length > 0) {
      //    for (let i=0; i<_this.mzPrescriptionData.length; i++) {
      //       for (let j=0; j<_this.patientFormPre.SYcompositionList.length; j++) {
      //         if (_this.mzPrescriptionData[i].type == 2 && _this.mzPrescriptionData[i].prescriptionName == _this.patientFormPre.SYcompositionList[j].prescriptionName) {
      //           _this.mzPrescriptionData[i]['WaitAuditPharmacistId'] = _this.patientFormPre.SYcompositionList[j].WaitAuditPharmacistId
      //           _this.mzPrescriptionData[i]['WaitAuditPharmacistName'] = _this.patientFormPre.SYcompositionList[j].WaitAuditPharmacistName
      //           _this.mzPrescriptionData[i]['WaitAuditPharmacistPhone'] = _this.patientFormPre.SYcompositionList[j].WaitAuditPharmacistPhone
      //         }
      //       }
      //    }
      // }
     
      _this.drugpatientDomain.getCompletePrescription(
        {
          mzPrescription: _this.mzPrescriptionData,
          mzOtherCharges: _this.mzOtherChargesData,
        },
        function (data) {
          // _this.$message({
          //     message: '出药成功',
          //     type: 'success'
          //     });
          _this.drugpatientDomain.getOrderDiagnoseDetail(
            _this.patientDiagnoseId,
            function (data) {
              let paramsOrderType = []
              for (let i=0; i<data.data.details.length; i++) {
                if (data.data.details[i].orderType != 2) {
                  paramsOrderType.push(data.data.details[i])
                }
              }
              _this.charge = data.data;
              _this.charge.details = paramsOrderType
              _this.charge.payMoney = data.data.totalMoney;
              _this.getAllUserBalance();
            },
            function (error) {
              console.log(error);
            }
          );
          _this.paydialogVisible = true;

          // _this.$router.push({
          //     name:'PremangePatientList'
          // })
        },
        function (data) {
          _this.$message({
            message: "免审核成功",
            type: "success",
          });
          // _this.$router.push({
          //   name: "PremangePatientList",
          // });
        },
        function (error) {
          console.log(error);
        }
      );
    },

    // 审核
    goNextData() {
      var _this = this;

      (_this.auditkeyWord = ""), (_this.departmentId = 0), _this.GetAuditDoctorData();
      if (!_this.messagePatinet.phone && _this.patientFormPre.isSelef == "2") {
        _this.$message({
          message: "暂未填写快递信息",
          type: "error",
        });
        return;
      }
      // _this.AuditNextDataSuc = false
      _this.doctorDialogVisible = true;
      // for (let i=0; i<_this.patientFormPre.MZOtherCharges.length; i++) {
      //     _this.patientFormPre.MZOtherCharges[i].patientDiagnoseId = _this.patientDiagnoseId * 1
      //     _this.patientFormPre.MZOtherCharges[i].diagnoseResult = _this.patientFormDes.diseaseDiagnose
      // }
      // // getCompletePrescription getMomentPrescription
      // _this.drugpatientDomain.getCompletePrescription({
      //     mzPrescription: this.patientFormPre.compositionList,
      //     mzOtherCharges:this.patientFormPre.MZOtherCharges
      // },function (data) {
      //     console.log(data)
      // }, function (error) {
      //     console.log(error)
      // })
    },
    handleClick3(e) {
      if (e.label == "常用处方") {
        if (this.drugOrgName == "个人") {
          this.GetMyCommonPrescriptionData();
        } else {
          this.GetorgMyCommonPrescriptionData();
        }
      }
      if (e.label == "经典方") {
        this.GetHerbalPrescriptionListdata();
         this.herbalDetail = [];
         this.JChooseName = "";
      }
      if (e.label == "智能处方") {
        this.JChooseName = "";
        this.herbalDetail = [];
      }
    },
    handleClick2(e) {
      if (e.label == "辨证论治") {
        this.ResultLatestData = [];
        this.getBZResultLatestData();
      }
      if (e.label == "辨病论治") {
        this.tcmResultLatestData = [];
        this.getBBResultLatestData();
      }
      if (e.label == "虚症辨证") {
        // this.goResultByUserList(0, "中医体质");
        this.goResultByUserList(1, "中医虚证");
      }
    },

    searchuserSymptom(){  
      var _this = this
        if (_this.userSymptomValue == '' || _this.userSymptomValue == ' ') {
          _this.searchResultByUserList = [];
          return
        }
        _this.staticsDomain.getQuestionnaireReulst(
        _this.userSymptomValue,
        "0",
        "中医虚证辨证与治疗",
        function (data) {
          _this.searchResultByUserList = data.data;
        },
        function (error) {
          _this.searchResultByUserList = [];
        }
      );
    },
    canclechuserSymptom(){
      var _this = this
      _this.userSymptomValue = ''
      _this.searchResultByUserList = []; 

    },
    searchuserBZ(){
      var _this = this
        if (_this.userSymptomValueBZ == '' || _this.userSymptomValueBZ == ' ') {
          _this.searchResultByUserListBZ = [];
          _this.searchResultByUserListDetail = []
          return
        }
        _this.DiseaseDomain.getSelfDiagnosisData(
        _this.userSymptomValueBZ,
        '1',
        function (data) {
          _this.searchResultByUserListBZ = data.data.results;
          _this.searchResultByUserListDetail = []
        },
        function (error) {
          _this.searchResultByUserListBZ = [];
        }
      );
    },
    searchuserBB(){
      var _this = this
        if (_this.userSymptomValueBB == '' || _this.userSymptomValueBB == ' ') {
          _this.searchResultByUserListBB = [];
          _this.searchResultByUserListDetailBB = []
          return
        }
        if (_this.Services.ClientId == 'AssociationWeb') {
          _this.DiseaseDomain.getTCMDiseaseDatamz(
            _this.userSymptomValueBB,
            '1',
            function (data) {
              _this.searchResultByUserListBB = data.data.results;
              _this.searchResultByUserListDetailBB = []
            },
            function (error) {
              _this.searchResultByUserListBB = [];
            }
          );
        } else {
          _this.DiseaseDomain.getTCMDiseaseData(
            _this.userSymptomValueBB,
            '1',
            function (data) {
              _this.searchResultByUserListBB = data.data.results;
              _this.searchResultByUserListDetailBB = []
            },
            function (error) {
              _this.searchResultByUserListBB = [];
            }
          );
        }
    },
    goResultByAnalysisBZ(item){
        var _this = this
        _this.DiseaseDomain.getSelfManagerData(
        item.id,
        function (data) {
          _this.searchResultByUserListDetail = data.data;
        },
        function (error) {
          _this.searchResultByUserListDetail = [];
        }
      );
      
    },
    goResultByAnalysisBB(item){
        var _this = this
        _this.DiseaseDomain.getSelfManagerData(
        item.id,
        function (data) {
          _this.searchResultByUserListDetailBB = data.data;
        },
        function (error) {
          _this.searchResultByUserListDetailBB = [];
        }
      );
      
    },
    canclechuserBZ(){
      var _this = this
      _this.userSymptomValueBZ = ''
      _this.searchResultByUserListBZ = []
      _this.searchResultByUserListDetail = []
    },
    canclechuserBB(){
      var _this = this
      _this.userSymptomValueBB = ''
      _this.searchResultByUserListBB = []
      _this.searchResultByUserListDetailBB = []
    },
    goMyCommonPrescription() {
      this.GetMyCommonPrescriptionData();
    },
    goMyCommonPrescrip(itemMyCommon, index) {
      this.MyCommonindex = index;
      this.GChooseName = itemMyCommon.prescriptionName;
      if (this.tab3ClickData != 2) {
        this.MyCommonPrescripTableData = itemMyCommon;
      } else {
        this.MyCommonPrescripTableDataSY = itemMyCommon;
      }
    },
    MyCommonKeywordInput() {
      if (this.drugOrgName == "个人") {
        this.GetMyCommonPrescriptionData();
      } else {
        this.GetorgMyCommonPrescriptionData();
      }
    },
    MyCommonCurrentChange(pageNum) {
      if (this.drugOrgName == "个人") {
        this.MyCommoncurrentPage = pageNum;
        this.MyCommonPageindex = pageNum;
        this.GetMyCommonPrescriptionData();
      } else {
        this.MyCommoncurrentPage = pageNum;
        this.MyCommonPageindex = pageNum;
        this.GetorgMyCommonPrescriptionData();
      }
    },
    // 获取个人常用处方
    GetMyCommonPrescriptionData() {
      let type = 0;
      var _this = this;
      if (_this.tab3ClickData == 0) {
        type = 1;
      }
      if (_this.tab3ClickData == 1) {
        type = 2;
      }
      if (_this.tab3ClickData == 2) {
        type = 3;
      }
      let keyword = _this.MyCommonKeyword ? _this.MyCommonKeyword : "%20";
      _this.drugpatientDomain.GetMyCommonPrescription(
        type,
        keyword,
        _this.MyCommonPageindex,
        15,
        function (data) {
          _this.MyCommonindex = -1;
          _this.MyCommonPrescriptionList = data.data.results;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    GetorgMyCommonPrescriptionData() {
      let type = -1;

      var _this = this;
      if (_this.tab3ClickData == 0) {
        type = 0;
      }
      if (_this.tab3ClickData == 1) {
        type = 1;
      }
      if (_this.tab3ClickData == 2) {
        type = 2;
      }
      let keyword = _this.MyCommonKeyword ? _this.MyCommonKeyword : "%20";
      _this.drugpatientDomain.GetPrecriptionListOrg(
        type,
        keyword,
        _this.MyCommonPageindex,
        function (data) {
          _this.MyCommonPrescriptionList = data.data.results;
          _this.MyCommonindex = -1;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    addpatientFormPreForm(indexCom) {
      var _this = this;
      let params = _this.patientFormPre.compositionList[indexCom];
      if (_this.patientFormPre.compositionList[indexCom].prescriptionName == "") {
        _this.$message({
          message: "暂未填写处方名称",
          type: "error",
        });
        return;
      }
      params.mzPrescriptionDetail.map((item, idnex) => {
        item.drugOrgId = item.drugOrgId ? item.drugOrgId : null;
      });
      _this.drugpatientDomain.getAddMZPrecriptionBatch(
        params,
        function (data) {
          _this.$message({
            message: "已存为常用处方",
            type: "success",
          });
        },
        function (error) {
          _this.$message({
            message: "请查看填写数据",
            type: "error",
          });
        }
      );
    },
    addCyFormPreForm(index) {
      var _this = this;
      if (_this.patientFormPre.CZcompositionList[index].prescriptionName == "") {
        _this.$message({
          message: "暂未填写处方名称",
          type: "error",
        });
        return;
      }
      let params = _this.patientFormPre.CZcompositionList[index];
      for (let i = 0; i < params.mzPrescriptionDetail.length; i++) {
        params.mzPrescriptionDetail[i].defaultDosage = params.mzPrescriptionDetail[i].defaultDosageList.defaultDosage;
        params.mzPrescriptionDetail[i].defaultDosageUnit = params.mzPrescriptionDetail[i].defaultDosageList.defaultDosageUnit;
        params.mzPrescriptionDetail[i].dosage = params.mzPrescriptionDetail[i].dosageList.dosage;
        params.mzPrescriptionDetail[i].dosageUnit = params.mzPrescriptionDetail[i].dosageList.dosageUnit;
      }
      _this.drugpatientDomain.getAddMZPrecriptionBatch(
        params,
        function (data) {
          _this.$message({
            message: "已存为常用处方",
            type: "success",
          });
        },
        function (error) {
          _this.$message({
            message: "请查看填写数据",
            type: "error",
          });
          console.log(error);
        }
      );
    },
    addSYFormPreForm(index) {
      var _this = this;
      if (_this.patientFormPre.SYcompositionList[index].prescriptionName == "") {
        _this.$message({
          message: "暂未填写处方名称",
          type: "error",
        });
        return;
      }
      let params = _this.patientFormPre.SYcompositionList[index];
      params.mzPrescriptionDetail = [];
      params.mzPrescriptionGroup = params.zucompositionList;
      let params1 = {
        mzPrescriptionDetail: params.mzPrescriptionDetail,
        prescriptionName: params.prescriptionName,
        prescriptionType: params.prescriptionType,
        type: params.type,
        mzPrescriptionGroup: [],
      };
      for (let i = 0; i < params.zucompositionList.length; i++) {
        for (let j = 0; j < params.zucompositionList[i].mzPrescriptionDetail.length; j++) {
          params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosage = params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosage;
          params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosageUnit = params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosageUnit;
          params.zucompositionList[i].mzPrescriptionDetail[j].dosage = params.zucompositionList[i].mzPrescriptionDetail[j].dosageList.dosage;
          params.zucompositionList[i].mzPrescriptionDetail[j].dosageUnit = params.zucompositionList[i].mzPrescriptionDetail[j].dosageList.dosageUnit;
        }
        params1.mzPrescriptionGroup.push({
          usage: params.zucompositionList[i].mzPrescriptionTitle[0].usage,
          days: params.zucompositionList[i].mzPrescriptionTitle[0].days,
          drippingSpeed: params.zucompositionList[i].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeed,
          drippingSpeedUnit: params.zucompositionList[i].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeedUnit,
          frequency: params.zucompositionList[i].mzPrescriptionTitle[0].frequency,
          startTime: params.zucompositionList[i].mzPrescriptionTitle[0].startTime,
          mzPrescriptionDetailSY: params.zucompositionList[i].mzPrescriptionDetail,
        });
      }
      _this.drugpatientDomain.getAddMZPrecriptionBatch(
        params1,
        function (data) {
          _this.$message({
            message: "已存为常用处方",
            type: "success",
          });
          console.log(data);
        },
        function (error) {
          _this.$message({
            message: "请查看填写数据",
            type: "error",
          });
          console.log(error);
        }
      );
    },
    MyCommonConstituteChange(val) {
      if (val == "机构") {
        this.GetorgMyCommonPrescriptionData();
      } else {
        this.GetMyCommonPrescriptionData();
      }
    },
    ConstituteChange(val, item) {
      this.EditDrugCompositio.map((res) => {
        if (res.drugOrgId == val) {
          item.drugOrgId = res.drugOrgId;
          // if (res.drug.mzDrug.drugType) {
          //   if (res.drug.mzDrug.drugType == 'T') {
          //     item.drugOrgName = res.drugOrgName1 + '(统)';
          //   }
          //   if (res.drug.mzDrug.drugType == 'X') {
          //     item.drugOrgName = res.drugOrgName1 + '(选)';
          //   }
          //   if (res.drug.mzDrug.drugType == 'J') {
          //     item.drugOrgName = res.drugOrgName1 + '(精选)';
          //   }
            
          // } else {
          //   item.drugOrgName = res.drugOrgName1;
          // }
          item.drugOrgName = res.drugOrgName1;
          item.inventoryNumber = res.drug.inventoryNumber ? res.drug.inventoryNumber : '';
          item.tradingPrice = res.drug.tradingPrice ? res.drug.tradingPrice : '';
          item.recommendDosage = res.drug.mzDrug.recommendDosage ? res.drug.mzDrug.recommendDosage : "";
          if (res.drug.mzDrug.recommendDosage) {
            var f = res.drug.mzDrug.recommendDosage.split('-')
            var f1 = parseFloat(f[0]) * 1 
            var f2 = parseFloat(f[1]) * 1
            item['recommendDosageMin'] = f1
            item['recommendDosageMax'] = f2
          }
          item.dosage = res.drug.dosage ? res.drug.dosage : "";
          item.usage = res.drug.usage ? res.drug.usage : "";
          item.remark = res.drug.remark ? res.drug.remark : "";
          item.hashKey = res.drug.mzDrug.hashKey ? res.drug.mzDrug.hashKey : "";
          item.drugHashKey = res.drug.mzDrug.hashKey ? res.drug.mzDrug.hashKey : "";
          item.frequency = res.drug.frequency ? res.drug.frequency : '';
          if (this.tab3ClickData == 2 || this.tab3ClickData == 1) {
            item.dosageList.dosageUnit = res.drug.dosageUnit ? res.drug.dosageUnit : "";
            item.dosageList.dosage = res.drug.dosage ? res.drug.dosage : "";
            item.defaultDosageList.defaultDosage = res.drug.defaultDosage ? res.drug.defaultDosage : "";
            item.defaultDosageList.defaultDosageUnit = res.drug.isSeparate ? res.drug.separateNumberUnit : (res.drug.defaultDosageUnit ? res.drug.defaultDosageUnit : res.drug.specificationUnit) ; 
          }
        }
      });
    },
    handleFocus() {
      this.EditDrugCompositio = [];
    },
    SelectComposition(val) {
      var _this = this;
      _this.drugpatientDomain.GetDrugByOrgInTop10(
        val,_this.orgexpertId,
        function (data) {
          _this.EditDrugCompositio = data.data.map(function (item) {
              if (item.mzDrug.drugType) {
                if (item.mzDrug.drugType == 'T') {
                  item.mzDrug.drugName = item.mzDrug.drugName + '(统)';
                }
                if (item.mzDrug.drugType == 'X') {
                  item.mzDrug.drugName = item.mzDrug.drugName + '(选)';
                }
                if (item.mzDrug.drugType == 'J') {
                  item.mzDrug.drugName = item.mzDrug.drugName + '(精选)';
                }
              } 
            // var label = `[${item.categoryName}]${item.compositionName}`;
            var label = `${item.mzDrug.drugName}（${item.specification}/${item.specificationUnit}-${item.productFactory}）`;
            // var label = `${item.mzDrug.drugName}`;
            // if (item.compositionAlias) label += `${item.compositionAlias}`;
            return {
              drugOrgName1: item.mzDrug.drugName,
              drugOrgName: label,
              drugOrgId: item.id,
              recommendDosage: item.mzDrug.recommendDosage,
              drug: item,
              inventoryNumber: item.inventoryNumber,
            };
          });
        },
        function (err) {
          _this.EditDrugCompositio = [];
          console.log(err);
        }
      );
    },
    gonaddPre(index) {
      let row = {
        drugOrgId: "",
        drugOrgName: "",
        dosage: "",
        dosageUnit: "g",
        usage: "",
        weight: "",
        remark: "",
        decoct: "",
        recommendDosage: "",
      };
      this.patientFormPre.compositionList[index].mzPrescriptionDetail.push(row);
    },
    gondeletePre(index1, index) {
      // if(this.patientFormPre.compositionList[index1].mzPrescriptionDetail.length == 1) {
      //     this.$message({
      //         message: '至少保留一项数据',
      //         type: 'warning'
      //     });
      //     return
      // }
      let map = this.patientFormPre.compositionList[index1].mzPrescriptionDetail[index];
      let indexNum = this.jdcompositionList.indexOf(map);
      if (indexNum > -1) {
        this.jdcompositionList.splice(indexNum, 1);
      }
      this.patientFormPre.compositionList[index1].mzPrescriptionDetail.splice(index, 1);
    },
    gonaddPreCY(index) {
      let row = {
        drugOrgId: "",
        drugOrgName: "",
        dosageList: {
          dosage: "",
          dosageUnit: "",
        },
        defaultDosageList: {
          defaultDosage: "",
          defaultDosageUnit: "",
        },
        groupNumber: "",
        frequency: "",
        usage: "",
        remark: "",
      };
      this.patientFormPre.CZcompositionList[index].mzPrescriptionDetail.push(row);
    },
    gondeletePreCY(index1, index) {
      // if(this.patientFormPre.CZcompositionList[index1].mzPrescriptionDetail.length == 1) {
      //     this.$message({
      //         message: '至少保留一项数据',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.patientFormPre.CZcompositionList[index1].mzPrescriptionDetail.splice(index, 1);
    },
    goaddSYList() {
      let row = {
        prescriptionName: "",
        type: 2,
        prescriptionType: 1,
        zucompositionList: [
          {
            prescriptionName: "组",
            mzPrescriptionTitle: [
              {
                usage: "",
                frequency: "",
                drippingSpeedList: {
                  drippingSpeed: "",
                  drippingSpeedUnit: "",
                },
                startTime: "",
                days: "",
              },
            ],
            mzPrescriptionDetail: [
              {
                drugOrgId: "",
                drugOrgName: "",
                st: "",
                dosageList: {
                  dosage: "",
                  dosageUnit: "",
                },
                defaultDosageList: {
                  defaultDosage: "",
                  defaultDosageUnit: "",
                },
              },
              {
                drugOrgId: "",
                drugOrgName: "",
                st: "",
                dosageList: {
                  dosage: "",
                  dosageUnit: "",
                },
                defaultDosageList: {
                  defaultDosage: "",
                  defaultDosageUnit: "",
                },
              },
              {
                drugOrgId: "",
                drugOrgName: "",
                st: "",
                dosageList: {
                  dosage: "",
                  dosageUnit: "",
                },
                defaultDosageList: {
                  defaultDosage: "",
                  defaultDosageUnit: "",
                },
              },
            ],
          },
        ],
      };
      this.patientFormPre.SYcompositionList.push(row);
    },
    godeleteSYList(index) {
      // if(this.patientFormPre.SYcompositionList.length == 1) {
      //     this.$message({
      //         message: '至少保留一项数据',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.patientFormPre.SYcompositionList.splice(index, 1);
    },
    goaddSYzuList(index) {
      let row = {
        prescriptionName: "组",
        mzPrescriptionTitle: [
          {
            usage: "",
            frequency: "",
            drippingSpeedList: {
              drippingSpeed: "",
              drippingSpeedUnit: "",
            },
            startTime: "",
            days: "",
          },
        ],
        mzPrescriptionDetail: [
          {
            drugOrgId: "",
            drugOrgName: "",
            st: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            st: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            st: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
          },
        ],
      };
      this.patientFormPre.SYcompositionList[index].zucompositionList.push(row);
    },
    godeleteSYzuList(index1, index) {
      // if(this.patientFormPre.SYcompositionList[index1].zucompositionList.length == 1) {
      //     this.$message({
      //         message: '至少保留一项数据',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.patientFormPre.SYcompositionList[index1].zucompositionList.splice(index, 1);
    },
    goaddSYzuDrugList(index1, indexComSY1) {
      let row = {
        drugOrgId: "",
        drugOrgName: "",
        st: "",
        dosageList: {
          dosage: "",
          dosageUnit: "",
        },
        defaultDosageList: {
          defaultDosage: "",
          defaultDosageUnit: "",
        },
      };
      this.patientFormPre.SYcompositionList[index1].zucompositionList[indexComSY1].mzPrescriptionDetail.push(row);
    },
    godeletSYzuDrugList(index1, index2, index) {
      // if(this.patientFormPre.SYcompositionList[index1].zucompositionList[index2].mzPrescriptionDetail.length == 1) {
      //     this.$message({
      //         message: '至少保留一项数据',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.patientFormPre.SYcompositionList[index1].zucompositionList[index2].mzPrescriptionDetail.splice(index, 1);
    },
    goaddCzcompositionList() {
      let row = {
        mzPrescriptionDetail: [
          {
            drugOrgId: "",
            drugOrgName: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
            groupNumber: "",
            frequency: "",
            usage: "",
            remark: "",
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            frequency: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
            groupNumber: "",
            usage: "",
            remark: "",
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            frequency: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
            groupNumber: "",
            usage: "",
            remark: "",
          },
        ],
        prescriptionName: "",
        type: 1,
        prescriptionType: 1,
      };
      this.patientFormPre.CZcompositionList.push(row);
    },
    godeleteCzList(index) {
      // if(this.patientFormPre.CZcompositionList.length == 1) {
      //     this.$message({
      //         message: '至少保留一项数据',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.patientFormPre.CZcompositionList.splice(index, 1);
    },
    goAddcompositionList() {
      let row = {
        mzPrescriptionDetail: [
          {
            drugOrgId: "",
            drugOrgName: "",
            dosage: "",
            dosageUnit: "g",
            usage: "",
            weight: "",
            remark: "",
            decoct: "",
            recommendDosage: "",
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            dosage: "",
            dosageUnit: "g",
            usage: "",
            weight: "",
            remark: "",
            decoct: "",
            recommendDosage: "",
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            dosage: "",
            dosageUnit: "g",
            usage: "",
            weight: "",
            remark: "",
            decoct: "",
            recommendDosage: "",
          },
        ],
        usage: "",
        remark: "",
        dosage: "",
        dosageNumber: "",
        frequency: "",
        prescriptionName: "",
        request: "",
        prescriptionType: 1,
        isDecoction: true,
        isdeliveryPrice: 0,
        type: 0
        // patientDiagnoseId:''
      };
      this.patientFormPre.compositionList.push(row);
      this.getcheckItemstDataPreD();
    },
    godeleteCompositionList(index) {
      // if(this.patientFormPre.compositionList.length == 1) {
      //     this.$message({
      //         message: '至少保留一项数据',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.jdcompositionList = [];
      this.patientFormPre.compositionList[index].mzPrescriptionDetail = [];
      this.patientFormPre.compositionList.splice(index, 1);
    },
    getMZDiagnoseListPatientData() {
      var _this = this;
      _this.patientDomain.getMZDiagnoseListPatient(
        _this.ZCDiagnoseindex,
        function (data) {
          _this.ZCPatientList = data.data.results;
          for (var i = 0; i < _this.ZCPatientList.length; i++) {
            var f = _this.ZCPatientList[i].addTime.split("T");
            _this.ZCPatientList[i].addTime = f[0] + " " + f[1].slice(0, 8);
          }
          _this.ZCpatientTotal = data.data.dataTotal;
          _this.ZCpatientSize = data.data.pageSize;
        },
        function (error) {
          console.log(error);
          _this.ZCPatientList = [];
        }
      );
    },
    AuditCurrentChange(pageNum) {
      this.AuditcurrentPage = pageNum;
      this.auditPageIndex = pageNum;
      if (this.indexTypeDoctor == 0) {
        this.GetAuditDoctorData()
      } else {
        this.GetPlantformExpertDoctorData()
      }
      // this.GetAuditDoctorData();
    },
    AdvicCurrentChange(pageNum) {
      this.AdvicurrentPage = pageNum;
      this.AdvicePageIndex = pageNum;
      this.GetDoctorAdviceListData();
    },
    ZChandleCurrentChange(pageNum) {
      this.ZCpatientcurrentPage = pageNum;
      this.ZCDiagnoseindex = pageNum;
      this.getMZDiagnoseListPatientData();
    },
    DiagnoseImagePreview(file) {
      this.Image.dialogImageUrl = file.url;
      this.Image.dialogVisible = true;
    },
    DiagnoseImageRemove(file, fileList) {
      var _this = this;
      const filePath = file.response[0].completeFilePath;
      //2.从pics数组中找到图片对应的索引值
      //形参‘x’，是数组pic里的每一项
      const i = _this.DiagnoseContent.findIndex((x) => x.completeFilePath == filePath);
      //调用数组的splice方法，把图片的信息对象从pics数组中移除
      _this.DiagnoseContent.splice(i, 1);
      _this.patientFormDes.DiagnoseImgList = _this.DiagnoseContent;
    },
    DiagnoseImageUpload(response, file, fileList) {
      var _this = this;
      response.forEach(function (file) {
        var imgarr = {
          filePath: file.completeFilePath,
          fileName: file.fileName,
          fileHash: file.fileHash,
          fileExt: file.fileExtension,
        };

        _this.DiagnoseContent.push(imgarr);
      });
      _this.patientFormDes.DiagnoseImgList = _this.DiagnoseContent;
    },
    goShowPhoto() {
      this.ShowPhoto = !this.ShowPhoto;
    },
    goShowPhotocf() {
      this.ShowPhotocf = !this.ShowPhotocf;
    },
    goShowPhotocfCliclk(e) {
      this.fitDialogVisible = true;
      this.fitDialogVisibleImgUrl = e.filePath;
    },
    goShowtemperature() {
      this.Showtemperature = !this.Showtemperature;
    },
    InputcheckItemsNum(e) {
      e.tradingPrice = Number(e.checkItemsNum) * e.checkItemValue.tradingPrice;
      this.CheckItemValue = 0;
      this.checkItemsList.map((item, index) => {
        this.CheckItemValue += item.tradingPrice;
      });
    },
    checkItemsChange(e,index1) {
      e.tradingPrice = e.checkItemValue.tradingPrice;
      this.CheckItemValue = 0;
      this.checkItemsList.map((item, index) => {
        this.CheckItemValue += item.tradingPrice;
      });
    },
    othercheckItemsChange(e){
      e.money = e.chargeName.tradingPrice;
      e.entityId = e.chargeName.id
    },
    goaddcheckItem() {
      this.checkItemsList.push({
        itemcheckItems: "",
        checkItemsNum: 1,
        tradingPrice: 0,
      });
    },
    godetcheckItem(index) {
      this.checkItemsList.splice(index, 1);
    },
    // 稍后付费添加
    cancleCheckItemsVisible(){
      var _this = this;
        _this.checkItemsVisible = false;
        _this.checkItemsList = [
          {
            value: "",
            checkItemsNum: 1,
            tradingPrice: 0,
          },
        ];
    },
     // 付费添加后付款
    submitCheckItemsVisible() {
      var _this = this;
      if (_this.checkItemsList.length == 0) {
        _this.$message.error("请选择检查治疗项")
        return
      }
      let params1 = []
      if (_this.patientFormDes.diseaseDiagnose) {
        _this.patientFormDes.diseaseDiagnose.map((item, index) => {
          if (item.diseaseName) {
            params1.push(item.diseaseName);
          } else {
            params1.push(item);
          }
        });
        params1.toString();
      } else {
        params1 = ''
      }
      let params = {
        OrgId: _this.userInfo.organizationId,
        OrgName: _this.userInfo.organizationName,
        PatientId: _this.patientDetail.id,
        PatientName: _this.patientDetail.name,
        DoctorId: _this.userInfo.id,
        DoctorName: _this.userInfo.name,
        DepartmentId:_this.userInfo.departmentId ? _this.userInfo.departmentId : 0,
        DepartmentName:_this.userInfo.department ? _this.userInfo.department : '',
        DoctorPhone:_this.userInfo.phone,
        PatientDiagnoseId: "",
        DiagnoseResult: params1.toString(),
        OrderType: 1,
        TotalMoney: 0,
        Details: [],
      };
      for (var i = 0; i < _this.checkItemsList.length; i++) {
        params.Details.push({
          OrderType: 2,
          EntityId: _this.checkItemsList[i].checkItemValue.id,
          EntityName: _this.checkItemsList[i].checkItemValue.itemName,
          Amount: _this.checkItemsList[i].checkItemsNum,
          Unit: "次",
          Price: _this.checkItemsList[i].checkItemValue.marketPrice,
          Money: _this.checkItemsList[i].checkItemValue.tradingPrice,
          CountMoney: _this.checkItemsList[i].tradingPrice,
        });
        params.TotalMoney += _this.checkItemsList[i].tradingPrice;
      }
      _this.showCheckItemsList = _this.checkItemsList;
      if (_this.isAlready == true) {
        params.PatientDiagnoseId = _this.AlreadyId;
        _this.patientDomain.checkItemAlreadyAdd(
          params,
          function (data) {
            _this.checkItemResult = data.data;
            _this.paystep2loading = true
            setTimeout(()=>{
            _this.paystep2(_this.AlreadyId)
            _this.isCheckItem = true;
            _this.checkItemsVisible = false;
            _this.isPaystep2 = false
            _this.checkItemsList = [
              {
                value: "",
                checkItemsNum: 1,
                tradingPrice: 0,
              },
            ];
             _this.paystep2loading = false
            },1000)
      
          },
          function (error) {
            console.log(error);
          }
        );
      } else {
        _this.patientDomain.checkItemAdd(
          params,
          function (data) {
            _this.checkItemResult = data.data;
            _this.paystep2loading = true
            setTimeout(()=>{
                  _this.paystep2(_this.checkItemResult)
                  _this.isCheckItem = true;
                  _this.checkItemsVisible = false;
                  _this.isPaystep2 = false
                  _this.checkItemsList = [
                    {
                      value: "",
                      checkItemsNum: 1,
                      tradingPrice: 0,
                    },
                  ];
                  _this.paystep2loading = false
            },1000)
      
          },
          function (error) {
            console.log(error);
          }
        );
      }
    },
    goisPaystep2(){
      var _this = this
      _this.paystep2(_this.checkItemResult)
    },
    // 检查治疗项付款
    paystep2(patientDiagnoseId){
        var _this = this
        _this.drugpatientDomain.getOrderDiagnoseDetail(
          patientDiagnoseId,
          function (data) {
            _this.charge = data.data;
            _this.charge.payMoney = data.data.totalMoney;
            _this.getAllUserBalance();
          },
          function (error) {
            console.log(error);
          }
        );
        _this.paystep2Visible = true;
    },
    SaveBtn2() {
      let _this = this;
      clearInterval(_this.timer);
      clearInterval(_this.timerZ);
      let form = {
        id: _this.charge.id,
        details: _this.charge.details,
        paymentType:1,
        payments: [
          {
            discountsMoney: _this.charge.discountsMoney,
            debtUserName: _this.charge.debtUserName,
            debtUserPhone: _this.charge.debtUserPhone,
            debtMoney: _this.charge.debtMoney,
            payWay: _this.charge.payWay,
            payMoney: (_this.charge.totalMoney - _this.charge.totalPayMoney).toFixed(2),
            totalMoney: (_this.charge.totalMoney - _this.charge.totalPayMoney).toFixed(2),
          },
        ],
      };
      if (_this.charge.payWay == 0) {
        _this.outpatientAuthorizationDomain.getIsSetPayPassword(
          function (data) {
            _this.outpatientAuthorizationDomain.getIsPayPassword(
              {
                payPassword: _this.charge.payPwd,
              },
              function (data) {
                _this.OutpatientDomain.DrugPayBalancePayment(form, function (data) {
                  _this.$message({
                    type: "success",
                    message: "支付成功!",
                  });
                  _this.paystep2Visible = false;
                  _this.isPaystep2 = true
                });
              },
              function (error) {
                _this.$message({
                  type: "info",
                  message: "支付密码错误",
                });
              }
            );
          },
          function (error) {
            _this
              .$confirm("未设置支付密码？去设置", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                const href = _this.$router.resolve("../personal/resetPassword");
                window.open(href.href, "_blank");
              });
          }
        );
      } else if (_this.charge.payWay == 1) {
        _this.OutpatientDomain.DrugPayMent(
          form,
          function (data) {
            // loading.close();
            // _this.qRcodeUrl = _this.Services.Drug + data.data.qRcodeUrl;
            _this.qRcodeUrl = 'data:image/jpeg;base64,' + data.data.qRcodeBase64;
            _this.weChatInfo = data.data;
            var isSuccess = false;
            _this.timer = window.setInterval(() => {
              setTimeout(function () {
                _this.UserDistributorltemDrugMain.VerificationSuccessPay(
                  _this.weChatInfo,
                  function (data2) {
                    isSuccess = data2.data.isSuccess;
                    if (isSuccess) {
                      clearInterval(_this.timer);
                      _this.$message({
                        type: "success",
                        message: "支付成功!",
                      });
                      _this.paystep2Visible = false;
                      _this.qRcodeUrl = ''
                      _this.isPaystep2 = true
                      // _this.chargedialogVisible = false
                      // _this.$router.go(-1);
                    }
                  },
                  function (error) {
                    console.log(error);
                  }
                );
              }, 0);
            }, 5000);
            // setTimeout(() => {
            //     if (!isSuccess) {
            //         clearInterval(timer)
            //         if (paymentDialog.value) {
            //             proxy.$message({
            //                 type: 'error',
            //                 message: '支付超时!'
            //             });
            //             getList();
            //         }
            //     }
            // }, 300000)
          },
          function (error) {
            // loading.close();
            let jsonObj = JSON.parse(error.msg);
            _this.$message({
              message: jsonObj.msg,
              type: "error",
            });
            console.log(error);
          }
        );
      } else  {
        _this.OutpatientDomain.DrugPayMent(
          form,
          function (data) {
            // loading.close();
            _this.qRcodeUrlZ = data.data.alipayData;
            _this.weChatInfo = data.data;
            var isSuccess = false;
            _this.timerZ = window.setInterval(() => {
              setTimeout(function () {
                _this.UserDistributorltemDrugMain.VerificationSuccessPay(
                  // _this.weChatInfo,
                  {orderNo: _this.weChatInfo.orderNo,
                    attach: "MZPayment"
                  },
                  function (data2) {
                    isSuccess = data2.data.isSuccess;
                    if (isSuccess) {
                      clearInterval(_this.timerZ);
                      _this.$message({
                        type: "success",
                        message: "支付成功!",
                      });
                      _this.paystep2Visible = false;
                      _this.qRcodeUrlZ = ''
                      _this.isPaystep2 = true
                      // _this.chargedialogVisible = false
                      // _this.$router.go(-1);
                    }
                  },
                  function (error) {
                    console.log(error);
                  }
                );
              }, 0);
            }, 5000);
            // setTimeout(() => {
            //     if (!isSuccess) {
            //         clearInterval(timer)
            //         if (paymentDialog.value) {
            //             proxy.$message({
            //                 type: 'error',
            //                 message: '支付超时!'
            //             });
            //             getList();
            //         }
            //     }
            // }, 300000)
          },
          function (error) {
            // loading.close();
            let jsonObj = JSON.parse(error.msg);
            _this.$message({
              message: jsonObj.msg,
              type: "error",
            });
            console.log(error);
          }
        );
      }
    },
    cancleSaveBtn2() {
      var _this = this;
      _this.OutpatientDomain.cacleMZPrescriptionDetail(
        _this.charge.id,
        function (data) {
          _this.$message({
            type: "error",
            message: "取消支付!",
          });
          clearInterval(_this.timerZ);
          _this.checkItemsList = [
            {
              value: "",
              checkItemsNum: 1,
              tradingPrice: 0,
            },
          ];
          _this.showCheckItemsList=[]
          _this.paystep2Visible = false;
        },
        function (err) {
          console.log(err);
        }
      );
          //       _this.$message({
          //   type: "error",
          //   message: "取消支付!",
          // });
          // clearInterval(_this.timerZ);
          // _this.checkItemsList = [
          //   {
          //     value: "",
          //     checkItemsNum: 1,
          //     tradingPrice: 0,
          //   },
          // ];
          // _this.showCheckItemsList=[]
          // _this.paystep2Visible = false;
    },
    goCheckItems() {
      this.checkItemsVisible = true;
      this.checkItemsList = [
        {
          value: "",
          checkItemsNum: 1,
          tradingPrice: 0,
        },
      ];
      this.getcheckItemstData();
    },
    gotodayChange(e) {
      this.todayStepIndex = e;
      if (e == 2) {
        this.getMZDiagnoseListPatientData();
      } else {
        this.getTodayPatientData();
      }
    },
    handleCurrentChange(pageNum) {
      this.patientcurrentPage = pageNum;
      this.patientPageIndex = pageNum;
      this.getPatientListdata();
    },
    patientSearch() {
      this.patientPageIndex = 1;
      this.getPatientListdata();
    },
    // 其他收费项目
    GetCheckItemsTopQT() {
      var _this = this;
      _this.drugpatientDomain.GetCheckItemsTop10(
        "%20",
        "-1",
        _this.orgexpertId,
        function (data) {
          _this.MZOtherChargesOptions = data.data
        },
        function (error) {
          _this.MZOtherChargesOptions = []
        }
      );
    },
    getcheckItemstDataPreD() {
      var _this = this;
      _this.drugpatientDomain.GetCheckItemsTop10(
        "%20",
        "4",
        _this.orgexpertId,
        function (data) {
          _this.getcheckItemstDataPreK();
          _this.isSelefPriceList = data.data
          _this.patientFormPre.compositionList.map((res) => {
            res.isdeliveryPrice = data.data[0].tradingPrice;
            res.isdeliveryPriceId = data.data[0].id;
            res.isSelefPriceName = data.data[0].itemName
          });
        },
        function (error) {
          _this.getcheckItemstDataPreK();
          _this.patientFormPre.compositionList.map((res) => {
            res.isdeliveryPrice = 0
            res.isdeliveryPriceId = ''
            res.isSelefPriceName = ''
          });
        }
      );
    },
    getcheckItemstDataPreK() {
      var _this = this;
      _this.drugpatientDomain.GetCheckItemsTop10(
        "%20",
        "6",
        _this.orgexpertId,
        function (data) {
          _this.patientFormPre.isSelefPrice = data.data[0].tradingPrice;
          _this.patientFormPre.chargeName = data.data[0].itemName
          _this.isSelefName = data.data[0].itemName
          _this.isSelefId = data.data[0].id
          _this.isSelefList= data.data
        },
        function (error) {
          _this.patientFormPre.isSelefPrice = 0;
        }
      );
    },
    getcheckItemstData() {
      var _this = this;
      _this.drugpatientDomain.GetCheckItemsTop10(
        _this.checkItemsKeyWord,
        "0",
        _this.orgexpertId,
        function (data) {
          _this.checkItemsOptions = data.data;
        },
        function (error) {
          console.log(error);
          _this.checkItemsOptions = [];
        }
      );
    },
    getPatientListdata() {
      var _this = this;
      _this.patientList = [];
      // var organitionId = ''
      // if(_this.detailUserType == '4') {

      // }
      _this.patientDomain.getPatientList(
        _this.patientType,
        _this.organitionId,
        _this.userInfo.id,
        "",
        "",
        _this.patientKeyWord,
        _this.patientPageIndex,
        function (data) {
          _this.patientList = data.data.results;
          for (var i = 0; i < _this.patientList.length; i++) {
            var f = _this.patientList[i].addTime.split("T");
            _this.patientList[i].addTime = f[0] + " " + f[1].slice(0, 8);
          }
          _this.patientTotal = data.data.dataTotal;
          _this.gettWaitData();
        },
        function (error) {
          _this.gettWaitData();
          console.log(error);
        }
      );
    },
    goAddPatientSex() {
      this.patientForm.sex = !this.patientForm.sex;
    },
    getTodayPatientData() {
      var _this = this;
      _this.todayPatientList = [];
      let keyword = _this.todayPatient ? _this.todayPatient : "%20";
      _this.patientDomain.getTodayPatient(
        keyword,
        function (data) {
          _this.AllMessages();
          _this.todayPatientList = data.data;
          for (var i = 0; i < _this.todayPatientList.length; i++) {
            var f = _this.todayPatientList[i].latestTime.split("T");
            _this.todayPatientList[i].latestTime = f[1].slice(0, 8);
          }
        },
        function (error) {
          _this.AllMessages();
          console.log(error);
          _this.todayPatientList = [];
        }
      );
    },
    depvalueChange(e) {
      this.departmentId = e;
    },
    auditkeySearch() {
      if (this.indexTypeDoctor == 0) {
        this.GetAuditDoctorData()
      } else {
        this.GetPlantformExpertDoctorData()
      }
    },
    auditkeyOption() {
      var _this = this;
      _this.patientDomain.departmentDoctor(
        _this.userInfo.organizationId,
        function (data) {
          _this.depoptions = data.data;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    auditkeyOption1() {
      var _this = this;
      _this.patientDomain.departmentDoctor(
        '130',
        function (data) {
          _this.depoptions = data.data;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    auditkeyDoctor(indexType) {
      this.auditDetail = {}
      this.indexTypeDoctor = indexType
      if (indexType == 0) {
        this.GetAuditDoctorData()
        this.auditkeyOption();
      } else {
        this.auditkeyOption1();
        this.GetPlantformExpertDoctorData()
      }
    },

    GetAuditPharmacistData(){
        var _this = this;
        _this.patientDomain.GetAuditPharmacist(
        _this.auditkeyWord,
        _this.departmentId ? _this.departmentId : "0",
        _this.auditPageIndex,
        _this.indexTypeDrug.toString(),
        _this.priceKeyWordmin.toString(),
        _this.priceKeyWordmax.toString(),
        function (data) {
          let paramsrList = []
          paramsrList = data.data.results;
          let params = []
          paramsrList.map(res => {
            params.push(res.id)
          })
          _this.drugpatientDomain.GetPharmacistAuditCountData(
            params.toString(),
            function (data) {
              for (let i = 0; i < paramsrList.length; i++) {
                for (let j = 0; j < data.data.length; j++) {
                  if (paramsrList[i].id == data.data[j].userId) {
                    paramsrList[i]['auditCount'] = data.data[j].waitAuditCountPharmacist
                  }
                }
              }
              _this.drugDoctorList = paramsrList
              console.log(_this.drugDoctorList)
            },
            function (error) {
              console.log(error);
              _this.drugDoctorList = [];
            }
          );
          _this.drugTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
          // _this.auditkeyOption();
          _this.drugDoctorList = [];
        }
      );
    },
    GetAuditDoctorData() {
      var _this = this;
      _this.patientDomain.GetAuditDoctor(
        _this.auditkeyWord,
        _this.departmentId ? _this.departmentId : "0",
        _this.auditPageIndex,
        _this.indexTypeDoctor.toString(),
        _this.priceKeyWordmin.toString(),
        _this.priceKeyWordmax.toString(),
        function (data) {
          let paramsrList = []
          paramsrList = data.data.results;
          let params = []
          paramsrList.map(res => {
            params.push(res.id)
          })
          _this.drugpatientDomain.GetDoctorAuditCountData(
            params.toString(),
            function (data) {
              for (let i = 0; i < paramsrList.length; i++) {
                for (let j = 0; j < data.data.length; j++) {
                  if (paramsrList[i].id == data.data[j].userId) {
                    paramsrList[i]['auditCount'] = data.data[j].waitAuditCount
                  }
                }
              }
              _this.AuditDoctorList = paramsrList
            },
            function (error) {
              console.log(error);
              _this.AuditDoctorList = [];
            }
          );
          _this.auditTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
          // _this.auditkeyOption();
          _this.AuditDoctorList = [];
        }
      );
    },
    GetPlantformExpertDoctorData() {
      var _this = this;
      _this.patientDomain.GetPlantformExpertDoctor(
        _this.auditkeyWord,
        _this.departmentId ? _this.departmentId : "0",
        _this.auditPageIndex,
        _this.priceKeyWordmin.toString(),
        _this.priceKeyWordmax.toString(),
        function (data) {
          let paramsrList = []
          paramsrList = data.data.results;
          let params = []
          paramsrList.map(res => {
            params.push(res.id)
          })
          _this.drugpatientDomain.GetDoctorAuditCountData(
            params.toString(),
            function (data) {
              for (let i = 0; i < paramsrList.length; i++) {
                for (let j = 0; j < data.data.length; j++) {
                  if (paramsrList[i].id == data.data[j].userId) {
                    paramsrList[i]['auditCount'] = data.data[j].waitAuditCount
                  }
                }
              }
              _this.AuditDoctorList = paramsrList
            },
            function (error) {
              console.log(error);
              _this.AuditDoctorList = [];
            }
          );
          _this.auditTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
          // _this.auditkeyOption();
          _this.AuditDoctorList = [];
        }
      );
    },
    addPatientForm(formName) {
      var _this = this;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.patientForm.userId = _this.userInfo.id;
          _this.patientForm.PatientType = 1;
          if (_this.detailUserType == '4') {
              _this.patientForm.organizationId = _this.orgexpertId
              _this.patientForm.organizationName = _this.orgexpertName
          }
          _this.patientDomain.patientAdd(
            _this.patientForm,
            function (data) {
              _this.userReferralId = data.data;
              // _this.patientList = data.data.results;
              // (_this.patientForm = {
              //   name: "",
              //   age: "",
              //   phone: "",
              //   idNo: "",
              //   address: "",
              //   sex: true,
              // }),
              _this.patientPageIndex = 1;
              _this.getPatientListdata();
              _this.stepIndex = 2;
              if (_this.patientForm.diseaseName) {
                _this.patientFormDes.past = _this.patientForm.diseaseName;
              }
              _this.patientDomain.GetPatientDetail(
                data.data,
                function (data) {
                  if (data.data.patientDiagnose.length > 0) {
                    for (let i = 0; i < data.data.patientDiagnose.length; i++) {
                      let f = data.data.patientDiagnose[i].addTime.split("T");
                      data.data.patientDiagnose[i].addTime = f[0];
                    }
                  }
                  _this.patientDetail = data.data;
                  _this.getBZResultLatestData();
                },
                function (error) {
                  console.log(error);
                  _this.todayPatientList = [];
                }
              );
            },
            function (error) {
              console.log(error);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    goaddPatientForm(formName) {
      var _this = this;
      _this.$refs[formName].validate((valid) => {
        if (valid) { 
          var params = {};
          params.userId = _this.userInfo.id;
          params.PatientType = 1;
          params.name = _this.patientForm.name;
          params.age = _this.patientForm.age;
          params.phone = _this.patientForm.phone;
          params.idNo = _this.patientForm.idNo;
          params.address = _this.patientForm.address;
          params.sex = _this.patientForm.sex;
          params.name = _this.patientForm.name;
          params.diseaseName = _this.patientForm.diseaseName;
          params.id = _this.patientForm.id;
          _this.userReferralId = _this.patientForm.id;
          if (_this.detailUserType == '4') {
            params.organizationId = _this.patientForm.organizationId
          }
          _this.patientDomain.patientEdit(
            params,
            function (data) {
              _this.stepIndex = 2;
              if (_this.patientForm.diseaseName) {
                _this.patientFormDes.past = _this.patientForm.diseaseName;
              }
              _this.getBZResultLatestData();
            },
            function (error) {
              console.log(error);
            }
          );
        }
      })  

    },
    alreadySubmitForm() {
      var _this = this;
      _this.patientFormDes.PatientId = _this.patientDetail.id;
      _this.patientFormDes.height = _this.patientFormDes.height ? _this.patientFormDes.height : 0;
      _this.patientFormDes.heightTension = _this.patientFormDes.heightTension ? _this.patientFormDes.heightTension : 0;
      _this.patientFormDes.lowTension = _this.patientFormDes.lowTension ? _this.patientFormDes.lowTension : 0;
      _this.patientFormDes.breathe = _this.patientFormDes.breathe ? _this.patientFormDes.breathe : 0;
      _this.patientFormDes.pulse = _this.patientFormDes.pulse ? _this.patientFormDes.pulse : 0;
      _this.patientFormDes.temperature = _this.patientFormDes.temperature ? _this.patientFormDes.temperature : 0;
      _this.patientFormDes.weight = _this.patientFormDes.weight ? _this.patientFormDes.weight : 0;
      _this.ShowPhotocfList = _this.patientFormDes.PrescriptionImgList;
      _this.ShowPhotoList = _this.patientFormDes.DiagnoseImgList;
      let params = [];
      _this.patientFormDes.diseaseDiagnose.map((item, index) => {
        if (item.diseaseName) {
          params.push(item.diseaseName);
        } else {
          params.push(item);
        }
      });
      _this.patientFormDes.diseaseDiagnose = params.toString();
        let paramsDisease = []
        if (_this.serchdrugIdList.length > 0) {
          for (let i=0; i<_this.serchdrugIdList.length; i++) {
            if (_this.serchdrugIdList[i].syndromeId) {
              paramsDisease.push({
                type:'bianzheng',
                syndromeId:_this.serchdrugIdList[i].syndromeId,
                syndromeName:_this.serchdrugIdList[i].syndromeName,
              });
            } else {
              paramsDisease.push({
                type:'bianbing',
                syndromeId:_this.serchdrugIdList[i].id,
                syndromeName:_this.serchdrugIdList[i].syndromeName,
              });
            }
          }
        }
        if (_this.zserchdrugIdList.length > 0) {
          for (let i=0; i<_this.zserchdrugIdList.length; i++) {
            paramsDisease.push({
              type:'xuzheng',
              id:_this.zserchdrugIdList[i].questionnaireResultId ? _this.zserchdrugIdList[i].questionnaireResultId : _this.zserchdrugIdList[i].id,
              name:_this.zserchdrugIdList[i].name
            });
          }
        }
        if (_this.patientFormDes.diseaseDiagnoseHashKeyList.length > 0 && _this.patientFormDes.diseaseDiagnoseHashKeyList)  {
            if (paramsDisease.length > 0) {
                paramsDisease = paramsDisease.concat(_this.patientFormDes.diseaseDiagnoseHashKeyList)
                let params2 = [] 
                params.map((res)=>{
                  paramsDisease.map((res1=>{
                      if (res == res1.name || res == res1.syndromeName) {
                        params2.push(res1)
                      }
                  }))
                })
                // var uniqueArray = params2.filter((item, index, self) => self.findIndex((t) => t.syndromeName === item.syndromeName) === index);
                // var uniqueArray1 = uniqueArray.filter((item, index, self) => self.findIndex((t) => t.name === item.name) === index);
              _this.patientFormDes.diseaseDiagnoseHashKeyList = params2.length > 0 ? JSON.stringify(params2) : ''
            } else {
              let params2 = [] 
                params.map((res)=>{
                  _this.patientFormDes.diseaseDiagnoseHashKeyList.map((res1=>{
                      if (res == res1.name || res == res1.syndromeName) {
                        params2.push(res1)
                      }
                  }))
                })
                // var uniqueArray = params2.filter((item, index, self) => self.findIndex((t) => t.syndromeName === item.syndromeName) === index);
                // var uniqueArray1 = uniqueArray.filter((item, index, self) => self.findIndex((t) => t.name === item.name) === index);
              _this.patientFormDes.diseaseDiagnoseHashKeyList = params2.length > 0 ? JSON.stringify(params2) : ''
            }
        } else {
            if (paramsDisease.length > 0) {
                let params2 = [] 
                params.map((res)=>{
                  paramsDisease.map((res1=>{
                      if (res == res1.name || res == res1.syndromeName) {
                        params2.push(res1)
                      }
                  }))
                })
              // var uniqueArray = params2.filter((item, index, self) => self.findIndex((t) => t.syndromeName === item.syndromeName) === index);
              // var uniqueArray1 = uniqueArray.filter((item, index, self) => self.findIndex((t) => t.name === item.name) === index);
              _this.patientFormDes.diseaseDiagnoseHashKeyList = params2.length > 0 ? JSON.stringify(params2) : ''
            } else {
              _this.patientFormDes.diseaseDiagnoseHashKeyList = ''
            }
        }
      if (_this.isCheckItem) {
        _this.patientFormDes.Id = Number(_this.checkItemResult);
        _this.patientDomain.EditMZDDiagnose(
          _this.patientFormDes,
          function (data) {
            _this.AlreadyId = Number(data.data);
            _this.isAlready = true;
            // _this.$message({
            //   message: "暂存成功",
            //   type: "success",
            // });
            _this.getMZDiagnoseListPatientData();
            _this.stepIndex = 1;
             _this.reload();
          },
          function (error) {
            console.log(error);
          }
        );
      } else {
        _this.patientFormDes.diseaseDiagnose = params.toString();
        _this.patientDomain.AddMZDDiagnose(
          _this.patientFormDes,
          function (data) {
            _this.AlreadyId = data.data;
            _this.isAlready = true;
            // _this.$message({
            //   message: "暂存成功",
            //   type: "success",
            // });
            _this.isCheckItem = false;
            _this.stepIndex = 1;
             _this.reload();
          },
          function (error) {
            console.log(error);
          }
        );
      }
    },
    addSubmitForm(formName) {
      var _this = this;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.patientFormDes.PatientId = _this.patientDetail.id;
          let params = [];
          _this.patientFormDes.height = _this.patientFormDes.height ? _this.patientFormDes.height : 0;
          _this.patientFormDes.heightTension = _this.patientFormDes.heightTension ? _this.patientFormDes.heightTension : 0;
          _this.patientFormDes.lowTension = _this.patientFormDes.lowTension ? _this.patientFormDes.lowTension : 0;
          _this.patientFormDes.breathe = _this.patientFormDes.breathe ? _this.patientFormDes.breathe : 0;
          _this.patientFormDes.pulse = _this.patientFormDes.pulse ? _this.patientFormDes.pulse : 0;
          _this.patientFormDes.temperature = _this.patientFormDes.temperature ? _this.patientFormDes.temperature : 0;
          _this.patientFormDes.weight = _this.patientFormDes.weight ? _this.patientFormDes.weight : 0;
          _this.ShowPhotocfList = _this.patientFormDes.PrescriptionImgList;
          _this.ShowPhotoList = _this.patientFormDes.DiagnoseImgList;
          _this.patientFormDes.diseaseDiagnose.map((item, index) => {
          if (item.diseaseName) {
              params.push(item.diseaseName);
            } else {
              params.push(item);
            }
          });
          _this.patientFormDes.diseaseDiagnose = params.toString();
          let paramsDisease = []
          if (_this.serchdrugIdList.length > 0) {
            for (let i=0; i<_this.serchdrugIdList.length; i++) {
              if (_this.serchdrugIdList[i].syndromeId) {
                paramsDisease.push({
                  type:'bianzheng',
                  syndromeId:_this.serchdrugIdList[i].syndromeId,
                  syndromeName:_this.serchdrugIdList[i].syndromeName,
                });
              } else {
                paramsDisease.push({
                  type:'bianbing',
                  syndromeId:_this.serchdrugIdList[i].id,
                  syndromeName:_this.serchdrugIdList[i].syndromeName,
                });
              }
            }
          }
          if (_this.zserchdrugIdList.length > 0) {
            for (let i=0; i<_this.zserchdrugIdList.length; i++) {
              paramsDisease.push({
                type:'xuzheng',
                id:_this.zserchdrugIdList[i].questionnaireResultId ? _this.zserchdrugIdList[i].questionnaireResultId : _this.zserchdrugIdList[i].id,
                name:_this.zserchdrugIdList[i].name
              });
            }
          }
          if (_this.patientFormDes.diseaseDiagnoseHashKeyList != null && _this.patientFormDes.diseaseDiagnoseHashKeyList.length > 0 )  {
              if (paramsDisease.length > 0) {
                 paramsDisease = paramsDisease.concat(_this.patientFormDes.diseaseDiagnoseHashKeyList)
                  let params2 = [] 
                  params.map((res)=>{
                    paramsDisease.map((res1=>{
                        if (res == res1.name || res == res1.syndromeName) {
                          params2.push(res1)
                        }
                    }))
                  })
                  // var uniqueArray = params2.filter((item, index, self) => self.findIndex((t) => t.syndromeName === item.syndromeName) === index);
                  // var uniqueArray1 = uniqueArray.filter((item, index, self) => self.findIndex((t) => t.name === item.name) === index);
                _this.patientFormDes.diseaseDiagnoseHashKeyList = params2.length > 0 ? JSON.stringify(params2) : ''
              } else {
                let params2 = [] 
                  params.map((res)=>{
                    _this.patientFormDes.diseaseDiagnoseHashKeyList.map((res1=>{
                        if (res == res1.name || res == res1.syndromeName) {
                          params2.push(res1)
                        }
                    }))
                  })
                  // var uniqueArray = params2.filter((item, index, self) => self.findIndex((t) => t.syndromeName === item.syndromeName) === index);
                  // var uniqueArray1 = uniqueArray.filter((item, index, self) => self.findIndex((t) => t.name === item.name) === index);
                _this.patientFormDes.diseaseDiagnoseHashKeyList = params2.length > 0 ? JSON.stringify(params2) : ''
              }
          } else {
              if (paramsDisease.length > 0) {
                  let params2 = [] 
                  params.map((res)=>{
                    paramsDisease.map((res1=>{
                        if (res == res1.name || res == res1.syndromeName) {
                          params2.push(res1)
                        }
                    }))
                  })
                // var uniqueArray = params2.filter((item, index, self) => self.findIndex((t) => t.syndromeName === item.syndromeName) === index);
                // var uniqueArray1 = uniqueArray.filter((item, index, self) => self.findIndex((t) => t.name === item.name) === index);
                _this.patientFormDes.diseaseDiagnoseHashKeyList = params2.length > 0 ? JSON.stringify(params2) : ''
              } else {
                _this.patientFormDes.diseaseDiagnoseHashKeyList = ''
              }
          }
          if (_this.isCheckItem) {
            _this.patientDiagnoseId = Number(_this.checkItemResult);
            _this.patientFormDes.Id = Number(_this.checkItemResult);
            _this.patientDomain.EditMZDDiagnose(
              _this.patientFormDes,
              function (data) {
                _this.getzResultDrugsData();
                _this.stepIndex = 3;
              },
              function (error) {
                console.log(error);
              }
            );
          } else {
            _this.patientDomain.AddMZDDiagnose(
              _this.patientFormDes,
              function (data) {
                _this.patientDiagnoseId = data.data;
                _this.getzResultDrugsData();
                _this.stepIndex = 3;
              },
              function (error) {
                console.log(error);
              }
            );
          }
        } else {
          return false;
        }
      });
    },
    goNextpatientStep(item) {
      var _this = this;
      //  _this.stepIndex = 2
      _this.patientDomain.GetPatientDetail(
        item,
        function (data) {
          if (data.data.patientDiagnose.length > 0) {
            for (let i = 0; i < data.data.patientDiagnose.length; i++) {
              let f = data.data.patientDiagnose[i].addTime.split("T");
              data.data.patientDiagnose[i].addTime = f[0];
            }
          }
          _this.patientDetail = data.data;
          _this.patientForm = data.data;
          _this.visitId = _this.patientDetail.patientDiagnose[0].id
          _this.patientDomain.GetPatientType(
            _this.patientDetail.id, _this.patientDetail.patientDiagnose[0].id,
            function (data) {
              _this.visitData = data.data.patientDiagnose
              // 获取最近一次暂存列表
                _this.patientDomain.getMZDiagnosePationt(
                  _this.patientDetail.id,
                  function (data) {
                    _this.checkItemResult = data.data.id;
                    _this.patientFormDes.breathe = data.data.breathe ? data.data.breathe : 0;
                    _this.patientFormDes.family = data.data.family ? data.data.family.toString() : "";
                    _this.patientFormDes.height = data.data.height ? data.data.height : 0;
                    _this.patientFormDes.heightTension = data.data.heightTension ? data.data.heightTension : 0;
                    _this.patientFormDes.irritability = data.data.irritability ? data.data.irritability.toString() : "";
                    _this.patientFormDes.lowTension = data.data.lowTension ? data.data.lowTension : 0;
                    _this.patientFormDes.main = data.data.main ? data.data.main.toString() : "";
                    _this.patientFormDes.otherCheck = data.data.otherCheck ? data.data.otherCheck.toString() : "";
                    _this.patientFormDes.past = data.data.past ? data.data.past.toString() : "";
                    _this.patientFormDes.pulse = data.data.pulse ? data.data.pulse : 0;
                    _this.patientFormDes.temperature = data.data.temperature ? data.data.temperature : 0;
                    _this.patientFormDes.weight = data.data.weight ? data.data.weight : 0;
                    _this.patientFormDes.PatientId = data.data.patientId;
                    _this.patientFormDes.diseaseDiagnose = data.data.diseaseDiagnose != '' ? data.data.diseaseDiagnose.split(",") : '';
                    _this.patientFormDes.diseaseDiagnoseHashKeyList = data.data.diseaseDiagnoseHashKeyList != '' ? JSON.parse(data.data.diseaseDiagnoseHashKeyList) : [];
                    _this.patientFormDes.discriminatoryAnalysis = data.data.discriminatoryAnalysis ? data.data.discriminatoryAnalysis : "";
                    if (_this.patientFormDes.discriminatoryAnalysis) {
                      _this.patientFormDes.discriminatoryAnalysisList.push(data.data.discriminatoryAnalysis)
                    }
                    _this.ShowPhotocfList = data.data.prescriptionImgList;
                    _this.ShowPhotoList = data.data.diagnoseImgList;
                    // _this.stepIndex = 2;
                    _this.isCheckItem = true;
                  },
                  function (error) {

                    console.log(error);
                })
            },
            function (error) {
              console.log(error);
            })
        },
        function (error) {
          console.log(error);
          _this.patientDetail = [];
        }
      );
    },
    //详情获取
    goDoctor() {
      if (this.userInfo.isAuditDiagnose == "True") {
        this.$router.push({
          name: "PremangePatientList",
        });
      } else {
        this.$message({
          message: "暂无审方权限,请联系管理员添加",
          type: "warning",
        });
      }
      // this.doctorDialogVisible = true
      // this.GetAuditDoctorData()
    },
    goNextStep(e) {
      this.stepIndex = e;
      if (this.stepIndex == 2) {
        this.patientFormDes.diseaseDiagnose = this.patientFormDes.diseaseDiagnose.split(",");
        this.patientFormDes.diseaseDiagnoseHashKeyList = this.patientFormDes.diseaseDiagnoseHashKeyList ? JSON.parse(this.patientFormDes.diseaseDiagnoseHashKeyList) : []
      }
      this.getPatientListdata();
    },
    requiredBox() {
      this.resultDialogVisible = true;
    },
    goleft2Patient(e) {
      this.leftTable = e;
    },
    go3tabClick(e) {
      this.tab3ClickData = e;
      this.changeTable = e
      this.getResultDrugsData(e)
      this.MyCommonPrescripTableData = [];
      this.MyCommonPrescripTableDataSY = [];
      if (this.drugOrgName == "个人") {
        this.MyCommoncurrentPage = 1;
        this.MyCommonPageindex = 1;
        this.GetMyCommonPrescriptionData();
      } else {
        this.MyCommoncurrentPage = 1;
        this.MyCommonPageindex = 1;
        this.GetorgMyCommonPrescriptionData();
      }
      if (e == 1 && this.patientFormPre.CZcompositionList.length == 0) {
        let row = {
          mzPrescriptionDetail: [
            {
              drugOrgId: "",
              drugOrgName: "",
              dosageList: {
                dosage: "",
                dosageUnit: "",
              },
              defaultDosageList: {
                defaultDosage: "",
                defaultDosageUnit: "",
              },
              groupNumber: "",
              frequency: "",
              usage: "",
              remark: "",
            },
            {
              drugOrgId: "",
              drugOrgName: "",
              frequency: "",
              dosageList: {
                dosage: "",
                dosageUnit: "",
              },
              defaultDosageList: {
                defaultDosage: "",
                defaultDosageUnit: "",
              },
              groupNumber: "",
              usage: "",
              remark: "",
            },
            {
              drugOrgId: "",
              drugOrgName: "",
              frequency: "",
              dosageList: {
                dosage: "",
                dosageUnit: "",
              },
              defaultDosageList: {
                defaultDosage: "",
                defaultDosageUnit: "",
              },
              groupNumber: "",
              usage: "",
              remark: "",
            },
          ],
          prescriptionName: "",
          type: 1,
          prescriptionType: 1,
        };
        this.patientFormPre.CZcompositionList.push(row);
      }
      if (e == 2 && this.patientFormPre.SYcompositionList.length == 0) {
        let row = {
          prescriptionName: "",
          type: 2,
          prescriptionType: 1,
          zucompositionList: [
            {
              prescriptionName: "组",
              mzPrescriptionTitle: [
                {
                  usage: "",
                  frequency: "",
                  drippingSpeedList: {
                    drippingSpeed: "",
                    drippingSpeedUnit: "",
                  },
                  startTime: "",
                  days: "",
                },
              ],
              mzPrescriptionDetail: [
                {
                  drugOrgId: "",
                  drugOrgName: "",
                  st: "",
                  dosageList: {
                    dosage: "",
                    dosageUnit: "",
                  },
                  defaultDosageList: {
                    defaultDosage: "",
                    defaultDosageUnit: "",
                  },
                },
                {
                  drugOrgId: "",
                  drugOrgName: "",
                  st: "",
                  dosageList: {
                    dosage: "",
                    dosageUnit: "",
                  },
                  defaultDosageList: {
                    defaultDosage: "",
                    defaultDosageUnit: "",
                  },
                },
                {
                  drugOrgId: "",
                  drugOrgName: "",
                  st: "",
                  dosageList: {
                    dosage: "",
                    dosageUnit: "",
                  },
                  defaultDosageList: {
                    defaultDosage: "",
                    defaultDosageUnit: "",
                  },
                },
              ],
            },
          ],
        };
        this.patientFormPre.SYcompositionList.push(row);
      }
    },
    goAdress() {
      this.InitialRegion()

      // let url = this.$router.resolve({
      //   name: "adressList",
      // });
      // window.open(url.href, "_blank");
    },
    refAdress() {
      this.AllMessages();
    },
    goComDialogVisible(){
      if (!this.Agreement && !this.compatibilityResultShow) {
        this.$message.error('请了解用药提醒并勾选我已知悉')
        return
      }
      this.GetUserSign()
      this.doctorDialogProgress = 2
      this.GetAuditPharmacistData()
      this.comDialogVisible = false
      this.compatibilityResultShow = true
    },
    cancleComDialogVisible(){
      this.comDialogVisible = false
      this.compatibilityResultShow = true
    },
    goComtabClick1(){
      var _this = this
      _this.compatibilityResultShow = true
      _this.comDialogVisible = true
      _this.goComtabClick()
    },
    goComtabClick() {
      var _this = this;
      let peiwuList = [];
      console.log(_this.patientFormPre.compositionList)
      _this.patientFormPre.compositionList.forEach((item, index) => {
        item.mzPrescriptionDetail.forEach((item1, index1) => {
          if (item1.drugOrgId) {
            if (item1.materialType) {
              peiwuList.push({
                type: item1.materialType,
                // name: item1.drugOrgName,
                // id: item1.drugOrgId,
                key: item1.drugHashKey,
              });
            } else {
              peiwuList.push({
                type: "Material",
                // name: item1.drugOrgName,
                // id: item1.drugOrgId,
                key: item1.hashKey ? item1.hashKey : item1.drugHashKey,
              });
            }
          }
        });
      });
      _this.patientFormPre.CZcompositionList.forEach((item, index) => {
        item.mzPrescriptionDetail.forEach((item1, index1) => {
          if (item1.drugOrgId) {
            peiwuList.push({
              type: "Drug",
              // name: item1.drugOrgName,
              // id: item1.drugOrgId,
              key: item1.hashKey ? item1.hashKey : item1.drugHashKey,
            });
          }
        });
      });
      _this.patientFormPre.SYcompositionList.forEach((item, index) => {
        item.zucompositionList.forEach((item1, index1) => {
          item1.mzPrescriptionDetail.forEach((item2, index2) => {
            if (item2.drugOrgId) {
              peiwuList.push({
                type: "Drug",
                // name: item2.drugOrgName,
                // id: item2.drugOrgId,
                key: item2.hashKey ? item2.hashKey : item1.drugHashKey,
              });
            }
          });
        });
      });

      if (peiwuList.length > 0) {
        peiwuList.forEach((res, index) => {
          switch (res.type) {
            case "Drug": //药品
              res.type = 1;
              break;
            case "Material": //中药
              res.type = 4;
              break;
            case "HerbalPrescription": //中药
              res.type = 2;
              break;
            case "FoodMaterialPrescription": //中药
              res.type = 3;
              break;
            case "HealthyDiet": //中药
              res.type = 5;
              break;
              
            default:
              res.type = 4;
              break
          }
        });
      }
      console.log(peiwuList)
      const uniqueArray = peiwuList.filter((item, index, self) => self.findIndex((t) => t.key === item.key) === index);
      // if (uniqueArray.length > 2) {
      //     _this.compatibility(uniqueArray)
      // } else  {
      //     _this.$message({
      //         message: '配伍数量不超过两个',
      //         type: 'error'
      //     });
      // }
      _this.compatibility(uniqueArray);
    },
    go2ClickPatient() {
      console.log(123123);
    },
    goIndexmess(item, index) {
      this.messageindex = index;
      this.messagePatinet = item;
    },
    goIndex(index, name) {
      var _this = this;
      let params = {
        userId: _this.userInfo.id,
        answerUserName: _this.patientDetail.name,
        answerSex: _this.patientDetail.sex ? 0 : 1,
        answerAge: 0,
        answerPhone: _this.patientDetail.phone,
        diseaseName: _this.patientDetail.diseaseName,
      };
      if (_this.patientDetail.age < 7) {
        params.answerAge = 0;
      } else if (_this.patientDetail.age < 13) {
        params.answerAge = 1;
      } else if (_this.patientDetail.age < 18) {
        params.answerAge = 2;
      } else if (_this.patientDetail.age < 46) {
        params.answerAge = 3;
      } else if (_this.patientDetail.age < 70) {
        params.answerAge = 4;
      } else {
        params.answerAge = 5;
      }
      _this.testDomain.MyselfAnswerInfo(
        "%20",
        (res) => {
          let resultList = [];
          res.data.map((item, index) => {
            if (item.answerUserName == params.answerUserName && item.answerPhone == params.answerPhone && item.answerSex == params.answerSex && item.answerAge == params.answerAge && item.diseaseName == params.diseaseName) {
              resultList.push(res.data[index]);
            }
          });
          var seesParams = {
            answerUserName: _this.patientDetail.name,
            answerPhone: _this.patientDetail.phone,
            answerSex: params.answerSex,
            answerAge: params.answerAge,
            diseaseName: _this.patientDetail.diseaseName,
            type: "bianzhen",
          };
          window.sessionStorage.setItem("bianzhenchooseUser", JSON.stringify(seesParams));
          if (resultList.length == 0) {
            _this.testDomain.AddAnswerUser(params, (res) => {
              if (_this.ResultByUserIndex == "0") {
                let url = _this.$router.resolve({
                  name: "startTest",
                  query: {
                    titleName: name,
                  },
                });
                // window.open(url.href, "_blank");
                _this.isShowZD = true;
              } else {
                let url = _this.$router.resolve({
                  name: "startTest",
                  query: {
                    titleName: "中医虚证",
                  },
                });
                // window.open(url.href, "_blank");
                _this.isShowZD = true;
              }
            });
          } else {
            if (_this.ResultByUserIndex == "0") {
              let url = _this.$router.resolve({
                name: "startTest",
                query: {
                  titleName: name,
                },
              });
              // window.open(url.href, "_blank");
              _this.isShowZD = true;
            } else {
              let url = _this.$router.resolve({
                name: "startTest",
                query: {
                  titleName: "中医虚证",
                },
              });
              // window.open(url.href, "_blank");
              _this.isShowZD = true;
            }
          }
        },
        (error) => {
          if (error.status == "404") {
            _this.testDomain.AddAnswerUser(params, (res) => {
              if (_this.ResultByUserIndex == "0") {
                let url = _this.$router.resolve({
                  name: "startTest",
                  query: {
                    titleName: name,
                  },
                });
                // window.open(url.href, "_blank");
                _this.isShowZD = true;
              } else {
                let url = _this.$router.resolve({
                  name: "startTest",
                  query: {
                    titleName: "中医虚证",
                  },
                });
                // window.open(url.href, "_blank");
                _this.isShowZD = true;
              }
            });
          }
        }
      );
      // this.$router.push({
      // name:'index'
      // })

      let url = this.$router.resolve({
        name: "startTest",
        query: {
          titleName: name,
        },
      });
      // window.open(url.href, "_blank");
      _this.isShowZD = true;
    },
    handleCloseDra() {
      let _this = this
      _this.getBZResultLatestData();
      _this.getBBResultLatestData();
      _this.goResultByUserList(1, '中医虚证')
      _this.isShowZD = false;
    },
    rehandleCloseDra(){
      if (this.activerightName == 1) {
        document.getElementById("myIframeId").src = this.pathStr1 
      }
      else if (this.activerightName == 2) {
        document.getElementById("myIframeId").src = this.pathStr2
      }
      else {
        document.getElementById("myIframeId").src = this.pathStr3
      }
      // this.pathStr1 = window.location.origin  + '/#/zdrugconsultation/zDrugStart?ZStype=1';
      // document.getElementById("myIframeId").contentWindow.location.reload();
      

    },
    rehandleCloseDra1(){
      this.$refs.myIframe.contentWindow.window.parent.history.back();
    },
    gosearchAdress() {
      var _this = this;
      _this.shoppingMallMain.getReceivingAddressSearch(_this.searchAdressKeyWord,
        function (data) {
          if (data.data) {
            _this.MessageList = data.data;
            _this.messagePatinet = data.data[0];
          }
          _this.getDrugCompositio();
        },
        function (err) {
          _this.MessageList = [];
          _this.getDrugCompositio();
          console.log(err);
        }
      );
    },
    goIndexBZDia(){
      var _this = this
      _this.drugDialogVisible = true
      _this.categoryList = [],
      _this.categoryList1 = [],
      _this.categoryList2 = [],
      _this.DiagnosiscategoryList = [],
      _this.categoryindex = -1,
      _this.categoryindex1 = -1,
      _this.categoryindex2 = -1,
      _this.Diagnosiscategoryindex = -1,
      _this.goStart()
    },
    goIndexBBDia(){
      var _this = this
      _this.diseaseDialogVisible = true
      _this.diseasecategoryList = [],
      _this.diseasecategoryList1 = [],
      _this.diseasecategoryList2 = [],
      _this.diseaseDiagnosiscategoryList = [],
      _this.diseasecategoryindex = -1,
      _this.diseasecategoryindex1 = -1,
      _this.diseasecategoryindex2 = -1,
      _this.diseaseDiagnosiscategoryindex = -1

      if (_this.Services.ClientId == 'AssociationWeb') { 
        _this.godiseaseStartmz()
       } 
      else { _this.godiseaseStart()}
      
    },
    goIndexBB() {
      var _this = this;
      let params = {
        userId: _this.userInfo.id,
        answerUserName: _this.patientDetail.name,
        answerSex: _this.patientDetail.sex ? 0 : 1,
        answerAge: 0,
        answerPhone: _this.patientDetail.phone,
        diseaseName: _this.patientDetail.diseaseName,
      };
      if (_this.patientDetail.age < 7) {
        params.answerAge = 0;
      } else if (_this.patientDetail.age < 13) {
        params.answerAge = 1;
      } else if (_this.patientDetail.age < 18) {
        params.answerAge = 2;
      } else if (_this.patientDetail.age < 46) {
        params.answerAge = 3;
      } else if (_this.patientDetail.age < 70) {
        params.answerAge = 4;
      } else {
        params.answerAge = 5;
      }
      _this.testDomain.MyselfAnswerInfo(
        "%20",
        (res) => {
          let resultList = [];
          res.data.map((item, index) => {
            if (item.answerUserName == params.answerUserName && item.answerPhone == params.answerPhone && item.answerSex == params.answerSex && item.answerAge == params.answerAge && item.diseaseName == params.diseaseName) {
              resultList.push(res.data[index]);
            }
          });
          if (resultList.length == 0) {
            _this.testDomain.AddAnswerUser(params, (res) => {
              let url = _this.$router.resolve({
                name: "diseaseStart",
                //   query:{
                //   answerUserName: _this.patientDetail.name,
                //   answerPhone:_this.patientDetail.phone,
                //   answerSex:params.answerSex,
                //   answerAge:params.answerAge,
                //   type:'menzhen'
                // }
              });
              //     window.open(url.href, "_blank");
              _this.isShowZD = true;
            });
            var seesParams = {
              answerUserName: _this.patientDetail.name,
              answerPhone: _this.patientDetail.phone,
              answerSex: params.answerSex,
              answerAge: params.answerAge,
              diseaseName: _this.patientDetail.diseaseName,
              type: "menzhen",
            };
            window.sessionStorage.setItem("chooseUser", JSON.stringify(seesParams));
          } else {
            let url = _this.$router.resolve({
              name: "diseaseStart",
              // query:{
              //   answerUserName: _this.patientDetail.name,
              //   answerPhone:_this.patientDetail.phone,
              //   type:'menzhen'
              // }
            });
            var seesParams = {
              answerUserName: _this.patientDetail.name,
              answerPhone: _this.patientDetail.phone,
              answerSex: params.answerSex,
              answerAge: params.answerAge,
              diseaseName: _this.patientDetail.diseaseName,
              type: "menzhen",
            };
            window.sessionStorage.setItem("chooseUser", JSON.stringify(seesParams));
            // window.open(url.href, "_blank");
            _this.isShowZD = true;
          }
        },
        (error) => {
          if (error.status == "404") {
            _this.testDomain.AddAnswerUser(params, (res) => {
              let url = _this.$router.resolve({
                name: "diseaseStart",
                //   query:{
                //   answerUserName: _this.patientDetail.name,
                //   answerPhone:_this.patientDetail.phone,
                //   type:'menzhen'
                // }
              });
              // window.open(url.href, "_blank");
              _this.isShowZD = true;
            });
            var seesParams = {
              answerUserName: _this.patientDetail.name,
              answerPhone: _this.patientDetail.phone,
              answerSex: params.answerSex,
              answerAge: params.answerAge,
              diseaseName: _this.patientDetail.diseaseName,
              type: "menzhen",
            };
            window.sessionStorage.setItem("chooseUser", JSON.stringify(seesParams));
            window.open(url.href, "_blank");
          }
        }
      );
    },
    goIndexBZ() {
      var _this = this;
      let params = {
        userId: _this.userInfo.id,
        answerUserName: _this.patientDetail.name,
        answerSex: _this.patientDetail.sex ? 0 : 1,
        answerAge: 0,
        answerPhone: _this.patientDetail.phone,
        diseaseName: _this.patientDetail.diseaseName,
      };
      if (_this.patientDetail.age < 7) {
        params.answerAge = 0;
      } else if (_this.patientDetail.age < 13) {
        params.answerAge = 1;
      } else if (_this.patientDetail.age < 18) {
        params.answerAge = 2;
      } else if (_this.patientDetail.age < 46) {
        params.answerAge = 3;
      } else if (_this.patientDetail.age < 70) {
        params.answerAge = 4;
      } else {
        params.answerAge = 5;
      }
      _this.testDomain.MyselfAnswerInfo(
        "%20",
        (res) => {
          let resultList = [];
          res.data.map((item, index) => {
            if (item.answerUserName == params.answerUserName && item.answerPhone == params.answerPhone && item.answerSex == params.answerSex && item.answerAge == params.answerAge && item.diseaseName == params.diseaseName) {
              resultList.push(res.data[index]);
            }
          });
          if (resultList.length == 0) {
            _this.testDomain.AddAnswerUser(params, (res) => {
              let url = _this.$router.resolve({
                name: "zDrugStart",
                //   query:{
                //   answerUserName: _this.patientDetail.name,
                //   answerPhone:_this.patientDetail.phone,
                //   answerSex:params.answerSex,
                //   answerAge:params.answerAge,
                //   type:'menzhen'
                // }
              });
              //     window.open(url.href, "_blank");
              _this.isShowZD = true;
            });
            var seesParams = {
              answerUserName: _this.patientDetail.name,
              answerPhone: _this.patientDetail.phone,
              answerSex: params.answerSex,
              answerAge: params.answerAge,
              diseaseName: _this.patientDetail.diseaseName,
              type: "menzhen",
            };
            window.sessionStorage.setItem("chooseUser", JSON.stringify(seesParams));
          } else {
            let url = _this.$router.resolve({
              name: "zDrugStart",
              // query:{
              //   answerUserName: _this.patientDetail.name,
              //   answerPhone:_this.patientDetail.phone,
              //   type:'menzhen'
              // }
            });
            var seesParams = {
              answerUserName: _this.patientDetail.name,
              answerPhone: _this.patientDetail.phone,
              answerSex: params.answerSex,
              answerAge: params.answerAge,
              diseaseName: _this.patientDetail.diseaseName,
              type: "menzhen",
            };
            window.sessionStorage.setItem("chooseUser", JSON.stringify(seesParams));
            // window.open(url.href, "_blank");
            _this.isShowZD = true;
          }
        },
        (error) => {
          if (error.status == "404") {
            _this.testDomain.AddAnswerUser(params, (res) => {
              let url = _this.$router.resolve({
                name: "zDrugStart",
                //   query:{
                //   answerUserName: _this.patientDetail.name,
                //   answerPhone:_this.patientDetail.phone,
                //   type:'menzhen'
                // }
              });
              // window.open(url.href, "_blank");
              _this.isShowZD = true;
            });
            var seesParams = {
              answerUserName: _this.patientDetail.name,
              answerPhone: _this.patientDetail.phone,
              answerSex: params.answerSex,
              answerAge: params.answerAge,
              diseaseName: _this.patientDetail.diseaseName,
              type: "menzhen",
            };
            window.sessionStorage.setItem("chooseUser", JSON.stringify(seesParams));
            window.open(url.href, "_blank");
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.diseaseIndexBox {
  padding: 5px 10px;
  border: 1px solid #00A592;
  border-radius: 5px;
}

.diseaseIndexBoxPot {
  cursor: pointer;
}

.noImgData {
  width: 285px;
  height: 164px;
}

.noImgData img {
  width: 100%;
  height: 100%;
}

.elImagePho {
  width: 60px;
  height: 60px;
}

.elImagePho img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

::v-deep .el-drawer1 .el-drawer__close-btn .el-icon-close:before {
  content: "返回诊断" !important;
  color: #ffffff !important;
  display: block !important;
  width: 150px !important;
  height: 50px !important;
  background: #F56C6C !important;
  line-height: 50px;
  border-radius: 25px;

}

/* ::v-deep .el-form-item {
    margin-bottom: 0;
} */
.ResultLateline {
  border-bottom: 1px dashed #e6e6e6;
}

.checkItemsBox {
  margin-bottom: 10px;
}

.pageBox {
  text-align: center;
  margin-top: 5px;
}

/*滚动条样式*/
.titleTopCenterRight::-webkit-scrollbar {
  width: 4px;
}

.titleTopCenterRight::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
  background: #ccc;
}

.titleTopCenterRight::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
  border-radius: 10px;
  background: #ffffff;
}

.inteBox {
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}

.titleTop {
  padding: 0px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleTopLeft {
  width: 85%;
  display: flex;
  align-items: center;
}

.titleTopLeftImg {
  width: 123px;
  height: 24px;
}

.titleTopLeftImg img {
  width: 100%;
  height: 100%;
}

.tabBox {
  padding-left: 12%;
  margin-top: 10px;
}

.el-tabs__item {
  height: 60px;
  color: white !important;
  text-align: center;
  padding-top: 10px;
  font-size: 25px !important;
  background-color: #00afb5 !important;
  border: none !important;
}

.is-active {
  color: white !important;
  background-color: #00afb5 !important;
}

::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff !important;
}

.titleTopRight {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.titleTopRight div {
  margin-left: 10px;
}

.titleTopLine {
  width: 100%;
  height: 12px;
}

.titleTopCenter {
  width: 100%;
  /* height: 100; */
  display: flex;
}

.titleTopCenterLeft {
  width: 15%;
  background-color: #ffffff;
  /* padding-top: 30px; */
  min-height: 1080px;
  overflow: auto;
}

.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 16px auto;
  cursor: pointer;
  /* margin-top: 0; */
}

.titleTopCenterLeftBoxno {
  font-size: 18px;
  color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 16px auto;
  cursor: pointer;
  /* margin-top: 0; */
}

.adressListBoxTop {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.adressListBox {
  width: 300px;
  padding: 10px 9px 20px 15px;
  border: 2px solid #00afb5;
  color: #00afb5;
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.adressListBoxno {
  width: 300px;
  padding: 10px 9px 20px 15px;
  border: 1px solid #cccccc;
  color: #999999;
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.todayTitle {
  font-size: 16px;
  color: #06add7;
  text-align: center;
  padding-top: 14px;
  padding-bottom: 20px;
  cursor: pointer;
}

.dangeGreen {
  font-size: 14px;
  color: #00A592;
}

.todayTitleno {
  cursor: pointer;
  font-size: 16px;
  color: #cccccc;
  text-align: center;
  padding-top: 14px;
  padding-bottom: 20px;
}

.todayTitleTh1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* text-align: center; */
}

.todayTitleTh {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.todayTitleThTtitle {
  padding: 15px;
  background: #f5f7fa;
  color: #999999;
  font-size: 14px;
}

.todayTitleThDetail {
  padding: 15px;
  color: #666666;
  font-size: 16px;
  cursor: pointer;
}

.todayTitleThDetail:hover {
  background: #f5f7fa;
}

.todayTitleThDetailR {
  padding: 15px;
  color: #666666;
  font-size: 14px;
}

.todayDetail {
  display: flex;
  flex-direction: column;
}

.todayDetailR {
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow: auto;
}

.thwidth {
  width: 50%;
  text-align: center;
}

.thwidthL1 {
  width: 50%;
  text-align: left;
}

.thwidthL2 {
  width: 50%;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  /* 将超出容器宽度的部分进行隐藏 */
  white-space: nowrap;
  /* 将文字的换行模式设置为 nowrap */
}

.thwidthL {
  width: 50%;
  text-align: center;
}

.thwidthR {
  width: 25%;
  text-align: center;
}

.titleTopCenterCenter {
  width: 63%;
  border-radius: 6px;
}

.titleTopCenterCenterTop {
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
}

.sortBox {
  width: 40px;
  height: 40px;
  background: rgba(172, 209, 254, 0);
  border: 1px solid #06afd3;
  border-radius: 50%;
  font-size: 20px;
  color: #06add7;
  text-align: center;
  line-height: 40px;
}

.nosortBox {
  width: 40px;
  height: 40px;
  background: rgba(172, 209, 254, 0);
  border: 1px solid #cccccc;
  border-radius: 50%;
  font-size: 20px;
  color: #cccccc;
  text-align: center;
  line-height: 40px;
}

.sortBoximg {
  width: 180px;
  height: 30px;
  font-size: 18px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  background-image: url("../../assets/img/interrogation/numbg01.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.nosortBoximg {
  width: 180px;
  height: 30px;
  font-size: 18px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  background-image: url("../../assets/img/interrogation/nonumbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.sortBoximg img {
  width: 100%;
  height: 100%;
}

.titleTopCenterCenterContent {
  width: 100%;
  min-height: 1080px;
  margin-top: 6px;
  background: #ffffff;
  overflow-y: auto;
}

.Content1Title {
  padding: 40px 15%;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  padding-bottom: 0;
}

.ageInput {
  width: 80%;
}

.titleTopCenterRight {
  width: 20%;
  min-height: 1080px;
  max-height: 1080px;
  overflow-y: auto;
  background: #ffffff;
}

.flex-l {
  display: flex;
  align-items: center;
}

.flex-l-s {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.noflex-l-s {
  display: flex;
  justify-content: space-around;
}

.flex-l-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-l-b1 {
  display: flex;
  justify-content: space-between;
}

.typeChecked {
  font-size: 14px;
  color: #ffffff;
  padding: 0px 15px;
  text-align: center;
  background: #00afb5;
  border-radius: 6px;
  cursor: pointer;
}

.notypeChecked {
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  padding: 0px 15px;
  border-radius: 6px;
  color: #00afb5;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.titleTopCenterRightBox {
  padding: 30px;
  padding-top: 10px;
}

.doctorDialogBox {
  min-height: 300px;
  display: flex;
  justify-content: space-between;
}

.resultDialogBox {
  min-height: 300px;
}

.resultDialogtopBox {
  width: 80%;
  margin-bottom: 20px;
}

.resultInput {
  width: 50%;
}

.doctorDialogleftBox {
  width: 50%;
  padding: 0 10px;
}

.doctorDialogrightBox {
  width: 50%;
  position: relative;
}

.doctorDialogTitle {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.doctorDialogContent {
  font-size: 16px;
  color: #666666;
}

.paginationBox {
  position: absolute;
  bottom: 0;
  left: 40%;
  transform: translateX(-50%);
}

.paginationBoxR {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.stepInput {
  width: 60%;
  margin-right: 10px;
}

.inputTag {
  width: 50px;
}

.inputTag1 {
  width: 30px;
}

.avatar-uploader-icon {
  font-size: 22px;
  color: #00afb5;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #b9dcdd;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: #f3f9f9;
}

.avatar-uploader-icon:hover {
  font-size: 22px;
  color: #00afb5;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-color: #409eff;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.titleTopLeftTable {
  padding: 20px 35px;
  padding-top: 14px;
  font-size: 16px;
  border-bottom: 2px solid #e1e3e6;
}

.titleTopLeftTableC {
  color: #06add7;
  cursor: pointer;
}

.notitleTopLeftTableC {
  color: #999999;
  cursor: pointer;
}

.titleTopLeftTableTag {
  width: 10px;
  height: 10px;
  background: linear-gradient(90deg, #07abda, #01c4af);
  border-radius: 50%;
  margin-right: 5px;
}

.titleTopLeftTableTag1 {
  width: 4px;
  height: 4px;
  background: #f82a2a;
  border-radius: 50%;
  margin-right: 5px;
}

.titleTopright1 {
  font-size: 16px;
  color: #06add7;
}

.titleSize16 {
  font-size: 16px;
}

.titleSize14 {
  font-size: 14px;
}

.titleRight2Content1 {
  position: relative;
  padding: 16px;
  padding-bottom: 30px;
  background: #f4f4f4;
  border-radius: 6px;
}

.titleRight2Contentcopy {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 14px;
  color: #00afb5;
}

.titlerightBox2 {
  width: 200px;
  height: 32px;
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
}

.titleRight2title {
  width: 100px;
  height: 32px;
  background: #00afb5;
  border: 1px solid #b9dcdd;
  border-radius: 4px;
  text-align: center;
  line-height: 32px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

.notitleRight2title {
  width: 100px;
  height: 32px;
  border-radius: 4px;
  text-align: center;
  line-height: 32px;
  color: #00afb5;
  font-size: 16px;
}

.thirdrightBox {
  border-top: 1px solid #e6e6e6;
  padding: 20px;
  font-size: 16px;
}

.firstRight3Box {
  margin-top: 10px;
  border-bottom: 1px solid #e1e3e5;
  max-height: 300px;
  overflow-y: auto;
}
.firstRight3Box1 {
  margin-top: 10px;
  /* border-bottom: 1px solid #e1e3e5; */
  min-height: 300px;
}
.firstRight3Box1 div {
  cursor: pointer;
}

.firstRight3BottomBoxDiv {
  font-size: 18px;
  color: #00afb5;
  font-weight: bold;
  padding-top: 0;
  padding-bottom: 10px;
}

.firstRight3BottomBox {
  padding: 20px 0px;
}

.Content3TitleBox {}

.Content3TitleBoxRight {
  margin: 15px 0;
  background: #fff7f7;
  border: 1px solid #f4d3d3;
  border-radius: 6px;
  text-align: right;
  padding: 10px 30px;
}

.Content3TitleBoxRightSpan {
  font-size: 24px;
  color: #ee1515;
}

.tag3 {
  width: 110px;
  height: 40px;
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  border-radius: 6px;
  text-align: center;
  line-height: 40px;
  color: #00afb5;
  margin-right: 10px;
  cursor: pointer;
}

.tag3 i {
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  border: 1px dashed #00a693;
  margin-right: 5px;
}

.tag3Checked {
  width: 110px;
  height: 40px;
  background: #00afb5;
  border-radius: 6px;
  text-align: center;
  line-height: 40px;
  color: #fefefe;
  margin-right: 10px;
  cursor: pointer;
}

.tag3Checked i {
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  border: 1px dashed #ffffff;
  margin-right: 5px;
}

.table3Box {
  border: 1px solid #b9dcdd;
  border-radius: 6px;
  padding: 30px;
  margin-top: 10px;
}

.titleTopright1Line {
  border-bottom: 1px dashed #999999;
}

.hardClick {
  cursor: pointer;
}

.pei-bottom {
  background: #ffffff;
  padding: 10px;
  /* margin-top: 20px; */
}

.tab-tilte {}

.tab-tilte li {
  float: left;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 7px;
  margin-right: 15px;
}

.tab-tilte li em {
  font-style: normal;
  margin: 0 5px;
  font-size: 14px;
}

/* 点击对应的标题添加对应的背景颜色 */
.tab-tilte li.greenbg {
  border: 1px solid #1fb7a6 !important;
}

.tab-tilte li.greenbg em {
  color: #1fb7a6 !important;
}

.tab-tilte li.active {
  border: 1px solid #f46e6e;
}

.tab-tilte li.active em {
  color: #f46e6e;
}

.tab-tilte li i {
  color: #f46e6e;
}

.tab-tilte li.active span {
  color: #f46e6e;
}

.tab-tilte li.greenbg i {
  color: #1fb7a6;
}

.tab-tilte li.greenbg span {
  color: #1fb7a6;
}

.tab-content {
  margin-top: 5px;
}

.peitab-tit {
  position: relative;
  /* border-top: 1px solid #e5e5e5; */
  padding: 20px 0px;
}

.peitab-tit h3 {
  display: inline-block;
  width: 830px;
  font-weight: normal;
  color: #333333;
  font-size: 18px;
}

.peitab-tit h3 span {
  color: #e94343;
}

.peitab-tit .show-more {
  display: inline-block;
  position: absolute;
  right: 0px;
  cursor: pointer;
  font-size: 14px;
  color: #666666;
}

.peitab-mes {
  display: none;
  width: 830px;
  padding: 10px 25px;
}

.peitab-mes p {
  line-height: 30px;
  color: #666666;
}

.peitab-mes .linkDetail {
  display: inline-block;
  color: #01c2ac;
  cursor: pointer;
}

.peitab-mes .linkDetail:hover {
  text-decoration: underline;
}

.infoWarn {
  position: absolute;
  bottom: -13px;
  left: 0;
}

.infoWarn1 {
  position: absolute;
  bottom: 8px;
  right: 5px;
}
.infoWarnCom {
  position: absolute;
  bottom: -8px;
  left: 5px;
}

.drugOrgNameBox {
  height: 50px;
}

.drugOrgNameBox ::v-deep .el-select {
  /* padding-bottom: 15px; */
}

::v-deep .el-form-item {
  margin-bottom: 0px;
}

.patientDiv ::v-deep .el-form-item {
  margin-bottom: 22px;
}

.newChecked {
  color: #df7d1f;
}

.dialog-form ::v-deep .el-form-item__content {
  margin-bottom: 22px;
}

.referralMessage {
  font-size: 16px;
}

.referralMessageTop {
  display: flex;
  margin-bottom: 30px;
}

.referralMessageTopLeft {
  width: 100px;
  color: #01C2AC;
  overflow: hidden;
}

.referralMessageTopRight {
  display: flex;
  flex-wrap: wrap;
}

.referralMessageTopRight p {
  margin-right: 30px;
  min-width: 10%;
}

.referralMessageTopRight P:last-child {
  width: 50%;
}

.referralMessageSecond {
  margin-top: 20px;
}

.referralMessageTitle {
  font-weight: bold;
  margin: 10px 0px;
}

.referralMessageSpan {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  background: #01C2AC;
}

.referralMessageThreeContent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.referralMessageThreeContent p {
  width: 15%;
  overflow: hidden;
}
.referralMessageThreeContent p:nth-child(4) {
  width: 21%;
}

.referralMessageFourContent {
  display: flex;
  flex-wrap: wrap;
}

.referralMessageFourContent p {
  width: 30%;
}
	.searchbox {
		position: absolute;
		top: 10px;
		left: 10px;
		width: 100%
	}
  .allDiseaseBox{
    position: absolute;
		top: 45px;
		left: 0px;
		width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px 0px rgba(130,130,130,0.35);
    border-radius: 4px;
    border: 1px solid #B9DCDD;
    max-height: 160px;
    overflow-y: auto;
    z-index: 999;
  }
   .allDiseaseBox1{
    position: absolute;
		top: 110px;
		left: 0px;
		width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px 0px rgba(130,130,130,0.35);
    border-radius: 4px;
    border: 1px solid #B9DCDD;
    max-height: 160px;
    overflow-y: scroll;
    z-index: 999;
  }
  .allDiseaseBox1::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .allDiseaseBox1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.2);
  }
  .allDiseaseBox1::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        border-radius: 0;
        background: rgba(0,0,0,0.1);
    }
  .allDiseaseBox::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .allDiseaseBox::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.2);
  }
  .allDiseaseBox::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        border-radius: 0;
        background: rgba(0,0,0,0.1);
    }
  .allDiseaseBoxTitle {
    height: 40px;
    background: #F3F9F9;
    color: #00AFB5;
    text-align: center;
    position: relative;
  }
  .allDiseaseBoxTitleIcon {
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .allDiseaseBoxContent {
    padding: 16px 6px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
    flex-wrap: wrap;
  }
  .allDiseaseBoxContent div {
    margin-left: 5px;
    cursor: pointer;
    line-height: 1.5;
    color: #333333;
  }
  .nopay{
    color: #FF0000;
  }
  .payTrue{
    color: #00AFB5;
  }
  .dialogTrue ::v-deep .el-dialog.is-fullscreen {
     background-image: url('../../assets/img/wlbg.jpg');
    background-size: cover;
  }
  /* .lockScreenBox {
    background-image: url('../../assets/img/wlbg.jpg');
    background-size: cover;
  } */
   .flex-l-c {
    display: flex;
    align-items: center;
    flex-direction: column;
   }
   .flex-l-c img {
      width: 107px;
      height: 100px;
      border-radius: 50%;
   }
   .textareaAllDiseaseBox1 ::v-deep .el-textarea__inner {
    min-height: 105px !important;
    max-height: 105px !important;
   }
   .goodTagCheck {
  font-size: 18px;
  text-align: center;
  padding: 5px 32px;
  background-color: #ffe6cd;
  border-radius: 20px;
  margin-left: 10px;
  color: #df7d1f;
  margin-top: 10px;
  cursor: pointer;
}
   .goodTagCheckD {
  font-size: 18px;
  text-align: center;
  padding: 5px 32px;
  background-color: #00AFB5;
  border-radius: 20px;
  margin-left: 10px;
  color: #ffffff;
  margin-top: 10px;
  cursor: pointer;
}
.goodTag {
  font-size: 18px;
  text-align: center;
  padding: 5px 32px;
  background-color: #f2f2f2;
  border-radius: 20px;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.eltagre {
  display: inline-block;
  background-color: #f0f9eb;
  border-color: #e1f3d8;
  color: #67c23a;
  padding: 5px 10px;
  line-height: 1.5;
  font-size: 14px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
}
.drawer1Btn {
  position: absolute;
  top: 20px;
  right: 11%;
  content: "再次诊断";
  color: #ffffff;
  display: block;
  font-size: 20px;
  text-align: center;
  width: 150px;
  height: 50px;
  background: #01C2AC;
  line-height: 50px;
  border-radius: 25px;
  cursor: pointer;
}
.drawer2Btn {
  position: absolute;
  top: 20px;
  right: 21%;
  content: "再次诊断";
  color: #ffffff;
  display: block;
  font-size: 20px;
  text-align: center;
  width: 150px;
  height: 50px;
  background: #01C2AC;
  line-height: 50px;
  border-radius: 25px;
  cursor: pointer;
}
::v-deep .el-dialog__body {
  padding-top: 5px !important;
}
::v-deep .el-table__body tr.current-row>td.el-table__cell {
  background-color: #ebd09e61;
}
</style>
